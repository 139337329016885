/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
import { Component, ViewChild } from '@angular/core';
import { AlertController, NavParams, MenuController, Platform, IonContent, IonRouterOutlet, InfiniteScrollCustomEvent } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { Listing, CurbUser, User_global } from '../../app/app.models';
import { NetworkProvider } from '../services/network';
import { CommonProvider } from '../services/common';
import { Colors, MAX_GUEST_COUNT } from '../constants';
import { Subscription } from 'rxjs';
import { ListingDataProviderService } from '../services/listing-data-provider.service';
import { ItemEvents } from '../components/listing-item/listing-item.component';
import { environment } from '../../environments/environment';

import { NavigationExtras, Router } from '@angular/router';
import { Events } from '../services/events.service';
import { UtilsService } from '../services/utils.service';
import { I8nService } from '../services/i8nService';

import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListingService } from '../services/listing.service';
import { IntercomService } from '../services/intercom.service';
import { AppRatingService } from '../services/app-rating.service';
import { GuestService } from '../services/guest.service';
import { ConnectionsService } from '../services/connections.service';
import { Timestamp } from 'firebase/firestore';


@Component({
  selector: 'app-my-listings',
  templateUrl: 'my-listings.html',
  styleUrls: ['./my-listings.scss']
})
export class MyListingsPage {

  @ViewChild(IonContent) content: IonContent;

  public deviceIsTablet: boolean;
  public myListingsRef: any;

  public myListings = [];
  public noListing = true;
  public buttonColor: string;
  public colors: any[];
  public showSkeleton: boolean;
  public maxGuestCount = MAX_GUEST_COUNT;
  guests: any[] = [];
  public countColor: any;
  listingsLabel: string;
  showRefresh = true;
  loading = true;
  initialized = false;
  loadInterval: any;
  offlineMode = false;
  userData: CurbUser;
  triedShowingRating = false;
  paginationSize = 12;
  finalResPaginated = [];
  lenderOverride = false;

  public heading: string;
  public description: string;
  public buttonText: string;
  private listingSubs: Subscription = null;
  private userDataSub: Subscription;
  private userRole: any;

  constructor(public navCtrl: Router,
    public navParams: NavParams,
    private auth: AuthService,
    private plt: Platform,
    public alertCtrl: AlertController,
    public events: Events,
    public common: CommonProvider,
    public menu: MenuController,
    public networkProvider: NetworkProvider,
    private listingDataProvider: ListingDataProviderService,
    private utils: UtilsService,
    private routerOutlet: IonRouterOutlet,
    public i8nService: I8nService,
    private listingService: ListingService,
    private intercomService: IntercomService,
    private appRating: AppRatingService,
    private guestService: GuestService,
    private connections: ConnectionsService
  ) {
    const currentDevice: any = this.plt.platforms();
    if (currentDevice.includes('tablet')) {
      this.deviceIsTablet = true;
    } else {
      this.deviceIsTablet = false;
    }
  }


  async ionViewDidEnter() {

    this.routerOutlet.swipeGesture = false;
    this.userData = User_global;

    const currentDevice: any = this.plt.platforms();
    if (currentDevice.includes('tablet')) {
      this.deviceIsTablet = true;
    } else {
      this.deviceIsTablet = false;
    }
    this.events.publish('listing:menu');
    this.countColor = '#' + Colors[Math.floor(Math.random() * Colors.length)];
    this.heading = 'No Listings Added';
    this.description = 'Holding an Open House? Add the property here. Then use our app for paperless sign-in, text follow up and much more';
    this.buttonText = 'Add Listing';

    if (this.auth.userInitialized) {
      this.initMyListings();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initMyListings();
        }
      }, 2000);
    }


    // const userLoggedin = await this.auth.isLoggedIn();
    // let localUserData;
    // this.userDataSub = await this.auth.subscribeToUser(userLoggedin.uid).subscribe((res) => {
    //   localUserData = res;
    // });
    // const ifUserExists = setInterval(async () => {
    //   if (localUserData) {
    //     this.checkForRating(localUserData);
    //     clearInterval(ifUserExists);
    //   }
    // }, 2000);
  }

  async checkForRating(userData) {

    if (!this.networkProvider.checkInternet() || !this.plt.is('cordova')) {
      return;
    }

    try {

      const limits = await this.connections.getInviteLimits();
      //const guestCount = await this.guestService.getGuestCount();
      const guestCount = User_global.leadCount;

      const limit = limits.appReviewGuestMin ? limits.appReviewGuestMin : 15;
      console.log('review limit is: ' + limit);
      if (this.userRole === 'agent' && guestCount >= limit) {
        console.log('Checking for rating...');
        console.log('this.userData.isRatingShown:', User_global.isRatingShown);

        const mostRecentLeadTimestamp = userData.mostRecentLeadCreatedAt;

        if (mostRecentLeadTimestamp) {
          console.log('mostRecentLeadTimestamp: ', mostRecentLeadTimestamp);

          // Convert mostRecentLeadTimestamp to a JavaScript Date object
          const mostRecentLeadDate = mostRecentLeadTimestamp.toDate();

          // Calculate the timestamp for 6 months ago in milliseconds
          const sixMonthsAgoTimestampMillis = Date.now() - (6 * 30 * 24 * 60 * 60 * 1000);

          // Check if mostRecentLeadDate is within the last 6 months
          if (mostRecentLeadDate >= new Date(sixMonthsAgoTimestampMillis)) {
            // mostRecentLead is within the last 6 months

            // IF NO RATING OR RATING WAS FROM 2 YEARS AGO ASK AGAIN
            const ratingTime: any = User_global.ratingTime;  // Firebase Timestamp or string

            // Check if ratingTime is a valid Firebase Timestamp and convert to milliseconds
            let ratingTimeMillis = null;

            if (ratingTime instanceof Timestamp) {
              ratingTimeMillis = ratingTime.toDate().getTime();  // Convert to milliseconds
            } else if (typeof ratingTime === 'string') {
              ratingTimeMillis = new Date(ratingTime).getTime();  // Handle if it's stored as a string
            }

            // 24 months ago in milliseconds
            const twoYearsAgo = Date.now() - (24 * 30 * 24 * 60 * 60 * 1000);

            console.log(ratingTimeMillis + ' ' + twoYearsAgo);

            const today = new Date().getDay();

            if (!User_global.isRatingShown || !ratingTimeMillis || ratingTimeMillis < twoYearsAgo) {
              // avoid asking for ratings on busy saturdays
              if (today >= 2 && today <= 4) { // Tuesday to Thursday for best odds of good review
                console.log('Showing rating...');
                this.appRating.showRating();
              }
            }
          }
        }
      }
    }
    catch (err) {
      console.log('err in app review ' + err);
    }
  }

  ionViewWillLeave() {

    clearInterval(this.loadInterval);

    if (this.listingSubs) {
      this.listingSubs.unsubscribe();
      this.listingSubs = null;
    }
    if (this.userDataSub) {
      this.userDataSub.unsubscribe();
      this.userDataSub = null;
    }
  }

  listingsTrackByFn(index, item) {
    return item.id;
  }

  openHelp() {
    if (!this.networkProvider.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }

  initMyListings() {


    this.auth.getLenderOverride().then(res => {
      this.lenderOverride = res;
    });

    this.menu.enable(true);
    this.menu.swipeGesture(true, 'left');

    console.log('listings view did enter.');
    console.log('User_global is ' + JSON.stringify(User_global));
    try {
      this.initListingData();
    } catch (err) {
      console.log('err: ' + err);
    }
    this.userRole = User_global.role;
    /*
    if (this.myListings.length === 0) {
      this.common.startLoading();
    }
    */

    if (this.listingSubs) {
      return;
    }
    setTimeout(() => {
      try {
        // this.listingSubs = combineLatest(this.auth.userDataObserver(), this.listingDataProvider.listings(), (user, listings) => ({ user, listings })).subscribe((data) => {

        this.listingSubs = combineLatest([this.auth.userDataObserver(), this.listingDataProvider.listings()]).pipe(
          map(([user, listings]) => ({ user, listings }))).subscribe((data) => {


            if (this.networkProvider.checkInternet()) {
              this.offlineMode = false;
            }
            else {
              this.offlineMode = true;
            }

            if (data && data.user) {
              const user = data.user;
              // const listings = data.listings;

              const listings = data.listings.filter(listing => !listing.archived);

              try {
                if (listings === undefined) {
                  this.showRefresh = true;
                  this.loading = false;
                  this.common.closeLoading();
                  return;
                }
                if (listings.length !== 0) {
                  if (listings.length === 1) {
                    this.listingsLabel = `${listings.length} Listing`;
                  } else {
                    this.listingsLabel = `${listings.length} Listings`;
                  }
                  this.noListing = false;
                  this.showSkeleton = false;
                } else {
                  this.showSkeleton = true;
                }
                console.log('my listings close loading');
                //    this.common.closeLoading();

                this.myListings = listings;
                this.finalResPaginated = [];
                this.generateItems();

                this.showRefresh = false;
                this.loading = false;
              } catch (ex) {
                console.log('listing err1: ' + ex);
                this.showRefresh = true;
                this.loading = false;
                console.log('my listings close loading 2');
                this.common.closeLoading();
              }
            } else {
              console.log('listing err2');
              this.common.closeLoading();
              this.showRefresh = true;
              this.loading = false;
            }
          }, (error) => {
            console.log('listing err3');
            this.common.closeLoading();
            this.showRefresh = true;
            this.loading = false;
          });
      } catch (ex) {
        console.log('listing err4');
        this.showRefresh = true;
        this.loading = false;
        this.common.closeLoading();
        console.log(ex);
      }
    }, 250);

    //   setTimeout(() => this.cleanupPhoneNumer(), 1000);
    setTimeout(() => this.checkURLredirect(), 250);
    this.initialized = true;

    if (this.userRole === 'lender') {
      console.log('User Role', this.userRole);
      this.navCtrl.navigate(['/my-paired-listings']);
      return;
    }
    else if (this.userRole === 'agentAdmin') {
      console.log('User Role', this.userRole);
      this.navCtrl.navigate(['/my-paired-listings']);
      return;
    }

    setTimeout(() => this.checkForRating(User_global), 3000);

  }

  // cleanup old format phones in DB
  cleanupPhoneNumer() {

    if (User_global.phoneNumber[0] === '(') {
      const cleanNum = this.common.cleanPhoneNumber(User_global.phoneNumber);
      console.log('saving clean phone num: ' + cleanNum);
      this.auth.updateUserDataProperty({ phoneNumber: cleanNum });
    }
  }

  initListingData() {
    console.log('subscribe to listing data');
    this.menu.swipeGesture(true);
    this.menu.enable(true);
    this.userDataSub = this.auth.userDataObserver().subscribe((data) => {
      if (data) {
        const { primaryColor } = data;
        this.buttonColor = primaryColor;
      }
    });
  }

  // check to see if user is trying to redirect to guests page
  // used by SMS notifications for deep linking
  checkURLredirect() {

    if (this.plt.is('cordova')) {
      return;
    }

    console.log('checking url redirect my listings ' + String(window.location));

    let url = String(window.location);

    // ENABLE MICROSITE VIA WEBLINK
    const guestId = url.split('gst=')[1];

    if (guestId) {
      console.log('gst found' + guestId);
      this.navCtrl.navigate(['/my-leads']);
    }

    // check for microsite signup
    url = String(window.location);
    const micrositeSignup = url.split('variant=')[1];

    if (micrositeSignup && micrositeSignup.length > 0) {
      const variant = micrositeSignup.split('&')[0];

      if (variant && variant === 'onlylink-sign-up-a') {
        window.history.pushState('microsite', 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
        this.navCtrl.navigate(['/my-link']);
      }
    }
  }

  goEditMode(event, selectedListing: Listing, mode = 'openhouse') {
    console.log('go Edit Mode called !' + mode);
    event.stopPropagation();

    const navigationExtras: NavigationExtras = {
      queryParams: {
        //   listingLayout: selectedListing.propertyLayout,
        mode
      }
    };

    this.navCtrl.navigate(['listing-detail/' + `${selectedListing.id}`], navigationExtras);
  }

  createNewListing() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        mode: 'openhouse'
      }
    };

    this.navCtrl.navigate(['listing-detail/new'], navigationExtras);

  }

  openMyProfile() {
    console.log('open my profile');
    this.navCtrl.navigate(['my-profile']);
  }

  goToOpenHouseMode(event, listingId: string, selectedListing: Listing) {
    event.stopPropagation();
    const self = this;


    // kiosk mode is not available on mobile web
    if (!this.plt.is('cordova') && this.utils.isMobilePhone()) {
      this.networkProvider.downloadNativeApp(this.userRole);
      return;
    }

    const navigationExtras: NavigationExtras = {
      queryParams: {
        // listingId
        //   listing: selectedListing,
        //   listingLayout: selectedListing.propertyLayout
      }
    };

    if (this.plt.is('cordova')) {
      if (self.networkProvider.checkInternet() || !selectedListing.guestFollowUp) {
        this.navCtrl.navigate(['kiosk-mode/' + `${listingId}`], navigationExtras);
      } else {
        self.networkProvider.showModal(listingId, selectedListing);
      }
    }
    else {
      self.navCtrl.navigate(['kiosk-mode/' + `${listingId}`], navigationExtras);
    }
  }

  async shareListing(event, listingId: string, selectedListing: Listing) {
    if (selectedListing.slug) {
      this.common.openLink(environment.blockparty + selectedListing.slug + '/?display=sharing', '_system');
    }
    else {
      this.common.openNeedPublishedMicrositeModal()
    }
  }

  listingEvents(event) {
    if (event.event === ItemEvents.EDIT) {
      // if (!this.plt.is('cordova')) {
      //   this.goEditMode(event.ev, event.listing, 'microsite');
      // }
      //    else {
      this.goEditMode(event.ev, event.listing, 'openhouse');
      //     }
    } else if (event.event === ItemEvents.GUESTS) {
      this.goToMyGuests(event.ev, event.listing.id);
    } else if (event.event === ItemEvents.KIOSK) {
      this.goToOpenHouseMode(event.ev, event.listing.id, event.listing);
    } else if (event.event === ItemEvents.SHARE) {
      if (!event.listing.enhancedMode && !this.lenderOverride) {

        this.common.showPairedLenderError();
        return;
      }

      this.shareListing(event.ev, event.listing.id, event.listing);
    } else if (event.event === ItemEvents.SAVEQR) {
      if (!event.listing.enhancedMode && !this.lenderOverride) {

        this.common.showPairedLenderError();
        return;
      }
      this.common.showQRCode(environment, event.listing);
    } else if (event.event === ItemEvents.CREATE_MICROSITE) {
      this.goEditMode(event.ev, event.listing, 'microsite');
    } else if (event.event === ItemEvents.DELETE) {
      this.deletePrompt(event.ev, event.listing);
    }
    else if (event.event === ItemEvents.OPENLINK) {

      if (!event.listing.enhancedMode && !this.lenderOverride) {

        this.common.showPairedLenderError();
        return;
      }

      this.common.openLink(environment.blockparty + event.listing.slug);
    }
  }


  generateItems() {
    const count = this.finalResPaginated.length;

    console.log('infinite scroll called with ' + count);

    if (count >= this.myListings.length) {
      return;
    }

    for (let i = count; i < count + this.paginationSize; i++) {
      if (this.myListings[i]) {
        this.finalResPaginated.push(this.myListings[i]);
      }
    }
  }

  onIonInfinite(ev) {
    this.generateItems();
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 500);
  }

  async deletePrompt(event, listing) {

    const self = this;
    const deleteConfirmInfoModal = await this.alertCtrl.create({
      header: 'Delete Listing',
      message: 'Are you sure you want to delete this listing on Curb Hero?',
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Delete',
          handler: data => {
            console.log('Current Delete ID: ' + listing.id);

            if (!listing.sampleListing) {
              self.listingService.deleteImages(listing);
            }

            self.listingService.removeListingFromMicrosite(listing, User_global);
            self.listingService.deleteListing(listing.id).then(
              success => { },
              error => console.log(error)
            );
          }
        },
      ]
    });
    await deleteConfirmInfoModal.present();
  }

  listingClicked(event, listing) {
    // if (!this.plt.is('cordova')) {
    //   this.goEditMode(event, listing, 'microsite');
    // }
    //  else {
    this.goEditMode(event, listing, 'openhouse');
    // }
  }

  goToMyGuests(event, listingId) {
    event.stopPropagation();

    console.log('go to my guests for listing: ' + listingId);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        listingId
      }
    };

    this.navCtrl.navigate(['my-leads'], navigationExtras);
  }

  refreshPage() {
    this.common.reloadPage();
  }
}
