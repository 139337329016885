/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ImagesManagementService } from '../../services/images-management.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-profile-image-item',
  templateUrl: 'profile-image-item.html',
  styleUrls: ['profile-image-item.scss']
})
export class ProfileImageItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input('profile') profile: any;
  @Input('kioskCheck') kioskCheck: any;
  @Input('isImageService') isImageService?: boolean = true;
  hasImage = false;
  imageUrl: string;
  avatarName: string;
  loaded = false;

  constructor(
    private images: ImagesManagementService,
    private auth: AuthService,
    private utils: UtilsService
  ) {

  }

  ngOnInit() {
    if (this.profile) {
      if (this.profile.username) {
        this.profile.avatarName = this.auth.createAvatarName(this.profile.username);
      }
      if (this.profile.avatarName) {
        this.profile.color = this.utils.avatarColor(this.profile.avatarName);
      }
    }

    this.loadImage();
    console.log('imageUrl: ' + this.imageUrl);
    console.log('hasImage: ' + this.hasImage);
    console.log('kioskCheck: ' + this.kioskCheck);
    console.log('loaded: ' + this.loaded);
  }

  ngOnChanges(changes: SimpleChanges) {
    //  if (!this.imageUrl) {
    this.loadImage();
    // }
  }

  loadImage() {
    if (this.profile && this.profile.profilePhoto && this.profile.profilePhoto[0]) {
      console.log('profile item has image: ' + JSON.stringify(this.profile));
      this.hasImage = true;
      if (this.isImageService) {
        this.images.realImageData(this.profile.profilePhoto[0], false).then((data) => {
          if (data) {
            this.imageUrl = data;
            this.loaded = true;
          } else {
            this.imageUrl = null;
            this.hasImage = false;
            this.loaded = true;
          }
        }).catch((error) => {
          console.log('error in profile image item: ' + error);
          this.imageUrl = null;
          this.hasImage = false;
          this.loaded = true;
        });
      }
      else {
        this.imageUrl = this.profile.profilePhoto[0];
      }
    } else {
      console.log('profile item has no image.');

      this.hasImage = false;
      this.loaded = true;

      if (this.profile) {
        console.log('service item photo: ' + this.profile);
        if (this.profile.logoFile) {
          this.imageUrl = this.profile.logoFile;
        } else {
          this.imageUrl = this.profile.logoImage;
        }

        if (this.profile.username) {
          this.profile.avatarName = this.auth.createAvatarName(this.profile.username);
        }
        if (this.profile.avatarName) {
          this.profile.color = this.utils.avatarColor(this.profile.avatarName);
        }
      }
    }


  }
  ngOnDestroy() {

  }
}
