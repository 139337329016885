/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CompressorService {

	constructor() { }

	/**
	 * Compresses a File object to the specified width and returns a new Image object.
	 *
	 * @param file The File object to compress.
	 * @param width The desired width of the compressed image.
	 * @returns A Promise that resolves to the compressed Image object.
	 */
	compress(file: File, width: number): Promise<HTMLImageElement> {
		const self = this;
		const reader = new FileReader();
		reader.readAsDataURL(file);

		return new Promise((resolve, reject) => {
			reader.onload = (ev) => {
				console.log('file reader on load.');
				const img = new Image();
				img.src = (ev.target as any).result;

				img.onload = () => {
					const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
					const scaleFactor = width / img.width;
					elem.width = width;
					elem.height = img.height * scaleFactor;
					const ctx = elem.getContext('2d');

					if (ctx) {
						ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
						ctx.canvas.toBlob(
							(blob) => {
								const compressedFile = new File([blob], file.name, {
									type: 'image/jpeg',
									lastModified: Date.now(),
								});

								resolve(self.fileToImage(compressedFile));
							},
							'image/jpeg',
							0.8
						);
					} else {
						reject(new Error('Unable to get canvas 2D context.'));
					}
				};

				img.onerror = (error) => {
					reject(error);
				};
			};

			reader.onerror = (error) => {
				reject(error);
			};
		});
	}


	/**
	 * Compresses a data URL image to the specified width.
	 *
	 * @param imageData The data URL of the image to compress.
	 * @param width The desired width of the compressed image.
	 * @returns A Promise that resolves to the compressed data URL string.
	 */
	compressDataUrl(imageData: any, width: number, type: string = 'image/jpeg', quality: number = 1): Promise<string> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = imageData;

			// Handle successful image load
			img.onload = () => {
				const elem = document.createElement('canvas');
				const scaleFactor = width / img.width;
				const canvasWidth = width;
				const canvasHeight = img.height * scaleFactor;

				elem.width = canvasWidth;
				elem.height = canvasHeight;

				const ctx = elem.getContext('2d');
				ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

				const compressedDataUrl = ctx.canvas.toDataURL(type, quality); // Specify 'image/jpeg' as the MIME type
				resolve(compressedDataUrl);
			};

			// Handle image load error
			img.onerror = (error) => {
				reject(error);
			};
		});
	}


	/**
	 * Compresses a data URL image to the specified width and returns a new image as a Blob.
	 *
	 * @param imageData The data URL of the image to compress.
	 * @param width The desired width of the compressed image.
	 * @returns A Promise that resolves to the compressed image as a Blob object.
	 */
	compressDataUrlFile(imageData: string, width: number): Promise<Blob> {
		return new Promise((resolve, reject) => {
			console.log('compressDataUrlFile called with: ' + imageData);
			const img = new Image();
			img.src = imageData;

			// Handle successful image load
			img.onload = () => {
				console.log('Image loaded from data URL to file');
				const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
				const scaleFactor = width / img.width;
				const canvasWidth = width;
				const canvasHeight = img.height * scaleFactor;

				elem.width = canvasWidth;
				elem.height = canvasHeight;

				const ctx = elem.getContext('2d');
				if (ctx) {
					ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

					const img2 = new Image();
					img2.src = ctx.canvas.toDataURL();

					ctx.canvas.toBlob(
						blob => {
							if (blob) {
								resolve(blob);
							} else {
								reject(new Error('Unable to create Blob object.'));
							}
						},
						'image/jpeg',
						0.8,
					);
				} else {
					reject(new Error('Unable to get canvas 2D context.'));
				}
			};

			// Handle image load error
			img.onerror = error => {
				reject(error);
			};
		});
	}


	/**
	 * Converts a File object to an Image object.
	 *
	 * @param file The File object to convert.
	 * @returns A Promise that resolves to the Image object representing the file.
	 */
	fileToImage(file: File): Promise<HTMLImageElement> {
		console.log('fileToImage called.');
		const reader = new FileReader();
		const img = new Image();

		return new Promise((resolve, reject) => {
			reader.onload = (e) => {
				img.onload = () => {
					console.log('Image resolved');
					resolve(img);
				};

				img.onerror = (error) => {
					reject(error);
				};

				img.src = e.target?.result as string;
			};

			reader.onerror = (error) => {
				reject(error);
			};

			reader.readAsDataURL(file); // convert to base64 string
		});
	}
}
