import { Pipe, PipeTransform } from '@angular/core';
import { CommonProvider } from 'src/app/services/common';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
  constructor(private common: CommonProvider) { }
  transform(value: any) {
    console.log('Phone Value', value);
    value = this.common.formatPhone(value);
    return value;
  }

}
