/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable max-len */
import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  NavController, NavParams,
  ModalController, Platform, LoadingController
} from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { CommonProvider } from '../../services/common';

@Component({
  selector: 'app-paired-agent-contact-info',
  templateUrl: 'paired-agent-contact-info.html',
  styleUrls: ['paired-agent-contact-info.scss']
})

export class PairedAgentContactInfoComponent implements OnInit {

  @Input('pairedAgentData') pairedAgentData: any;
  contactData;

  public isLoading: boolean;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public plt: Platform,
    public loadingCtrl: LoadingController,
    public common: CommonProvider,
    private auth: AuthService
  ) {
    this.isLoading = true;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.modalCtrl.dismiss();
  }

  ngOnInit(): void {

    const lookupId = this.pairedAgentData.objectID || this.pairedAgentData.id;
    this.auth.contactLookup(lookupId).then(res => {
      console.log('contact Loookup response: ' + res);
      this.contactData = JSON.parse(res);
      setTimeout(() => {
        this.isLoading = false;
      }, 250);

    });
  }

  copyVal(text: string) {
    this.common.copyToClipboard(text, 'Details copied', 1000);
  }
}
