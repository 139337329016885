/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { ListingService } from './listing.service';
import { GuestService } from './guest.service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Guest, LoanOfficer, User_global } from '../app.models';

@Injectable({
  providedIn: 'root'
})
export class ListingDataProviderService {
  constructor(
    public utils: UtilsService,
    public auth: AuthService,
    public listingService: ListingService,
    public guestsService: GuestService
  ) {

  }

  updateListing(id: string, listing: any) {
    console.log(`trying to update listing: id: ${listing.id}`);
    const propertyImage = listing.propertyImage;

    // don't update the slug
    if (listing.slug) {
      delete listing.slug;
    }
    if (propertyImage && propertyImage.base64) {
      delete propertyImage.base64;
    }
    delete listing.guests;
    return this.auth.updateListing(id, listing);
  }

  listing(id: string): Promise<any> {
    return this.auth.getListingById(id);
  }

  submitGuestSurvey(survey: any, id?: any, offline?: any): Promise<any> {
    return this.auth.updateGuestSurvey(survey, id, offline,);
  }

  updateKioskTimestamp(listingId): Promise<any> {
    return this.auth.updateKioskTimestamp(listingId);
  }

  loanOfficer(id: string): Promise<LoanOfficer> {
    console.log('looking for loan officer: ' + id);
    return this.auth.getLoanOfficer(id);
  }

  loanOfficerReviews(id: string): Promise<any> {
    console.log('looking for loan officer reviews: ' + id);
    return this.auth.getLoanOfficerReviews(id);
  }

  mortgageQuestionDetails(): Promise<any> {
    console.log('looking for mortage question details');
    return this.auth.getMortgageKioskInfo();
  }

  rawListings(): Observable<any> {
    return this.listingService.listings();
  }

  autoPopulatedData(listingId): Observable<any> {
    return this.listingService.autoPopulatedFields(listingId);
  }

  data(): Observable<any> {

    return combineLatest([this.listingService.listings(), this.guestsService.guests()]).pipe(
      map(([listings, guests]) => ({ listings, guests })));

    // if (User_global.role === 'agent') {
    //   console.log('data called on listing service');
    //   return combineLatest([this.listingService.listings(), this.guestsService.guests()]).pipe(
    //     map(([listings, guests]) => ({ listings, guests })));
    // }
    // else if (User_global.role === 'lender') {
    //   console.log('data called on listing service lender');
    //   return combineLatest([this.listingService.listings(), this.guestsService.guestsLender()]).pipe(
    //     map(([listings, guests]) => ({ listings, guests })));
    // }
    // else if (User_global.role === 'agentAdmin') {
    //   console.log('data called on listing service');
    //   return combineLatest([this.listingService.listings(), this.guestsService.guests()]).pipe(
    //     map(([listings, guests]) => ({ listings, guests })));
    // }
  }

  listings(): Observable<any[]> {
    // return combineLatest([this.listingService.listings(), this.guestsService.guests()]).pipe(
    //   map(([listings, guests]) => {

    //     try {
    //       if (listings === undefined) { return undefined; }
    //       const result = [...listings];
    //       result.forEach(listing => {
    //         listing.propertyImage = listing.propertyImage || {};
    //         if (listing.propertyImage) {
    //           listing.hasPropertyImage = listing.propertyImage.imageURL || listing.propertyImage.imagePath || false;
    //         }
    //         listing.propertyLayout = listing.propertyLayout || 1;
    //         if (listing.address.title) {
    //           listing.avatarName = this.auth.createAvatarName(listing.address.title);
    //           listing.color = this.utils.avatarColor(listing.avatarName);
    //           listing.date = (new Date(listing.createdAt)).toDateString();
    //         }
    //         listing.guests = guests.filter(guest => (guest as Guest).listingId === listing.id);
    //       });
    //       return result;
    //     } catch (ex) {
    //       console.log(ex);
    //       return undefined;
    //     }
    //   }));

    if (User_global.role === 'agent' || User_global.role === 'agentAdmin') {

      return combineLatest([this.listingService.listings(), this.guestsService.guests()]).pipe(
        map(([listings, guests]) => {

          try {
            if (listings === undefined) { return undefined; }
            const result = [...listings];
            result.forEach(listing => {
              listing.propertyImage = listing.propertyImage || {};
              if (listing.propertyImage) {
                listing.hasPropertyImage = listing.propertyImage.imageURL || listing.propertyImage.imagePath || false;
              }
              listing.propertyLayout = listing.propertyLayout || 1;
              if (listing.address.title) {
                listing.avatarName = this.auth.createAvatarName(listing.address.title);
                listing.color = this.utils.avatarColor(listing.avatarName);
                listing.date = (new Date(listing.createdAt)).toDateString();
              }
              listing.guests = guests.filter(guest => (guest as Guest).listingId === listing.id);
            });
            return result;
          } catch (ex) {
            console.log(ex);
            return undefined;
          }
        }));
    }
    else if (User_global.role === 'lender') {
      return combineLatest([this.listingService.listings(), this.guestsService.guestsLender()]).pipe(
        map(([listings, guests]) => {

          try {
            if (listings === undefined) { return undefined; }
            const result = [...listings];
            result.forEach(listing => {
              listing.propertyImage = listing.propertyImage || {};
              if (listing.propertyImage) {
                listing.hasPropertyImage = listing.propertyImage.imageURL || listing.propertyImage.imagePath || false;
              }
              listing.propertyLayout = listing.propertyLayout || 1;
              if (listing.address.title) {
                listing.avatarName = this.auth.createAvatarName(listing.address.title);
                listing.color = this.utils.avatarColor(listing.avatarName);
                listing.date = (new Date(listing.createdAt)).toDateString();
              }
              listing.guests = guests.filter(guest => (guest as Guest).listingId === listing.id);
            });
            return result;
          } catch (ex) {
            console.log(ex);
            return undefined;
          }
        }));
    }
  }

  // latest 40 listings
  latestListings(): Observable<any[]> {

    return combineLatest([this.listingService.latestListings(), this.guestsService.guests()]).pipe(
      map(([listings, guests]) => {

        try {
          if (listings === undefined) { return undefined; }
          const result = [...listings];
          result.forEach(listing => {
            listing.propertyImage = listing.propertyImage || {};
            if (listing.propertyImage) {
              listing.hasPropertyImage = listing.propertyImage.imageURL || listing.propertyImage.imagePath || false;
            }
            listing.propertyLayout = listing.propertyLayout || 1;
            if (listing.address.title) {
              listing.avatarName = this.auth.createAvatarName(listing.address.title);
              listing.color = this.utils.avatarColor(listing.avatarName);
              listing.date = (new Date(listing.createdAt)).toDateString();
            }
            listing.guests = guests.filter(guest => (guest as Guest).listingId === listing.id);
          });
          return result;
        } catch (ex) {
          console.log(ex);
          return undefined;
        }

      }));
  }

  addListing(listing: any): string {

    const propertyImage = listing.propertyImage;
    if (propertyImage && propertyImage.base64) {
      delete propertyImage.base64;
    }

    delete listing.guests;

    console.log('add listing called with listing: ' + JSON.stringify(listing));
    return this.auth.addListing(listing);
  }

  deleteListing(id: string) {
    return this.auth.deleteListing(id);
  }

  getKioskOptIn(): Promise<any> {
    console.log('/BackendData/smsOptIn');
    return this.auth.getSMSOptIn();
  }
}
