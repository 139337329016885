import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class EncryptionService {

	// private secretKey = environment.encryption.secret_key;

	// constructor() { }

	// // Method to encrypt two IDs together
	// encryptIds(id1: string, id2: string): string {
	// 	const combinedIds = `${id1}-${id2}`;
	// 	const encrypted = CryptoJS.AES.encrypt(combinedIds, this.secretKey).toString();
	// 	return encrypted;
	// }

	// // Method to decrypt the encrypted string back to the original IDs
	// decryptIds(encryptedIds: string): { id1: string, id2: string } | null {
	// 	try {
	// 		const decrypted = CryptoJS.AES.decrypt(encryptedIds, this.secretKey).toString(CryptoJS.enc.Utf8);
	// 		const [id1, id2] = decrypted.split('-');
	// 		return { id1, id2 };
	// 	} catch (e) {
	// 		console.error('Decryption failed', e);
	// 		return null;
	// 	}
	// }
}
