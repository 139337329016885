import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'my-listings',
    loadChildren: () => import('./my-listings/my-listings.module').then(m => m.MyListingsPageModule)
  },
  {
    path: 'my-paired-listings',
    loadChildren: () => import('./my-paired-listings/my-paired-listings.module').then(m => m.MyPairedListingsPageModule)
  },
  {
    path: 'my-paired-lender',
    loadChildren: () => import('./my-paired-lenders/my-paired-lenders.module').then(m => m.MyPairedLendersPageModule)
  },
  {
    path: 'my-paired-agents',
    loadChildren: () => import('./my-paired-agents/my-paired-agents.module').then(m => m.MyPairedAgentsPageModule)
  },
  {
    path: 'my-leads',
    loadChildren: () => import('./my-leads/my-leads.module').then(m => m.MyLeadsPageModule)
  },
  {
    path: 'my-link',
    loadChildren: () => import('./my-microsite/my-microsite.module').then(m => m.MyMicrositePageModule)
  },
  {
    path: 'property-layout-preview/:id',
    loadChildren: () => import('./components/property-layout-preview/property-layout-preview').then(m => m.PropertyLayoutPreviewPage)
  },
  {
    path: 'kiosk-mode/:id',
    loadChildren: () => import('./kiosk-mode/kiosk-mode.module').then(m => m.KioskModePageModule)
  },
  {
    path: 'listing-detail/:id',
    loadChildren: () => import('./listing-detail/listing-detail.module').then(m => m.ListingDetailPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'download-disclosure/:id',
    loadChildren: () => import('./download-disclosure/download-disclosure.module').then(m => m.DownloadDisclosurePageModule)
  },
  // {
  //   path: 'my-lender-profile',
  //   loadChildren: () => import('./my-lender-profile/my-lender-profile.module').then(m => m.MyLenderProfilePageModule)
  // },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
