import { Component, Input, OnChanges, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-auto-populate-item',
  templateUrl: './auto-populate-item.component.html',
  styleUrls: ['./auto-populate-item.component.scss'],

  animations: [
    trigger('fadeOut', [
      state('divState', style({ opacity: 1 })),
      transition(':leave', [
        animate('0.3s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AutoPopulateItemComponent implements OnInit, OnChanges {

  @Input('message') message;
  @Input('key') key;
  @Output('select') select = new EventEmitter();
  @Output('cancel') cancel = new EventEmitter();
  isLoaded = true;
  title: any;
  emailConfig = {
    spaceBetween: 0,
    navigation: false
  };
  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
  }

  setSuggestion(value) {
    const selectedObject = { selectedValue: value, key: this.key };
    setTimeout(() => {
      this.select.emit(selectedObject);
    }, 300);
  }

  cancelSuggestion(value, index) {
    const selectedObject = { selectedValue: value, selectedIndex: index, key: this.key };
    setTimeout(() => {
      this.cancel.emit(selectedObject);
    }, 300);
  }

}
