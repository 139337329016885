
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { IOS_INTERCOM_SECRET, ANDROID_INTERCOM_SECRET, WEBAPP_INTERCOM_SECRET } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private secret: string;

  constructor(private platform: Platform) {

  }

  initSecret() {
    if (this.platform.is('ios') && this.platform.is('cordova')) {
      this.secret = IOS_INTERCOM_SECRET;
    } else if (this.platform.is('android') && this.platform.is('cordova')) {
      this.secret = ANDROID_INTERCOM_SECRET;
    } else {
      this.secret = WEBAPP_INTERCOM_SECRET;
    }
  }

  getHmacHash(property: string) {
    this.initSecret();

    const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, this.secret);
    hmac.update(property);
    const hash = hmac.finalize();

    return hash.toString(CryptoJS.enc.Hex);
  }
}
