/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { Component, Input, OnDestroy, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ImagesManagementService } from '../../services/images-management.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-guest-item',
  templateUrl: 'guest-item.component.html',
  styleUrls: ['./guest-item.component.scss']
})
export class GuestItemComponent implements OnDestroy, OnChanges {
  @Input('guest') guest: any;
  hasImage = false;
  imageUrl: string = null;
  partialLeadColor = '#ff9839';

  constructor(
    private images: ImagesManagementService,
    private utils: UtilsService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.guest.avatarImg && this.guest.avatarImg !== '') {
      this.hasImage = true;
      this.images.realImageData({ imageURL: this.guest.avatarImg, imagePath: `guest_${this.utils.hashCode(this.guest.avatarImg)}` }).then((data) => {
        if (data) {
          this.imageUrl = data;
        } else {
          this.imageUrl = null;
          this.hasImage = false;
        }
      }).catch((error) => {
        this.imageUrl = null;
        this.hasImage = false;
      });
    } else {
      this.hasImage = false;
      this.imageUrl = null;
    }

    // console.log('guest verified: ' + JSON.stringify(this.guest));
  }

  ngOnDestroy() {

  }
}
