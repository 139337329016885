import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-avatar',
  templateUrl: 'text-avatar.component.html',
  styleUrls: ['text-avatar.component.scss']
})
export class TextAvatarComponent {
  @Input() avatarColor: string;
  @Input() avatarName: string;

  constructor() { }
}
