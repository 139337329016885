import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';
import type {
  IndexWidget,
  InstantSearchOptions,
  UiState,
  Widget,
} from 'instantsearch.js';
import { environment } from '../../environments/environment';
import aa from 'search-insights';

const searchClient = algoliasearch(
  environment.algolia.appId,
  environment.algolia.apiKey
);

// Page name + (optional) current user role + role to invite
export enum AlgoliaInstanceNames {
  my_profile_invite_admin = 'my_profile_invite_admin',
  my_paired_agents_admin_invite_agent = 'my_paired_agents_admin_invite_agent',
  my_paired_agents_lender_invite_agent = 'my_paired_agents_lender_invite_agent',
  my_paired_lenders_agent_invites_lender = 'my_paired_lenders_agent_invites_lender',
  enhanced_modal_agent_invites_admin = 'enhanced_modal_agent_invites_admin',
  enhanced_modal_agent_invites_lender = 'enhanced_modal_agent_invites_lender',
}

type AlgoliaConfig = InstantSearchOptions<UiState, UiState>;

@Injectable({
  providedIn: 'root',
})
export class AlgoliaInstantSearchService {
  // Since this service is a singleton and different components might use different configurations, we need to store different instances of InstantSearch
  public instantSearchInstances: Map<AlgoliaInstanceNames, InstantSearch> =
    new Map();

  start(instanceName: AlgoliaInstanceNames, hasInsights: boolean) {
    const existingInstance = this.exists(instanceName);
    if (existingInstance) {
      console.log(
        `Skipping algolia initialization, instance ${instanceName} already exists`
      );
      // If we are reuisng the instance, we need to clear the query so that old results are not being displayed
      // @TODO: This may be an issue if two components are using the same instance in the same page
      existingInstance.helper.setQuery('');
      return existingInstance;
    }

    const newInstance = this.createInstance(instanceName, hasInsights);
    newInstance.start();

    this.instantSearchInstances.set(instanceName, newInstance);

    return newInstance;
  }

  private createInstance(
    instanceName: AlgoliaInstanceNames,
    hasInsights: boolean
  ) {
    const existingInstance = this.instantSearchInstances.get(instanceName);
    if (existingInstance) {
      throw new Error(`Instance ${instanceName} already exists`);
    }

    console.log('Creating instance', instanceName);

    if (!ALGOLIA_CONFIGS[instanceName]) {
      throw new Error(`No configuration found for instance ${instanceName}`);
    }

    const { instanceConfigs } = ALGOLIA_CONFIGS[instanceName]();

    const searchOptions = {
      searchClient,
      future: { preserveSharedStateOnUnmount: true },
      // @TODO: This option is deprecated, use `onStateChange` instead
      searchFunction: (helper) => {
        // Prevent empty searches, including the one that trigger as soon as the component loads
        if (helper.state.query === '') {
          return;
        }

        helper.search();
      },
      ...instanceConfigs,
    } as AlgoliaConfig;

    if (hasInsights) {
      aa('init', {
        appId: environment.algolia.appId,
        apiKey: environment.algolia.apiKey,
      });

      // @TODO: This is deprecated, will be removed in algolia 5
      searchOptions.insightsClient = (window as any).aa;
    }

    return new InstantSearch(searchOptions);
  }

  get(instanceName: AlgoliaInstanceNames): InstantSearch {
    if (!this.exists(instanceName)) {
      throw new Error(`Instance ${instanceName} does not exist`);
    }

    return this.instantSearchInstances.get(instanceName);
  }

  private exists(instanceName: AlgoliaInstanceNames) {
    return this.instantSearchInstances.get(instanceName);
  }

  addWidgets(
    instanceName: AlgoliaInstanceNames,
    widgets: Array<IndexWidget | Widget>
  ) {
    if (!this.exists(instanceName)) {
      throw new Error(`Instance ${instanceName} does not exist`);
    }

    this.instantSearchInstances.get(instanceName).addWidgets(widgets);
  }
}

export const ALGOLIA_CONFIGS: Record<
  AlgoliaInstanceNames,
  () => {
    refinements: string[];
    searchParameters?: Record<string, any>;
    instanceConfigs: Partial<AlgoliaConfig>;
  }
> = {
  [AlgoliaInstanceNames.my_profile_invite_admin]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';

    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agentAdmin'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.my_paired_agents_admin_invite_agent]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agent'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.my_paired_agents_lender_invite_agent]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agent'],
            hasActivePreferredLender: ['false'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.my_paired_lenders_agent_invites_lender]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod_lenders'
      : 'public_profiles_dev_lenders';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['lender'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      searchParameters: {
        filters: `(NOT regionalLender: 'true')`,
        hitsPerPage: 10,
      },
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.enhanced_modal_agent_invites_admin]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';

    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agentAdmin'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      searchParameters: {
        clickAnalytics: true,
        enablePersonalization: true,
      },
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.enhanced_modal_agent_invites_lender]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod_lenders'
      : 'public_profiles_dev_lenders';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['lender'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      searchParameters: {
        clickAnalytics: true,
        enablePersonalization: true,
      },
      instanceConfigs: config,
    };
  },
} as const;

function getRefinements(config: Partial<AlgoliaConfig>, indexName: string) {
  return Object.keys(config.initialUiState[indexName].refinementList);
}
