/* eslint-disable max-len */
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'mapUrl',
})
export class MapUrlPipe implements PipeTransform {
  transform(value: string, ...args) {
    const api = environment.maps_key;

    const encoded = encodeURIComponent(value).replace(/%20/g, '+').replace(/%2C/g, ',');
    const mapSize = '116x80';
    return `https://maps.googleapis.com/maps/api/staticmap?center=${encoded}&zoom=15&scale=2&size=${mapSize}&maptype=roadmap&key=${api}&format=png&visual_refresh=false`;
  }
}
