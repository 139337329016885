/* eslint-disable @typescript-eslint/naming-convention */
export const API = {
  users_endpoint: 'users',
  listings_endpoint: 'listings',
  guests_endpoint: 'guests',
  loanOfficers_endpoint: 'loanOfficers',
  backend_data: 'BackendData',
  public_profiles: 'public_profiles',
  connections: 'connections',
  processedData: 'processedData',
  suggestions: 'suggestions',
  listingDetails: 'listingDetails',
  featureLocks: 'featureLocks'
} as const;
