/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { Component, ViewEncapsulation } from '@angular/core';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { CurbUser, User_global } from 'src/app/app.models';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionsService } from 'src/app/services/connections.service';
import { I8nService } from 'src/app/services/i8nService';

@Component({
	selector: 'app-listing-action',
	templateUrl: 'listing-action.html',
	styleUrls: ['listing-action.scss'],
	//	encapsulation: ViewEncapsulation.ShadowDom
})

export class ListingActionPopoverComponent {

	listing: any;
	options: any;
	userId: any;
	isPaired = '';
	listingAgent: any = { id: '', username: '' };
	role;
	editListing;

	constructor(public viewCtrl: PopoverController, public navParams: NavParams,
		public alertCtrl: AlertController, public i8nService: I8nService,
		private auth: AuthService, private connectionsService: ConnectionsService) {
		this.listing = this.navParams.get('data');
		this.options = this.navParams.get('options');
		this.userId = String(this.auth.getUID());
		this.role = User_global.role;
		if ( this.role === 'lender') {
			this.isPaired = 'linkDisabled';
		}
		else if(this.role === 'agent') {
			this.isPaired = '';
		}
		else if(this.role === 'agentAdmin') {
            //agentAdmin
			this.isPaired = '';
        }
		if(this.listing.assignedByUser !== undefined){
			if(this.listing.assignedByUser[0] === this.userId.toString()){
				this.editListing = true;
			}
			else{
				this.editListing = false;
			}
		}
		else {
			this.editListing = false;
		}
	}

	edit() {
		this.viewCtrl.dismiss('edit');
	}
	launch() {
		this.viewCtrl.dismiss('launch');
	}
	share() {
		this.viewCtrl.dismiss('share');
	}
	link() {
		this.viewCtrl.dismiss('link');
	}
	save() {
		this.viewCtrl.dismiss('save');
	}
	create() {
		this.viewCtrl.dismiss('create');
	}
	delete() {
		this.viewCtrl.dismiss('delete');
	}
	sync() {
		this.viewCtrl.dismiss('sync');
	}
	copy() {
		this.viewCtrl.dismiss('copy');
	}
	view() {
		this.viewCtrl.dismiss('view');
	}
	close() {
		this.viewCtrl.dismiss('close');
	}
	restart() {
		this.viewCtrl.dismiss('restart');
	}
	addNotePrev() {
		this.viewCtrl.dismiss('addNotePrev');
	}
	skip() {
		this.viewCtrl.dismiss('skip');
	}
	shareMicrosite() {
		this.viewCtrl.dismiss('shareMicrosite');
	}
	howDoIuseMyOnlylink() {
		this.viewCtrl.dismiss('howDoIuseMyOnlylink');
	}
	whatIsAMicrosite() {
		this.viewCtrl.dismiss('whatIsAMicrosite');
	}

	async actionDisabled() {
		const myAlert = await this.alertCtrl.create({
			header: 'Disabled Action',
			message: this.i8nService.messages.disabledActionMessage,
			buttons: [
				{
					role: 'cancel',
					text: 'OK',
					handler: data => {
						console.log('Cancel clicked');
					}
				},
			]
		});
		myAlert.present();
	}
}
