/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */
import { Component } from '@angular/core';
import {
  NavParams,
  ModalController,
  AlertController,
  ActionSheetController,
  LoadingController,
  MenuController
} from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { CurbUser, User_global } from '../../app/app.models';
// import { CameraProvider } from '../services/camera';
import { CommonProvider } from './../services/common';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConnectionsService } from '../services/connections.service';
import { NetworkProvider } from '../services/network';
import { SMSService } from '../services/sms.service';
import { Router } from '@angular/router';
import { EditInputPage } from '../components/edit-input/edit-input';
import algoliasearch from 'algoliasearch/lite';
import aa from 'search-insights';
import { LocationService } from '../services/location.service';

const searchClient = algoliasearch(
  'N26A8PC608',
  '068c90fcb4bdad125a840343a2725949'
);

aa('init', {
  appId: 'N26A8PC608',
  apiKey: '068c90fcb4bdad125a840343a2725949'
});

declare let google;

@Component({
  selector: 'app-my-paired-lenders',
  templateUrl: 'my-paired-lenders.html',
  styleUrls: ['./my-paired-lenders.scss']
})
export class MyPairedLendersPage {

  userData: CurbUser;
  env: any = {};
  userSubs: any;
  myLendersSubs: any = {};
  myPairedLenders: any = [];
  myPairedLendersInvites: any = [];
  uid: string;
  myAdminInvites: any = [];

  webhookSent = false;
  inviteEmail = '';
  showLenders = false;
  loading = true;
  alreadyConnected = false;
  initialized = false;
  loadInterval: any;
  userRole: string;
  currentLocationView = '';  //searchZipCodeView, enterZipCodeView, currentZipCodeView
  recommendSearchParams = {};
  geoLocationCordinates;
  geoZipcode;
  radiusSearch = 100;
  showRecommendedFeature = false;
  recommendedLendersPerPage = 6;
  recommendedLenderConfig;
  offlineMode = false;
  algoliaLoading = false;

  userZipcode = '';
  userCountry = '';

  constructor(
    public navCtrl: Router,
    public navParams: NavParams,
    private auth: AuthService,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public actionSheetCtrl: ActionSheetController,
    public modalCtrl: ModalController,
    public common: CommonProvider,
    // public cameraProvider: CameraProvider,
    private userConnectionsService: ConnectionsService,
    private network: NetworkProvider,
    private messageService: SMSService,
    private menu: MenuController,
    private locationService: LocationService
  ) {

  }

  async ionViewDidEnter() {
    this.userCountry = (await this.locationService.getCurrentLocation()).country_code;

    console.log('paired lenders page enter');
    this.loading = true;
    this.userData = User_global;
    this.env = environment;

    if (this.auth.userInitialized) {
      this.initPairedLenders();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initPairedLenders();
        }
      }, 2000);
    }
  }

  initPairedLenders() {

    this.uid = this.auth.getUID();
    this.menu.enable(true);
    this.menu.swipeGesture(true, 'left');

    const self = this;
    this.common.closeLoading();
    // this.userSubs = combineLatest(this.auth.userDataObserver(), (user) => ({ user })).subscribe((data) => {

    this.userSubs = combineLatest([this.auth.userDataObserver()]).pipe(
      map(([user]) => ({ user }))).subscribe((data) => {

        if (this.network.checkInternet()) {
          this.offlineMode = false;
        }
        else {
          this.offlineMode = true;
        }

        if (data && data.user) {
          console.log('user data subscribe: ' + JSON.stringify(data));
          self.userData = data.user;


          if (data.user.address && data.user.address.zip !== this.userZipcode) {
            this.userZipcode = data.user.address.zip;

            if (data.user.address.zip) {
              this.userZipcode = data.user.address.zip;
              this.currentLocationView = 'currentZipCodeView'; //searchZipCodeView, enterZipCodeView, currentZipCodeView

              this.auth.restrictedRecommendStates().then(res => {
                console.log('restrict states :' + JSON.stringify(res.states));
                this.auth.restrictedRecommendStatesArray = res.states;
                this.searchRecommendedLenders();
              });
            }
            else if (!User_global.address || !User_global.address.zip) {
              this.currentLocationView = 'enterZipCodeView';
              this.auth.restrictedRecommendStates().then(res => {
                this.auth.restrictedRecommendStatesArray = res.states;
              });
            }
          }
          else if (!data.user.address || !data.user.address.zip) {
            this.currentLocationView = 'enterZipCodeView';
            this.auth.restrictedRecommendStates().then(res => {
              this.auth.restrictedRecommendStatesArray = res.states;
            });
          }
        }

      });

    this.myLendersSubs = this.userConnectionsService.connections().subscribe((data) => {
      console.log('my paired lenders data: ' + JSON.stringify(data));

      if (data) {


        self.myAdminInvites = data.filter(agent => agent.status === 'invited' && agent.role === 'agentAdmin');


        self.myPairedLenders = data.filter(agent => agent.status === 'active' && !agent.regionalLender);
        self.myPairedLendersInvites = data.filter(agent =>
          (agent.status === 'invited'
            || agent.status === 'lender_invited_agent'
            || agent.status === 'agent_invited_lender')
          && !agent.regionalLender
          && agent.role !== 'agentAdmin'
        );

        self.myPairedLendersInvites.sort(function (a, b) {
          return a.status === 'agent_invited_lender'
            && b.status === 'lender_invited_agent' ? 1 : -1;
        });

        self.myPairedLendersInvites.sort(function (a, b) {
          return a.status === 'invited'
            && b.status === 'agent_invited_lender' ? 1 : -1;
        });

        self.myPairedLendersInvites.sort(function (a, b) {
          return a.status === 'invited'
            && b.status === 'lender_invited_agent' ? 1 : -1;
        });

        if (self.myPairedLenders.length > 0) {
          self.showLenders = true;
        }
        else {
          self.showLenders = false;
        }

        // find out if already connected
        if (self.myPairedLenders.length > 0 && self.myPairedLendersInvites.length > 0) {
          self.alreadyConnected = true;
        }
        else {
          self.alreadyConnected = false;
          // this.currentLocationView = 'enterZipCodeView';
        }
      }
      else {
        //    this.currentLocationView = 'enterZipCodeView';
      }
    });

    setTimeout(() => { this.common.closeLoading(); this.loading = false; }, 1000);

    this.initialized = true;
    this.userRole = User_global.role;
    if (this.userRole !== 'agent') {
      console.log('User Role', this.userRole);
      this.navCtrl.navigate(['/my-paired-agents']);
      return;
    }

    aa('setUserToken', this.auth.getUID());
    // PROD ALGOLIA IS DIFF
    if (environment.production) {

      this.recommendedLenderConfig = {
        indexName: 'public_profiles_prod_lenders',
        searchClient,
        insightsClient: (window as any).aa,
        initialUiState: {
          public_profiles_prod_lenders: {
            refinementList: {
              'role': ['lender'],
              'enableLenderCommunication': ['true'],
              'subscriptionStatus': ['active']
            }
          }
        }
      };
    }

    // DEV INDEX NAMES
    else if (!environment.production) {

      this.recommendedLenderConfig = {
        indexName: 'public_profiles_dev_lenders',
        searchClient,
        insightsClient: (window as any).aa,
        initialUiState: {
          public_profiles_dev_lenders: {
            refinementList: {
              'role': ['lender'],
              'enableLenderCommunication': ['true'],
              'subscriptionStatus': ['active'],
            }
          }
        }
      };
    };
  }

  ionViewWillLeave() {

    clearInterval(this.loadInterval);

    console.log('unsubscribe paired lender');
    if (this.userSubs) {
      this.userSubs.unsubscribe();
      this.userSubs = null;
    }
  }

  openMyProfile() {
    console.log('open my profile');
    this.navCtrl.navigate(['/my-profile']);
  }

  openHelp() {
    if (!this.network.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }

  sendEmailWebhook() {

    this.webhookSent = true;

    this.messageService.sendEmailWebhook(this.inviteEmail).then(res => {

      if (res) {
        this.inviteEmail = '';
        this.common.toast('Email sent to your lender!');
      }
      else {
        this.auth.logErrors('Error inviting lender 3: ' + this.inviteEmail);
        this.common.showDBWriteError();
      }
    }, (err) => {
      console.log('webhook error : ' + err);
      this.auth.logErrors('Error inviting lender 5: ' + this.inviteEmail + ' error: ' + JSON.stringify(err));
      this.common.showDBWriteError();
    });
  }

  copyReferral() {
    this.common.copyToClipboard(environment.referralURL + this.userData.referralCode);
  }

  learnMore(val) {
    this.common.openLink('https://juuj.me/real-estate-agents-invite-lender');
  }

  async enterLocation() {

    if (this.algoliaLoading) {
      return;
    }
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    const inputModal = await this.modalCtrl.create({
      component: EditInputPage, componentProps: {
        displayCountryPicker: true,
        selectedCountry: this.userCountry,
        label: 'Location',
        data: this.userZipcode,
        placeholder: 'Zip/Postal Code or Similar Location',
        helperText: 'Zip code or postal codes are strongly recommended. If entering a city name, also include state or region (ex: Beverly Hills, California).',
        //  limit: 5,
        //  numericInput: true
      }
    });

    await inputModal.present();
    const { data } = await inputModal.onWillDismiss() as { data: { country: string, zipOrAddress: string }};

    console.log('dismiss ' + data);
    if (data === undefined || data === null) {
      return;
    }

    // When we opened the modal, we passed the current location, but the user may have changed it from the country picker
    this.userCountry = data.country;
    this.userZipcode = data.zipOrAddress;

    this.searchRecommendedLenders(true); // force geo change
  }

  async searchRecommendedLenders(forceReload = false) {
    if (!this.userZipcode) {
      return;
    }

    this.algoliaLoading = true;
    const result = await this.locationService.getCoordsFromAddressOrZip(this.userZipcode, this.userCountry, this.userData, forceReload);
    this.algoliaLoading = false

    if (result.length > 1) {
      let customFilter = '';
      const restrictedStates = this.auth.restrictedRecommendStatesArray;

      for (let i = 0; i < restrictedStates.length; i++) {
        console.log(restrictedStates[i]);
        customFilter += `(NOT locations.lvl0: ${restrictedStates[i]})`;

        if (i !== restrictedStates.length - 1) {
          customFilter += ' AND ';
        }
      }

      customFilter += ` AND (NOT regionalLender: 'true')`; // no regional lenders  

      this.recommendSearchParams = {
        aroundLatLng: result[0] + ',' + result[1],
        aroundRadius: this.radiusSearch * 1000, // in multipes of 1 km
        hitsPerPage: this.recommendedLendersPerPage,
        filters: customFilter
      };
    }
    
    this.showRecommendedFeature = true;
    this.currentLocationView = 'currentZipCodeView';
  }

  notInInviteList(id) {
    // console.log('not in invite list: ' + this.myPairedLendersInvites.find(x => x.id === id));
    return !this.myPairedLendersInvites.find(x => x.id === id) && !this.myPairedLenders.find(x => x.id === id);
  }
}
