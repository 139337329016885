/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ImagesManagementService } from '../../services/images-management.service';
import { ListingImage, Listing, User_global } from '../../app.models';
import { UtilsService } from '../../services/utils.service';
import { Router } from '@angular/router';
import { MAX_LISTING_PHOTOS } from '../../constants';
import { I8nService } from 'src/app/services/i8nService';

/**
 * Generated class for the CustomScreensInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-custom-screens-input',
  templateUrl: 'custom-screens-input.component.html',
  styleUrls: ['custom-screens-input.component.scss']
})
export class CustomScreensInputComponent implements OnInit, OnDestroy, OnChanges {

  @Input('property') property: ListingImage;
  @Input('currentListing') currentListing: Listing;
  @Input('suggestedlistingImages') suggestedlistingImages: ListingImage[];
  @Output('clicked') clicked = new EventEmitter<string>();
  @Output('deleted') deleted = new EventEmitter<string>();
  @Output('webimage') webimage = new EventEmitter<any>();
  @Output('makeDefault') makeDefault = new EventEmitter<any>();
  @Output('selectImageEvent') selectImageEvent = new EventEmitter();
  @Output('cancelImageEvent') cancelImageEvent = new EventEmitter();

  resizeSubs: Subscription;
  propertyData: any = '';
  deviceAspectRatio: number;
  imageAspectRatio: number;
  isWebApp: boolean;
  currentPhoto: any = {};
  role = 'agent';
  maxPhotos = MAX_LISTING_PHOTOS;

  newImage = {
    id: '',
    base64: '',
    imagePath: '',
    imageURL: ''
  };

  constructor(
    private navCtrl: Router,
    private platform: Platform,
    private imageService: ImagesManagementService,
    private utils: UtilsService,
    public alertCtrl: AlertController,
    public i8nService: I8nService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    this.role = User_global.role;

    console.log('ng on custom screen changes');
    if (this.property && (this.property.imageURL || this.property.imagePath)) {
      this.imageService.realImageData(this.property).then((data) => {
        this.imageService.aspectRatio(data).then((ar) => {
          this.imageAspectRatio = ar;
        });
        this.propertyData = data;
      });

    } else {
      this.imageAspectRatio = null;
      this.propertyData = '';
    }

    console.log('suggested images are: ', this.suggestedlistingImages);
  }

  ngOnInit() {

    if (!this.suggestedlistingImages) {
      this.suggestedlistingImages = [];
    }

    console.log('suggested images are: ', this.suggestedlistingImages);
    console.log('custom screens input ngOnInit');

    console.log('current listing: ' + JSON.stringify(this.currentListing));
    this.isWebApp = !this.platform.is('cordova');
    this.resizeSubs = this.platform.resize.asObservable().subscribe(() => {
      this.handleResize();
    });
    this.handleResize();
  }

  handleResize() {
    this.deviceAspectRatio = this.utils.deviceAspectRatio();
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.imageURL;
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    if (this.resizeSubs && !this.resizeSubs.closed) {
      this.resizeSubs.unsubscribe();
    }
  }

  addImage() {
    try {
      if (this.isWebApp) {
        if (this.currentListing.startingMode &&
          this.currentListing.startingMode === 'marketing-services') {
          this.actionDisabled();
        }
        else {
          // document.getElementById('fileInput').click();
          this.clicked.emit('');
        }
      }
      else {
        this.clicked.emit('');
      }
    }
    catch (err) {
      console.log('error in image add ' + err);
      this.clicked.emit('');
    }
  }

  // onEdit(photo) {
  //   this.currentPhoto = photo;
  //   console.log('photoId clicked ' + photo.id);
  //   if (this.isWebApp) {
  //     document.getElementById('curbHeroListingFileInput').click();
  //   }
  //   else {
  //     this.clicked.emit(photo);
  //   }
  // }

  async makeImageDefault(photo) {
    console.log('makeDefault custom screens input');
    const self = this;

    const myAlert = await this.alertCtrl.create({
      header: 'Set Default Image',
      message: 'Are you sure you want to make this image the default image?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Set as Default',
          role: 'OK',
          handler: data => {
            self.makeDefault.emit(photo);
          }
        }

      ]
    });

    await myAlert.present();
  }

  onFileChanged(event) {

    if (!event.target.files[0]) {
      return;
    }

    if (!this.currentPhoto.id) {
      this.currentPhoto.id = '';
    }

    event.currentPhotoId = this.currentPhoto.id;
    // console.log('on file changed ' + URL.createObjectURL(event.target.files[0]));

    // get the file size in standard format
    console.log('File size is: ' + event.target.files[0].size);

    this.webimage.emit(event);

    /*
    this.propertyData = event.target.files[0];
    const doka = Doka.create();

    doka.edit(event.target.files[0])
      .then(output => {
        console.log('Done editing image', output);
        self.propertyData = output;
        self.imageService.selectPhotoWeb(output);
        this.clicked.emit(true);
      });
      */
  }

  onDelete(photo) {
    // this.imageAspectRatio = null;
    this.deleted.emit(photo);
    this.propertyData = null;

  }

  openMyProfile() {
    this.navCtrl.navigate(['/my-profile']);
  }

  async actionDisabled() {
    const myAlert = await this.alertCtrl.create({
      header: 'Disabled Action',
      message: this.i8nService.messages.disabledActionMessage,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
      ]
    });
    myAlert.present();
  }

  cancelImage(event) {
    const selectedObject = { selectedValue: event.selectedValue, selectedIndex: event.selectedIndex, key: 'listingImages', id: event.id };
    setTimeout(() => {
      this.cancelImageEvent.emit(selectedObject);
    }, 300);
  }

  selectImage(event) {
    const selectedObject = { selectedValue: event.selectedValue, selectedIndex: event.selectedIndex, key: 'listingImages', id: event.id };
    setTimeout(() => {
      this.selectImageEvent.emit(selectedObject);
    }, 300);
  }

}
