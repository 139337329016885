/* eslint-disable @angular-eslint/no-input-rename */

import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject, combineLatest, Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-center-crop-thumbnail',
  templateUrl: 'center-crop-thumbnail.html',
  styleUrls: ['center-crop-thumbnail.scss']
})
export class CenterCropThumbnailComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {
  @Input('imageUrl') imageUrl: string;
  @ViewChild('img') imgRef: ElementRef;
  backgroundUrl: string;
  backgroundX = 0;
  backgroundY = 0;
  backgroundWidth: number;
  backgroundHeight: number;
  resizeSub: Subscription;
  imgInfo: Subject<any>;
  resizeSubj: Subject<any>;
  img: any;

  constructor(public plt: Platform) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.imageUrl) {
      this.img.src = this.imageUrl;
    }
  }

  ngOnInit() {
    this.resizeSub = this.plt.resize.asObservable().subscribe(() => {
      this.resizeSubj.next(1);
    });
    this.resizeSubj = new Subject<any>();
    this.imgInfo = new Subject<any>();
    this.img = new Image();
    this.img.onload = () => {
      this.imgInfo.next({ width: this.img.width, height: this.img.height });
    };
    if (this.imageUrl) {
      this.img.src = this.imageUrl;
    }

    combineLatest(this.imgInfo, this.resizeSubj, interval(1000)).subscribe(([imgSize, resize]) => {
      console.log(imgSize);
      console.log(this.imgRef.nativeElement.clientWidth);
      console.log(this.imgRef.nativeElement.clientHeight);
      let targetHeight = this.imgRef.nativeElement.clientHeight;
      let targetWidth = this.imgRef.nativeElement.clientWidth;
      const mW = this.imgRef.nativeElement.clientWidth / imgSize.width;
      const mH = this.imgRef.nativeElement.clientHeight / imgSize.height;
      if (mH > mW) {
        targetWidth = targetHeight / imgSize.height * imgSize.width;
      }
      else if (mW > mH) {
        targetHeight = targetWidth / imgSize.width * imgSize.height;
      }
      this.backgroundX = (this.imgRef.nativeElement.clientWidth - targetWidth) / 2;
      this.backgroundY = (this.imgRef.nativeElement.clientHeight - targetHeight) / 2;
      this.backgroundUrl = this.imageUrl;
      this.backgroundWidth = targetWidth;
      this.backgroundHeight = targetHeight;

    });
  }


  imgStyles() {
    return {
      'background-image': `url(${this.backgroundUrl})`,
      'background-position-x': `${this.backgroundX}px`,
      'background-position-y': `${this.backgroundY}px`,
      'background-size': `${this.backgroundWidth}px ${this.backgroundHeight}px`
    };
  }

  ngAfterViewInit() {
    this.resizeSubj.next(1);
  }

  ngOnDestroy() {
    if (!this.resizeSub && !this.resizeSub.closed) {
      this.resizeSub.unsubscribe();
    }
  }
}
