import { Component } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { CommonProvider } from '../../services/common';

@Component({
	selector: 'app-profile-action',
	templateUrl: 'profile-action.html',
	styleUrls: ['profile-action.scss']
})

export class ProfileActionPopoverComponent {

	profile: any;
	options: any;

	constructor(
		public viewCtrl: PopoverController,
		public navParams: NavParams,
		public auth: AuthService,
		public common: CommonProvider
	) {
		this.profile = this.navParams.get('data');
		this.options = this.navParams.get('options');
	}

	unpair() {
		this.viewCtrl.dismiss('unpair');
	}

	showContact() {
		this.viewCtrl.dismiss('contact');
	}

	async impersonate() {
		const didSucceed = await this.common.withLoading(() => this.auth.impersonateUser(this.profile.id), { loadingText: "Accessing Account" });

		this.viewCtrl.dismiss();

		if (didSucceed) {
			window.location.reload();
		} else {
			this.common.toast('Failed to logging as user');
		}
	}
}
