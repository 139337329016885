import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-skeleton-component',
  templateUrl: 'skeleton-component.component.html',
  styleUrls: ['skeleton-component.component.scss']
})
export class SkeletonComponent {
  @Input() heading: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Output() addData = new EventEmitter();

  buttonClicked() {
    this.addData.emit();
  }
}
