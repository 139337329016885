import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {

  transform(value: string, ...args) {

    let format = 'MM-DD-YY';
    if (args && args.length > 0) {
      format = args[0];
    }
    return formatDate(value, 'MM/dd/YY', 'en-US');
    // return moment(parseInt(value, 10)).format(format);
  }
}
