import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User_global } from 'src/app/app.models';
import { AuthService } from 'src/app/services/auth.service';
import { Events } from 'src/app/services/events.service';
import { ImagesManagementService } from 'src/app/services/images-management.service';

@Component({
  selector: 'app-profile-component',
  templateUrl: './profile-component.component.html',
  styleUrls: ['./profile-component.component.scss'],
})
export class ProfileComponentComponent implements OnInit {
  loadInterval: any;
  userMainImage: any = '';

  private userSub: Subscription;
  constructor(private auth: AuthService,
    private imageService: ImagesManagementService,
    private events: Events) {

    events.subscribe('refresh:userData', (userId) => {
      console.log('refresh:userData');
      this.loadProfileIfAvailable();
    });

    events.subscribe('login:userLogin', (data) => {
      this.loadProfileIfAvailable();
    });
    events.subscribe('refresh:userProfileImageLoaded', (data) => {
      this.loadProfileIfAvailable();
    });
  }

  ngOnInit() {
    if (this.auth.userInitialized) {
      this.loadProfileIfAvailable();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.loadProfileIfAvailable();
        }
      }, 1000);
    }
  }

  async loadProfileIfAvailable() {
    console.log('loadProfileIfAvailable');
    if (User_global.profilePhoto && User_global.profilePhoto[0] && this.auth.loadedProfileImage) {
      this.userMainImage = this.auth.loadedProfileImage;
    }
  }

}
