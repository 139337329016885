/* eslint-disable max-len */
import { Component, ChangeDetectorRef } from '@angular/core';
import { Platform, NavParams } from '@ionic/angular';

import { NetworkProvider } from '../services/network';

import { environment } from '../../environments/environment';
import { CommonProvider } from '../services/common';
import { Router } from '@angular/router';

// declare const window;

@Component({
  selector: 'app-help',
  templateUrl: 'help.html',
  styleUrls: ['help.scss']
})
export class HelpPage {

  public showDetails: false;
  env: any = {};
  gifImgs: any;
  faqs: Array<{ question: string; answer: string; showDetails?: boolean }> = [];

  constructor(public plt: Platform, public navCtrl: Router,
    public navParams: NavParams, public network: NetworkProvider,
    private common: CommonProvider,
    public cd: ChangeDetectorRef) {

    this.env = environment;

    this.gifImgs = this.plt.is('android') ? 'assets/imgs/android_hotspot_flow.gif' : 'assets/imgs/ios_hotspot_flow.gif';
    this.faqs = [{
      question: 'How can I get my device online to take advantage of all Curb Hero features?',
      showDetails: false,
      answer: '<ul><li>For some features to work (ex: creating a new account OR features involving text messaging), you\'ll need to be online. This can be done by turning on cellular data or connecting to a Wi-Fi network.</li><li>If no Wi-Fi networks are available, you can also turn your mobile phone into a Wi-Fi hotspot. Then you can connect the device running Curb Hero to that wi-fi network. </li><li>Follow the instructions that correspond with your phone\'s operating system</li><br/><div class=\'text-center\'><img class=\'img-center\'  src=\'' + this.gifImgs + '\' /></div></ul>'
    },
    /*
    {
      "question": "How can I remove the question asking guests whether they're interested in mortgage information?",
      "showDetails": false,
      "answer": "<ul><li>This question can be toggled off in each listing's setup under <a    class='mylisting_1'> My Listings </a></li></ul>"
    },
    */

    /*
    {
      "question": "What are ListReports and how are they used with Curb Hero?",
      "showDetails": false,
      "answer": "<ul><li>ListReports provides beautiful marketing material for property listings. It’s free of charge for agents who are paired with a lender that has a ListReports account. Luckily our five star, local lenders are happy to pair. Once you are paired, you will have access to property listing materials that you can share in your Guest Follow Up.</li></ul>"
    },
    */
    {
      question: 'After my open house, how can I access my guest data?',
      showDetails: false,
      answer: '<ul><li>There are many ways, but the best place to start is  <a class=\'my_guest\'>My Leads</a>. From there you can </li><li>share all of your open house guest data in an attachment via email, text and more. </li><li>if you select any individual guest, you will see a share option so you can share just their info too.</li><li><a class=\'next_question\'> We also highly recommend syncing your CRM with Curb Hero so every client is in one place </a></li></ul>'
    },

    {
      question: 'What features require an internet connection?',
      showDetails: false,
      answer: 'The following features will not work without an internet connection:<ul><li>account creation and login</li><li>text message follow up</li><li> syncing leads via Zapier or webhooks </li><li>lead verification and background data lookup </li><li> publishing and updating a listing microsite </li><li> publishing and updating My Link </li><li> inviting, pairing, and unpairing a lender </li><li> sharing microsites and lead data </li></ul>'
    }

    ];

  }

  ionViewDidEnter() {
    // this.youtube.openVideo('HgzGwKwLmgM')
    console.log('ionViewDidLoad HelpPage');


    setTimeout(() => {
      for (let i = 1; i < 4; i++) {
        const element = document.getElementsByClassName('mylisting_' + i);
        if (element && element[0]) {
          element[0].addEventListener('click', (event) => this.mylisting());
        }
      }
      const nextElement = document.getElementsByClassName('next_question');
      if (nextElement[0]) {
        nextElement[0].addEventListener('click', (event) => {
          this.faqs[6].showDetails = true;
        });
      }

      const myGuest = document.getElementsByClassName('my_guest');
      myGuest[0].addEventListener('click', (event) => {
        this.navCtrl.navigate(['my-leads']);
      });

    }, 1000);
  }

  mylisting() {
    this.navCtrl.navigate(['my-listings']);
  }

  openMyProfile() {
    console.log('open my profile');
    this.navCtrl.navigate(['my-profile']);
  }

  blockparty() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    this.common.openLink('https://curbhe.ro');
  }

  terms() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    this.common.openLink('https://curbhe.ro/terms/');
  }

  privacy() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    this.common.openLink('https://curbhe.ro/privacy-policy/');
  }

  toggleDetails(data) {
    if (data.showDetails) {
      data.showDetails = false;
    } else {
      data.showDetails = true;
    }
  }

  openSupport() {
    this.common.openLink('https://juuj.me/help-home', '_system');
  }
}
