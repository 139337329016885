import { Injectable } from '@angular/core';
import { exec } from '../../utils/utils';
import { COUNTRIES } from '../constants';
import { CurbUser } from '../app.models';
import { AuthService } from './auth.service';

declare const google: any;

interface LocationResponse {
  country_code: string;

  ip?: string;
  network?: string;
  version?: string;
  city?: string;
  region?: string;
  region_code?: string;
  country?: string;
  country_name?: string;
  country_code_iso3?: string;
  country_capital?: string;
  country_tld?: string;
  continent_code?: string;
  in_eu?: boolean;
  postal?: string;
  latitude?: number;
  longitude?: number;
  timezone?: string;
  utc_offset?: string;
  country_calling_code?: string;
  currency?: string;
  currency_name?: string;
  languages?: string;
  country_area?: number;
  country_population?: number;
  asn?: string;
  org?: string;
}

interface GetCurrentLocationOptions {
  onlySupportedCountries?: boolean;
  force?: boolean;
}

const DEFAULT_LOCATION: LocationResponse = {
  country_code: 'US',
};

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private cachedLocation: LocationResponse | undefined;
  // @TODO: Fix
  private geocoderInstance: any;

  constructor(private auth: AuthService) {}

  async getCurrentLocation(
    options: GetCurrentLocationOptions = {}
  ): Promise<LocationResponse> {
    const { onlySupportedCountries = true, force = false } = options;

    if (this.cachedLocation && !force) {
      return this.cachedLocation;
    }

    const [response, error] = await exec(fetch('https://ipapi.co/json'));

    if (error) {
      return DEFAULT_LOCATION;
    }

    let location = (await response.json()) as LocationResponse;

    // The API can return any country, if `onlySupportedCountries` is set to true we narrow down to only supported countries
    if (
      onlySupportedCountries &&
      !COUNTRIES.find((country) => country.value === location.country_code)
    ) {
      location = DEFAULT_LOCATION;
    }

    this.cachedLocation = location;
    return location;
  }

  private getGeocoderInstance() {
    if (!this.geocoderInstance) {
      this.geocoderInstance = new google.maps.Geocoder();
    }

    return this.geocoderInstance;
  }

  /**
   * Returns the latitude and longitude of a given ZIP code or address.
   */
  async getCoordsFromAddressOrZip(
    zipOrAddress: string,
    country: string,
    // @TODO: Remove the need of passing this parameter
    userData: CurbUser,
    forceReload = false
  ): Promise<[lat: string, long: string]> {
    let latitude;
    let longitude;

    if (userData?.address?.lat && userData?.address?.lng && !forceReload) {
      return [userData.address.lat, userData.address.lng];
    }

    const address = {
      address: zipOrAddress,
      componentRestrictions: {
        country,
      },
    };

    return new Promise((resolve, reject) => {
      this.getGeocoderInstance().geocode(address, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          latitude = results[0].geometry.location.lat();
          longitude = results[0].geometry.location.lng();
          this.auth.updateUserDataProperty({
            address: { zip: zipOrAddress, lat: latitude, lng: longitude },
          });
          resolve([latitude, longitude]);
        } else {
          reject('error getting geo loc');
        }
      });
    });
  }
}
