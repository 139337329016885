import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { DisclosureDoc, Listing } from '../app.models';

interface RequestOptions {
  method: 'post' | 'get';
  url: string;
  body?: any;
  requiresAuthentication?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HTTPService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  private async _makeRequestToServer(options: RequestOptions) {
    const { method, url, body, requiresAuthentication } = options;
    let response;
    try {
      if (method === 'post') {
        response = await this.http
          .post(url, body, {
            headers: requiresAuthentication ? this._makeAuthHeaders() : {},
          })
          .toPromise();
      } else if (method === 'get') {
        response = await this.http
          .get(url, {
            headers: requiresAuthentication ? this._makeAuthHeaders() : {},
          })
          .toPromise();
      } else {
        throw new Error('Invalid method');
      }

      return response;
    } catch (error) {
      console.log(`Error in request to server at URL ${url}`);
      throw error;
    }
  }

  private _makeAuthHeaders() {
    return {
      Authorization: `Bearer ${this.authService.currentUser.accessToken}`,
    };
  }

  async getDisclosureDownloadData(
    encryptedId: string
  ): Promise<{ disclosure: DisclosureDoc, guest: any; listing: Listing }> {
    return this._makeRequestToServer({
      method: 'post',
      url: environment.getDisclosureDownloadData,
      body: { data: encryptedId },
      requiresAuthentication: false,
    });
  }
}
