/* eslint-disable @typescript-eslint/dot-notation */
import { Component, HostListener, OnInit } from '@angular/core';
import { NavParams, ModalController, Platform } from '@ionic/angular';
import { I8nService } from 'src/app/services/i8nService';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-offline-modal',
  templateUrl: 'offline-modal.html',
  styleUrls: ['offline-modal.scss']
})

export class OfflineModalPage implements OnInit {

  constructor(
    public plt: Platform,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public i8nService: I8nService,
    public utils: UtilsService
  ) {


  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  ngOnInit(): void {

  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  done() {
    this.viewCtrl.dismiss();
  }

  openHelp() {
    this.viewCtrl.dismiss({ openHelp: true });
  }
}
