/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone } from '@angular/core';
import { NavController, NavParams, ModalController, AlertController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NetworkProvider } from '../../services/network';
import { Colors } from '../../constants';
import { ActivatedRoute } from '@angular/router';
import { I8nService } from 'src/app/services/i8nService';

@Component({
  selector: 'app-color-picker-primary',
  templateUrl: 'color-picker-primary.html',
  styleUrls: ['color-picker-primary.scss']
})

export class ColorPickerPrimaryPage {

  public currentColor: string;
  public colorForm: FormGroup;
  public colors: any;

  colorTitle = 'Edit Primary Color';
  colorInstructions = 'This color will be used as the background to white text so it will need to be dark enough so that guests can read it.';

  isLocked;
  lockMessage = 'This value will be used for all paired agents accounts and will overwrite existing values they\'ve added.';
  constructor(
    public navCtrl: NavController,
    public network: NetworkProvider,
    public viewCtrl: ModalController,
    public fb: FormBuilder,
    public navParams: NavParams,
    private route: ActivatedRoute,
    public i8nService: I8nService,
    private ngZone: NgZone,
    public alertCtrl: AlertController,
    private cdr: ChangeDetectorRef
  ) {

    this.currentColor = navParams.get('color').substring(1);
    // this.currentColor = this.route.snapshot.paramMap.get('color').substring(1);

    this.colorTitle = navParams.get('title');
    this.colorInstructions = navParams.get('subtitle');
    this.isLocked = navParams.get('isLocked');
    if (this.isLocked !== undefined) {
      console.log('isLocked: ' + this.isLocked);
    }

    this.colors = Colors;

    this.colorForm = this.fb.group({
      inputColor: ['', Validators.compose([this.colorValidator.bind(this)])],
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  handleSelection(event) {
    console.log('color selection: ' + JSON.stringify(event.value));
    this.currentColor = event.value;

    this.ngZone.run(() => {
      this.colorForm.controls['inputColor'].setValue(event.value);
    });

    this.cdr.detectChanges();
  }

  onChanges(): void {
    this.colorForm.valueChanges.subscribe(val => {
      if (this.colorForm.valid) {
        this.currentColor = val.inputColor;
      }
    });
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  done() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    if (this.isLocked) {
      this.showConfirmationMessage('Confirm Update', this.lockMessage);
    }
    else {
      this.viewCtrl.dismiss(this.currentColor);
    }
  }
  public async showConfirmationMessage(title, subTitle) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: subTitle,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: (data) => {
            this.viewCtrl.dismiss(this.currentColor);
          }
        },
      ]
    });
    alert.present();
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad ColorPickerPage');



    this.onChanges();
  }

  colorValidator(control: FormControl) {

    const self = this;
    const color = control.value;

    const hexToRgb = (hex) => {
      const result = /([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    };

    if (color.length !== 6) {
      return {
        colorDomain: {
          colorMessage: self.i8nService.messages.hexColorCodeError
        }
      };
    }
    // validation when color is 6 characters
    const rgb = hexToRgb(color);
    if (rgb === null) {
      return {
        colorDomain: {
          colorMessage: self.i8nService.messages.hexColorCodeError
        }
      };
      // ONLY FOR PRIMARY COLOR
    } else if (this.colorTitle === 'Edit Primary Color') {
      if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186) {
        return {
          colorDomain: {
            colorMessage: self.i8nService.messages.colorNotDarkEnough,
          }
        };
      } else {
        return null;
      }
    }
  }
}
