/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */
import { Component, ViewChild } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';

import {
  NavParams,
  ModalController,
  AlertController,
  ActionSheetController,
  LoadingController,
  MenuController,
  Platform,

} from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { Listing, CurbUser, User_global } from '../../app/app.models';

// import { CameraProvider } from '../services/camera';
import { CommonProvider } from './../services/common';

import { environment } from '../../environments/environment';
import { ConnectionsService } from '../services/connections.service';
import { NetworkProvider } from '../services/network';
import { NavigationExtras, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditInputPage } from '../components/edit-input/edit-input';
import aa from 'search-insights';
import { LocationService } from '../services/location.service';
import { AlgoliaAutomaticSearch } from '../components/algolia-automatic-search/algolia-automatic-search';
import { AlgoliaInstanceNames, AlgoliaFilterHitsFn, AlgoliaHit } from '../constants/algolia';

const searchClient = algoliasearch(
  'N26A8PC608',
  '068c90fcb4bdad125a840343a2725949'
);

aa('init', {
  appId: 'N26A8PC608',
  apiKey: '068c90fcb4bdad125a840343a2725949'
});

declare let google;
const MIN_LEADCOUNT = 10; // MIN LEAD COUNT FILTER

@Component({
  selector: 'app-my-paired-agents',
  templateUrl: 'my-paired-agents.html',
  styleUrls: ['./my-paired-agents.scss']
})
export class MyPairedAgentsPage {

  @ViewChild('clearRefinments') clearRefinments;
  @ViewChild('myHierarchicalMenu') myHierarchicalMenu;
  @ViewChild('algoliaSearch') algoliaSearch;

  userZipcode = '';
  userCountry = '';
  userData: CurbUser;
  env: any = {};
  userSubs: any;
  myAgentSubs: any;
  myPairedAgents: any = [];
  myInvitedAgents: any = [];
  copySlugTitle = 'Copy';
  loading = true;
  showRefresh = true;
  loadInterval: any;
  mySearchParams = {};
  recentlyActiveModel;
  finalDate;
  config;
  eligibleStates;
  eligibleStatesLoaded = false;
  loadFailed = false;
  userRole: string;
  geoLocationCordinates;
  geoZipcode;
  radiusSearch = 100;
  public NUMBER_REGEX = `[0-9]*`;
  currentLocationView = '';  //searchZipCodeView, enterZipCodeView, currentZipCodeView
  showPairedAgentsDiv = true;
  firstLoad = true;
  segmentValue = 'pairWithLink';
  uid: string;
  pairingLink;
  public isMobilePhone: boolean;
  pairedType;
  enableSeeMore = true;
  isPairedAgentAvailable = '';

  @ViewChild('algoliaAutomaticAgentSearch') algoliaAutomaticSearch: AlgoliaAutomaticSearch;
  instanceName = AlgoliaInstanceNames.my_paired_agents_automatic_search_for_agents;
  filterHitsFn: AlgoliaFilterHitsFn = this.notInInviteList.bind(this);

  constructor(
    public navCtrl: Router,
    public navParams: NavParams,
    private auth: AuthService,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public actionSheetCtrl: ActionSheetController,
    public modalCtrl: ModalController,
    private network: NetworkProvider,
    public common: CommonProvider,
    // public cameraProvider: CameraProvider,
    private menu: MenuController,
    private connectionsDataService: ConnectionsService, // user connections
    private platform: Platform,
    private locationService: LocationService
  ) {
  }

  async ionViewDidEnter() {
    this.userCountry = (await this.locationService.getCurrentLocation()).country_code;
    
    console.log('my paired agents did enter.');
    if (this.auth.userInitialized) {
      this.initPairedAgents();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initPairedAgents();
        }
      }, 2000);
    }
  }

  async searchRecommendedAgents(forceReload = false) {
    if (!this.userZipcode) {
      return;
    }

    const result = await this.locationService.getCoordsFromAddressOrZip(this.userZipcode, this.userCountry, this.userData, forceReload);

    if (result.length > 1) {
      let customFilter = '';
      const restrictedStates = this.auth.restrictedRecommendStatesArray;

      for (let i = 0; i < restrictedStates.length; i++) {
        customFilter += `(NOT locations.lvl0: ${restrictedStates[i]})`;

        if (i !== restrictedStates.length - 1) {
          customFilter += ' AND ';
        }
      }

      customFilter += ' AND ' + `(leadCount > ${MIN_LEADCOUNT})`;
      this.algoliaAutomaticSearch.updateConfigs({
        aroundLatLng: result[0] + ',' + result[1],
        aroundRadius: this.radiusSearch * 1000, // in multipes of 1 km
        hitsPerPage: 6,
        filters: customFilter
      } as any)
    }
    
    this.currentLocationView = 'currentZipCodeView';
  }

  goEditMode(event, selectedListing: Listing, mode = 'openhouse') {
    console.log('go Edit Mode called !' + mode);
    event.stopPropagation();

    const navigationExtras: NavigationExtras = {
      queryParams: {
        //   listingLayout: selectedListing.propertyLayout,
        mode
      }
    };

    this.navCtrl.navigate(['listing-detail/' + `${selectedListing.id}`], navigationExtras);
  }

  mobileDeviceCheck() {
    if (this.platform.width() < 567) {
      this.isMobilePhone = true;
    }
    else {
      this.isMobilePhone = false;
    }
  }

  async initPairedAgents() {
    this.mobileDeviceCheck();
    this.platform.resize.subscribe(() => {
      this.mobileDeviceCheck();
    });
    if (this.platform.is('cordova') && User_global.role === 'lender') {
      this.navCtrl.navigate(['/my-profile']);
      return;
    }
    else if (User_global.role === 'agent') {
      //agent
    }
    else if (this.platform.is('cordova') && User_global.role === 'agentAdmin') {
      //agentAdmin
      this.navCtrl.navigate(['/my-profile']);
      return;
    }

    this.uid = this.auth.getUID();

    console.log('init paired agents UID :' + this.auth.getUID());
    if (User_global.regionalLender === true) {
      //  alert('regoinal lender!');
      this.auth.getLoanOfficerStates().then((states) => {
        //    alert('got loan officer elible states: ' + states);
        this.eligibleStates = states;
        this.eligibleStatesLoaded = true;
      });
    }
    else {
      this.eligibleStatesLoaded = true;
    }

    aa('setUserToken', this.auth.getUID());

    // PROD ALGOLIA IS DIFF
    if (environment.production) {
      if (User_global.regionalLender === true) {
        // REGIONAL LENDERS NEED ADDITIONAL FILTERS
        this.config = {
          indexName: 'public_profiles_prod',
          searchClient,
          insightsClient: (window as any).aa,
          initialUiState: {
            public_profiles_prod: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                'mostRecentListingEnhancedModeStatus': ['true'],
                'enableLenderCommunication': ['true'],
                'role': ['agent']
              }
            }
          }
        };
      }

      // NON REGIONAL LENDERS ONLY NEED PAIRED USER ID FILTER
      else if (User_global.role === 'lender') {
        this.config = {
          indexName: 'public_profiles_prod',
          searchClient,
          insightsClient: (window as any).aa,
          initialUiState: {
            public_profiles_prod: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                'role': ['agent']
              }
            }
          }
        };
      }
      else if (User_global.role === 'agentAdmin') {
        this.config = {
          indexName: 'public_profiles_prod',
          searchClient,
          insightsClient: (window as any).aa,
          initialUiState: {
            public_profiles_prod: {
              refinementList: {
                pairedAdminId: [String(this.auth.getUID())],
                'role': ['agent']
              }
            }
          }
        };
      }
    }
    // DEV INDEX NAMES
    else if (!environment.production) {
      if (User_global.regionalLender === true) {
        this.config = {
          indexName: 'public_profiles_dev',
          searchClient,
          insightsClient: (window as any).aa,
          initialUiState: {
            public_profiles_dev: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                'mostRecentListingEnhancedModeStatus': ['true'],
                'enableLenderCommunication': ['true'],
                'role': ['agent']
              }
            }
          }
        };
      }
      else if (User_global.role === 'lender') {
        this.config = {
          indexName: 'public_profiles_dev',
          searchClient,
          insightsClient: (window as any).aa,
          initialUiState: {
            public_profiles_dev: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                'role': ['agent']
              }
            }
          }
        };
      }
      else if (User_global.role === 'agentAdmin') {
        this.config = {
          indexName: 'public_profiles_dev',
          searchClient,
          insightsClient: (window as any).aa,
          initialUiState: {
            public_profiles_dev: {
              refinementList: {
                pairedAdminId: [String(this.auth.getUID())],
                'role': ['agent']
              }
            }
          }
        };
      }
    }


    this.menu.enable(true);
    this.menu.swipeGesture(true, 'left');

    const self = this;
    this.loading = true;
    this.copySlugTitle = 'Copy';
    this.userData = User_global;
    this.env = environment;

    this.pairingLink = environment.referralURL + this.userData.referralCode;

    // JUST TO CHECK IF CONNECTION IS WORKING
    //  this.userSubs = combineLatest(this.auth.userDataObserver(), (user) => ({ user })).subscribe((data) => {

    this.userSubs = combineLatest([this.auth.userDataObserver()]).pipe(
      map(([user]) => ({ user }))).subscribe((data) => {

        //  console.log('userSubs subscribe: ' + JSON.stringify(data));
        if (data) {
          this.showRefresh = false;

          this.cleanupPhoneNumer();

          if (data.user.address && data.user.address.zip !== this.userZipcode) {
            this.userZipcode = data.user.address.zip;

            if (!data.user.regionalLender && data.user.address.zip) {
              this.userZipcode = data.user.address.zip;
              this.currentLocationView = 'currentZipCodeView'; //searchZipCodeView, enterZipCodeView, currentZipCodeView

              this.auth.restrictedRecommendStates().then(res => {
                console.log('restrict states :' + JSON.stringify(res.states));
                this.auth.restrictedRecommendStatesArray = res.states;
                this.searchRecommendedAgents();
              });
            }
            else if (!User_global.address || !User_global.address.zip) {
              this.currentLocationView = 'enterZipCodeView';
              this.auth.restrictedRecommendStates().then(res => {
                this.auth.restrictedRecommendStatesArray = res.states;
              });
            }
          }
          else if (!data.user.address || !data.user.address.zip) {
            this.currentLocationView = 'enterZipCodeView';
            this.auth.restrictedRecommendStates().then(res => {
              this.auth.restrictedRecommendStatesArray = res.states;
            });
          }
        }
        else if (data === undefined) {
          this.showRefresh = true;
          this.loadFailed = true;
        }
      });

    if (!User_global.enableAgentsListingsResultsFiltering) {
      if (this.myAgentSubs) {
        this.myAgentSubs.unsubscribe();
      }
      this.myAgentSubs = this.connectionsDataService.connections().subscribe((data) => {
        console.log('my paired agents data: ' + JSON.stringify(data));
        self.myPairedAgents = data.filter(agent => (agent.status === 'active'
          || agent.status === 'regional_lender'
          || agent.status === 'agent_admin'));

        if (self.myPairedAgents.length < 1) {
          this.showPairedAgentsDiv = false;
          this.isPairedAgentAvailable = 'my-paired-agents-not-available';
        }
        else {
          this.showPairedAgentsDiv = true;
          this.isPairedAgentAvailable = 'my-paired-agents-available';
        }

        if (!this.firstLoad) {
          // RELOAD ALGOLIA SEARCH
          setTimeout(() => {
            this.refreshAlgolia();
          }, 30000);
        }
        this.firstLoad = false;

        if (User_global.role === 'agentAdmin') {
          self.myInvitedAgents = data.filter(agent => (agent.status === 'invited' || agent.status === 'agent_invited_agentadmin'));
        }
        else if (User_global.role === 'lender') { // lender
          self.myInvitedAgents = data.filter(agent => (agent.status === 'agent_invited_lender' || agent.status === 'lender_invited_agent'));

          self.myInvitedAgents.sort(function (a, b) {
            return a.status === 'agent_invited_lender' && b.status === 'lender_invited_agent' ? -1 : 1;
          });
        }

      });
    }
    // USERS USING ALGOLIA DONT NEED MY PAIRED AGENTS FROM DB
    else if (!User_global.regionalLender) {
      this.myAgentSubs = this.connectionsDataService.connections().subscribe((data) => {
        console.log('my paired agents data 2: ' + JSON.stringify(data));

        if (User_global.role === 'agentAdmin') {
          self.myInvitedAgents = data.filter(agent => (agent.status === 'invited' || agent.status === 'agent_invited_agentadmin'));
        }
        else if (User_global.role === 'lender') { // lender
          self.myInvitedAgents = data.filter(agent => (agent.status === 'agent_invited_lender' || agent.status === 'lender_invited_agent'));

          self.myInvitedAgents.sort(function (a, b) {
            return a.status === 'agent_invited_lender' && b.status === 'lender_invited_agent' ? -1 : 1;
          });
        }

        if (!this.firstLoad) {
          // RELOAD ALGOLIA SEARCH
          setTimeout(() => {
            this.refreshAlgolia();
          }, 30000);
        }

        this.firstLoad = false;
      });
    }

    setTimeout(() => { this.common.closeLoading(); this.loading = false; }, 1000);

    this.userRole = User_global.role;
    if (this.userRole === 'agent') {
      console.log('User Role', this.userRole);
      this.navCtrl.navigate(['/my-paired-lender']);
      return;
    }
  };

  ionViewWillLeave() {

    clearInterval(this.loadInterval);

    console.log('unsubscribe paired agents');
    if (this.userSubs) {
      this.userSubs.unsubscribe();
      this.userSubs = null;
    }
  }

  async enterLocation() {

    const inputModal = await this.modalCtrl.create({
      component: EditInputPage, componentProps: {
        displayCountryPicker: true,
        selectedCountry: this.userCountry,
        label: 'Location',
        data: this.userZipcode,
        placeholder: 'Zip/Postal Code or Similar Location',
        helperText: 'Zip code or postal codes are strongly recommended. If entering a city name, also include state or region (ex: Beverly Hills, California).',
        //  limit: 5,
        //  numericInput: true
      }
    });

    await inputModal.present();
    const { data } = await inputModal.onWillDismiss();

    console.log('dismiss ' + data);
    if (data === undefined || data === null) {
      return;
    }

    // When we opened the modal, we passed the current location, but the user may have changed it from the country picker
    this.userCountry = data.country;
    this.userZipcode = data.zipOrAddress;

    this.searchRecommendedAgents(true);
  }

  changeLocationView(loc) {
    this.currentLocationView = loc;
  }

  openHelp() {

    if (!this.network.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }

  openMyProfile() {
    console.log('open my profile');
    this.navCtrl.navigate(['/my-profile']);
  }

  openMySubscription() {
    if (this.auth.getUserAuthToken()) {
      this.common.openSubscription(environment.stripeSubscription + this.auth.getUserAuthToken());
    }
  }

  openMicrosite() {
    this.navCtrl.navigate(['/my-link']);
  }

  openLeads() {
    this.navCtrl.navigate(['/my-leads']);
  }

  // cleanup old format phones in DB
  cleanupPhoneNumer() {

    if (this.userData.phoneNumber && this.userData.phoneNumber[0] === '(') {
      const cleanNum = this.common.cleanPhoneNumber(this.userData.phoneNumber);
      console.log('cleaning up phone num: ' + cleanNum);
      this.auth.updateUserDataProperty({ phoneNumber: cleanNum });
    }
  }



  learnMore(section) {

    if (section === 1 && User_global.role === 'agentAdmin') {
      section = 4;
    }

    switch (section) {
      case 1:
        this.common.openLink('https://juuj.me/creating-and-managing-a-lender-subscription');
        break;
      case 2:
        this.common.openLink('https://juuj.me/what-is-onlyl-ink');
        break;
      case 3:
        this.common.openLink('https://juuj.me/how-do-lenders-pair-with-real-estate-agents');
        break;
      case 4:
        this.common.openLink('https://juuj.me/how-does-agent-admin-pairing-work');
        break;
      default:
      // nothing
    }
  }

  copyReferral() {
    const self = this;
    self.common.copyToClipboard(environment.referralURL + this.userData.referralCode);

    self.copySlugTitle = 'Copied';
    setTimeout(() => {

      self.copySlugTitle = 'Copy';
    }, 2000);
  }

  refreshPage() {
    this.common.reloadPage();
  }

  toggleFilterVisbility() {
    document.querySelectorAll('.collapsy').forEach((panel: any) => {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    });
  }

  recentlyActiveSelectionChange(event) {

    if (this.recentlyActiveModel === 'all') {
      this.mySearchParams = {};
    }
    else if (this.recentlyActiveModel) {
      const d = new Date();
      d.setDate(d.getDate() - Number(this.recentlyActiveModel));
      console.log('time: ' + d.getTime());
      this.finalDate = d.getTime();
      // this.mySearchParams = { hitsPerPage: 2 };
      this.mySearchParams = { filters: `mostRecentLeadCreatedAt > ${this.finalDate}` };
    }
  }

  resetAlgoliaFilters() {

    // document.getElementById('clearRefinments').click();
    this.clearRefinments.state.refine();

    this.myHierarchicalMenu.reset();

    setTimeout(() => {
      this.recentlyActiveModel = 'all';
      this.mySearchParams = {};
    }, 250);
  }

  seeMoreClicked() {
    this.connectionsDataService.incrementSeeMoreCount();
    this.enableSeeMore = this.connectionsDataService.canSeeMore();
  }

  refreshAlgolia(hit?, hits?, hitIndex?) {
    console.log('algolia refresh event recieved!');
    // refresh algolia after unpair/pair

    // set some search param so it can be reset
    const d = new Date();
    d.setDate(d.getDate() - 150);
    console.log('time: ' + d.getTime());
    this.finalDate = d.getTime();
    this.mySearchParams = { filters: `mostRecentLeadCreatedAt > ${this.finalDate}` };

    setTimeout(() => {
      searchClient.clearCache();
      //  searchClient.initIndex('public_profiles');
      if (this.algoliaSearch) {
        this.algoliaSearch.refresh();
      }
      this.resetAlgoliaFilters();
    }, 150);

  }

  inviteClicked(hit: AlgoliaHit) {
    this.algoliaAutomaticSearch.sendEvent('click', hit, 'recommendationClick', {
      objectIDs: [hit.objectID], 
      userToken: this.uid 
    });
  }

  notInInviteList(id) {
    return !this.myInvitedAgents.find(x => x.id === id) && !this.myPairedAgents.find(x => x.id === id);
  }


  // open email invites for agents
  inviteAgentsEmail() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.common.openLink('https://curbhe.ro/invitations');
  }

  segmentChanged(ev: string, element?) {
    console.log('Segment changed', ev);
    this.segmentValue = ev;
  }

  createAgentAccounts() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.common.openLink('https://curbhe.ro/invitations');
  }
}
