import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, IonTextarea } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-input-followup',
  templateUrl: 'edit-input-followup.html',
  styleUrls: ['edit-input-followup.scss']
})
export class EditInputFollowUpPage implements OnInit, AfterViewInit {
  /*
  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }
*/
  @ViewChild('ionTextarea') ionTextarea: IonTextarea;
  public inputLabel: any;
  public inputData: string;
  public countdown = 0;
  public limitNum = 160;
  public type = 'text';
  public isDark = true;
  isDisabled = false;

  constructor(
    public plt: Platform,
    private elementRef: ElementRef,
    public navCtrl: NavController,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public utils: UtilsService
  ) {
    this.inputLabel = this.navParams.get('label');
    this.inputData = this.navParams.get('data');
    this.type = this.navParams.get('type') || 'text';

    setTimeout(() => {
      this.limitText();
    }, 200);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  ionViewDidLoad() {

  }

  ngOnInit(): void {
    setTimeout(() => this.adjust(), 0);
  }

  dismiss() {
    this.viewCtrl.dismiss(undefined);
  }

  done() {
    this.viewCtrl.dismiss(this.inputData);
  }

  limitText() {
    if (this.inputData) {
      if (this.inputData.length > this.limitNum) {
        this.isDisabled = true;
        this.isDark = false;
        this.countdown = this.limitNum - this.inputData.length;

        // this.inputData = this.inputData.substring(0, this.limitNum);
      } else {
        this.isDisabled = false;
        this.countdown = this.limitNum - this.inputData.length;
        this.isDark = true;
      }
    }
    else {
      this.countdown = 160;
    }
  }

  ngAfterViewInit() {
    //const element = this.elementRef.nativeElement.querySelector('textarea');
    // we need to delay our call in order to work with ionic ...

    setTimeout(() => {
      this.ionTextarea.setFocus();
    }, 1000);
  }

  adjust(): void {
    const textArea = this.elementRef.nativeElement.getElementsByTagName('textarea')[0];
    if (textArea.scrollHeight < 100) {
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    } else {
      textArea.style.overflow = 'scroll';
    }
  }
}
