import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { NavController, NavParams, ModalController, Platform } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { SqlService } from 'src/app/services/sql.service';
import { NetworkProvider } from '../../services/network';

@Component({
  selector: 'app-view-raw-leads',
  templateUrl: 'view-raw-leads.html',
  styleUrls: ['view-raw-leads.scss']
})
export class ViewRawLeadsComponent implements OnInit, AfterViewInit {

  public inputLabel: any;
  rawData = [];
  isDark = true;
  title = '';
  mode = '';
  records = 2;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public plt: Platform,
    public network: NetworkProvider,
    public viewCtrl: ModalController,
    public sql: SqlService,
    private auth: AuthService) {

    try {
      this.title = this.navParams.get('title');
      this.mode = this.navParams.get('mode');
      this.rawData = JSON.parse(JSON.stringify(this.navParams.get('rawData')));
    }
    catch (err) {
      this.auth.logErrors('error opening offline logs: ' + err);
      // parsing screwed up or there simply is no data.
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {

  }

  ionViewDidLoad() {

  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  done() {
    this.viewCtrl.dismiss();
  }

  // deleteAllKioskLogsForUID() {
  //   this.sql.DeleteKioskLogForUID(this.records).then(() => {
  //     this.dismiss();
  //   });
  // }
}
