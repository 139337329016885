/* eslint-disable max-len */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, IonInput, AlertController } from '@ionic/angular';
import { I8nService } from 'src/app/services/i8nService';
import { UtilsService } from 'src/app/services/utils.service';
const DEFAULT_LIMIT = 250;

@Component({
  selector: 'app-edit-links',
  templateUrl: 'edit-links.html',
  styleUrls: ['edit-links.scss']
})
export class EditLinksPage implements OnInit {

  @ViewChild('urlInputId') urlInputId: IonInput;
  @ViewChild('titleInputId') titleInputId: IonInput;

  public inputLabel: any;
  public inputData: any;
  public countdown = 0;
  public limitNum = 250;
  public isDark = true;
  public rows = 1;
  public editLink = false;
  public showTitle = true;

  showURL = false;
  showFormTitle = true;
  showTitleOnTop = false;

  isDisabled = false;
  showAnimationSection = false;
  showTypeSection = false;
  type = false;

  /*
  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    // this.adjust();
  }
  */

  constructor(
    private elementRef: ElementRef,
    public plt: Platform,
    public navCtrl: NavController,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    private cdr: ChangeDetectorRef,
    public utils: UtilsService,
    private alertCtrl: AlertController,
    public i8nService: I8nService
  ) {

    this.inputLabel = this.navParams.get('label');
    this.inputData = this.navParams.get('data');

    this.limitNum = this.navParams.get('limit');
    this.editLink = this.navParams.get('edit');
    this.limitNum = this.navParams.get('limit') || DEFAULT_LIMIT;
    this.rows = this.navParams.get('rows') || 1;
    this.showTitle = this.navParams.get('showTitle');
    this.showURL = this.navParams.get('showURL');
    this.showFormTitle = this.navParams.get('showFormTitle');
    this.showAnimationSection = this.navParams.get('showAnimationSection');
    this.showTypeSection = this.navParams.get('showTypeSection');
    this.showTitleOnTop = this.navParams.get('showTitleOnTop');


    if (this.showAnimationSection && !this.inputData.animation) {
      this.inputData.animation = '';
    }

    this.limitText();

    if (this.inputData.type === 'vCard') {
      this.showTitleOnTop = false;
      this.inputLabel = 'Edit vCard';
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  limitText() {
    if (this.inputData) {
      if (this.inputData.length > this.limitNum) {
        this.isDisabled = true;
        this.isDark = false;
        this.countdown = this.limitNum - this.inputData.length;

        // this.inputData = this.inputData.substring(0, this.limitNum);
      } else {
        this.isDisabled = false;
        this.countdown = this.limitNum - this.inputData.length;
        this.isDark = true;
      }
    } else {
      this.countdown = this.limitNum;
    }
  }


  ngOnInit() {
    // const element = this.elementRef.nativeElement.querySelector('textarea');
    // we need to delay our call in order to work with ionic ...

    if (this.showTitle) {
      setTimeout(() => {
        this.titleInputId.setFocus();
      }, 1500);
    }
    else {
      setTimeout(() => {
        this.urlInputId.setFocus();
      }, 1500);
    }

  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  done() {
    this.viewCtrl.dismiss(this.inputData);
  }

  async deleteLink() {

    if (this.inputData.type === 'vCard') {
      this.checkVCard();
    }
    else {
      this.viewCtrl.dismiss('delete');
    }

  }

  adjust(): void {
    const textArea = this.elementRef.nativeElement.getElementsByTagName('textarea')[0];
    if (textArea.scrollHeight < 100) {
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    } else {
      textArea.style.overflow = 'scroll';
    }
  }

  setAnimationType(type: string) {
    this.inputData.animation = type;
  }

  setLandingPage(page: string) {
    this.inputData.landingPage = page;
  }

  async handleTakeoverChange(item, index, event, value) {
    console.log('item clicked ' + value);
    // check if one is already enabled

    // const res = await this.updateTakeoverAlert();
    // if (!res) {
    //   return;
    // }

    this.inputData.fullScreenTakeover = value;
  }

  async updateTakeoverAlert(): Promise<any> {

    return new Promise(async (resolve, reject) => {

      const myAlert = await this.alertCtrl.create({
        header: 'Update Takeover',
        message: this.i8nService.messages.takeoverLinkConfirm,
        buttons: [
          {
            role: 'cancel',
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
              resolve(false);
            }
          },
          {
            role: 'update',
            text: 'Update',
            handler: data => {
              resolve(true);
            }
          }
        ]
      });
      await myAlert.present();
    });
  }

  async checkVCard() {
    if (this.inputData.type === 'vCard') {
      //       Header: Contact Info Download
      //       Body:
      //       //         Buttons: Edit My Profile[takes users to My Profile screen] , OK

      const self = this;
      const msgBody = 'The contact info used comes from My Profile and is saved as a "vCard". NOTE: Apps like Instagram can disable vCard downloads. We recommend testing to ensure expected behavior.';
      const myAlert = await this.alertCtrl.create({
        header: 'Contact Info Download',
        message: msgBody,
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: data => {

            }
          },
          {
            text: 'Edit My Profile',
            role: 'Edit',
            handler: data => {
              this.viewCtrl.dismiss('profile');
            }
          }
        ]
      });

      await myAlert.present();

    }
  }
}
