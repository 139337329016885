/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { ListingDataProviderService } from '../services/listing-data-provider.service';

import { combineLatest, Observable, of, BehaviorSubject, asapScheduler, forkJoin, from } from 'rxjs';
import { map, filter, mergeMap, delay, tap, finalize } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { ImagesManagementService } from '../services/images-management.service';
import { IOS_DOWNLOAD, ANDROID_DOWNLOAD, API } from '../constants';
import { CompressorService } from '../services/compressor.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Events } from './events.service';
import { I8nService } from './i8nService';
import {
  disableNetwork,
  enableNetwork, getFirestore
} from '@firebase/firestore';
import { User, getAuth } from '@firebase/auth';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkProvider {

  public onlineSubject: BehaviorSubject<boolean>;
  public firestoreStatus = true;
  firestoreDataCheck = true;
  firestoreConnectionTimer: any = false;
  dbErrorAlert: any = false;
  private checkInternetConnectionInterval: any;

  private urls = [
  ];

  internetHealthy = true;

  constructor(
    public auth: AuthService,
    public plt: Platform,
    public iab: InAppBrowser,
    public network: Network,
    public compressor: CompressorService,
    public listingDataProvider: ListingDataProviderService,
    public openNativeSettings: OpenNativeSettings,
    private images: ImagesManagementService,
    public alertCtrl: AlertController,
    private events: Events,
    public i8nService: I8nService,
    private imageService: ImagesManagementService,
    private http: HttpClient
  ) {

    console.log('init networkProvider.');

    this.urls = [environment.log_errors];
  }

  public initializeNetworkService() {
    console.log('network provider constructor');
    this.onlineSubject = new BehaviorSubject<boolean>(this.checkInternet());
    this.initializeNetworkEvents();
  }

  public turnOnAirplaneMode() {
    const firestore = getFirestore();
    disableNetwork(firestore).then(() => {
      this.firestoreStatus = false;
      console.log('Firestore network disabled');
    });
  }

  public async initializeNetworkEvents(): Promise<void> {

    console.log('initializeNetworkEvents');

    this.network.onDisconnect().subscribe(() => {

      const firestore = getFirestore();
      disableNetwork(firestore).then(() => {
        this.firestoreStatus = false;
        console.log('Firestore network disabled');
      });

      this.onlineSubject.next(false);
    });

    this.network.onConnect().subscribe(() => {
      const firestore = getFirestore();

      this.tryEnableNetwork();

      this.onlineSubject.next(true);

      // NOT SURE IF NEEDED
      // const uid = localStorage.getItem('uid');
      // if (uid != null && uid !== undefined) {
      //   console.log('refresh user data called.');
      //   this.events.publish('refresh:userData', uid);
      // }
    });


    if (!this.plt.is('cordova')) {
      console.log('not cordova, ignoring file upload event');
      return;
    }

    combineLatest([this.auth.userDataObserver(), this.onlineSubject]).pipe(
      map(([user, online]) => ({ user, online }), asapScheduler)).subscribe((notification) => {

        try {
          asapScheduler.schedule(() => {
            const data = notification.user as any;
            const online = notification.online;
            console.log(`Global: user updated ${data} ${online}`);
            if (data && online) {
              console.log('trying to upload logo image');
              if (data.logoImage && data.logoImage.imagePath && !data.logoImage.imageURL) {
                this.images.realImageData(data.logoImage).then((imageData) => {
                  if (imageData) {
                    this.imageService.uploadImage(this.images.generateImageID(25), 'logo', imageData).then((url) => {
                      if (url) {
                        this.auth.updateUserDataProperty({
                          logoImage: {
                            imagePath: data.logoImage.imagePath,
                            imageURL: url
                          }
                        }).catch((err) => {
                          this.auth.logErrors('Error uploading image from offline upload: ' + JSON.stringify(err) + ' ', true);
                        });
                      }
                    }).catch((err2) => {
                      this.auth.logErrors('Error uploading image from offline upload 2: ' + JSON.stringify(err2) + ' ', true);
                    });
                  }
                });
              }
            }
          }, 500);

        } catch (err3) {
          console.log('error combine latest: ' + JSON.stringify(err3));
          this.auth.logErrors('Error uploading image from offline upload 3: ' + JSON.stringify(err3) + ' ', true);
        }
      });

    const auth = getAuth();

    auth.onAuthStateChanged((user: User | null) => {
      if (user) {
        console.log(`auth.onAuthStateChanged: ${JSON.stringify(user)} user.uid ${user.uid}`);

        combineLatest([this.listingDataProvider.rawListings(), this.onlineSubject]).pipe(
          map(([listings, online]) => ({ success: true, listings, online })),
          filter((notification) => notification.online),
          mergeMap((notification) => {
            console.log(`Global: Listings updated ${notification.online}`);
            const obsArray: Observable<any>[] = [];

            notification.listings.forEach((listing) => {
              if (listing?.listingImages?.length > 0) {
                console.log('Processing listing: ', listing.id);
                listing.listingImages.forEach((image, index: number) => {
                  if (image.imagePath && !image.imageURL) {
                    console.log('Uploading image to Firebase from local storage!');
                    obsArray.push(
                      of(null).pipe(
                        delay(1000),
                        mergeMap(() => from(this.uploadListingPhoto(listing, index)))
                      )
                    );
                  }
                });
              }
            });

            return obsArray.length > 0 ? forkJoin(obsArray).pipe(map(() => notification)) : of(null);
          }),
          tap({
            next: (res) => {
              if (res !== null) {
                console.log('Notifications processing result: ', res);
              }
            },
            error: (error) => {
              console.error('Error during notifications processing: ', error);
            },
            complete: () => {
              console.log('Notifications processing completed.');
            }
          }),
          finalize(() => {
            console.log('All processing for this auth state change is done.');
          })
        ).subscribe(); // Still necessary to trigger the observable chain.
      } else {
        console.log('User is null in network.');
      }
    });
  }


  startInternetChecks() {
    console.log('startInternetChecks');
    this.checkInternetConnection();

    if (this.checkInternetConnectionInterval) {
      clearInterval(this.checkInternetConnectionInterval);
    }

    this.checkInternetConnectionInterval = setInterval(this.checkInternetConnection.bind(this), 30000);
  }

  stopInternetChecks() {
    console.log('stopInternetChecks');

    if (this.checkInternetConnectionInterval) {
      clearInterval(this.checkInternetConnectionInterval);
    }
  }

  tryEnableNetwork() {
    try {
      // if (!this.plt.is('cordova')) {
      //   return;
      // }
      const db = getFirestore();
      enableNetwork(db).then(() => {
        console.log("Firestore network enabled.");
        this.firestoreStatus = true;
      }).catch((error) => {
        console.error("Error enabling Firestore network:", error);
        this.auth.logErrors('Error in Firestore network enable 1:  ' + error.message + ' ' + JSON.stringify(error.stack));
      });
    }
    catch (err) {
      console.error("Error enabling Firestore network:", err);
      this.auth.logErrors('Error in Firestore network enable: ' + err.message + ' ' + JSON.stringify(err.stack));
    }
  }

  public isKioskOffline(): boolean {

    return (this.checkInternet() && !this.internetHealthy);
  }

  // checks wifi first, then connection
  public checkInternet(): boolean {
    let wifiEnabled = false;

    if (this.plt.is('cordova')) {

      wifiEnabled = this.network.type !== null && this.network.type !== 'none';
    }
    else {
      wifiEnabled = window.navigator.onLine;
    }

    return wifiEnabled;
  }

  // checks if connection is healthy
  checkInternetConnection() {

    if (!this.checkInternet()) {
      this.internetHealthy = false;
      return;
    }

    console.log('checkInternetConnection called');

    this.isInternetHealthy().subscribe({
      next: (isHealthy: boolean) => {
        this.internetHealthy = isHealthy;
        console.log('Is Internet Healthy?', isHealthy);
      },
      error: (error) => {
        console.error('Error checking internet connection:', error);
      },
      complete: () => {
        console.log('Internet check completed.');
      }
    });

    this.tryEnableNetwork();
  }

  public goOnline() {
    this.tryEnableNetwork();
  }

  public async showModal(listingId, listing) {
    const self = this;
    const networkAlert = await self.alertCtrl.create({
      header: 'Device is Offline',
      message: this.i8nService.messages.deviceIsOfflineOpenHouseStillActive,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            self.events.publish('open:kioskmode', { id: listingId, data: listing });
          }
        },
        {
          text: 'Settings',
          handler: data => {
            if (self.openNativeSettings) {
              console.log('openNativeSettings is active');
              self.openNativeSettings.open('storage').then(() => {

              });
            } else {
              console.log('openNativeSettings is not active!');
            }
          }
        }
      ]
    });
    await networkAlert.present();
  }

  public async noNetworkModal() {
    const self = this;
    const networkAlert = await self.alertCtrl.create({
      header: 'Connection Error',
      message: this.i8nService.messages.actionRequiresANetworkConnection,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Settings',
          handler: data => {
            if (self.openNativeSettings) {
              console.log('openNativeSettings is active');
              self.openNativeSettings.open('wifi').then(() => { });
            } else {
              console.log('openNativeSettings is not active!');
            }
          }
        }
      ]
    });

    await networkAlert.present();
  }

  public async downloadNativeApp(userType?) {
    if (userType === 'agentAdmin') {
      const downloadAlert = await this.alertCtrl.create({
        header: 'Feature Unavailable',
        message: this.i8nService.messages.featureNotAvailableOnWebAdmin,
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            handler: data => {
            }
          }
        ]
      });
      await downloadAlert.present();
    }
    else {
      const downloadAlert = await this.alertCtrl.create({
        header: 'Feature Unavailable',
        message: this.i8nService.messages.featureNotAvailableOnWeb,
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            handler: data => {
            }
          },
          {
            text: 'Download',
            role: 'download',
            handler: data => {
              if (this.plt.is('ios')) {
                this.iab.create(IOS_DOWNLOAD, '_system');
              }
              else if (this.plt.is('android')) {
                this.iab.create(ANDROID_DOWNLOAD, '_system');
              }
            }
          }
        ]
      });
      await downloadAlert.present();
    }
  }

  // MANUAL CHECK FOR DATA CONNECTION
  checkFirestoreDataConnection() {

    const self = this;
    // only do data checks on ios

    if (self.checkInternet() && self.auth.getUID()) {
      clearTimeout(self.firestoreConnectionTimer);
      self.firestoreDataCheck = undefined;

      self.firestoreConnectionTimer = setTimeout(() => {
        if (self.firestoreDataCheck === undefined) {
          self.auth.logErrors('error checking firestore connection: '
            + 'checking user doc took over 20 seconds for'
            + ' current auth user: '
            + this.auth.getUID(), true);
          self.showDBWriteError();
        }
      }, 20000);

      console.log('checkFirestoreDataConnection called');
      self.auth.getUserDoc().then(res => {
        if (res) {
          console.log('firestore connection is alive.');
          self.firestoreDataCheck = true;
          clearTimeout(self.firestoreConnectionTimer);
        }
        else {
          self.firestoreDataCheck = undefined;
          console.log('err no user doc');
          // self.showDBWriteError();
        }
      }).catch(err => {
        clearTimeout(self.firestoreConnectionTimer);
        console.log(err);
        self.firestoreDataCheck = undefined;
        self.auth.logErrors('error checking firestore connection 2: ' + err, true);
        self.showDBWriteError();
      });
    }

  }



  /**
 * Pings the URLs and checks if at least one is healthy
 */
  public isInternetHealthy(): Observable<boolean> {
    const pingObservables = this.urls.map(url => this.pingUrl(url));

    return forkJoin(pingObservables).pipe(
      map((results: boolean[]) => results.some(result => result === true)) // Check if at least one result is true
    );
  }

  /**
   * Sends a ping request to the given URL
   * @param url The URL to ping
   * @returns Observable<boolean> indicating success or failure
   */
  private pingUrl(url: string): Observable<boolean> {
    return from(
      fetch(url, { method: 'POST' })
        .then(response => response.ok) // If response is OK (status 200-299), consider the site reachable
        .catch(() => false) // If there's an error, consider the site unreachable
    );
  }


  async showDBWriteError() {
    console.log('show db write error!');

    const callbackURL = 'https://juuj.me/troubleshooting-app-issues';
    const errTitle = 'Oops. There\'s a Problem.';
    const errorMessage = this.i8nService.messages.dbWriteError;

    if (!this.dbErrorAlert) {

      this.dbErrorAlert = await this.alertCtrl.create({
        header: errTitle,
        message: errorMessage,
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: data => {
              try {
                this.dbErrorAlert = false;
                window.location.reload();
              }
              catch (err) {
                console.log('err closing popup: ' + err);
              }
            }
          },
          {
            text: 'Help Section',
            handler: data => {
              console.log('help clicked');
              if (!this.checkInternet()) {
                this.noNetworkModal();
                return;
              }
              this.dbErrorAlert = false;
              this.iab.create(callbackURL, '_blank');
            }
          }
        ]
      });

      await this.dbErrorAlert.present();
    }
  }

  private uploadListingPhoto(listing: any, index: number): Promise<void> {
    const self = this;

    console.log(`Start uploading photo for ${listing.id} index is ${index}`);

    if (listing.listingImages[index]) {
      return this.images.realImageData(listing.listingImages[index])
        .then((data) => {
          if (data) {
            console.log('toDataUrl: ' + data);
            return this.images.toDataUrl(data)
              .then((dt) => {
                if (dt) {
                  const photoPath = `${listing.id}/${listing.listingImages[index].id}`;
                  console.log('Upload image from offline: ' + dt + ' to path: ' + photoPath);
                  return this.imageService.uploadImage(photoPath, 'property', dt)
                    .then((url) => {
                      if (url) {
                        console.log(`End uploading photo for ${listing.id} url: ${url}`);
                        listing.listingImages[index].imageURL = url;
                        self.updateListingUpload(dt, listing, index);
                      }
                    });
                }
              });
          }
        })
        .catch((error) => {
          console.error('Error trying to upload listing photo: ', error);
          throw error;  // Rethrow to propagate the error
        });
    } else {
      return Promise.resolve(); // Return a resolved Promise if there is no image to upload
    }
  }



  private updateListingUpload(dt, listing, index) {

    this.saveThumbnailPhoto(dt, listing, index);
  }

  private saveThumbnailPhoto(imgFile, listing, index) {
    const self = this;

    console.log('in saveThumbnailPhoto');
    const photoId = this.images.generateImageID(25);
    const fileName = `${photoId}_propertyThumbnail`;

    // compress the photo
    self.compressor.compressDataUrl(imgFile, 600).then(res => {

      if (res) {
        console.log('compressed thumbnail photo for ' + listing.id);
        // upload photo
        self.images.uploadListingPhotoWeb(photoId, listing.id, res, 'propertyThumbnail').then(imgURL => {

          if (imgURL) {
            console.log('thumbnail uploaded: ' + imgURL);
            listing.listingImages[index].thumbnailPath = fileName;
            listing.listingImages[index].thumbnailURL = imgURL;

            if (listing.listingImages.length < 2) {
              listing.propertyImage = {
                id: listing.listingImages[index].id,
                imagePath: listing.listingImages[index].imagePath,
                imageURL: listing.listingImages[index].imageURL,
                thumbnailPath: listing.listingImages[index].thumbnailPath,
                thumbnailURL: listing.listingImages[index].thumbnailURL
              };
            }

            this.auth.updateListingImages(listing.id, listing);
            console.log('finished saving thumbnail');
          }
        });
      }
    }).catch(err => {
      console.log('Issue in image resize: ' + JSON.stringify(err));

    });
  }
}
