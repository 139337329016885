/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */

import { AfterViewInit, Component } from '@angular/core';
import { NavParams, AlertController, ModalController, PopoverController, MenuController, Platform } from '@ionic/angular';
import { CommonProvider } from '../services/common';
import { AuthService } from '../services/auth.service';
import { User_global, Links, FeaturedLink } from '../../app/app.models';
import { EditInputPage } from '../components/edit-input/edit-input';
import { EditLinksPage } from '../components/edit-links/edit-links';
import { Subscription, combineLatest, asapScheduler } from 'rxjs';
import { ListingDataProviderService } from '../services/listing-data-provider.service';
import { environment } from '../../environments/environment';
import { NetworkProvider } from '../services/network';
import { MIN_USERNAME_LENGTH, MAX_FEATURED_LINKS, MAX_LISTINGS_LINKS, BLANK_SOCIAL_LINKS } from '../constants/general.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesManagementService } from '../services/images-management.service';
import { UtilsService } from '../services/utils.service';
import { AnalyticsPopupComponent } from '../components/analytics-popup/analytics-popup';
import { ListingActionPopoverComponent } from '../components/listing-action/listing-action';

import { NavigationExtras, Router } from '@angular/router';
import { Events } from '../services/events.service';

import { ViewChild } from '@angular/core';
import { IonReorderGroup } from '@ionic/angular';
import { InfiniteScrollCustomEvent, ItemReorderEventDetail } from '@ionic/core';
import { I8nService } from '../services/i8nService';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { ItemEvents } from '../components/listing-item/listing-item.component';
import { QRPopupComponent } from '../components/qr-popup/qp-popup';

@Component({
  selector: 'app-my-microsite',
  templateUrl: 'my-microsite.html',
  styleUrls: ['./my-microsite.scss']
})

export class MyMicrositePage implements AfterViewInit {

  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;
  aLinkBuiltForText = 'A link in bio built for agents';
  addBrokerageText = 'Add Brokerage';
  notSeeingListingsText = 'Not seeing all your listings?';

  segmentValue: string;
  copySlugTitle = 'Copy';
  featuredLinks: any;
  webListings: any;
  socialLinks: any;
  loadInterval: any;
  myProfilePhoto: any;
  userData: any;
  userSubs: Subscription;
  usernameLoading: boolean;
  env: any = environment;
  imageToEdit: any;
  showDoka = false;
  previewMicrositeLink: any;
  firstLoad: boolean;
  freshAccount = false;
  refreshTimeout: any;
  isLoading = false;
  userMainImage: any = '';
  userLogoImage: any = '';
  showSocialLinks: any = false;
  showPreviewLayout = true;
  filterOptions: Array<string> = ['30daysAgo', '60daysAgo', '7daysAgo', 'yesterday', ''];
  analyticsFilter: Array<string> = ['30daysAgo', '30 days ago'];
  analyticsFilterOptions: any;
  analyticsLoading = false;
  analyticsError = false;
  analyticsAttempts = 2;
  analyticsStats: Array<any> = [];
  currentPopover: any;
  initialized = false;
  offlineMode = false;
  removedExtraListings = false;
  allListings = [];
  totalShowListings = 0;

  percentage_width_available = 47;
  width_of_more_button = 40;
  each_button_width = 46;
  element_width;

  selectedStats = {
    pageviews: '0',
    uniquePageviews: '0',
    users: '0',
    EmailClick: {
      totalEvents: '0',
      uniqueEvents: '0',
      users: '0',
      action: 'mailto:nevenskoro@gmail.com',
      label: 'EmailClick',
      category: 'EmailClick'
    },
    PhoneNumberDesktopClick: {
      totalEvents: '0',
      uniqueEvents: '0',
      users: '0', action: '0',
      label: 'PhoneNumberDesktopClick',
      category: 'PhoneNumberDesktopClick'
    },
    PhoneNumberClick: {
      totalEvents: '0',
      uniqueEvents: '0',
      users: '0', action: '0',
      label: 'PhoneNumberClick',
      category: 'PhoneNumberClick'
    },
    SMSClick: {
      totalEvents: '0',
      uniqueEvents: '0',
      users: '0', action: '0',
      label: 'SMSClick',
      category: 'SMSClick'
    }
  };
  colourOverrideTitle = 'Primary Color Override';
  colourOverrideSubtile = 'If you don\'t want to use the primary color in My Link you can override it here.';
  number_of_buttons: number;
  resizeEvent: any;
  startLeadsTitle = 'Ready to start converting your social audience into leads?';
  previewTimeoutDuration = 15000;
  paginationSize = 15;
  finalResPaginated = [];

  constructor(public navCtrl: Router,
    public navParams: NavParams,
    private common: CommonProvider,
    public alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private listingDataProvider: ListingDataProviderService,
    public events: Events,
    private network: NetworkProvider,
    private sanitizer: DomSanitizer,
    private imageService: ImagesManagementService,
    private utils: UtilsService,
    private popoverCtrl: PopoverController,
    private menu: MenuController,
    public i8nService: I8nService,
    private plt: Platform
  ) {

    this.selectedStats = {
      pageviews: '0',
      uniquePageviews: '0',
      users: '0',
      EmailClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0',
        action: 'mailto:nevenskoro@gmail.com',
        label: 'EmailClick',
        category: 'EmailClick'
      },
      PhoneNumberDesktopClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0', action: '0',
        label: 'PhoneNumberDesktopClick',
        category: 'PhoneNumberDesktopClick'
      },
      PhoneNumberClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0', action: '0',
        label: 'PhoneNumberClick',
        category: 'PhoneNumberClick'
      },
      SMSClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0', action: '0',
        label: 'SMSClick',
        category: 'SMSClick'
      }
    };

    const self = this;
    self.env = environment;

    self.userData = User_global;

    self.usernameLoading = false;
    self.featuredLinks = [];
    self.webListings = [];

    self.previewMicrositeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.onlylink);
    self.firstLoad = true;
    self.freshAccount = false;
    this.segmentValue = 'links';
  }

  ionViewDidEnter() {
    // resubscribe
    this.selectedStats = {
      pageviews: '0',
      uniquePageviews: '0',
      users: '0',
      EmailClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0',
        action: 'mailto:nevenskoro@gmail.com',
        label: 'EmailClick',
        category: 'EmailClick'
      },
      PhoneNumberDesktopClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0', action: '0',
        label: 'PhoneNumberDesktopClick',
        category: 'PhoneNumberDesktopClick'
      },
      PhoneNumberClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0', action: '0',
        label: 'PhoneNumberClick',
        category: 'PhoneNumberClick'
      },
      SMSClick: {
        totalEvents: '0',
        uniqueEvents: '0',
        users: '0', action: '0',
        label: 'SMSClick',
        category: 'SMSClick'
      }
    };
    const self = this;
    self.env = environment;

    self.userData = User_global;
    self.showPreviewLayout = self.userData.webPublished;

    console.log('starting self.showPreviewLayout: ' + self.showPreviewLayout);
    self.usernameLoading = false;
    self.featuredLinks = [];
    self.webListings = [];

    console.log('webListings in constructor ' + JSON.stringify(self.webListings));
    console.log(JSON.stringify(self.userData));
    self.previewMicrositeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.onlylink);
    self.firstLoad = true;
    self.freshAccount = false;

    if (this.userData.role === 'lender') {
      this.aLinkBuiltForText = 'A link in bio built for real estate';
      this.addBrokerageText = 'Add Bank/Broker';
      this.notSeeingListingsText = 'Not seeing all your agents\' listings?';
    }
    else if (this.userData.role === 'agent') {
      //agent
    }
    else if (this.userData.role === 'agentAdmin') {
      //agentAdmin
      this.aLinkBuiltForText = 'A link in bio built for real estate';
      this.addBrokerageText = 'Add Bank/Broker';
      this.notSeeingListingsText = 'Not seeing all your agents\' listings?';
    }
    // self.myProfilePhoto = self.userData.profilePhoto[0];
    // console.log('myProfilePhoto ' + JSON.stringify(self.myProfilePhoto));
    if (this.auth.userInitialized) {
      this.initializeMicrositePage();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initializeMicrositePage();
        }
      }, 2000);
    }
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  onResize(event?) {
    const isElementAvailable = setInterval(() => {
      const elem = document.getElementById('micrositePreview');
      if (elem !== null) {
        setTimeout(() => {
          this.resizeUI();
        }, 1000);
        const element_width = elem.offsetWidth;
        if (this.element_width === elem.offsetWidth) {
          clearInterval(isElementAvailable);
        }
      }
    }, 1000);
  }

  resizeUI() {
    const micrositeElem = document.getElementById('micrositePreview');
    if (micrositeElem) {
      this.element_width = micrositeElem.offsetWidth;
      const percentage_area = ((this.percentage_width_available * this.element_width) / 100) - this.width_of_more_button;
      this.number_of_buttons = Math.floor(percentage_area / this.each_button_width);
    }
  }

  initializeMicrositePage() {

    this.menu.enable(true);
    this.menu.swipeGesture(true, 'left');

    this.network.checkFirestoreDataConnection();

    const self = this;
    this.userMainImage = '';
    this.userLogoImage = '';
    this.common.closeLoading();
    this.startLeadsTitle = 'Ready to start converting your social audience into leads?';
    console.log('initializeMicrositePage');

    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }

    // combineLatest([this.auth.userDataObserver(), this.onlineSubject]).pipe(
    //   map(([user, online]) => ({ user, online }), asapScheduler)).subscribe((notification) => {

    this.userSubs = combineLatest([this.auth.userDataObserver(), this.listingDataProvider.latestListings()]).pipe(
      map(([user, listings]) => ({ user, listings }), asapScheduler)).subscribe((data) => {


        if (this.network.checkInternet()) {
          this.offlineMode = false;
        }
        else {
          this.offlineMode = true;
        }

        if (data && data.user) {

          this.showPreviewLayout = data.user.webPublished;
          this.isLoading = true;
          console.log('microsite subscribe update: ' + JSON.stringify(data));

          self.userData = data.user;
          // self.allListings = data.listings.filter((item) => item.slug && item.webPublished === true);
          self.allListings = data.listings;

          if (JSON.stringify(self.featuredLinks) !== JSON.stringify(self.userData.featuredLinks)) {
            self.featuredLinks = (self.userData.featuredLinks) ? JSON.parse(JSON.stringify(self.userData.featuredLinks)) : [];
          }
          if (!this.common.isDeepEqual(self.webListings, self.userData.webListings)) {
            self.webListings = (self.userData.webListings) ? JSON.parse(JSON.stringify(self.userData.webListings)) : [];
          }
          if (JSON.stringify(self.socialLinks) !== JSON.stringify(self.userData.socialLinks)) {
            self.socialLinks = (self.userData.socialLinks) ? JSON.parse(JSON.stringify(self.userData.socialLinks)) : BLANK_SOCIAL_LINKS;
          }

          self.userData.profilePhoto = data.user.profilePhoto || [];
          self.userData.micrositeUserId = data.user.micrositeUserId || '';

          // self.customColor = data.user.customColor;
          // console.log("*** whammo set! with: " + self.customColor);

          console.log('social links: ' + JSON.stringify(self.socialLinks));
          // this.syncAllElementOrder(this.featuredLinks);
          //this.syncAllElementOrder(this.webListings);

          console.log('webListings in update ' + JSON.stringify(self.webListings));
          console.log(JSON.stringify(self.userData));

          self.updateAllWebListings(data);
          self.addSocialIcons();

          self.finalResPaginated = [];
          self.generateItems();
          // LOAD USER IMAGE FIRST, OR LOGO, OR Curb Hero
          console.log('user data in microsite: ' + JSON.stringify(this.userData));
          if (this.userData.profilePhoto[0]) {
            this.imageService.realImageData(this.userData.profilePhoto[0]).then((res) => {
              this.userMainImage = res;
            });
          }

          if (this.userData.logoFile) {
            setTimeout(() => {
              this.imageService.realImageData({ imageURL: this.userData.logoFile, imagePath: this.utils.logoPath(this.userData.logoFile) }).then((res) => {
                this.userLogoImage = res + '?=' + Math.random();
              });
            }, 1000);
          }

          // first load but not fresh account runs instantly
          if (this.firstLoad && this.userData.webPublished === true && !this.freshAccount
            && this.userData.micrositeUserId && this.userData.micrositeUserId !== '') {
            console.log('running first load fetch');
            //        self.readUserAnalytics(this.filterOptions[0]);

            self.previewMicrositeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.onlylink + this.userData.micrositeUserId + '?=' + Math.random());
            self.firstLoad = false;
            self.reloadPreview();
            self.doneLoadingPreview();
          }
          else if (this.freshAccount && this.userData.webPublished === true) {
            console.log('running fresh account fetch');
            clearTimeout(self.refreshTimeout);
            self.refreshTimeout = setTimeout(() => {
              self.previewMicrositeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.onlylink + this.userData.micrositeUserId + '?=' + Math.random());
              console.log('setting new preview link: ' + self.previewMicrositeLink);
              self.reloadPreview();
              self.doneLoadingPreview();
              self.freshAccount = false;

            }, 15000);
          }
          // all others add a delay
          else if (this.userData.micrositeUserId && this.userData.micrositeUserId !== ''
            && this.userData.webPublished === true) {
            console.log('running second load');
            clearTimeout(self.refreshTimeout);
            self.refreshTimeout = setTimeout(() => {
              self.previewMicrositeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.onlylink + this.userData.micrositeUserId + '?=' + Math.random());
              self.reloadPreview();
              self.doneLoadingPreview();

            }, this.previewTimeoutDuration);
          }
          else if (this.firstLoad && this.userData.webPublished === false
            && (this.userData.micrositeUserId === null || this.userData.micrositeUserId === '')) {
            this.startLeadsTitle = 'Combine your <span class="italic" >link in bio</span> and QR code marketing with one simple site';
            this.aLinkBuiltForText = '';
          }
        }
      });
    self.plt.ready().then(() => {
      self.resizeEvent = self.plt.resize.subscribe(() => {
        setTimeout(() => {
          this.resizeUI();
        }, 1000);
      });
    });


    this.initialized = true;
  }

  updateAllWebListings(data) {

    this.webListings = this.webListings.filter(item => item.enabled);
    console.log('webListings filter 2: ' + JSON.stringify(this.webListings));
    this.userData.webListings = JSON.parse(JSON.stringify(this.webListings));

    if (!this.common.isDeepEqual(this.webListings, this.userData.webListings)) {
      console.log('arrays are not equal. update.');
      this.auth.updateUserDataProperty({ webListings: this.webListings });
    }

    for (let i = 0; i < this.allListings.length; i++) {
      this.allListings[i].showListing = this.notInEnabledList(this.allListings[i].slug);
    }

  }

  // updateAllWebListings(data) {
  //   for (let i = 0; i < data.listings.length; i++) {
  //     console.log('updateAllWebListings ' + i + ' length: ' + data.listings.length);
  //     const listingItem = data.listings[i];

  //     console.log('listingItem ' + JSON.stringify(listingItem));
  //     //    console.log('listingItem.enhancedMode is ' + listingItem.enhancedMode);
  //     //    console.log('listingItem.webPublished is ' + listingItem.webPublished);

  //     // only show published listings non archived
  //     if (listingItem.enhancedMode !== true
  //       || listingItem.webPublished !== true
  //       || listingItem.archived === true) {
  //       continue;
  //     }

  //     // only show paired listings for lender
  //     if (this.userData.role === 'lender' && listingItem.pairedStatus !== 'paired') {
  //       continue;
  //     }

  //     console.log('listingItem.archived ' + listingItem.archived);

  //     //   console.log('passed the enhanced mode.');

  //     // max 40 listings
  //     if (!this.matchListing(this.webListings, listingItem)) {
  //       //    console.log('pushing listing to web listings: ' + JSON.stringify(listingItem));
  //       this.webListings.push({ id: listingItem.id, title: listingItem.address.title, order: this.webListings.length, URL: listingItem.slug, enabled: false });
  //     }

  //     if (this.matchListing(this.webListings, listingItem)) {
  //       const index = this.getListingIndex(listingItem);
  //       //     console.log('looking for listing: ' + index);
  //       if (index > -1) {
  //         this.webListings[this.getListingIndex(listingItem)].title = listingItem.address.title;
  //         this.webListings[this.getListingIndex(listingItem)].URL = listingItem.slug;
  //       }
  //     }
  //   }

  //   console.log('webListings after update ' + JSON.stringify(this.webListings));
  //   // if (this.userData.webListings.length === 0) {

  //   if (!this.common.isDeepEqual(this.webListings, this.userData.webListings)) {
  //     this.auth.updateUserDataProperty({ webListings: this.webListings });
  //   }

  //   this.userData.webListings = JSON.parse(JSON.stringify(this.webListings));

  // }


  openHelp() {
    if (!this.network.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }



  addSocialIcons() {
    try {
      for (let i = 0; i < this.socialLinks.length; i++) {

        switch (this.socialLinks[i].id) {
          case 'facebook':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-facebook.svg';
            break;
          case 'instagram':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-instagram.svg';;
            break;
          case 'linkedin':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-linkedin.svg';
            break;
          case 'twitter':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-twitter.svg';
            break;
          case 'yelp':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-yelp.svg';
            break;
          case 'youtube':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-youtube.svg';
            break;
          case 'zillow':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-zillow.svg';
            break;
          case 'tiktok':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-tiktok.svg';
            break;
        }
        if (this.socialLinks[i].enabled) {
          this.showSocialLinks = true;
        }
      }
    }
    catch (err) {
      console.log('error adding social icons ' + err);
    }
  }

  readUserAnalytics(filter) {
    const self = this;

    this.analyticsLoading = true;
    console.log('readying user analytics: ' + filter);
    this.analyticsError = false;

    this.auth.getUserAnalytics(filter).then(res => {
      if (res) {
        // self.analyticsStats['counter'].push(res);

        let analytics;

        try {
          analytics = JSON.parse(res.replace(/ga:|\s/g, ''));

          console.log('parsed requestion: ' + JSON.stringify(analytics));
          self.analyticsStats[filter] = JSON.parse(JSON.stringify(self.selectedStats));
          if (analytics.uniquePageviews) {
            self.analyticsStats[filter].uniquePageviews = analytics.uniquePageviews;
          }
          if (analytics.pageviews) {
            self.analyticsStats[filter].pageviews = analytics.pageviews;
          }
          else if (analytics.pageviews === undefined) {
            self.analyticsStats[filter].pageviews.totalEvents = '0';
          }
          if (analytics.EmailClick) {
            self.analyticsStats[filter].EmailClick = analytics.EmailClick;
          } else if (analytics.EmailClick === undefined) {
            self.analyticsStats[filter].EmailClick.totalEvents = '0';
          }
          if (analytics.PhoneNumberClick) {
            self.analyticsStats[filter].PhoneNumberClick = analytics.PhoneNumberClick;
          }
          else if (analytics.PhoneNumberClick === undefined) {
            self.analyticsStats[filter].PhoneNumberClick.totalEvents = '0';
          }
          if (analytics.SMSClick) {
            self.analyticsStats[filter].SMSClick = analytics.SMSClick;
          }
          else if (analytics.SMSClick === undefined) {
            self.analyticsStats[filter].SMSClick.totalEvents = '0';
          }
          //) {
          self.selectedStats = self.analyticsStats[filter];
          // }

          console.log('analytics saved -> ' + JSON.stringify(self.analyticsStats[filter]));
          self.analyticsLoading = false;
        } catch (err) {
          console.log('err parsing analytics ' + err);
          this.handleAnalyticsError();
        }
      }
      else {
        this.handleAnalyticsError();
      }
    }).catch(err => {
      console.log('err parsing analytics 2: ' + err);
      this.handleAnalyticsError();
      // this.analyticsLoading = false;
    });
  }

  // try twice before showing error
  handleAnalyticsError() {
    const self = this;

    if (this.analyticsAttempts % 2 === 0) {
      this.analyticsAttempts++;
      setTimeout(() => { self.readUserAnalytics(self.filterOptions[0]); }, 2000);
    }
    else {
      this.analyticsError = true;
    }
  }

  retryAnalytics() {
    console.log('retry analytics');
    this.analyticsAttempts++;
    // this.readUserAnalytics(this.filterOptions[0]);
  }

  async openAnalyticsFilter(event) {

    if (this.analyticsLoading) {
      return;
    }

    const customMode = !this.plt.is('cordova') || this.plt.is('ios') ? 'ios' : 'md';
    this.currentPopover = await this.popoverCtrl.create({
      component: AnalyticsPopupComponent,
      componentProps: {},
      event,
      cssClass: 'my-custom-popover',
      showBackdrop: true,
      mode: customMode,
    });

    await this.currentPopover.present();
    const { data } = await this.currentPopover.onWillDismiss();

    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      if (data) {
        console.log('data is: ' + data[0]);
        if (!this.analyticsStats[data[0]]) {
          // this.readUserAnalytics(data[0]);
        }
        else {
          this.selectedStats = this.analyticsStats[data[0]];
        }

        this.analyticsFilter = data;
      }
    }


    /* today
      yesterday
      7daysAgo
      30daysAgo
      60daysAgo
      and nothing = lifetime*/
  }

  doneLoadingPreview() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  reloadPreview() {
    setTimeout(() => {
      this.previewMicrositeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.onlylink + this.userData.micrositeUserId + '?=' + Math.random());
      this.previewTimeoutDuration = 15000; //reset to default

    }, 5000);


  }

  ionViewWillLeave() {

    clearInterval(this.loadInterval);

    if (this.userSubs) {
      this.userSubs.unsubscribe();
      this.userSubs = null;
    }

    console.log('unsubscribe microsite');
    if (this.currentPopover) {
      this.currentPopover.dismiss();
    }
    //  this.auth.updateUserDataProperty({ featuredLinks: this.featuredLinks });
    //  this.auth.updateUserDataProperty({ webListings: this.webListings });

    if (this.resizeEvent) {
      this.resizeEvent.unsubscribe();
    }
  }

  matchListing(listings, listing): boolean {
    for (let i = 0; i < listings.length; i++) {
      //   console.log('matching ' + listing.id + ' \n\nwith ' + listings[i].id);
      if (listing.id === listings[i].id) {
        //   console.log('match found!');
        return true;
      }
    }
    return false;
  }

  getListingIndex(listing): number {
    for (let i = 0; i < this.webListings.length; i++) {
      if (listing.id === this.webListings[i].id) {
        return i;
      }
    }

    return -1;
  }

  reorderFeaturedLinks(ev: CustomEvent<ItemReorderEventDetail>) {
    const tempOrder = ev.detail.from;

    if (ev.detail.to === this.featuredLinks.length) {
      ev.detail.to = ev.detail.to - 1;
    }

    this.featuredLinks[ev.detail.from].order = this.featuredLinks[ev.detail.to].order;
    this.featuredLinks[ev.detail.to].order = tempOrder;

    const element = this.featuredLinks[ev.detail.from];
    this.featuredLinks.splice(ev.detail.from, 1);
    this.featuredLinks.splice(ev.detail.to, 0, element);
    this.syncAllElementOrder(this.featuredLinks);
    ev.detail.complete();
    this.auth.updateUserDataProperty({ featuredLinks: this.featuredLinks });
  }

  reorderWebListings(ev: CustomEvent<ItemReorderEventDetail>) {
    const tempOrder = ev.detail.from;

    if (ev.detail.to === this.webListings.length) {
      ev.detail.to = ev.detail.to - 1;
    }

    this.webListings[ev.detail.from].order = this.webListings[ev.detail.to].order;
    this.webListings[ev.detail.to].order = tempOrder;

    const element = this.webListings[ev.detail.from];
    this.webListings.splice(ev.detail.from, 1);
    this.webListings.splice(ev.detail.to, 0, element);
    this.syncAllElementOrder(this.webListings);
    ev.detail.complete();

    this.userData.webListings = JSON.parse(JSON.stringify(this.webListings));

    console.log('update web listings in reorder');
    this.auth.updateUserDataProperty({ webListings: this.webListings });
  }


  syncAllElementOrder(arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i].order = i;
    }
    console.log('list after order sync: ' + JSON.stringify(arr));
  }

  bubbleUpList(source, item) {
    const origin = source.indexOf(item);
    console.log('origin is : ' + origin);

    if (origin === 0 || !item.enabled) {
      return;
    }

    for (let i = 0; i < source.length; i++) {
      if (source[i].enabled === false) {
        console.log('splicing array at ' + i);
        source.splice(origin, 1);
        source.splice(i, 0, item);
        return;
      }
    }
  }

  addFeaturedLink() {
    this.openEditModal('addFeaturedLink');
  }

  async featuredLinkChanged(link, index, event) {

    console.log('featuredLinkChanged checked: ' + event.checked);
    console.log('index: ' + index + ' original: ' + JSON.stringify(this.userData.featuredLinks[index]));
    if (event.checked === this.userData.featuredLinks[index].enabled) {
      console.log('no change to link, returning');
      return;
    }

    const self = this;
    const totalEnabled = this.featuredLinks.filter((item) => item.enabled === true);

    if (link.enabled && totalEnabled.length > MAX_FEATURED_LINKS) {
      const myAlert = await this.alertCtrl.create({
        header: 'Featured Link Limit Reached',
        message: this.i8nService.messages.myMicrositeDisableExistingLinkToEnableAnother,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
              self.featuredLinks[link.order].enabled = false;
              //  return;
            }
          }
        ]
      });
      await myAlert.present();
    }


    console.log('featuredLinkChanged link: ' + JSON.stringify(link));
    console.log('featuredLinkChanged updated: ' + JSON.stringify(this.featuredLinks));
    this.auth.updateUserDataProperty({ featuredLinks: this.featuredLinks });
  }

  async webListingsChanged(link, index, event, fullScreenTakeoverEvent = undefined) {

    event.stopPropagation();
    let fullTakeoverObj;

    if (!event || !link || index < 0 || !this.userData.webListings) {
      console.log('web listings return without change ' + event + ' ' + link + ' ' + index + ' ' + this.userData.webListings);
      return;
    }

    console.log(' webListings changed ' + JSON.stringify(this.webListings));


    if (event.detail.checked === true && event.detail.checked === this.userData.webListings[index].enabled) {
      console.log('no change to link, returning');
      return;
    }


    // if (event.detail.checked === false) {
    //   const res = await this.deleteLinkPrompt();
    //   if (!res) {
    //     event.detail.checked = true;
    //     link.enabled = true;
    //     console.log('return without making a change');
    //     return;
    //   }
    // }

    console.log('web listings changed event ' + index + ' ' + JSON.stringify(link) + ' event ' + JSON.stringify(event.detail));

    // this.bubbleUpList(this.webListings, link);

    console.log('webListings link update: ' + JSON.stringify(link));

    const itemToDisable = this.allListings.findIndex(element => element.slug === link.URL);
    if (itemToDisable && this.allListings[itemToDisable]) {
      this.allListings[itemToDisable].enabled = false;
    }

    this.webListings = this.webListings.filter(item => item.enabled);
    // this.webListings.splice(index, 1);
    this.userData.webListings = JSON.parse(JSON.stringify(this.webListings));

    console.log('update webListings in listings changed' + JSON.stringify(this.webListings));



    if (link.fullScreenTakeover === true) {
      fullTakeoverObj = {
        id: link.id,
        title: link.title,
        order: index,
        URL: link.URL,
        enabled: true,
        fullScreenTakeover: link.fullScreenTakeover,
        landingPage: link.landingPage || 'touch-free-sign-in'
      };
    }

    if (fullScreenTakeoverEvent === false) {
      link.fullScreenTakeover = false;

      this.auth.updateUserDataProperty({ webListings: this.webListings, onlylinkTakeover: {} });
    }
    else if (fullScreenTakeoverEvent === true) {
      link.fullScreenTakeover = true;

      this.auth.updateUserDataProperty({ webListings: this.webListings, onlylinkTakeover: fullTakeoverObj });
    }
    else if (!link.enabled) {

      this.auth.updateUserDataProperty({ webListings: this.webListings, onlylinkTakeover: {} });
    }
    // else if (link.fullScreenTakeover) {
    //   alert('add takeover');
    //   this.auth.updateUserDataProperty({ webListings: this.webListings, onlylinkTakeover: fullTakeoverObj });
    // }
    else {
      alert('no change to takeover');
      this.auth.updateUserDataProperty({ webListings: this.webListings });
    }

  }


  generateItems() {
    const count = this.finalResPaginated.length;

    console.log('infinite scroll called with ' + count);
    if (this.totalShowListings === 0) {
      this.totalShowListings = this.allListings.filter(listing => listing.showListing === true).length;
    }

    if (count >= this.allListings.length) {
      return;
    }

    for (let i = count; i < count + this.paginationSize; i++) {
      if (this.allListings[i] && this.allListings[i].showListing) {
        this.finalResPaginated.push(this.allListings[i]);
      }
    }
  }

  onIonInfinite(ev) {

    this.generateItems();
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 500);
  }

  // add new weblisting
  async addWeblisting(listingItem, index, event) {
    const self = this;
    event.stopPropagation();

    if (event.detail.checked === false && event.detail.checked === this.allListings[index].enabled) {
      return;
    }

    if (this.webListings.length > MAX_LISTINGS_LINKS) {
      const myAlert = await this.alertCtrl.create({
        header: 'Listing Link Limit Reached',
        message: this.i8nService.messages.myMicrositeDisableExistingLinkToEnableAnother,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
              self.allListings[index].enabled = false;
            }
          }
        ]
      });
      await myAlert.present();


    }
    else {

      this.allListings[index].showListing = false;
      listingItem.showListing = false;

      this.webListings.push({
        id: listingItem.id,
        title: listingItem.address.title,
        order: this.webListings.length,
        URL: listingItem.slug,
        enabled: true,
        fullScreenTakeover: listingItem.fullScreenTakeover,
        landingPage: listingItem.landingPage || 'touch-free-sign-in'
      });

      const fullTakeoverObj = {
        id: listingItem.id,
        title: listingItem.address.title,
        order: this.webListings.length,
        URL: listingItem.slug,
        enabled: true,
        fullScreenTakeover: listingItem.fullScreenTakeover,
        landingPage: listingItem.landingPage || 'touch-free-sign-in'
      };

      this.userData.webListings = JSON.parse(JSON.stringify(this.webListings));
      console.log('update listings in push ' + JSON.stringify(this.webListings));
      console.log('listingItem ' + JSON.stringify(listingItem));

      if (listingItem.fullScreenTakeover === true) {
        console.log('fullTakeoverObj ' + JSON.stringify(fullTakeoverObj));
        this.auth.updateUserDataProperty({ webListings: this.userData.webListings, onlylinkTakeover: fullTakeoverObj });
      }
      else {
        console.log('update without takeover');
        this.auth.updateUserDataProperty({ webListings: this.userData.webListings });
      }

      // regenerate paginated items
      self.finalResPaginated = [];
      self.generateItems();
    }
  }

  async handleTakeoverChange(item, index, event, value) {
    console.log('handleTakeoverChange ' + JSON.stringify(item), index, event, value);
    // check if one is already enabled

    const res = await this.updateTakeoverAlert();
    if (!res) {
      return;
    }


    if (value) {
      const result = this.webListings.indexOf(this.webListings.find(x => x.fullScreenTakeover === true));
      console.log('search result: ' + JSON.stringify(result));
      if (result > -1) {

        this.webListings[result].fullScreenTakeover = false;

      }
    }

    item.fullScreenTakeover = value;

    if (!item.enabled) {
      this.addWeblisting(item, index, event);
    }
    else {
      this.webListingsChanged(item, index, event, value);
    }

    // if (value) {
    //   this.auth.updateUserDataProperty({ onlylinkTakeover: item });
    // }
    // else {
    //   this.auth.updateUserDataProperty({ onlylinkTakeover: {} });
    // }

    this.previewTimeoutDuration = 15000;
    this.common.startLoading('Please wait...', 2000);
  }

  notInEnabledList(id) {
    console.log('not in enabled list: ' + this.webListings.find(x => x.URL === id));
    return !this.webListings.find(x => x.URL === id);
  }

  async usernameMoreInfo(event) {
    event.stopPropagation();

    const myAlert = await this.alertCtrl.create({
      header: 'My Username',
      message: 'Your username will be used in the My Link URL so it must be unique. Once you select your username, you can include the URL or QR Code in your marketing.',
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');

          }
        }
      ]
    });
    myAlert.present();
  }

  async openListingHelper() {
    if (this.userData.role === 'agent') {
      const myAlert = await this.alertCtrl.create({
        header: 'My Listings',
        message: 'Listings must have a microsite published in order to be available in My Link. The microsite can be published within the setup of each listing.',
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');

            }
          }
        ]
      });
      myAlert.present();
    }
    else if (this.userData.role === 'lender') {
      const myAlert = await this.alertCtrl.create({
        header: 'My Paired Listings',
        message: 'You can select from the 40 most recent listing microsites created by your paired agents. An agent can publish a microsite for a listing at any time.',
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            role: 'help',
            text: 'Help Section',
            handler: data => {
              console.log('Help Section');
              this.common.openLink('https://juuj.me/creating-a-listing-microsite');
            }
          }
        ]
      });
      myAlert.present();
    }

    else if (this.userData.role === 'agentAdmin') {
      const myAlert = await this.alertCtrl.create({
        header: 'My Paired Listings',
        message: 'You can select from the 40 most recent listing microsites created by your paired agents. An agent can publish a microsite for a listing at any time.',
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            role: 'help',
            text: 'Help Section',
            handler: data => {
              console.log('Help Section');
              this.common.openLink('https://juuj.me/creating-a-listing-microsite');
            }
          }
        ]
      });
      myAlert.present();
    }
  }

  copySlug(event) {
    event.stopPropagation();

    const self = this;
    self.common.copyToClipboard(environment.onlylink + self.userData.micrositeUserId);

    self.copySlugTitle = 'Copied';
    setTimeout(() => {

      self.copySlugTitle = 'Copy';
    }, 2000);
  }

  openSlugLink(event) {
    console.log('open slug link function clicked! ' + event.target);
    event.stopPropagation();

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.common.openLink(environment.onlylink + this.userData.micrositeUserId, '_system');
  }

  shareSlugLink(event) {
    console.log('share slug link function clicked! ' + event.target);
    event.stopPropagation();

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    this.common.openLink(environment.onlylink + this.userData.micrositeUserId + '?display=sharing', '_system');
  }

  publishWeb() {

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.common.startLoading('Publishing...');

    const self = this;
    // this.usernameLoading = true;
    // this.userData.webPublished = true;
    this.freshAccount = true; // first publish
    this.firstLoad = false;
    // GIVE SOME TIME BEFORE AND AFTER PUBLISH IS INITIATED
    setTimeout(() => { self.auth.updateUserDataProperty({ webPublished: true }); }, 250);
    setTimeout(() => { self.common.closeLoading(); }, 12000);
    // setTimeout(() => { self.usernameLoading = false; }, 5000);
    // setTimeout(() => { self.readUserAnalytics(self.filterOptions[0]); }, 60000);
  }

  async editMicrositeUsername(event) {

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    if (this.userData.webPublished === true) {
      const myAlert = await this.alertCtrl.create({
        header: 'Username Cannot Be Changed',
        message: 'At this time, we don\'t support username changes for My Link within this application. However, if you submit a request to <a href="mailto:support@.com" target="_blank" rel="noopener noreferrer">support@curbhe.ro</a> we can coordinate the username change over email.',
        buttons: [
          {
            role: 'cancel',
            text: 'Close',
            handler: data => {
              console.log('Cancel clicked');
              return;
            }
          },
          {
            text: 'Email Support',
            handler: data => {
              console.log('email support');
              this.common.openLink('mailto:support@curbhe.ro', '_system');
            }
          }
        ]
      });
      await myAlert.present();
    }
    else {
      this.openEditModal('micrositeId');
    }
  }

  async openMicrositeUsernameHelper() {
    const helpURL = 'https://juuj.me/where-can-i-use-my-onlyl-ink';

    const myAlert = await this.alertCtrl.create({
      header: 'Where To Use My Link URL',
      message: 'The My Link was designed to be copied and pasted into the "website" field in your Instagram profile. That way you can easily include custom content, property listings, and lead capture right within your profile.'
        + ' Check our Help Section for more My Link use cases including detailed instructions on adding your URL to various social networks and digital marketing.',

      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
            return;
          }
        },
        {
          text: 'Help Section',
          handler: data => {
            console.log('Help Section');
            this.common.openLink(helpURL);
          }
        }

      ]
    });
    myAlert.present();
  }

  async openEditModal(value: any, link?: Links, event = null) {
    const self = this;
    console.log('openEditModal called');
    //link address message i
    let profileModal;

    if (value === 'micrositeId') {
      profileModal = await this.modalCtrl.create({
        component: EditInputPage, componentProps: {
          label: 'Username',
          data: this.userData.micrositeUserId,
          micrositeUsernameInput: true,
          limit: 30,
          minLimit: MIN_USERNAME_LENGTH
        }
      });
      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismissing edit modal ' + data);
      if (!data || data === undefined || data === null) {
        return;
      }

      this.usernameLoading = true;
      this.auth.updateUserDataProperty({ micrositeUserId: data }).then(res => {

        self.publishWeb();

      }).catch(err => {
        self.auth.logErrors('Error making usernameid: ' + err, true);
      });

      setTimeout(() => {
        self.usernameLoading = false;
      }, 8000);
    }
    else if (value === 'addFeaturedLink') {

      const newLink: FeaturedLink = { id: '', title: '', URL: '', order: this.featuredLinks.length, enabled: false };

      profileModal = await this.modalCtrl.create({
        component: EditLinksPage, componentProps: {
          label: 'Add Featured Link',
          data: newLink,
          showTitle: true,
          showTitleOnTop: true,
          showAnimationSection: true,
          showFormTitle: false
        }
      });
      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismissing edit modal ' + data);
      if (data === undefined || data === null) {
        return;
      }
      this.featuredLinks.push(data);
      this.auth.updateUserDataProperty({ featuredLinks: this.featuredLinks });
    }
    else if (value === 'editFeaturedLink') {
      profileModal = await self.modalCtrl.create({
        component: EditLinksPage, componentProps: {
          label: 'Edit Featured Link',
          data: JSON.parse(JSON.stringify(link)),
          edit: true,
          showTitle: true,
          showTitleOnTop: true,
          showAnimationSection: true,
          showFormTitle: false
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();
      console.log('dismissing edit modal ' + JSON.stringify(data));
      if (data === 'delete') {

        self.deleteLink(link, self.featuredLinks);
        return;
      }
      else if (data === 'profile') {

        this.navCtrl.navigate(['/my-profile']);
        return;
      }
      if (data === undefined || data === null) {
        return;
      }
      // let newLink = { id: link.id, title: link.title, URL: link.URL, order: link.order, enabled: link.enabled };
      console.log('index of edit link: ' + self.featuredLinks.indexOf(link));
      self.featuredLinks[self.featuredLinks.indexOf(link)] = data;

      console.log('edit user data: ' + JSON.stringify(self.featuredLinks));
      self.auth.updateUserDataProperty({ featuredLinks: self.featuredLinks });
    }
    else if (value === 'mylisting') {
      console.log('link: ' + JSON.stringify(link));
      console.log('link updated: ' + JSON.stringify(link));
      profileModal = await self.modalCtrl.create({
        component: EditLinksPage, componentProps: {
          label: 'Edit Listing Link',
          data: JSON.parse(JSON.stringify(link)),
          edit: false,
          showTitle: false,
          showTitleOnTop: true,
          showFormTitle: false,
          showAnimationSection: false,
          showTypeSection: true
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      if (data) {

        console.log('dismissing my listing modal ' + JSON.stringify(data));

        // if (data.fullScreenTakeover !== undefined &&
        //   data.fullScreenTakeover !== self.webListings[self.webListings.indexOf(link)].fullScreenTakeover) {

        //   this.handleTakeoverChange(data, self.webListings.indexOf(link), event, data.fullScreenTakeover);
        //   self.webListings[self.webListings.indexOf(link)] = data;
        //   console.log('edit user data weblistings: ' + JSON.stringify(self.webListings));
        //   self.auth.updateUserDataProperty({ webListings: self.webListings });
        // }
        // else {
        self.webListings[self.webListings.indexOf(link)] = data;
        console.log('edit user data weblistings: ' + JSON.stringify(self.webListings));

        link = data;
        let fullTakeoverObj;

        if (link.fullScreenTakeover === true) {
          fullTakeoverObj = {
            id: link.id,
            title: link.title,
            order: link.order,
            URL: link.URL,
            enabled: true,
            fullScreenTakeover: link.fullScreenTakeover,
            landingPage: link.landingPage || 'touch-free-sign-in'
          };
        }

        if (fullTakeoverObj) {
          this.auth.updateUserDataProperty({ webListings: this.webListings, onlylinkTakeover: fullTakeoverObj });
        }
        else {
          console.log('no change to takeover');
          this.auth.updateUserDataProperty({ webListings: this.webListings });
        }
      }
    }
  }

  editFullScreenTakeoverDirect(event) {
    const self = this;

    const result = this.webListings.indexOf(this.webListings.find(x => x.fullScreenTakeover === true));

    this.openEditModal('mylisting', self.webListings[result], event);
  }

  async createNewListing() {
    if (this.userData.role === 'agent') {
      this.events.publish('open:newListing', { listing: 'new' });
    }
    else if (this.userData.role === 'lender') {
      const myAlert = await this.alertCtrl.create({
        header: 'My Paired Listings',
        message: 'Adding listing microsites lets you capture leads that are interested in properties your paired agents are marketing. To add listings you will need to pair with agents. Those agents will need to have listing microsites published in order for them to be enabled in your My Link',
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            role: 'pairAgents',
            text: 'Pair Agents',
            handler: data => {
              console.log('pair agents clicked');
              this.navCtrl.navigate(['/my-paired-agents']);
              //  return;
            }
          }
        ]
      });
      await myAlert.present();
    }
    else if (this.userData.role === 'agentAdmin') {
      const myAlert = await this.alertCtrl.create({
        header: 'My Paired Listings',
        message: 'Adding listing microsites lets you capture leads that are interested in properties your paired agents are marketing. To add listings you will need to pair with agents. Those agents will need to have listing microsites published in order for them to be enabled in your My Link',
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            role: 'pairAgents',
            text: 'Pair Agents',
            handler: data => {
              console.log('pair agents clicked');
              this.navCtrl.navigate(['/my-paired-agents']);
              //  return;
            }
          }
        ]
      });
      await myAlert.present();
    }
  }

  openMyProfile(section: string = '') {
    console.log('open my profile');
    const navigationExtras: NavigationExtras = {
      queryParams: {
        section: section
      }
    };

    if (this.userData.role === 'agent') {
      this.navCtrl.navigate(['/my-profile'], navigationExtras);
    }
    else if (this.userData.role === 'lender') {
      this.navCtrl.navigate(['/my-profile'], navigationExtras);
    }
    else if (this.userData.role === 'agentAdmin') {
      this.navCtrl.navigate(['/my-profile'], navigationExtras);
    }
  }

  async deleteLink(link, source) {
    const self = this;


    const res = await this.deleteLinkPrompt();
    if (!res) {
      //link.enabled = true;
      console.log('return without making a change');

      this.openEditModal('editFeaturedLink', link);
      return;
    }

    source.splice(source.indexOf(link), 1);
    self.auth.updateUserDataProperty({ featuredLinks: self.featuredLinks });
    console.log('links after removal: ' + JSON.stringify(source));
  }

  manualSync() {
    console.log('manual sync called');

    this.common.startLoading('Syncing...');
    this.userData.synced = new Date();
    // GIVE SOME TIME BEFORE AND AFTER SYNC
    setTimeout(() => { this.auth.updateUserDataProperty({ synced: this.userData.synced }); }, 1000);
    setTimeout(() => { this.common.closeLoading(); }, 3000);
  }

  async presentMorePopover(event) {

    event.stopPropagation();

    const options = {
      copy: true,
      shareMicrosite: true,
      save: true,
      howDoIuseMyOnlylink: true,

      edit: false,
      launch: false,
      share: false,
      view: false,
      create: false,
      sync: false,
      delete: false
    };

    if (this.number_of_buttons === 3) {
      options.copy = false;
      options.shareMicrosite = false;
      options.save = false;
    }
    else if (this.number_of_buttons === 2) {
      options.copy = false;
      options.shareMicrosite = false;
    }
    else if (this.number_of_buttons === 1) {
      options.copy = false;
    }

    const customMode = !this.plt.is('cordova') || this.plt.is('ios') ? 'ios' : 'md';
    this.currentPopover = await this.popoverCtrl.create({
      component: ListingActionPopoverComponent,
      event,
      componentProps: { data: { webPublished: true }, options },
      cssClass: 'my-custom-popover',
      showBackdrop: true,
      mode: customMode,
    });

    await this.currentPopover.present();
    const { data } = await this.currentPopover.onWillDismiss();

    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      if (data === 'copy') {
        this.copySlug(event);
      }
      else if (data === 'save') {
        this.openYourQRCode();
      }
      else if (data === 'shareMicrosite') {
        this.shareSlugLink(event);
      }

      else if (data === 'howDoIuseMyOnlylink') {
        this.openMicrositeUsernameHelper();
      }
    }
  }

  async openYourQRCode(event?) {
    if (event) {
      event.stopPropagation();
    }
    const modal = await this.modalCtrl.create({
      component: QRPopupComponent, componentProps: {
        url: environment.onlylink + this.userData.micrositeUserId,
        common: this.common,
        slug: environment.onlylink + this.userData.micrositeUserId,
        qrOnlylink: this.userData.qrCodeOnlylink,
        cordova: this.plt.is('cordova'),
        type: 'your-qr'
      }
    });

    await modal.present();
  }

  segmentChanged(ev: string, element?) {
    console.log('Segment changed', ev);
    this.segmentValue = ev;

    if (element && this.plt.is('mobileweb')) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setButtonsType(type: string) {
    this.userData.customButtonShape = type;
    this.auth.updateUserDataProperty({ customButtonShape: this.userData.customButtonShape });
  }

  setCustomEffect(customEffect: string) {
    this.userData.customEffect = customEffect;
    this.auth.updateUserDataProperty({ customEffect: this.userData.customEffect });
  }

  async updateTakeoverAlert(): Promise<any> {

    return new Promise(async (resolve, reject) => {

      const myAlert = await this.alertCtrl.create({
        header: 'Update Takeover',
        message: this.i8nService.messages.takeoverLinkConfirm,
        buttons: [
          {
            role: 'cancel',
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
              resolve(false);
            }
          },
          {
            role: 'update',
            text: 'Update',
            handler: data => {
              resolve(true);
            }
          }
        ]
      });
      await myAlert.present();
    });
  }

  async showTakeoverInfo(event): Promise<any> {

    event.stopPropagation();

    return new Promise(async (resolve, reject) => {

      const myAlert = await this.alertCtrl.create({
        header: 'Full Screen Takeover Info',
        message: this.i8nService.messages.takeoverInfo,
        buttons: [
          {
            role: 'ok',
            text: 'OK',
            handler: data => {
              resolve(true);
            }
          },
          {
            role: 'Help',
            text: 'Help Section',
            handler: data => {
              this.common.openLink('https://juuj.me/onlylink-takeover');
              resolve(false);
            }
          }

        ]
      });
      await myAlert.present();
    });
  }

  async removeOnlylinkTakeover(event) {

    event.stopPropagation();

    const res = await this.updateTakeoverAlert();
    if (!res) {
      return;
    }

    const result = this.webListings.indexOf(this.webListings.find(x => x.fullScreenTakeover === true));
    console.log('search result: ' + JSON.stringify(result));


    if (result > -1) {
      this.webListings[result].fullScreenTakeover = false;
    }

    this.webListings = this.webListings.filter(item => item.enabled);
    this.userData.webListings = JSON.parse(JSON.stringify(this.webListings));


    // longer timeout for onlylink takeover
    this.previewTimeoutDuration = 15000;

    console.log('update webListings in listings changed' + JSON.stringify(this.webListings));
    this.auth.updateUserDataProperty({ webListings: this.webListings, onlylinkTakeover: {} });
    // this.auth.updateUserDataProperty({ onlylinkTakeover: {} });

    this.common.startLoading('Please wait...', 2000);
  }


  async deleteLinkPrompt(): Promise<any> {

    return new Promise(async (resolve, reject) => {

      const myAlert = await this.alertCtrl.create({
        header: 'Delete Link',
        message: this.i8nService.messages.deleteMicrositeLink,
        buttons: [
          {
            role: 'cancel',
            text: 'Cancel',
            handler: data => {
              resolve(false);
            }
          },
          {
            role: 'Delete',
            text: 'Delete',
            handler: data => {
              console.log('delete clicked');
              resolve(true);
            }
          }
        ]
      });
      await myAlert.present();
    });
  }

  async takeoverUnavailable() {

    const myAlert = await this.alertCtrl.create({
      header: 'Options Unavailable',
      message: this.i8nService.messages.takeoverOptionsUnavailable,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {

          }
        },
        {
          role: 'help',
          text: 'Help Section',
          handler: data => {
            console.log('Help Section');
            this.common.openLink('https://juuj.me/adding-links-to-onlylink');
          }
        }
      ]
    });
    await myAlert.present();
  }

  scrollToMyListings() {
    const element = document.getElementById('myListings');
    element.scrollIntoView({ behavior: 'smooth' });
  }

}
