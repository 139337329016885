/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { merge } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ListingDataProviderService } from 'src/app/services/listing-data-provider.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { ConnectionsService } from 'src/app/services/connections.service';
import { User_global } from 'src/app/app.models';
import { environment } from 'src/environments/environment';
import { CommonProvider } from 'src/app/services/common';
import { ImagesManagementService } from 'src/app/services/images-management.service';
import { IntercomService } from 'src/app/services/intercom.service';
import { EnhancedModalPage } from '../enhanced-modal/enhanced-modal';
import { NetworkProvider } from 'src/app/services/network';
import { Roles } from '../../types';
import { AlgoliaInstanceNames } from '../../constants/algolia';

@Component({
  selector: 'app-floating-paired-item',
  templateUrl: './floating-paired-item.component.html',
  styleUrls: ['./floating-paired-item.component.scss'],
})

export class FloatingPairedItemComponent implements OnInit {
  instanceName: AlgoliaInstanceNames;
  @Input() hideInviteButton = false;
  @Input() noResultsMessage: string;
  
  @ViewChild('myHierarchicalMenu') myHierarchicalMenu;

  name = '';
  company = '';
  pairedItemInputDiv = 7;
  pairedItemContainerDiv = 5;
  state;
  allowedStates: any;

  loanOfficer: any;
  loanOfficerReviews;
  lenderUserLogo;
  anyReviewEnabled;

  isDesktop;

  myRole;

  preferred_headeer_message: string;
  @Input('type') type;
  @Input('pairingLink') pairingLink;

  EmptyStateMessage;
  pairedType;
  floatingItemType;

  isSubscribed;

  constructor(
    private listingDataProvider: ListingDataProviderService,
    public plt: Platform,
    private screenOrientation: ScreenOrientation,
    private connectionService: ConnectionsService,
    private common: CommonProvider,
    private imageService: ImagesManagementService,
    private intercomService: IntercomService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private network: NetworkProvider) {
  }

  async ngOnInit() {
    const self = this;

    const role = User_global.role;
    this.myRole = role;

    if (role === Roles.agentAdmin) {
      this.instanceName = AlgoliaInstanceNames.my_paired_agents_input_admin_invite_agent;
    } else if (role === Roles.agent) {
      this.instanceName = AlgoliaInstanceNames.my_paired_lenders_input_agent_invites_lender;
    } else {
      this.instanceName = AlgoliaInstanceNames.my_paired_agents_input_lender_invite_agent;
    }

    this.onPageResize();
    merge(this.plt.resize, this.screenOrientation.onChange()).subscribe(() => {
      this.onPageResize();
    });

    if (this.type === 'lender') {
      this.preferred_headeer_message = 'Pair your preferred lender in your property marketing';
      this.EmptyStateMessage = 'Enter a lender\'s email to invite them';
      this.pairedType = 'paired-lender';
      this.floatingItemType = 'lender';
    }
    else if (this.type === 'agent') {
      this.preferred_headeer_message = 'Pair Agents on Curb Hero';
      this.EmptyStateMessage = 'Agent not found but still can be invited to Curb Hero';
      this.pairedType = 'paired-agent';
      this.floatingItemType = 'agent';
    }
    // else if (this.type === 'agentAdmin') {
    //   //agentAdmin
    //   this.preferred_headeer_message = 'Pair your preferred lender in your property marketing';
    //   this.EmptyStateMessage = 'Enter a lender\'s email to invite them';
    //   this.pairedType = 'paired-lender';
    //   this.floatingItemType = 'lender';
    // }

    if (User_global.locations && User_global.locations.lvl0 && User_global.locations.lvl0.length) {


      let lenderState = User_global.locations.lvl0[0];

      // DONT USE THE SAMPLE STATE WE USE
      if (User_global.locations.lvl0[0] === 'NA') {
        if (User_global.locations.lvl0.length > 1) {
          lenderState = User_global.locations.lvl0[1];
        }
        else {
          lenderState = false;
        }
      }

      if (this.common.states.indexOf(lenderState) < 0) {
        lenderState = false;
      }

      if (lenderState) {

        this.auth.getLoanOfficerByState(lenderState).subscribe(res => {
          this.connectionService.public_profile(res[0].id).then(res1 => {
            this.loanOfficer = res1;
            this.name = res1.username;
            this.company = res1.company;
            this.state = this.common.statesLongnames[this.common.states.indexOf(User_global.locations.lvl0[0])];


            self.loanOfficer.name = self.loanOfficer.teamname || self.loanOfficer.username;
            self.loanOfficer.shortname = self.loanOfficer.username.split(' ')[0];
            self.loanOfficerReviews = self.loanOfficer.reviews;

            // attach img path to logos
            for (let i = 0; i < self.loanOfficerReviews.length; i++) {
              if (!self.loanOfficerReviews[i].review.reviewLogo.startsWith('assets')) {
                self.loanOfficerReviews[i].review.reviewLogo = environment.lenderReviewImages + self.loanOfficerReviews[i].review.reviewLogo;
              }
              if (self.loanOfficerReviews[i].review.enabled && !this.anyReviewEnabled) {
                this.anyReviewEnabled = self.loanOfficerReviews[i].review.enabled;
              }
              console.log('Review Enabled', this.anyReviewEnabled);
            }

            console.log('user data: ' + JSON.stringify(res));

            if (self.loanOfficer.profilePhoto[0]) {
              self.imageService.realImageData(self.loanOfficer.profilePhoto[0]).then((data) => {
                if (data) {
                  self.loanOfficer.image = data;
                }
              });
            }

            if (self.loanOfficer.logoFile) {
              console.log('loading user logo from app component. ' + self.loanOfficer.logoFile);
              this.imageService.realImageData
                ({ imageURL: self.loanOfficer.logoFile, imagePath: self.loanOfficer.id + '_logo' })
                .then((data) => {
                  if (data) {
                    self.lenderUserLogo = data + '?=' + Math.random();
                  }
                });
            }
          });
        });
      }
    }

    if (User_global.subscriptionStatus !== 'active') {
      this.isSubscribed = false;
    } else {
      this.isSubscribed = true;
    }

    setTimeout(() => {
      // this.toggleFilterVisbility();
      if (User_global.subscriptionStatus !== 'active') {
        this.isSubscribed = false;
      } else {
        this.isSubscribed = true;
      }

    }, 2000);
  }

  async showEnhancedModal(index = 'Intro') {
    const self = this;
    this.intercomService.hideLauncher();

    const modal = await this.modalCtrl.create({
      component: EnhancedModalPage, componentProps:
        { data: index, loanOfficer: this.loanOfficer, loanOfficerReviews: this.loanOfficer.reviews, listing: null },
      cssClass: 'custom-modal-css'
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

    this.intercomService.showLauncher();
  }

  searchInputChange(value) {
    console.log('search input: ' + value);
  }

  onPageResize() {
    if (this.plt.width() < 567) {
      this.pairedItemInputDiv = 12;
      this.pairedItemContainerDiv = 12;
      this.isDesktop = false;
    }
    else {
      if (this.type === 'lender') {
        this.pairedItemInputDiv = 8;
        this.pairedItemContainerDiv = 4;
      }
      else if (this.type === 'agent') {
        this.pairedItemInputDiv = 7;
        this.pairedItemContainerDiv = 5;
      }
      else if (this.type === 'agentAdmin') {
        //agentAdmin
        this.pairedItemInputDiv = 8;
        this.pairedItemContainerDiv = 4;
      }
      this.isDesktop = true;
    }
  }

  async getLoanOfficer(uid) {
    if (uid) {
      return await this.listingDataProvider.loanOfficer(uid).then((res) => {
        this.allowedStates = res.states;
      });
    }
  }

  toggleFilterVisbility() {
    document.querySelectorAll('.collapsy').forEach((panel: any) => {
      // if (panel.style.maxHeight) {
      //   panel.style.maxHeight = null;
      // } else {
      //   panel.style.maxHeight = panel.scrollHeight + 'px';
      // }
      panel.style.maxHeight = panel.scrollHeight + 'px';
    });
  }

  copySlug() {
    this.common.copyToClipboard(this.pairingLink);
  }

  inviteHereAction() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.common.openLink('https://curbhe.ro/invitations');
  }
}
