import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { RolloutConnectProviderProps, BlueprintEnablerProps } from '@rollout/connect-react'

declare global {
  interface Window {
    Rollout: any;
  }
}

@Component({
  selector: 'app-rollout-integration',
  templateUrl: './rollout-integration.component.html',
  styleUrls: ['./rollout-integration.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RolloutIntegrationComponent implements OnInit {
  constructor(private auth: AuthService) {}

  async ngOnInit() {
    const container = document.querySelector('#rollout-container');

    const [token, options] = await Promise.all([
      this.auth.getRolloutToken(),
      this.auth.getRolloutOptions(),
    ]);

    // @TODO: Maybe replace with `RolloutConnectProvider`, we can then remove the import from the index.html
    const ro = window.Rollout.init({
      token,
      apiBaseUrl: environment.rollout_base_url,
    } as RolloutConnectProviderProps);

    const trigger = {
      appKey: environment.rollout_app,
      triggerKey: 'new-lead-trigger',
    } as BlueprintEnablerProps['prefilled']['trigger'];

    const blueprints = [];

    if (options.syncToFollowUpBoss) {
      blueprints.push(getFollowUpBossIntegration(trigger));
    }
    if (options.syncToSalesForce) {
      blueprints.push(getSalesForceIntegration(trigger));
    }
    if (options.syncToRealGeeks) {
      blueprints.push(getRealGeeksIntegration(trigger));
    }
    if (options.syncToCloze) {
      blueprints.push(getClozeIntegration(trigger));
    }
    if (options.syncToSierra) {
      blueprints.push(getSierraIntegration(trigger));
    }
    if (options.syncToHubspot) {
      blueprints.push(getHubspotIntegration(trigger));
    }
    if (options.syncToCINC) {
      blueprints.push(getCINCIntegration(trigger));
    }
    if (options.syncToPipedrive) {
      blueprints.push(getPipedriveIntegration(trigger));
    }

    ro.renderBlueprintsManager(container, { blueprints });
  }
}

function getSalesForceIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToSalesForce',
    name: 'Salesforce',
    prefilled: {
      trigger,
      action: {
        appKey: 'salesforce',
      },
    },
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        ActionKeyField,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Select account and provide lead details',
          createElement(ActionKeyField),
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
  };
}

function getFollowUpBossIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToFollowUpBoss',
    name: 'Follow Up Boss',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Select account and provide contact details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'followup-boss',
        actionKey: 'create-contact',
        inputParams: {
          emailAddress: '{{email}}',
          firstName: '{{firstName}}',
          lastName: '{{lastName}}',
          phoneNumber: '{{phoneNumber}}',
          agentEmail: '{{agentEmail}}',
          // listing
          streetAddress: '{{listingAddress}}',
          city: '{{listingCity}}',
          stateProvince: '{{listingRegion}}',
          zipPostalCode: '{{listingPostalCode}}',

          price: '{{listingPrice}}',
          bedrooms: '{{listingBeds}}',
          bathrooms: '{{listingBaths}}',

          type: 'Property Inquiry',
          source: 'Open House',
        },
      },
    },
  };
}

function getRealGeeksIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToRealGeeks',
    name: 'RealGeeks',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'realgeeks',
        actionKey: 'create-lead',
        inputParams: {
          email: '{{email}}',
          first_name: '{{firstName}}',
          last_name: '{{lastName}}',
          phone: '{{phoneNumber}}',
          notes: '{{Notes}}',
          source: 'Open House',
          agentEmail: '{{agentEmail}}',
          // listing
          street_address: '{{listingAddress}}',
          city: '{{listingCity}}',
          state: '{{listingRegion}}',
          zip: '{{listingPostalCode}}',
          listing_price: '{{listingPrice}}',
          beds: '{{listingBeds}}',
          full_baths: '{{listingBaths}}',
        },
      },
    },
  };
}

function getClozeIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToCloze',
    name: 'Cloze',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'cloze',
        actionKey: 'create-contact',
        inputParams: {
          email: '{{email}}',
          first_name: '{{firstName}}',
          last_name: '{{lastName}}',
          phone: '{{phoneNumber}}',
          source: 'Open House',
          agentEmail: '{{agentEmail}}',
          // listing
          street_address: '{{listingAddress}}',
          city: '{{listingCity}}',
          state: '{{listingRegion}}',
          zip: '{{listingPostalCode}}',
          listing_price: '{{listingPrice}}',
          beds: '{{listingBeds}}',
          full_baths: '{{listingBaths}}',
        },
      },
    },
  };
}


function getSierraIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToSierra',
    name: 'Sierra Interactive',
    renderFields: {
      trigger: false,
      action: {
        appKey: false,
        actionKey: false,
        // Fields that we don't render
        inputParams: {
          password: false,
        }
      }
    },

    prefilled: {
      trigger,
      action: {
        appKey: 'sierra-interactive',
        actionKey: 'create-or-update-deal',
        inputParams: {
          // Even that we don't render the password field, we still need to send an actual value
          password: "password",
          email: '{{email}}',
          firstName: '{{firstName}}',
          lastName: '{{lastName}}',
          phone: '{{phoneNumber}}',
          source: 'Open House',
          streetAddress: '{{listingAddress}}',
          city: '{{listingCity}}',
          state: '{{listingRegion}}',
          zip: '{{listingPostalCode}}',
          note: '{{Notes}}',
        },
      },
    },
  };
}

function getHubspotIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToHubspot',
    name: 'Hubspot',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'hubspot',
        actionKey: 'create-contact',
        inputParams: {
          email: '{{email}}',
          firstName: '{{firstName}}',
          lastName: '{{lastName}}',
          phoneNumber: '{{phoneNumber}}',
        },
      },
    },
  };
}

function getCINCIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToCINC',
    name: 'cinc',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'cinc',
        actionKey: 'create-lead',
        inputParams: {
          email: '{{email}}',
          firstName: '{{firstName}}',
          lastName: '{{lastName}}',
          cellphone: '{{phoneNumber}}',
          source: 'Open House',
        },
      },
    },
  };
}

function getPipedriveIntegration(trigger): BlueprintEnablerProps {
  return {
    blueprintKey: 'syncToPipedrive',
    name: 'pipedrive',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'pipedrive',
        actionKey: 'add-person',
        inputParams: {
          name: '{{fullName}}',
          email: '{{email}}',
          phone: '{{phoneNumber}}',
        },
      },
    },
  };
}
