/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { Component, Input, Output, AfterViewInit, EventEmitter, AfterContentChecked, OnInit, ChangeDetectorRef, OnChanges, OnDestroy, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { ImagesManagementService } from '../../services/images-management.service';
import { Colors, MAX_GUEST_COUNT } from '../../constants';
import { Subscription } from 'rxjs';
import { ListingActionPopoverComponent } from '../listing-action/listing-action';
import { CommonProvider } from 'src/app/services/common';
import { NetworkProvider } from 'src/app/services/network';
import { environment } from '../../../environments/environment';
import { I8nService } from 'src/app/services/i8nService';
import { ConnectionsService } from 'src/app/services/connections.service';

export const GUEST_ICON_WIDTH = 40;
export enum ItemEvents {
  KIOSK,
  EDIT,
  GUESTS,
  SHARE,
  SAVEQR,
  CREATE_MICROSITE,
  DELETE,
  DETAILS,
  OPENLINK
}

@Component({
  selector: 'app-listing-item',
  templateUrl: 'listing-item.component.html',
  styleUrls: ['listing-item.component.scss']
})
export class ListingItemComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, AfterContentChecked {

  @ViewChild('listingBody') listingBody: ElementRef;
  @Input('listing') listing: any;
  @Output('onEvents') onEvents = new EventEmitter();
  @Input('content') content: any;

  @Output('edit') edited = new EventEmitter<boolean>();
  @Output('share') shared = new EventEmitter<boolean>();
  @Output('launch') launched = new EventEmitter<boolean>();
  @Output('create') created = new EventEmitter<boolean>();
  @Output('save') saved = new EventEmitter<boolean>();

  hasPropertyImage: boolean;
  propertyImage: any;
  public maxGuestCount = MAX_GUEST_COUNT;
  countColor: string;
  resizeSubs: Subscription;
  loaded = false;
  currentPopover: any;
  addedByUserText = 'you';

  constructor(
    private images: ImagesManagementService,
    private change: ChangeDetectorRef,
    private plt: Platform,
    public popoverCtrl: PopoverController,
    public common: CommonProvider,
    public networkProvider: NetworkProvider,
    public i8nService: I8nService,
    public alertCtrl: AlertController,
    private connectionsService: ConnectionsService

  ) {
    this.countColor = '#' + Colors[Math.floor(Math.random() * Colors.length)];
  }

  ngOnInit(): void {
    this.hasPropertyImage = this.listing.hasPropertyImage;
    this.resizeSubs = this.plt.resize.subscribe(() => {
      this.maxGuestCount = Math.floor(this.listingBody.nativeElement.clientWidth / GUEST_ICON_WIDTH) - 1;
    });

    this.listing.guests = this.listing.guests.filter(guest => (!guest.archived && !guest.guest.partialLead));

    //console.log('listing item: ' + JSON.stringify(this.listing));

    if (this.listing.listingAssigned) {
      this.loadAssignedUser();
    }
  }

  async loadAssignedUser() {
    console.log('listing is assigned');
    if (!this.listing.assignedByUser || !this.listing.assignedByUser[0]) {
      return;
    }

    const res = await this.connectionsService.getConnectionDetails(this.listing.assignedByUser[0]);

    if (res) {
      //    console.log('listing assigned by user: ' + JSON.stringify(res));
      this.addedByUserText = res.username;
    }
  }

  ngOnDestroy() {
    if (this.resizeSubs && !this.resizeSubs.closed) {
      this.resizeSubs.unsubscribe();
    }

    if (this.currentPopover) {
      this.currentPopover.dismiss();
    }
  }

  ngOnChanges(changes) {

    this.listing.guests = this.listing.guests.filter(guest => !guest.archived && !guest.guest.partialLead);

    this.hasPropertyImage = this.listing.hasPropertyImage;
    if (this.hasPropertyImage && !this.propertyImage || this.listingImg(changes.listing.currentValue) !== this.listingImg(changes.listing.previousValue)) {
      this.loadImage();
    }
    else {
      this.showImage();
    }
  }

  listingImg(listing) {
    if (!listing) {
      return new Date().getTime();
    }
    if (listing.propertyImage) {
      return listing.propertyImage.imageURL;
    }
    return '';
  }

  ngAfterContentChecked() {
    try {
      this.maxGuestCount = Math.floor(this.listingBody.nativeElement.clientWidth / GUEST_ICON_WIDTH) - 1;

      this.listing.guests = this.listing.guests.filter(guest => !guest.archived && !guest.guest.partialLead);
    }
    catch (err) {
      this.maxGuestCount = MAX_GUEST_COUNT;
      // supress
    }
  }

  ngAfterViewInit() {
    if (this.hasPropertyImage) {
      this.loadImage();
    }

    this.listing.guests = this.listing.guests.filter(guest => !guest.archived && !guest.guest.partialLead);
  }

  loadImage() {
    this.images.realImageData(this.listing.propertyImage)
      .then((url) => {

        console.log('setting listing item image url: ' + url);
        this.propertyImage = url;
        this.showImage();
        this.change.detectChanges();

      })
      .catch((error) => {
        console.error('Error loading image:', error);

        // You can handle the error here, for example, setting a default image or displaying an error message.
        this.propertyImage = null;
        this.hasPropertyImage = false;
        this.loaded = true;
      });
  }


  showImage() {
    setTimeout(() => { this.loaded = true; }, 500);
  }

  goToOpenHouseMode(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.KIOSK });
  }
  goEditMode(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.EDIT });
  }
  goToMyGuests(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.GUESTS });
  }
  share(event, eventProp?) {
    if (eventProp) {
      eventProp.stopPropagation();
    }

    if (!this.listing.webPublished) {
      this.warnNotPublished();
    }
    else {
      this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.SHARE });
    }
  }
  saveQR(event) {
    if (event) {
      event.stopPropagation();
    }

    if (!this.listing.webPublished) {
      this.warnNotPublished();
    }
    else {
      this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.SAVEQR });
    }
  }
  createMicrosite(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.CREATE_MICROSITE });
  }
  deleteListing(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.DELETE });
  }

  async presentPopover(mode, event) {
    event.stopPropagation();

    let options;

    if (mode === 'desktop') {
      options = {
        edit: true,
        launch: true,
        delete: true,
        link: true
      };
    }
    else {
      options = {
        edit: true,
        launch: true,
        share: true,
        save: true,
        delete: true,
        link: true
      };
    }


    const customMode = !this.plt.is('cordova') || this.plt.is('ios') ? 'ios' : 'md';
    this.currentPopover = await this.popoverCtrl.create({
      component: ListingActionPopoverComponent,
      event,
      cssClass: 'my-custom-popover',
      showBackdrop: true,
      mode: customMode,
      componentProps: { data: this.listing, options }
    });
    await this.currentPopover.present();

    const { data } = await this.currentPopover.onWillDismiss();
    console.log(data);

    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      if (data === 'edit') {
        this.goEditMode(event);
      }
      else if (data === 'launch') {
        this.goToOpenHouseMode(event);
      }
      else if (data === 'share') {
        console.log('Event for share', data);
        this.share(event);
      }
      else if (data === 'save') {
        this.saveQR(event);
      }
      else if (data === 'create') {
        this.createMicrosite(event);
      }
      else if (data === 'delete') {
        this.deleteListing(event);
      }
      else if (data === 'link') {
        this.openSlugLink(this.listing, event);
      }
    }



  }
  copyListing(listing) {
    console.log('Listing here', this.listing);
    this.common.copyToClipboard(environment.blockparty + listing.slug, 'Details copied', 1000);
  }

  async openSlugLink(listing, event?) {
    console.log('Listing', listing);

    if (event) {
      event.stopPropagation();
    }
    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    else if (!listing.webPublished) {
      this.warnNotPublished();
    }

    // else if (!listing.enhancedMode) {
    //   this.common.showPairedLenderError();
    //   return;
    // }

    else {
      //this.common.openLink(environment.blockparty + listing.slug);
      this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.OPENLINK });
      console.log('Env save', environment.blockparty + listing.slug);
    }
  }

  async warnNotPublished() {
    this.common.openNeedPublishedMicrositeModal()
  }
}
