export const notAllowedPasswords = [
  'thunderbird',
  'christopher',
  'basketball',
  'manchester',
  'enterprise',
  'chevrolete',
  '123456789',
  'alexandra',
  '333333333',
  'godfather',
  'jamesbond',
  'password1',
  'valentine',
  'microsoft',
  'sebastian',
  '987654321',
  '222222222',
  'Iloveyou2',
  'liverpool',
  'spiderman',
  'amsterdam',
  'benjamin',
  'wolfpacks',
  'hollywood',
  'something',
  'password',
  '12345678',
  'baseball',
  'football',
  'jennifer',
  'superman',
  'trustno1',
  'michelle',
  'sunshine',
  'starwars',
  'computer',
  'corvette',
  'princess',
  'iloveyou',
  'maverick',
  'samantha',
  'steelers',
  'whatever',
  'hardcore',
  '1a2b3c4d',
  'internet',
  'mercedes',
  'bigdaddy',
  'Midnight',
  'snowball',
  'carolina',
  '1q2w3e4r',
  'kimberly',
  'slipknot',
  'lasvegas',
  'passw0rd',
  'alexande',
  'wolfgang',
  'pavilion',
  'zeppelin',
  'harrison',
  'hamilton',
  'marathon',
  'predator',
  'hello123',
  'napoleon',
  'stranger',
  'fernando',
  'a1b2c3d4',
  '1234abcd',
  '1qazxsw2',
  '12344321',
  'zaq12wsx',
  'spectrum',
  'testing1',
  'poseidon',
  '24682468',
  '11111111',
  'marlboro',
  'victoria',
  'butthead',
  'startrek',
  'liverpoo',
  'colorado',
  'creative',
  'bollocks',
  'darkness',
  'paradise',
  'maryjane',
  'anderson',
  'sterling',
  'devildog',
  'bluebird',
  '12121212',
  'mongoose',
  'motorola',
  'peterpan',
  'candyman',
  'santiago',
  'catherin',
  'front242',
  'universe',
  'luckydog',
  'halflife',
  'anaconda',
  '19691969',
  'casanova',
  'pumpkins',
  'danielle',
  'redskins',
  'mountain',
  'shithead',
  'xxxxxxxx',
  '88888888',
  'nicholas',
  'metallic',
  'qwertyui',
  'marshall',
  '1qaz2wsx',
  'caroline',
  'snickers',
  'courtney',
  'westside',
  'patricia',
  'semperfi',
  'freeuser',
  'infinity',
  '21122112',
  'icecream',
  'atlantis',
  'stardust',
  'robinson',
  'hongkong',
  'dingdong',
  'monopoly',
  'starship',
  'qwer1234',
  'handsome',
  'gotohell',
  'handyman',
  'cocacola',
  'Rush2112',
  'jonathan',
  'scorpion',
  'einstein',
  'champion',
  'security',
  'veronica',
  'abcd1234',
  'remember',
  'wildcats',
  'wolverin',
  'mistress',
  'stargate',
  'brittany',
  'scotland',
  'swordfis',
  'testtest',
  '12341234',
  'campbell',
  'clifford',
  'sherlock',
  'frontier',
  'anything',
  'eternity',
  'angelica',
  'technics',
  'mechanic',
  'paaswort',
  '21212121',
  'asdfasdf',
  'godzilla',
  'williams',
  'lifehack',
  'platinum',
  'garfield',
  '69696969',
  'jordan23',
  'passport',
  'rolltide',
  'bulldogs',
  'mitchell',
  'patriots',
  'kawasaki',
  'hercules',
  'wrangler',
  'q1w2e3r4',
  'precious',
  '1234qwer',
  'dietcoke',
  'mustangs',
  'columbia',
  'kingkong',
  'thompson',
  'cooldude',
  'piercing',
  'keystone',
  'pa55word',
  'monalisa',
  'lionking',
  'meowmeow',
  'airborne',
  'elephant',
  'explorer',
  'christin',
  'december',
  'dickhead',
  'brooklyn',
  'redwings',
  'michigan',
  '87654321',
  'guinness',
  'elizabet',
  'lawrence',
  'american',
  'stingray',
  'hendrick',
  'panthers',
  'pussycat',
  'loverboy',
  'charlie1',
  'asdf1234',
  'blizzard',
  'engineer',
  'pleasure',
  '51505150',
  'rockstar',
  'ultimate',
  'florence',
  'showtime',
  'maryland',
  'robotech',
  'kingston',
  'happy123',
  'james007',
  'sandwich'
];
