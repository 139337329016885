/* eslint-disable max-len */
import { Component, HostListener, SecurityContext } from '@angular/core';
import {
  NavController, NavParams,
  ModalController, Platform, LoadingController, AlertController
} from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { EditNotesPage } from '../edit-notes/edit-notes';
import { GuestService } from '../../services/guest.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { NetworkProvider } from '../../services/network';
import { UtilsService } from '../../services/utils.service';
import { GUEST_NOTE_PLACEHOLDER } from '../../constants';
import { CommonProvider } from '../../services/common';
import { ConnectionsService } from '../../services/connections.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../services/auth.service';
import { DisclosureDoc, QuestionsAnswers, User_global } from '../../app.models';
import { I8nService } from 'src/app/services/i8nService';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { PdfEditorService } from '../../services/pdf.service';

@Component({
  selector: 'app-my-leads-details',
  templateUrl: 'my-leads-details.html',
  styleUrls: ['my-leads-details.scss']
})

export class MyLeadsDetailsComponent {

  public avatarName: string;
  public avatarColor: any;
  public formData: any;
  public placeholderNote: any;
  fullInfoItems: any[] = [];
  listingAgent;
  userRole;
  pairedAgentInfoMessage: string;

  phone;
  pn: any;
  isMask = false;
  displayName;
  displayNameWordsLength;
  profileModal;

  public isMobilePhone: boolean;

  public noMicrositeQuestionMessage = 'Only partial info will be visible for leads that didn\'t opt-in to having their information shared with a lender. If you don\'t want to see these leads use the filter option in the My Leads table.';

  constructor(
    private utils: UtilsService,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public viewCtrl: ModalController,
    private guestService: GuestService,
    public plt: Platform,
    public network: NetworkProvider,
    public file: File,
    public loadingCtrl: LoadingController,
    private socialSharing: SocialSharing,
    public common: CommonProvider,
    private alertCtrl: AlertController,
    private connectionsService: ConnectionsService,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    public i8nService: I8nService,
    private platform: Platform,
    private pdfEditorService: PdfEditorService
  ) {

    this.userRole = User_global.role;
    this.pairedAgentInfoMessage = this.i8nService.messages.pairedAgentModalMessage;
    this.mobileDeviceCheck();
    this.platform.resize.subscribe(() => {
      this.mobileDeviceCheck();
    });

    setTimeout(() => { this.initialize(); }, 100);
  }

  initialize() {
    try {
      this.formData = this.navParams.get('data');

      console.log('this.formData in constructor ' + JSON.stringify(this.formData));
      if (this.formData.guest) {

        if (!this.formData.guest.source) {
          this.formData.guest.source = '';
        }

        if (this.formData.guest.notes === '') {
          this.placeholderNote = GUEST_NOTE_PLACEHOLDER;
        }

        // PARTIAL LEADS OR SOME OLD LEADS DON'T HAVE THESE 2 FIELDS
        // THEY CAN BE REMADE
        if (!this.formData.createdAt) {
          this.formData.createdAt = this.common.formatDate(new Date(this.formData.createdDate));
          this.formData.time = this.common.formatAMPM(new Date(this.formData.createdDate));
        }

        this.avatarName = this.auth.createAvatarName(this.formData.guest.fullName);
        this.avatarColor = this.utils.avatarColor(this.avatarName);

        const fullInfo: any[] = [];
        this.validDetails(fullInfo, { title: 'General Info', values: this.generalInfoItems(this.details()) });
        this.validDetails(fullInfo, { title: 'Financial Info', values: this.financialInfoItems(this.details()) });
        this.validDetails(fullInfo, { title: 'Property Info', values: this.propertyInfoItems(this.details()) });
        this.validDetails(fullInfo, { title: 'Social Accounts', values: this.socialItems(this.details()) });
        this.fullInfoItems = fullInfo;

        console.log('lead detail: ' + JSON.stringify(this.formData));

        this.phone = this.common.formatPhone(this.formData.guest.phoneNumber, this.formData.guest.phoneCountryCode);
        this.pn = parsePhoneNumber(this.phone);
        //  const countryCode = this.formData.guest.phoneCountryCode ? '+' + this.formData.guest.phoneCountryCode : '';
        // this.pn = parsePhoneNumber(countryCode + this.phone);
      }

      if (this.formData.listings && this.formData.listings.listingAgent) {
        console.log('passed the first if');
        let userId = this.formData.guest.userId ? this.formData.guest.userId : this.formData.listings.listingAgent;
        console.log('need to load agent: ' + userId);

        if (!this.formData.guest && this.formData.listing && this.formData.listing.secondaryListingAgent) {
          userId = this.formData.listing.secondaryListingAgent;
        }

        if (!userId) {
          userId = this.formData.listings.listingAgent;
        }

        if (userId) {
          this.connectionsService.getConnectionDetails(userId).then(res => {
            if (res) {
              this.listingAgent = res;
            }
          }).catch(err2 => {
            console.log('error loading user: ', err2);
          });
        }
      }
    }
    catch (err) {

      console.log('guest details constructor err: ' + err);
      if (this.formData && !this.formData.guest.source) {
        this.formData.guest.source = '';
      }
    }

    if (User_global.role === 'lender' && this.formData && this.formData.questionsAnswers) {
      for (const answers of this.formData.questionsAnswers) {
        if (answers.question === 'Are you looking For Info Regarding a Mortgage?') {
          if (answers.answer === 'No') {
            this.isMask = true;
            this.displayName = this.formData.guest.fullName.split(' ')[0];
            this.displayNameWordsLength = this.formData.guest.fullName.split(' ').length;
          } else {
            this.isMask = false;
            this.displayName = this.formData.guest.fullName;
          }
          return;
        }
        else {
          this.displayName = this.formData.guest.fullName;
        }
      }
    }
    else if (this.userRole === 'agent') {
      this.displayName = this.formData.guest.fullName;
      //agent
    }
    else if (this.userRole === 'agentAdmin' && this.formData && this.formData.questionsAnswers) {
      //agentAdmin
      this.displayName = this.formData.guest.fullName;
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  async deletePrompt() {
    const self = this;

    const alert = await this.alertCtrl.create({
      header: 'Delete Lead',
      message: 'Are you sure you want to delete this lead?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Delete',
          role: 'delete',
          handler: data => {
            self.deleteLead();
          }
        }
      ]
    });

    await alert.present();
  }

  deleteLead() {
    console.log('trying to delete guest: ' + this.formData.guestId);

    this.formData.archived = true;
    this.guestService.archiveGuest(this.formData.guestId, this.formData).then(
      success => console.log('Update guest success : ', success),
      error => console.log(error)
    );
    this.viewCtrl.dismiss('archived');
  }

  validDetails(list: any[], item: any) {
    if (item.values.length > 0) {
      list.push(item);
    }
  }

  details() {
    return this.formData.fullContactGuestDetails || {};
  }

  socialItems(details: any): any[] {
    const ret = [];
    if (details.twitter) {
      ret.push({
        title: 'X',
        values: [{ label: details.twitter, link: details.twitter }],
      });
    }
    if (details.linkedin) {
      ret.push({
        title: 'LinkedIn',
        values: [{ label: details.linkedin, link: details.linkedin }]
      });
    }
    if (details.facebook) {
      ret.push({
        title: 'Facebook',
        values: [{ label: details.facebook, link: details.facebook }]
      });
    }
    return ret;
  }
  propertyInfoItems(details: any): any[] {
    if (!details.household || !details.household.homeInfo) { return []; }
    return [
      { title: 'Home Value', values: [{ label: details.household.homeValueEstimate }] },
      { title: 'Home Loan to Value', values: [{ label: details.household.loanToValueEstimate }] }
    ];
  }
  financialInfoItems(details: any): any[] {
    if (!details.details || !details.details.household || !details.details.household.finance) { return []; }
    return [
      { title: 'Net Worth Estimate', values: [{ label: details.details.household.finance.netWorthRange }] },
      { title: 'Household Income Estimate', values: [{ label: details.details.household.finance.householdIncomeEstimate }] },
      { title: 'Household Debt Estimate', values: [{ label: details.details.household.finance.financialDebtRangeEstimate }] }
    ];
  }

  generalInfoItems(details: any): any[] {
    const ret = [];
    if (details.bio) {
      ret.push({ title: 'Bio', values: [{ label: details.bio }] });
    }
    if (details.details && details.details.age && details.details.age.value) {
      ret.push({ title: 'Age', values: [{ label: details.details.age.value }] });
    }
    if (details.details && details.details.demographics && details.details.demographics.maritalStatus) {
      ret.push(
        { title: 'Marital Status', values: [{ label: details.details.demographics.maritalStatus }] }
      );
    }
    if (details.location) {
      ret.push({
        title: 'Location', values: [{ label: details.location }]
      });
    }
    if (details.details && details.details.employment) {
      this.validDetails(ret, {
        title: 'Current job',
        values: details.details.employment.filter((loc) => loc.current).map((loc) => ({ label: loc.title }))
      });
    }
    if (details.details && details.details.education) {
      this.validDetails(ret, {
        title: 'Education', values: details.details.education.map((loc) => {
          let label = '';
          if (loc.degree && loc.name) {
            label = `${loc.degree} - ${loc.name}`;
          } else if (loc.degree) {
            label = loc.degree;
          } else if (loc.name) {
            label = `${loc.name}`;
          }
          if (loc.end && loc.end.year) {
            label = `${label}\n${loc.end.year}`;
          }
          return [{ label }];
        }).flat()
      });
    }
    return ret;
  }

  public share() {
    const me = this;
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    const address = (this.formData.listings.address.title) ? this.formData.listings.address.title.replace(',', '') : this.formData.listings.address.title;
    const time = (this.formData.time) ? this.formData.time : '';
    let htmlRecord = '';
    if (this.formData.questionsAnswers.length === 0) {
      /*obj['Created At'] = this.formData.createdAt + " " + time;
      obj['Listing Address'] = address;
      obj['Name'] = this.formData.guest.fullName.replace(",", "");
      obj['Email'] = this.formData.guest.email;
      obj['Phone Number'] = this.formData.guest.phoneNumber;
      obj['Notes'] = this.formData.guest.notes;*/
      htmlRecord = 'Created At: ' + this.formData.createdAt + ' ' + time + '\r\n' +
        'Listing Address: ' + address + '\r\n' +
        'Name: ' + this.formData.guest.fullName.replace(',', '') + '\r\n' +
        'Email: ' + this.formData.guest.email + '\r\n' +
        'Phone Number: ' + this.common.formatPhone(this.formData.guest.phoneNumber, this.formData.guest.phoneCountryCode) + '\r\n' +
        'Notes: ' + this.formData.guest.notes;

    }
    this.formData.questionsAnswers.forEach((element, index) => {
      if (index === 0) {
        /*   obj['Created At'] = this.formData.createdAt + " " + time;
           obj['Listing Address'] = address;
           obj['Name'] = this.formData.guest.fullName.replace(",", "");
           obj['Email'] = this.formData.guest.email;
           obj['Phone Number'] = this.formData.guest.phoneNumber; */
        htmlRecord = 'Created At: ' + this.formData.createdAt + ' ' + time + '\r\n' +
          'Listing Address: ' + address + '\r\n' +
          'Name: ' + this.formData.guest.fullName.replace(',', '') + '\r\n' +
          'Email: ' + this.formData.guest.email + '\r\n' +
          'Phone Number: ' + this.common.formatPhone(this.formData.guest.phoneNumber, this.formData.guest.phoneCountryCode) + '\r\n';
      }

      // Questions and answers output
      if (element.multipleChoiceOptions && element.multipleChoiceOptions.length > 0) {
        htmlRecord = htmlRecord + element.question + ': ' + this.getSelectedMultipleChoiceAnswerText(element) + '\r\n';
      }
      else {
        htmlRecord = htmlRecord + element.question + ': ' + element.answer + '\r\n';
      }

      /*  obj[element.question] = element.answer; */
      if (this.formData.questionsAnswers.length === index + 1) {
        /*  obj['Notes'] = this.formData.guest.notes; */
        htmlRecord = htmlRecord + 'Notes: ' + this.formData.guest.notes;
      }
    });

    if (this.plt.is('cordova')) {

      me.socialSharing.share(htmlRecord, 'Curb Hero Open House Guests', null, null).then(() => {
      }).catch((err) => {
        alert(err);
      });

    }
    else {
      const blob = new Blob([htmlRecord], { type: 'text/csv' });
      const fileName = 'Lead_Detail' + new Date().getTime() + '.csv';
      console.log('trying to export on the web');

      const csvURL = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob)));

      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
    }

    /*  itemArr.push(obj);
      var me = this;
      let loading = this.loadingCtrl.create({
        content: 'Please wait...'
      });
      loading.present();
      var result = this.formDataService.JSONToCSVConvertor(itemArr, "", true);
      var blob = new Blob([result], { type: 'text/csv' });
      let fileName = new Date().getTime() + ".csv";
      if (this.plt.is('android')) {
        me.file.createFile(me.file.externalDataDirectory, fileName, true).then(optFile => {
          var blob = new Blob([result], { type: 'text/csv' });
          me.file.writeExistingFile(me.file.externalDataDirectory, fileName, blob).then(writeFile => {
            // alert(JSON.stringify(writeFile)); optFile.nativeURL
            loading.dismiss();
            me.socialSharing.share("Homeslice", "Homeslice File", optFile.nativeURL, null).then(() => {
            }).catch((err) => {
              alert(err);
            });
          }).catch(err => {
            alert('write error' + err);
            loading.dismiss();
          });
        }).catch(err => {
          loading.dismiss();
        });

      } else {
        me.file.createFile(me.file.dataDirectory, fileName, true).then(optFile => {
          var blob = new Blob([result], { type: 'text/csv' });
          me.file.writeExistingFile(me.file.dataDirectory, fileName, blob).then(writeFile => {
            // alert(JSON.stringify(writeFile)); optFile.nativeURL
            loading.dismiss();
            me.socialSharing.share("Homeslice", "Homeslice File", optFile.nativeURL, null).then(() => {
            }).catch((err) => {
              alert(err);
            });
          }).catch(err => {
            alert('write error' + err);
            loading.dismiss();
          });
        }).catch(err => {
          loading.dismiss();
        });

      }
  */
    /*let fileName = new Date().getTime() + ".csv";

    var ref = this.storage.ref('files/' + fileName);
    var uploadTask = ref.put(blob);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        ref.getDownloadURL().subscribe(url => {



          me.socialSharing.share("Homeslice", "", null, url).then(() => {

          }).catch((err) => {
            alert(err);
          });
        });
      })
    ).subscribe()*/
  }

  dismiss() {
    console.log('dismiss guest details');
    this.viewCtrl.dismiss();
  }

  openEmailComposer(emailAddress) {
    // then use alias when sending email
    this.common.sendEmail(emailAddress);
  }

  async editNotes(event) {

    event.stopPropagation();

    if (this.userRole === 'lender') {
      return;
    }
    else if (this.userRole === 'agent') {
      //agent
    }
    else if (this.userRole === 'agentAdmin') {
      //agentAdmin
      return;
    }

    if (this.profileModal) {
      return;
    }

    console.log('edit notes called');

    this.profileModal = await this.modalCtrl.create({ component: EditNotesPage, componentProps: { label: 'Notes', data: this.formData.guest.notes } });
    await this.profileModal.present();

    const { data } = await this.profileModal.onWillDismiss();

    this.profileModal = null;

    if (data === undefined || !data) {
      return;
    }
    if (!this.formData.guest.source) {
      this.formData.guest.source = '';
    }

    if (data === GUEST_NOTE_PLACEHOLDER) {
      this.formData.guest.notes = '';
    } else {
      this.formData.guest.notes = data || '';
    }

    this.updateGuest();
  }

  updateGuest() {
    this.guestService.updateGuest(this.formData.guestId, this.formData.guest).then(
      success => console.log('Update listing success : ', success),
      error => console.log(error)
    );
  }

  getSelectedMultipleChoiceAnswerText(question: QuestionsAnswers) {
    const defaultMissing = 'N/A';

    if (question.multipleChoiceOptions == null || question.multipleChoiceOptions.length === 0) {
      return defaultMissing;
    }

    const selectedAnswer = question.multipleChoiceOptions.find(o => o.isSelected);

    if (selectedAnswer && !selectedAnswer.details?.inputValue) {
      return (selectedAnswer) ? selectedAnswer.value : defaultMissing;
    }

    return (selectedAnswer) ? selectedAnswer.value + ': ' + selectedAnswer.details?.inputValue : defaultMissing;
  }


  goToMarketingServices() {
    this.viewCtrl.dismiss('marketing-services');
    // this.iab.create('https://dncapi-uat1.sbicard.org/v1/t').show();
  }

  mobileDeviceCheck() {
    if (this.platform.width() < 567) {
      this.isMobilePhone = true;
    }
    else {
      this.isMobilePhone = false;
    }
  }

  async disclosureClicked() {
    if (this.formData?.disclosureSelection !== 'confirmButton') {
      const alert = await this.alertCtrl.create({
        header: 'Disclosure Rejected',
        message: `This lead did not accept the disclosure. It won't be available for download`,
        buttons: [
          {
            text: 'Ok'
          }
        ],
        cssClass: 'danger'
      });
      return alert.present();
    }

    await this.common.withLoading(() => this.pdfEditorService.downloadFulfilledDisclosurePDF(this.formData), { loadingText: 'Downloading...'})
  }
}
