import { Component } from '@angular/core';
import { NavParams, NavController } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { TEXT_MODE_INTRO, TEXT_MODE_OUTRO } from '../../components/property-layout/property-layout.component';
import { IntercomService } from '../../services/intercom.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-property-layout-preview',
  templateUrl: 'property-layout-preview.html',
  styleUrls: ['property-layout-preview.scss']
})

export class PropertyLayoutPreviewPage {
  listing: any;
  previewMode = TEXT_MODE_INTRO;
  showOverlay: boolean;
  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private screenOrientation: ScreenOrientation,
    private intercomService: IntercomService,
    private utils: UtilsService
  ) {
    this.listing = this.navParams.get('listing');
    this.showOverlay = this.navParams.get('showOverlay');
    console.log(`Listing: ${this.listing}`);
  }

  ionViewWillEnter() {
    this.intercomService.hideLauncher();
    const orientation = this.utils.propertyLayoutOrientation(this.utils.mapPropertyLayout(this.listing.propertyLayout));
    this.screenOrientation.lock(orientation);
  }

  ionViewWillLeave() {
    setTimeout(() => {
      this.utils.unlockOrientation();
    }, 500);
    this.intercomService.showLauncher();
  }

  close() {
    this.navCtrl.pop();
  }

  previewClick() {
    if (this.previewMode === TEXT_MODE_INTRO) {
      this.previewMode = TEXT_MODE_OUTRO;
    } else {
      this.previewMode = TEXT_MODE_INTRO;
    }
  }
}
