import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, IonTextarea, AlertController } from '@ionic/angular';
import { User_global } from 'src/app/app.models';
import { I8nService } from 'src/app/services/i8nService';

@Component({
  selector: 'app-filter-leads-popup',
  templateUrl: './filter-leads-popup.component.html',
  styleUrls: ['./filter-leads-popup.component.scss'],
})
export class FilterLeadsPopupComponent implements OnInit {

  partialLeadOption = { isSelected: false };
  withoutMortgageOptInLeadOption = { isSelected: false };

  filterOptions;
  selectAll = { isSelected: true };
  currentSelection = 0;
  userRole;

  constructor(public navCtrl: NavController,
    public viewCtrl: ModalController,
    public navParams: NavParams,
    private i8nService: I8nService,
    private alertCtrl: AlertController) {

    this.userRole = User_global.role;

    this.filterOptions = this.navParams.get('data');
    this.currentSelection = this.navParams.get('selectedListing');
    this.partialLeadOption.isSelected = this.navParams.get('showPartialLeads');
    this.withoutMortgageOptInLeadOption.isSelected = this.navParams.get('showMortgageLeads');


    console.log('filter options: ' + JSON.stringify(this.filterOptions));

    console.log('this.currentSelection: ' + this.currentSelection);
    // if (this.currentSelection) {
    //   const itm = this.filterOptions.find(listing => listing.listingId === this.currentSelection);
    //   if (itm) {
    //     itm.isSelected = true;
    //   }
    // }
  }


  async leadPartialInfo(event) {
    event.stopPropagation();

    const myAlert = await this.alertCtrl.create({
      header: 'Partial Entries',
      message: this.i8nService.messages.partialEntriesAlert,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
          }
        }
      ]
    });

    await myAlert.present();
  }

  async leadWithoutMortgageInfo(event) {
    event.stopPropagation();

    const myAlert = await this.alertCtrl.create({
      header: 'Leads without Opt-in',
      message: this.i8nService.messages.withoutMortgageQuestionLeadsFilter,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
          }
        }
      ]
    });

    await myAlert.present();
  }


  handleSelection(event) {
    console.log('selection: ' + event.value);
    if (event.value === '') {
      return;
    }
    this.currentSelection = event.value;
  }


  ngOnInit() { }

  selectedListingChanged(item, index, event) {

  }

  done() {
    this.viewCtrl.dismiss({
      selectedListing: this.currentSelection || 0,
      partialLeads: this.partialLeadOption.isSelected,
      mortgageLeads: this.withoutMortgageOptInLeadOption.isSelected
    });
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
