/* eslint-disable object-shorthand */
import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable } from '@firebase/functions';

@Injectable({ providedIn: 'root' })
export class OpenAIService {

	constructor() { }

	openai(func, prompt): Promise<any> { // Change the return type to Promise<string[]> to match the type of generatedVariations

		// Define the prompt text

		return new Promise<any>((resolve, reject) => {
			const functions = getFunctions();
			const callOpenAi = httpsCallable(functions, func);
			// Call the callable function with the prompt as data
			callOpenAi({ prompt: prompt })
				.then(result => {
					// Handle the result (generated variations)
					const generatedVariations = result.data;
					console.log(generatedVariations); // Log the generated variations to the console
					// Resolve the Promise with the generated variations
					resolve(generatedVariations);
				})
				.catch(error => {
					// Handle errors
					console.error('Error:', error);
					// Reject the Promise with the error
					reject(error);
				});
		});
	}
}
