/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable arrow-body-style */
/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, Inject, forwardRef, Optional, OnInit, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex,
} from 'angular-instantsearch';

import connectHierarchicalMenu, {
  HierarchicalMenuWidgetDescription,
  HierarchicalMenuConnectorParams,
} from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';

@Component({
  selector: 'app-hierarchical-menu',
  templateUrl: './hierarchical-menu.html',
  styleUrls: ['hierarchical-menu.scss']
})
export class HierarchicalMenu extends TypedBaseWidget<
HierarchicalMenuWidgetDescription,
HierarchicalMenuConnectorParams
> {
  @Input('locationData') locationData: Array<string>;
  @Input('restrictStates') restrictStates: boolean;
  @Input('restrictToStates') restrictToStates: Array<string>;

  public selectedLvl0: any;
  public selectedLvl1: any;
  public selectedLvl2: any;

  disableCitiesSelect = true;
  eligibleStates = [];

  public state:
    | HierarchicalMenuWidgetDescription['renderState']
    | undefined; // Rendering options

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    private alertCtrl: AlertController
  ) {

    super('HierarchicalMenu');
  }

  public ngOnInit() {

    this.createWidget(connectHierarchicalMenu, {
      // instance options
      //  attributes: ['locations.lvl0', 'locations.lvl1', 'locations.lvl2'],
      attributes: this.locationData,
      separator: ' > ',
      showParentLevel: true,
      limit: 1000,
      showMore: false,
      showMoreLimit: 1000
    });
    super.ngOnInit();

    // TRYING TO HACK FIX SOME SLOW DEVICE LOAD
    if (this.restrictStates && this.restrictToStates.length) {
      console.log('restrictToStates: ' + this.restrictToStates);
      if (this.state?.items.length) {
        this.eligibleStates = this.state?.items.filter((el) => {
          console.log('filtering: ' + JSON.stringify(el));
          return this.restrictToStates.indexOf(el.value) > -1;
        });
      }
      else {
        setTimeout(() => {
          console.log('trying to filter out eligible states ' + this.state?.items.length);
          this.eligibleStates = this.state?.items.filter((el) => {
            console.log('filtering: ' + JSON.stringify(el));
            return this.restrictToStates.indexOf(el.value) > -1;
          });
        }, 2000);
      }
    }
  }

  // updateLevel0(event: any) {
  //   console.log('update level 0 called: ' + event);
  //   this.state?.refine(event);
  //   this.selectedLvl0 = event;
  // }

  // updateLevel1(event: any) {
  //   console.log('update level 1 called: ' + event);
  //   this.state?.refine(event);
  //   this.selectedLvl1 = event;
  // }

  updateLevel0(event: any) {
    if (event.detail) {
      console.log(JSON.stringify(event.detail));

      this.state?.refine(event.detail.value);

      setTimeout(() => {
        this.selectedLvl0 = event.detail.value;

        console.log('event value: ' + event.detail.value);
      }, 200);
    }
  }

  updateLevel1(event: any) {
    if (event.detail) {
      console.log(JSON.stringify(event.detail));
      this.state?.refine(event.detail.value);
      setTimeout(() => {
        this.selectedLvl1 = event.detail.value;
        console.log('event value: ' + event.detail.value);
      }, 100);
    }
  }

  updateLevel2(event: any) {
    console.log('update level 2 called: ' + event);
    this.state?.refine(event);
    this.selectedLvl2 = event;
  }

  getLevel1() {
    if (this.selectedLvl0) {

      if (this.state && this.state.items && this.state.items.length) {
        this.disableCitiesSelect = false;
      }

      return this.state?.items.filter((el) => {
        console.log(el);
        return String(el.value) === String(this.selectedLvl0);
      })[0];
    }
    return null;
  }

  getLevel2() {
    if (this.selectedLvl1) {
      return this.getLevel1()?.data?.filter((el) => {
        return String(el.value) === String(this.selectedLvl1);
      })[0];
    }
    return null;
  }

  public reset() {
    this.selectedLvl0 = '';
    this.selectedLvl1 = '';
    this.disableCitiesSelect = true;
  }

  async checkForInvalidCity() {
    if (this.disableCitiesSelect) {

      const self = this;

      const myAlert = await self.alertCtrl.create({
        header: 'State Required',
        message: 'Before filtering by a city, a state needs to be selected',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: data => {

            }
          }
        ]
      });

      await myAlert.present();
    }

  }
}
