/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import {
  NavController,
  LoadingController,
  ToastController,
  MenuController,
  AlertController,
  Platform
} from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { NetworkProvider } from '../services/network';
import { IntercomService } from '../services/intercom.service';
import { EMAIL_REGEX, ANDROID_KEYBOARD_HEADER } from '../constants/general.constants';
import { CommonProvider } from '../services/common';
import { UtilsService, KeyboardAction } from '../services/utils.service';
import { Subscription } from 'rxjs';
import { Events } from '../services/events.service';
import { NavigationExtras, Router } from '@angular/router';
import { I8nService } from '../services/i8nService';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';


@Component({
  selector: 'app-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss']
})



export class LoginPage implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loginError: string;
  loginAttempt = false;
  emailValid = true;
  passwordValid = true;
  keyboardHeight: number;
  keyboardSubs: Subscription;
  passwordFails = 0;
  disableLogin = false;

  constructor(
    private navCtrl: NavController,
    private auth: AuthService,
    public alertController: AlertController,
    fb: FormBuilder,
    public menu: MenuController,
    public platform: Platform,
    public network: NetworkProvider,
    public loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private intercomService: IntercomService,
    private common: CommonProvider,
    private changeDetectorRef: ChangeDetectorRef,
    public utils: UtilsService,
    private events: Events,
    private alertCtrl: AlertController,
    private router: Router,
    public i8nService: I8nService,
    private keybaord: Keyboard

  ) {

    this.loginForm = fb.group({
      email: ['', Validators.compose([Validators.required, this.validateEmail])],
      password: ['', Validators.compose([Validators.required])]
    });
    this.menu.swipeGesture(false);
  }

  ngOnInit() {

    this.menu.enable(false);
    this.menu.swipeGesture(false, 'left');

    if (this.network.checkInternet()) {
      this.intercomService.registerUnidentifiedUser();
      this.intercomService.showLauncher();
    }

    // if (this.platform.is('android')) {
    //   this.keyboardSubs = this.utils.keyboardEvents().subscribe((event: any) => {
    //     if (event.action === KeyboardAction.SHOW) {
    //       this.keyboardHeight = event.height + ANDROID_KEYBOARD_HEADER;
    //     } else {
    //       this.keyboardHeight = 0;
    //     }
    //     this.changeDetectorRef.detectChanges();
    //   });
    // }

    this.checkUrlRequest();
  }

  async ionViewWillEnter() {
    this.auth.checkUserBackNavigation();
  }

  // check if the user is trying to signup from the web link
  checkUrlRequest() {

    if (this.platform.is('cordova')) {
      return;
    }

    const url = String(window.location);
    const ref = url.split('?page=')[1];

    // console.log('signUp state: ' + signUp);

    if (ref && ref.length > 0 && ref === 'register') {
      window.history.pushState('register', 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
      this.goToSignUp();
    }
  }

  ngOnDestroy() {
    if (this.keyboardSubs) {
      this.keyboardSubs.unsubscribe();
    }
  }

  checkEmail() {
    const email = this.loginForm.controls.email;
    if (email.valid) {
      this.emailValid = true;
    } else {
      this.emailValid = false;
    }
  }

  validateEmail(c: AbstractControl): { [key: string]: any } {
    if (!c.value) { return null; }
    return EMAIL_REGEX.test(c.value) ? null : {
      validEmail: true
    };
  }

  checkPassword() {
    const password = this.loginForm.controls.password;
    if (password.valid) {
      this.passwordValid = true;
    } else {
      this.passwordValid = false;
    }
  }

  resetEmail() {
    this.emailValid = true;
  }

  resetPassword() {
    this.passwordValid = true;
  }

  public async login() {

    this.common.closeLoading();
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      setTimeout(() => { this.disableLogin = false; }, 1000);
      return;
    }

    if (!this.disableLogin) {
      this.disableLogin = true;


      this.checkEmail();
      this.checkPassword();

      if (this.emailValid && this.passwordValid) {
        this.loginAttempt = true;
        const data = this.loginForm.value;

        if (!this.loginForm.valid) {
          this.loginError = this.i8nService.messages.loginAllFieldsRequired;
          setTimeout(() => { this.disableLogin = false; }, 1000);
          return;
        }


        const credentials = {
          email: data.email,
          password: data.password
        };
        await this.auth.signInWithEmail(credentials)
          .then(async (res) => {
            if (res) {

              // while (!firebase.auth().currentUser) {
              //   console.log('waiting for auth user to exist to login');
              //   await this.utils.delay(1000);
              // }

              setTimeout(() => { this.disableLogin = false; }, 1000);
              if (this.platform.is('cordova')) {
                this.keybaord.hide();
              }
              //intercom first time registeration user
              setTimeout(async () => {
                console.log('publish login event from login page');
                this.events.publish('login:userLogin', res.user.uid);

                if (this.network.checkInternet()) {
                  this.intercomService.showLauncher();
                  this.intercomService.loginIntercomUser(data.email, res.user.uid);
                }
              }, 1000);



              setTimeout(async () => {
                //  console.log('login user res: ' + JSON.stringify(res));
                const toast = await this.toastCtrl.create({
                  message: 'Login Successful',
                  duration: 2500,
                  position: 'top'
                });
                await toast.present();
              }, 500);

              setTimeout(() => {
                this.auth.updateLoginDate(); // update last login timestamp
              }, 2000);
              // this.navCtrl.setRoot(MyListingsPage)
            }
            else {
              this.common.closeLoading();
            }
          },
            error => {
              this.common.closeLoading();
              setTimeout(() => { this.disableLogin = false; }, 1000);
              if (error.code) {

                this.auth.logErrors('LOGIN ISSUES: ' + error.code + ' email: ' + credentials.email, false);

                switch (error.code) {
                  case ('auth/invalid-email'):
                    this.loginError = this.i8nService.messages.invalidEmailOrPassword;
                    break;
                  case 'auth/user-not-found':
                    this.loginError = this.i8nService.messages.invalidEmailOrPassword;
                    break;
                  case 'auth/wrong-password':
                    this.passwordFails++;

                    if (this.passwordFails > 1) {
                      this.accountWrongPassword();
                    }
                    this.loginError = this.i8nService.messages.invalidEmailOrPassword;
                    break;
                  case 'auth/too-many-requests':
                    this.loginError = this.i8nService.messages.loginTooManyAttempts;
                    break;
                  default:
                    // this.loginError = error.message;
                    this.loginError = this.i8nService.messages.serviceUnavailable;
                    this.common.
                      showSimpleError(this.i8nService.messages.loginIssueLoggingYouInDetailed, true);
                    break;
                }
              }
              else {
                setTimeout(() => { this.disableLogin = false; }, 1000);
                this.common.closeLoading();
                this.auth.logErrors('LOGIN ISSUES: ' + error + ' email: ' + credentials.email);
                this.loginError = this.i8nService.messages.serviceUnavailable;
                this.common.
                  showSimpleError(this.i8nService.messages.loginIssueLoggingYouInDetailed, true);
              }
            }
          );
      }
      else {
        setTimeout(() => { this.disableLogin = false; }, 1000);
      }
    }
    else {
      setTimeout(() => { this.disableLogin = false; }, 1000);
    }
  }

  async accountWrongPassword() {
    this.common.closeLoading();
    this.passwordFails = 0;
    const myAlert = await this.alertCtrl.create({
      header: 'Reset Password?',
      message: 'For security purposes, repeated incorrect password attempts can lock your account. Would you like us to send a password reset email to your email address? Otherwise you can dismiss this message and retry entering your password.',
      buttons: [
        {
          role: 'cancel',
          text: 'Dismiss',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Reset Password',
          handler: data => {
            this.onForgotPasswordClick();
          }
        }
      ]
    });
    myAlert.present();
  }

  public goToSignUp() {

    const url = String(window.location);
    const referralCode = url.split('?connection_invite=')[1];
    const navigationExtras: NavigationExtras = {
      queryParams: { connection_invite: referralCode }
    };

    if (referralCode && referralCode.length > 0) {
      this.router.navigateByUrl('/sign-up?connection_invite=' + referralCode);
    }
    else {
      this.navCtrl.navigateForward('sign-up');
    }
  }


  async onForgotPasswordClick(): Promise<void> {
    const me = this;
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    const alert = await this.alertController.create({
      header: 'Forgot Password?',
      backdropDismiss: false,
      message: 'Enter your email so we can send you a reset password link.',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Send',
          handler: (data) => {
            if (!data.email || data.email.trim().length === 0) {
              me.toastCtrl.create({
                message: this.i8nService.messages.enterValidEmail,
                duration: 2500,
                position: 'top'
              }).then(toast => {
                toast.present();
              });
              return;
            }

            me.common.startLoading();
            me.auth.forgotEmail(data.email)
              .then(
                async () => {
                  me.common.closeLoading();
                  const toast = await me.toastCtrl.create({
                    message: 'Reset password link sent.',
                    duration: 2500,
                    position: 'top'
                  });
                  await toast.present();
                },
                async error => {
                  let message;
                  switch (error.code) {
                    case ('auth/invalid-email'):
                      message = this.i8nService.messages.invalidEmailOrPassword;
                      break;
                    case 'auth/user-not-found':
                      message = this.i8nService.messages.invalidEmailOrPassword;
                      break;
                    case 'auth/too-many-requests':
                      message = this.i8nService.messages.loginTooManyAttempts;
                      break;
                    default:
                      message = error.message;
                      break;
                  }
                  const toast = await me.toastCtrl.create({
                    message,
                    duration: 2500,
                    position: 'top'
                  });
                  await toast.present();
                  me.common.closeLoading();
                }
              );
          }
        }
      ]
    });
    await alert.present();
  }
}
