/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, ViewChild } from '@angular/core';
import { NavController, AlertController, NavParams, MenuController, Platform, IonContent, InfiniteScrollCustomEvent } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { Listing, User_global } from '../../app/app.models';
import { NetworkProvider } from '../services/network';
import { CommonProvider } from '../services/common';
import { Colors, MAX_GUEST_COUNT } from '../constants';
import { Subscription, combineLatest } from 'rxjs';
import { ListingDataProviderService } from '../services/listing-data-provider.service';
import { ItemEvents } from '../components/listing-item/listing-item.component';

import { environment } from '../../environments/environment';
import { Events } from '../services/events.service';
import { I8nService } from '../services/i8nService';
import algoliasearch from 'algoliasearch/lite';
import { NavigationExtras, Router } from '@angular/router';
import { ConnectionsService } from '../services/connections.service';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { ListingService } from '../services/listing.service';

const searchClient = algoliasearch(
  'N26A8PC608',
  '068c90fcb4bdad125a840343a2725949'
);

@Component({
  selector: 'app-my-paired-listings',
  templateUrl: 'my-paired-listings.html',
  styleUrls: ['./my-paired-listings.scss']
})
export class MyPairedListingsPage {

  @ViewChild('clearRefinments') clearRefinments;
  @ViewChild('myHierarchicalMenu') myHierarchicalMenu;
  @ViewChild('algoliaSearch') algoliaSearch;

  @ViewChild(IonContent) content: IonContent;
  listingsLabel = 'Paired Listings';
  myConnectionsSub: any;
  myConnections: any;
  connectionsDetails: any = {};
  loading = true;
  showRefresh = true;
  loadInterval: any;
  recentlyActiveModel;
  mySearchParams = { filters: `(NOT listingSnapshot.archived: true)` };
  finalDate;
  config;
  user;
  addListings = false;
  myAgentSubs;
  myPairedAgents;
  userRole = 'lender';
  paginationSize = 12;
  finalResPaginated = [];

  public deviceIsTablet: boolean;
  public myListingsRef: any;

  public myListings = [];
  public noListing = true;
  public buttonColor: string;
  public colors: any[];
  public showSkeleton: boolean;
  public maxGuestCount = MAX_GUEST_COUNT;
  guests: any[] = [];
  public countColor: any;

  public heading: string;
  public description: string;
  public buttonText: string;
  private listingSubs: Subscription;
  private userDataSub: Subscription;


  constructor(
    public navCtrl: Router,
    public navParams: NavParams,
    private auth: AuthService,
    private plt: Platform,
    public alertCtrl: AlertController,
    public events: Events,
    public common: CommonProvider,
    public menu: MenuController,
    public networkProvider: NetworkProvider,
    public router: Router,
    private listingDataProvider: ListingDataProviderService,
    public i8nService: I8nService,
    private connectionsDataService: ConnectionsService,
    private listingService: ListingService
  ) {

  }

  ionViewDidEnter() {
    this.config = {};
    this.mySearchParams = { filters: '' };

    console.log('paired listings did enter');
    if (this.auth.userInitialized) {
      this.initializePairedListings();
      console.log('Users here', this.user);

    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initializePairedListings();
          console.log('Users here', this.user);
        }
      }, 2000);
    }

  }

  initializePairedListings() {

    this.userRole = User_global.role;
    if (this.userRole === 'agent') {
      console.log('User Role', this.userRole);
      this.router.navigate(['/my-listings']);
      return;
    }

    // PROD ALGOLIA IS DIFF
    if (this.userRole === 'lender') {
      if (environment.production) {
        this.config = {
          indexName: 'listings_prod',
          searchClient,
          initialUiState: {
            listings_prod: {
              refinementList: {
                'listingSnapshot.loanOfficer': [String(this.auth.getUID())],
                'listingSnapshot.enhancedMode': ['true']
              }
            }
          }
        };
      }
      else {
        this.config = {
          indexName: 'listings_dev',
          searchClient,
          initialUiState: {
            listings_dev: {
              refinementList: {
                'listingSnapshot.loanOfficer': [String(this.auth.getUID())],
                'listingSnapshot.enhancedMode': ['true']
              }
            }
          }
        };
      }
    }
    else
      if (this.userRole === 'agentAdmin') {
        if (environment.production) {
          this.config = {
            indexName: 'listings_prod',
            searchClient,
            initialUiState: {
              listings_prod: {
                refinementList: {
                  'listingSnapshot.agentAdmin': [String(this.auth.getUID())],
                  // 'listingSnapshot.enhancedMode': ['true']
                }
              }
            }
          };
        }
        else {
          this.config = {
            indexName: 'listings_dev',
            searchClient,
            initialUiState: {
              listings_dev: {
                refinementList: {
                  'listingSnapshot.agentAdmin': [String(this.auth.getUID())],
                  //   'listingSnapshot.enhancedMode': ['true']
                }
              }
            }
          };
        }
      }

    this.menu.enable(true);
    this.menu.swipeGesture(true, 'left');

    this.loading = true;
    const currentDevice: any = this.plt.platforms();
    if (currentDevice.includes('tablet')) {
      this.deviceIsTablet = true;
    } else {
      this.deviceIsTablet = false;
    }
    this.events.publish('listing:menu');
    this.countColor = '#' + Colors[Math.floor(Math.random() * Colors.length)];
    this.heading = 'No Listings Added';

    console.log('listings view did enter.');
    try {
      this.initListingData();
    } catch (err) {
      console.log('err: ' + err);
    }

    if (this.myListings.length === 0) {
      //  this.common.startLoading();
    }

    if (User_global.enableAgentsListingsResultsFiltering) {

      this.noListing = false;
      this.showSkeleton = false;
      this.showRefresh = false;
      this.common.closeLoading();
      this.user = User_global;

      // FORCE RELOAD OF ALGOLIA ON ENTER

      this.resetAlgoliaFilters();


      return;
    }

    setTimeout(() => {
      try {
        this.listingSubs = combineLatest(this.auth.userDataObserver(), this.listingDataProvider.listings(), (user, listings) => ({ user, listings }))
          .subscribe((data) => {

            if (data && data.user) {

              this.user = data.user;

              const listings = data.listings;

              if (User_global.role === 'lender') {
                this.myListings = listings.filter(listing => (listing.enhancedMode === true && listing.pairedStatus === 'paired' && !listing.archived));
              }
              else if (User_global.role === 'agentAdmin') {
                this.myListings = listings.filter(listing => (listing.agentAdmin === this.auth.getUID() && !listing.archived));
              }

              try {
                if (this.myListings === undefined) {
                  this.showRefresh = true;
                  this.common.closeLoading();
                  return;
                }
                if (this.myListings.length !== 0) {
                  if (this.myListings.length === 1) {
                    this.listingsLabel = `${this.myListings.length} Paired Listing`;
                  } else {
                    this.listingsLabel = `${this.myListings.length} Paired Listings`;
                  }
                  this.noListing = false;
                  this.showSkeleton = false;
                } else {
                  this.showSkeleton = true;
                }

                this.finalResPaginated = [];
                this.generateItems();
                this.showRefresh = false;
                // this.common.closeLoading();
              } catch (ex) {
                console.log('listing err1: ' + ex);
                this.showRefresh = true;
                this.common.closeLoading();
              }
            } else {
              console.log('listing err2');
              this.common.closeLoading();
              this.showRefresh = true;
            }
          }, (error) => {
            console.log('listing err3');
            this.common.closeLoading();
            this.showRefresh = true;
          });
      } catch (ex) {
        console.log('listing err4');
        this.showRefresh = true;
        this.common.closeLoading();
        console.log(ex);
      }
    }, 250);


    // SHOW ADD LISTINGS IF LENDER HAS ACTIVE CONNECTIONS
    if (!User_global.enableAgentsListingsResultsFiltering) {
      const self = this;

      if (this.myAgentSubs) {
        this.myAgentSubs.unsubscribe();
      }
      this.myAgentSubs = this.connectionsDataService.connections().subscribe((data) => {
        console.log('my paired agents data: ' + JSON.stringify(data));
        if (User_global.role === 'lender') {
          self.myPairedAgents = data.filter(agent => (agent.status === 'active'));
          if (self.myPairedAgents.length < 1) {
            this.addListings = false;
          }
          else {
            this.addListings = true;
          }
        }
        else if (User_global.role === 'agentAdmin') {
          self.myPairedAgents = data.filter(agent => (agent.status === 'agent_admin'));
          if (self.myPairedAgents.length < 1) {
            this.addListings = false;
          }
          else {
            this.addListings = true;
          }
        }
      });
    }
  }

  ionViewWillLeave() {

    clearInterval(this.loadInterval);

    if (this.listingSubs) {
      this.listingSubs.unsubscribe();
      this.listingSubs = null;
    }
    if (this.userDataSub) {
      this.userDataSub.unsubscribe();
      this.userDataSub = null;
    }
  }


  pairAgents() {
    this.events.publish('open:pairAgents');
  }

  listingsTrackByFn(index, item) {
    return item.id;
  }

  openHelp() {
    if (!this.networkProvider.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }

  initListingData() {

    console.log('subscribe to listing data');
    //   this.menu.swipeGesture(true);

    this.userDataSub = this.auth.userDataObserver().subscribe((data) => {
      if (data) {
        const { primaryColor } = data;
        this.buttonColor = primaryColor;
      }
    });
    // populate connection data
  }

  // check to see if user is trying to redirect to guests page
  // used by SMS notifications for deep linking
  goEditMode(event, selectedListing: Listing, mode = 'openhouse') {
    console.log('go Edit Mode called !' + mode);
    event.stopPropagation();
    if (selectedListing.id !== undefined) {
      this.navCtrl.navigate(['listing-detail/' + `${selectedListing.id}`], {
        queryParams: {
          //  listing: selectedListing,
          listingLayout: selectedListing.propertyLayout,
          //  id: selectedListing.id,
          mode
        }
      });
    }
  }

  createNewListing() {
    const navigationExtras: NavigationExtras = {
      queryParams:
      {
        mode: 'openhouse'
      }
    };
    this.router.navigate(['listing-detail/new'], navigationExtras);
  }

  openMyProfile() {
    console.log('open my profile');
    this.navCtrl.navigate(['/my-profile']);
  }

  async shareListing(event, listingId: string, selectedListing: Listing) {
    if (selectedListing.slug) {
      this.common.openLink(environment.blockparty + selectedListing.slug + '/?display=sharing', '_system');
    }
    else {
      this.common.openNeedPublishedMicrositeModal();
    }
  }

  generateItems() {
    const count = this.finalResPaginated.length;

    console.log('infinite scroll called with ' + count);

    if (count >= this.myListings.length) {
      return;
    }

    for (let i = count; i < count + this.paginationSize; i++) {
      if (this.myListings[i]) {
        this.finalResPaginated.push(this.myListings[i]);
      }
    }
  }

  onIonInfinite(ev) {
    this.generateItems();
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 500);
  }

  listingEvents(event) {
    console.log('listing event: ' + event.event);

    if (event.event === ItemEvents.DETAILS) {
      if (!this.plt.is('cordova')) {
        this.goEditMode(event.ev, event.listing, 'openhouse');
      }
      else {
        this.goEditMode(event.ev, event.listing);
      }
    }
    else if (event.event === ItemEvents.EDIT) {
      // ADMINS CAN OPEN ANYTHING
      if (event.listing.agentAdmin === this.auth.getUID()) {
        this.goEditMode(event.ev, event.listing, 'openhouse');
      } else if (event.listing.listingAssigned && event.listing.assignedByUser[0] === this.auth.getUID()) {
        this.goEditMode(event.ev, event.listing, 'openhouse');
      }
      else if (event.displayMode === 'marketing-services') {
        this.goEditMode(event.ev, event.listing, 'marketing-services');
      }
      else {
        this.actionDisabled();
      }
    } else if (event.event === ItemEvents.GUESTS) {
      this.goToMyGuests(event.ev, event.listing.id);
    } else if (event.event === ItemEvents.KIOSK) {
      this.actionDisabled();
    } else if (event.event === ItemEvents.SHARE) {
      this.shareListing(event.ev, event.listing.id, event.listing);
    } else if (event.event === ItemEvents.SAVEQR) {

      if (User_global.enableAgentsListingsResultsFiltering === true) {

        this.auth.getListingById(event.listing.id).then(res => {
          //   alert(JSON.stringify(res));
          this.common.showQRCode(environment, res);
        });
      }
      else {
        this.common.showQRCode(environment, event.listing);
      }



    } else if (event.event === ItemEvents.CREATE_MICROSITE) {
      this.goEditMode(event.ev, event.listing, 'microsite');
    } else if (event.event === ItemEvents.DELETE) {

      if (User_global.role === 'lender') {
        this.actionDisabled();
        return;
      }

      if (User_global.role === 'agentAdmin'
        && event.listing.assignedByUser
        && event.listing.assignedByUser[0] === this.auth.getUID()) {
        this.deletePrompt(event.ev, event.listing);
        return;
      }

      this.actionDisabled();
    }
  }

  async deletePrompt(event, listing) {

    const self = this;
    const deleteConfirmInfoModal = await this.alertCtrl.create({
      header: 'Delete Listing',
      message: 'Are you sure you want to delete this listing on Curb Hero?',
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Delete',
          handler: data => {
            console.log('Current Delete ID: ' + listing.id);

            if (!listing.sampleListing) {
              self.listingService.deleteImages(listing);
            }

            self.listingService.removeListingFromMicrosite(listing, User_global);
            self.listingService.deleteListing(listing.id).then(
              success => { },
              error => console.log(error)
            );
          }
        },
      ]
    });
    await deleteConfirmInfoModal.present();
  }

  goToMyGuests(event, listingId) {
    event.stopPropagation();
    const navigateExtras: NavigationOptions = {
      queryParams: {
        listingId
      }
    };

    this.navCtrl.navigate(['/my-leads'], navigateExtras);
  }

  goToPairedAgents(event) {
    event.stopPropagation();
    this.navCtrl.navigate(['/my-paired-agents']);
  }

  learnMore() {
    if (User_global.role === 'agentAdmin') {
      this.common.openLink('https://juuj.me/how-does-agent-admin-pairing-work');
    }
    else {
      this.common.openLink('https://juuj.me/creating-and-managing-a-lender-subscription');
    }
  }

  refreshPage() {
    this.common.reloadPage();
  }

  toggleFilterVisbility() {
    document.querySelectorAll('.collapsy').forEach((panel: any) => {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    });
  }

  recentlyActiveSelectionChange(event) {

    if (this.recentlyActiveModel === 'all') {
      this.mySearchParams = { filters: `(NOT listingSnapshot.archived: true)` };
    }
    else if (this.recentlyActiveModel) {
      const d = new Date();
      d.setDate(d.getDate() - Number(this.recentlyActiveModel));
      console.log('time: ' + d.getTime());
      this.finalDate = d.getTime();
      // this.mySearchParams = { hitsPerPage: 2 };

      if (User_global.regionalLender === true) {
        this.mySearchParams = { filters: `listingSnapshot.createdAt > ${this.finalDate}` };
      }
      else {
        this.mySearchParams = { filters: `listingSnapshot.mostRecentLeadCreatedAt > ${this.finalDate}` };
      }
    }
  }

  resetAlgoliaFilters() {

    if (this.clearRefinments && this.clearRefinments.state) {
      this.clearRefinments.state.refine();
    }

    if (this.myHierarchicalMenu) {
      this.myHierarchicalMenu.reset();
    }


    setTimeout(() => {
      this.recentlyActiveModel = 'all';
      this.mySearchParams = { filters: `(NOT listingSnapshot.archived: true)` };
    }, 250);
  }

  async actionDisabled() {
    const myAlert = await this.alertCtrl.create({
      header: 'Disabled Action',
      message: this.i8nService.messages.disabledActionMessage,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
      ]
    });
    myAlert.present();
  }
}
