/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */

import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { LoadingController, Platform, MenuController, AlertController, IonRouterOutlet } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { CurbUser, User_global, BlankUser } from '../../app/app.models';
import { NetworkProvider } from '../services/network';
import { notAllowedPasswords } from '../constants';
import { IntercomService } from '../services/intercom.service';
import { EMAIL_REGEX, ANDROID_KEYBOARD_HEADER, MASK } from '../constants/general.constants';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { Subscription } from 'rxjs';
import { UtilsService, KeyboardAction } from '../services/utils.service';
import { CommonProvider } from '../services/common';
import { Router } from '@angular/router';
import { Events } from '../services/events.service';
import { CookieService } from 'ngx-cookie';
import { I8nService } from '../services/i8nService';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-page-sign-up',
  templateUrl: 'sign-up.html',
  styleUrls: ['sign-up.scss']
})

export class SignUpPage implements OnInit, OnDestroy {
  signUpError: string;
  signUpForm: FormGroup;
  mask: any;
  submitAttempt = false;
  nameValid = true;
  phoneValid = true;
  emailValid = true;
  passwordValid = true;
  keyboardHeight: number;
  keyboardSubs: Subscription;
  keyboard: Keyboard;
  variant = '';
  role: any = 'agent';
  agentChecked = { isChecked: true };
  lenderChecked = { isChecked: false };
  agentAdminChecked = { isChecked: false };
  reloadAppTimeout: any = {};
  disableSignup = false;
  fpid = '';
  welcomeMessage = 'You are one step away from real estate property marketing that\’s gonna make you look SO good';
  btnRealEstate = 'outline';
  btnMortgage = 'solid';
  btnTeamAdmin = 'solid';

  constructor(
    fb: FormBuilder,
    private navCtrl: Router,
    private auth: AuthService,
    public platform: Platform,
    public loadingCtrl: LoadingController,
    public network: NetworkProvider,
    private intercomService: IntercomService,
    private changeDetectorRef: ChangeDetectorRef,
    public utils: UtilsService,
    private common: CommonProvider,
    private menu: MenuController,
    private events: Events,
    private alertCtrl: AlertController,
    private cookieService: CookieService,
    public i8nService: I8nService,
    public routerOutlet: IonRouterOutlet
  ) {

    // Stop swiping back to login screen on iOS.
    try {
      this.routerOutlet.swipeGesture = false;
      this.mask = MASK;

      this.signUpForm = fb.group({
        name: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern('^[A-Za-z0-9? .\']+$')])],
        phone: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(20)])],
        email: ['', Validators.compose([Validators.required, this.validateEmail])],
        password: ['', Validators.compose([Validators.required, this.passwordValidator.bind(this), Validators.minLength(8)])],
        termsAndConditions: [false, Validators.compose([Validators.requiredTrue])]
      });
    }
    catch (err) {
      console.log(err);
    }

    console.log('constructor sign up loaded fine.');
  }

  validateEmail(c: AbstractControl): { [key: string]: any } {

    if (!c.value) { return null; }

    return EMAIL_REGEX.test(c.value) ? null : {
      validEmail: true
    };
  }

  // validatePhone(c: AbstractControl): { [key: string]: any } {

  //   if (!c.value) { return null; }
  //   return PHONE_REGEX.test(c.value) ? null : {
  //     validPhone: true
  //   };
  // }

  checkValidation() {
    console.log('Sign Up', this.signUpForm.controls);
    if (this.signUpForm.controls.name.valid) {
      this.nameValid = true;
    } else {
      this.nameValid = false;
    }
    if (this.signUpForm.controls.phone.valid) {
      this.phoneValid = true;
    } else {
      this.phoneValid = false;
    }
    if (this.signUpForm.controls.email.valid) {
      this.emailValid = true;
    } else {
      this.emailValid = false;
    }
    if (this.signUpForm.controls.password.valid) {
      this.passwordValid = true;
    } else {
      this.passwordValid = false;
    }
    if (!this.signUpForm.controls.termsAndConditions.valid) {
      this.submitAttempt = true;
    }
  }

  resetFields(value) {
    if (value === 'name') { this.nameValid = true; }
    if (value === 'phone') { this.onPhoneChange(this.signUpForm.value.phone); this.phoneValid = true; }
    if (value === 'email') { this.emailValid = true; }
    if (value === 'password') { this.passwordValid = true; }
  }

  public signupWrapper() {
    try {
      this.signup();
    }
    catch (err) {
      this.common.closeLoading();
      this.auth.logErrors('Failed creating account for credentials : ' + err, true);
      this.common.
        showSimpleError(this.i8nService.messages.signupCreateUserDBError, true);
    }
  }

  public async signup() {

    console.log('submit button clicked');

    this.auth.logClientInfo('GET STARTED SUBMIT CLICKED: '
      + ' email: ' + this.signUpForm.value.email + ' -- '
      + ' phone: ' + this.signUpForm.value.phone + ' -- '
      + ' username: ' + this.signUpForm.value.name + ' -- '
      + ' role: ' + this.role + ' -- '
      + ' password valid: ' + this.passwordValid + ' -- '
      + ' form valid: ' + this.signUpForm.valid + ' -- '
      + ' term checkbox: ' + this.signUpForm.controls.termsAndConditions.valid,
    );

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      setTimeout(() => { this.disableSignup = false; }, 1000);
      return;
    }

    if (!this.disableSignup) {
      this.disableSignup = true;

      this.checkValidation();
      const data = this.signUpForm.value;

      if (this.nameValid && this.phoneValid && this.emailValid && this.passwordValid) {
        this.submitAttempt = true;
        if (this.signUpForm.valid) {

          this.common.startLoading('Creating Account...');

          //   const data = this.signUpForm.value;
          const credentials = JSON.parse(JSON.stringify(BlankUser));

          credentials.email = data.email.toLowerCase();
          credentials.password = data.password;
          credentials.username = data.name;

          // Try this out, if good, we can amend the common func below later.
          let phone = data.phone.trim();
          phone = (phone.startsWith('+1')) ? phone.substring(2) : phone;
          phone = this.common.cleanPhoneNumber(phone);
          credentials.phoneNumber = this.common.cleanPhoneNumber(phone);

          credentials.role = this.role;
          credentials.createdAt = new Date();
          credentials.firstPromoterTid = this.fpid;

          await this.auth.createUserAuth(credentials).then(async (user) => {
            if (user) {

              if (this.auth.getUID()) {
                console.log('waiting for auth user to exist');
                this.createUserDB(credentials, user);
              }
              else {
                setTimeout(() => {
                  this.createUserDB(credentials, user);
                }, 3000);
              }

              setTimeout(() => { this.disableSignup = false; }, 1000);
            }
            else {
              this.common.closeLoading();
              delete credentials.password;
              this.auth.logErrors('Failed creating account for credentials 2: ' + JSON.stringify(credentials), true);
              this.common.
                showSimpleError(this.i8nService.messages.signupCreateUserDBError, true);
            }
          },
            error => {
              setTimeout(() => { this.disableSignup = false; }, 1000);

              const errorData = {
                email: credentials.email,
                username: credentials.username,
                phone: credentials.phoneNumber,
                role: credentials.role,
                errorCode: error
              };

              this.auth.logErrors('Failed creating account for credentials 3: ' + JSON.stringify(errorData), true);

              this.common.closeLoading();
              if (error.code) {
                switch (error.code) {
                  case ('auth/invalid-email'):
                    this.signUpError = 'Invalid email';
                    break;
                  case 'auth/email-already-in-use':
                    //  this.signUpError = 'Email already in use';
                    this.accountExistsError();
                    break;
                  case 'auth/operation-not-allowed':
                    this.signUpError = this.i8nService.messages.signupEmailRestrictedContactSupport;
                    break;
                  case 'auth/weak-password':
                    this.signUpError = this.i8nService.messages.signupWeakPassword;
                    break;
                  case 'app/storage-get':
                    this.common.showDBWriteError(true);
                    break;
                  default:
                    // this.signUpError = 'To create a secure account, your browser settings need to allow cookies. See our terms of service for how we manage your data privacy.';
                    this.signUpError = this.i8nService.messages.serviceUnavailable;
                    this.auth.logErrors('Sign up error: ' + JSON.stringify(error.code));
                    this.common.
                      showSimpleError(this.i8nService.messages.signupCreateUserDBError, true);
                    break;
                }
              }
            }
          );
        }
        else {
          setTimeout(() => { this.disableSignup = false; }, 1000);
        }
      }
      else {
        setTimeout(() => { this.disableSignup = false; }, 1000);
      }
    }
  }

  async accountExistsError() {
    this.common.closeLoading();
    const myAlert = await this.alertCtrl.create({
      header: 'Account Issues?',
      message: this.i8nService.messages.signupEmailInUseLoginHint,
      buttons: [
        {
          role: 'cancel',
          text: 'Dismiss',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Login',
          handler: data => {
            this.navCtrl.navigate(['/login']);
          }
        }
      ]
    });
    myAlert.present();
  }

  async createUserDB(credentials, user) {
    try {
      // using callable
      const res = await this.auth.createUserCallable(credentials, user);

      setTimeout(() => {
        this.disableSignup = false;
      }, 1000);

      if (res && (res === 'USER_CREATED' || res?.status === 'USER_CREATED')) {
        setTimeout(() => {
          this.events.publish('login:userLogin', user.uid);
          this.auth.setIntercomTag(credentials.email, credentials.role);
        }, 500);

        setTimeout(() => {
          this.common.toast('Profile has been successfully created', 5000, 'top');
        }, 2000);

        if (this.network.checkInternet()) {
          this.intercomService.showLauncher();
          this.intercomService.registerUser(credentials.email, credentials.username, credentials.phoneNumber, user.uid, credentials.role);
        }

        console.log('Response from create user db: ' + JSON.stringify(res));
      } else {
        this.auth.logErrors(
          `Error creating user: ${credentials.email}, username: ${credentials.username}, phone: ${credentials.phoneNumber}, role: ${credentials.role}, response: ${JSON.stringify(res)}`,
          true
        );
        setTimeout(() => {
          this.common.showSimpleError(this.i8nService.messages.signupCreateUserDBError, true);
        }, 2000);
      }
    } catch (err) {
      this.disableSignup = false;
      this.auth.logErrors(
        `Error creating user: ${credentials.email}, username: ${credentials.username}, phone: ${credentials.phoneNumber}, role: ${credentials.role}, error: ${err}`,
        true
      );

      setTimeout(() => {
        this.common.showSimpleError(this.i8nService.messages.signupCreateUserDBError, true);
      }, 2000);

      console.log('Error creating user: ' + err);
      this.common.closeLoading();
    }
  }



  onPhoneChange(phone: string) {
    // this.rawPhone = phone;
    if (phone) {
      const output = phone.replace(/\D+/g, '');
      console.log(`phone: ${phone} output: "${output}"`);
      return output;
    }
  }

  readTerms() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    this.common.openLink('https://curbhe.ro/terms/');
  }

  openLoginPage() {

    const url = String(window.location);
    const referralCode = url.split('?connection_invite=')[1];
    /*
    const navigationExtras: NavigationExtras = {
      queryParams: { connection_invite: referralCode }
    };
*/
    if (referralCode && referralCode.length > 0) {
      this.navCtrl.navigateByUrl('/login?connection_invite=' + referralCode);
    }
    else {
      this.navCtrl.navigate(['/login']);
    }
  }

  ngOnInit() {

    setTimeout(() => { this.getFirstPromoterID(); }, 5000);
    this.menu.enable(false);
    this.menu.swipeGesture(false, 'left');
    this.checkUrlRequest();

    // if (this.platform.is('android')) {

    //   this.keyboardSubs = this.utils.keyboardEvents().subscribe((event: any) => {
    //     if (event.action === KeyboardAction.SHOW) {
    //       this.keyboardHeight = event.height + ANDROID_KEYBOARD_HEADER;
    //     } else {
    //       this.keyboardHeight = 0;
    //     }
    //     this.changeDetectorRef.detectChanges();
    //   });
    // }
  }
  ionViewDidEnter() {
    try {
      this.auth.checkUserBackNavigation();
    }
    catch (err) {
      console.log(err);
    }

    console.log('did enter - sign up loaded fine.');
  }
  // check if the user is trying to signup from the web link
  checkUrlRequest() {

    if (this.platform.is('cordova')) {
      return;
    }

    const url = String(window.location);
    let ref = url.split('?variant=')[1];

    if (ref && ref.length > 0) {
      this.variant = ref.split('&')[0];
      console.log('variant is: ' + this.variant);
      // this.variant = ref;

      if (this.variant === 'lender-invite-aa') {
        this.updateRole('lender');
        this.lenderChecked.isChecked = true;
      }
      else if (this.variant === 'onlylink-sign-up-a') {
        this.updateRole('agent');
        this.agentChecked.isChecked = true;
      }
      else if (this.variant === 'open-house-sign-up-a') {
        this.updateRole('agent');
        this.agentChecked.isChecked = true;
      }
      else if (this.variant === 'agent-admin-aa') {
        this.updateRole('agentAdmin');
        this.agentAdminChecked.isChecked = true;
      }
      else {
        this.variant = '';
      }
    }

    // CHECK SIGN UP ROLE
    ref = url.split('role=')[1];
    let role;
    if (ref && ref.length > 0) {
      role = ref.split('&')[0];
      console.log('role is: ' + role);

      if (role === 'lender' || role === 'teamlead') {
        this.role = role;
        this.updateRole(this.role);
      }
      else if (role === 'agent') {
        //agent
      }
      else if (role === 'agentAdmin') {
        //agentAdmin
        this.role = role;
        this.updateRole(this.role);
      }
      // this.variant = ref;
    }
  }

  checkToRedirectToWebForLenderSignup() {
    if (this.platform.is('cordova')) {
      return this.alertCtrl.create({
        header: 'Mortgage Role',
        message: 'The features for lenders are only available on our web application.',
        buttons: [
          {
            text: 'Open Web App',
            handler: data => {
              //  this.common.openLink(environment.webapp + '/?variant=lender-invite-aa', '_system');
              this.common.openLink(environment.webapp + '/?variant=lender-invite-aa');
            }
          },
          {
            role: 'cancel',
            text: 'Dismiss',
            handler: () => { }
          },
        ]
      }).then(alert => {
        alert.present().then(() => {
          this.agentChecked.isChecked = true;
          this.lenderChecked.isChecked = false;
          this.agentAdminChecked.isChecked = false;
        });
      });
    }

    this.updateRole('lender');
  }

  checkToRedirectToWebForAdminSignup() {
    if (this.platform.is('cordova')) {
      return this.alertCtrl.create({
        header: 'Team Admin Role',
        message: 'The features for Team Admin are only available on our web application.',
        buttons: [
          {
            text: 'Open Web App',
            handler: data => {
              // this.common.openLink(environment.webapp + '/?variant=agent-admin-aa', '_system');
              this.common.openLink(environment.webapp + '/?variant=agent-admin-aa');
            }
          },
          {
            role: 'cancel',
            text: 'Dismiss',
            handler: () => { }
          },
        ]
      }).then(alert => {
        alert.present().then(() => {
          this.agentChecked.isChecked = true;
          this.lenderChecked.isChecked = false;
          this.agentAdminChecked.isChecked = false;
        });
      });
    }
    this.btnTeamAdmin = 'outline';
    this.updateRole('agentAdmin');
  }

  updateRole(role) {
    this.role = role;
    console.log('update role to : ' + role);

    if (role === 'lender') {
      //lender
      this.btnMortgage = 'solid';
      this.btnRealEstate = 'outline';
      this.btnTeamAdmin = 'outline';
      this.welcomeMessage = 'Partner with agents to get in front of buyers that are actively looking at property';
      this.agentChecked.isChecked = false;
      this.agentAdminChecked.isChecked = false;
    }
    else if (role === 'agent') {
      //agent
      this.btnMortgage = 'outline';
      this.btnRealEstate = 'solid';
      this.btnTeamAdmin = 'outline';
      this.welcomeMessage = 'You are one step away from real estate property marketing that\’s gonna make you look SO good';
      this.lenderChecked.isChecked = false;
      this.agentAdminChecked.isChecked = false;
    }
    else if (role === 'agentAdmin') {
      //agentAdmin
      this.btnMortgage = 'outline';
      this.btnRealEstate = 'outline';
      this.btnTeamAdmin = 'solid';
      this.welcomeMessage = 'As a team admin, you\’ll save your agents\’ time, maintain consistent branding, and manage lead activity';
      this.agentChecked.isChecked = false;
      this.lenderChecked.isChecked = false;
    }
  }

  ngOnDestroy() {
    if (this.keyboardSubs) {
      this.keyboardSubs.unsubscribe();
    }
  }

  passwordValidator(control: FormControl) {
    const password = control.value;
    if (notAllowedPasswords.indexOf(password) >= 0) {
      return {
        passwordDomain: {
          parsedDomain: this.i8nService.messages.signupPasswordNotAllowed
        }
      };
    }
    return null;
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  getFirstPromoterID() {
    console.log('get first promoter called ');
    this.fpid = this.getCookie('_fprom_tid') || '';
    console.log('first promoter id: ' + this.fpid);
  }
}
