import { Pipe, PipeTransform } from '@angular/core';
import { ImagesManagementService } from '../../services/images-management.service';

@Pipe({
  name: 'listingImg',
  pure: false
})
export class ListingImgPipe implements PipeTransform {
  private currentUrl: any;
  private targetUrl = '';
  constructor(
    private images: ImagesManagementService
  ) {

  }
  transform(value: string, ...args) {
    let img = '';
    let id = '';
    if (value === 'property') {
      img = args[0].propertyImage;
      id = args[0].id;
    } else if (value === 'raw') {
      img = args[0];
    }
    if (id && img) {
      if (this.targetUrl !== id) {
        this.currentUrl = '';
        this.targetUrl = id;
        new Promise((resolve, reject) => {

          if (img) {
            this.images.realImageData(img).then((data) => {
              this.currentUrl = data;
            }).catch((error) => {

            });
          } else {

          }
        });
      }
    } else {
      return '';
    }
    return this.currentUrl;
  }
}
