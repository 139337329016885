/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnInit, ViewChild, AfterViewChecked, HostListener } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, LoadingController } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { NetworkProvider } from '../../services/network';
import { environment } from '../../../environments/environment';
import { CommonProvider } from '../../services/common';

import Swiper from 'swiper';

import { Autoplay, Pagination, Navigation, EffectCreative, Parallax } from 'swiper/modules';
import { EMAIL_REGEX } from '../../constants';

import { AlgoliaInstanceNames } from '../../services/algolia-instant-search.service';
import { Roles } from '../../types';

Swiper.use([Pagination, Navigation]);

@Component({
  selector: 'app-enhanced-modal',
  templateUrl: 'enhanced-modal.html',
  styleUrls: ['enhanced-modal.scss']
})
export class EnhancedModalPage implements OnInit, AfterViewChecked {

  // @ViewChild('enhancedModalSlides') slides: IonSlides;

  @ViewChild('swiper', { static: false }) swiper?: any;

  slideIndex: any;
  loanOfficer: any;
  loanOfficerReviews: any;
  currentPage = 'Intro';
  // Since a couple of slides are shared for both inviting lenders and admins, we track which version are we supposed to show
  roleToInvite: Roles.lender | Roles.agentAdmin = Roles.lender;
  listing: any;
  showAllSlides = true;

  config = {
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  instanceName: AlgoliaInstanceNames;
  noResultsMessage: string;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public viewCtrl: ModalController,
    public plt: Platform,
    public network: NetworkProvider,
    public file: File,
    public loadingCtrl: LoadingController,
    private common: CommonProvider
  ) { }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  ngOnInit(): void {
    this.slideIndex = this.navParams.get('data');
    this.loanOfficer = this.navParams.get('loanOfficer');
    this.loanOfficerReviews = this.navParams.get('loanOfficerReviews');
    this.listing = this.navParams.get('listing');

    const self = this;
    console.log('show enhanced modal for listing: ' + JSON.stringify(this.listing));

    console.log('loanOfficerReviews: ' + JSON.stringify(this.loanOfficerReviews));

    // attach img path to logos
    for (let i = 0; i < self.loanOfficerReviews.length; i++) {
      if (!self.loanOfficerReviews[i].review.reviewLogo.startsWith('assets')) {
        self.loanOfficerReviews[i].review.reviewLogo = environment.lenderReviewImages + self.loanOfficerReviews[i].review.reviewLogo;
      }
    }

    //alert(JSON.stringify(this.loanOfficer.objectID) + ' ' + this.loanOfficer.regionalLender);
    if (this.loanOfficer && this.loanOfficer.name !== '' && this.slideIndex === 'Intro') {

      if (this.loanOfficer.regionalLender === true) {
        this.showAllSlides = true;
        this.currentPage = 'Intro';
      }
      else {
        this.showAllSlides = false;
        this.currentPage = 'Intro';
      }


    }
    else {
      this.currentPage = 'EnableLender';
    }


    setTimeout(() => {

      const swiperEl = document.querySelector('#enhancedModelSwiper') as HTMLElement;

      if (swiperEl) {
        //   alert('addign obj');
        // Assign the parameters to the Swiper element
        Object.assign(swiperEl, this.config);

        // Check if initialize method exists before calling
        if (typeof (swiperEl as any).initialize === 'function') {
          console.log('enhancedModelSwiper init called');
          (swiperEl as any).initialize();


        } else {
          console.error('enhancedModelSwiper initialize method not found');
        }
      } else {
        console.error('enhancedModelSwiper element not found');
      }
    }, 100);
  }

  ngAfterViewChecked() {
    if (this.swiper) {
      this.swiper.update();
    }
  }

  changeEnhancedMode(value) {
    this.viewCtrl.dismiss({ type: 'enhancedMode', enhancedMode: value });
  }

  nextSlide() {
    this.swiper.slideNext();
  }

  tellMeMore() {
    this.viewCtrl.dismiss({ type: 'tellMeMore' });
  }

  disableLender() {
    this.currentPage = this.loanOfficer.regionalLender ? 'DisableRegionalLender' : 'DisableLender';
  }

  openLoanOfficerWebsite() {
    console.log('open website: ' + this.loanOfficer.website);
    if (this.loanOfficer.website) {
      this.common.openLink(this.loanOfficer.website);
    }
  }

  openExternalLink(link) {
    if (link === 1) {
      this.common.openLink('https://juuj.me/all-about-the-expert-lender-concierge');
    }
    else {
      this.common.openLink('https://juuj.me/what-are-enhanced-features');
    }
  }

  goTo(page) {
    this.currentPage = page;
  }

  dismiss() {
    console.log('dismiss enhanced details');
    this.viewCtrl.dismiss();
  }

  invite(roleToInvite: Roles.lender | Roles.agentAdmin) {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.roleToInvite = roleToInvite;

    if (this.roleToInvite === Roles.lender) {
      this.instanceName = AlgoliaInstanceNames.enhanced_modal_agent_invites_lender;
      this.noResultsMessage = "Enter a lender's email to invite them";
    } else if (this.roleToInvite === Roles.agentAdmin) {
      this.instanceName = AlgoliaInstanceNames.enhanced_modal_agent_invites_admin;
      this.noResultsMessage = "Enter Team Admin's email to invite them";
    }

    this.currentPage = 'Invite';
  }

  onEmailSent() {
    this.currentPage = 'ThankYouPage';
  }
}
