import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ImagesManagementService } from 'src/app/services/images-management.service';

@Pipe({
  name: 'firebaseUrl',
})
export class FirebaseUrlPipe implements PipeTransform {
  constructor(
    private imageService: ImagesManagementService
  ) { }

  transform(value: string, ...args) {
    if (value) {
      if (value.startsWith('http')) {
        return Promise.resolve(value);
      }
      if (value.startsWith('data:')) {
        return Promise.resolve(`${value}`);
      }
      return this.imageService.firebaseUrl(value);
    } else {
      return Promise.resolve('');
    }
  }
}
