/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-property-layout-selector',
  templateUrl: 'property-layout-selector.component.html',
  styleUrls: ['property-layout-selector.component.scss']
})
export class PropertyLayoutSelectorComponent {
  @Input('listing') listing: any;
  @Output('onSelect') onSelect = new EventEmitter();
  isPhone: boolean;

  constructor(
    private utils: UtilsService
  ) {

    console.log('property layout selector init');

    this.isPhone = this.utils.isMobilePhone();

    console.log('property layout isphone: ' + this.isPhone);
  }

  selectPropertyLayout(layout) {
    this.onSelect.emit(layout);
  }
}
