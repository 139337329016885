import { Injectable } from '@angular/core';
import QRCodeStyling, { CornerDotType } from 'qr-code-prettier';
import { environment } from 'src/environments/environment';
import { User_global } from '../app.models';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

  constructor(private platform: Platform) { }

  /**
   * Generate a QR code with customizable options.
   *
   * @param url - The data to encode in the QR code.
   * @param primaryColor - The color of the QR code dots and corners.
   * @param img - The URL or path to an image to overlay on the QR code.
   * @param cornerDots - The type of corner dots to use.
   * @param outerMargin - The margin around the QR code.
   * @returns Promise that resolves to a Blob containing the generated QR code.
   */
  async generateQRCode(
    url: string = '',
    primaryColor: string = '#000',
    img: string = '',
    cornerDots: CornerDotType = 'square',
    outerMargin = 2
  ): Promise<Blob> {

    console.log('generateQRCode: ' + url + ' - ' + primaryColor + ' - ' + img + ' - ' + cornerDots + ' - ' + outerMargin);
    const now = new Date();
    if (img && img.startsWith('assets')) {
      const imgName = img.split('/');
      console.log(JSON.stringify(imgName));
      img = environment.storageLogos + imgName[3] + '?now=' + now.getTime() + '&alt=media';
    }

    else if (img) {
      if (img.indexOf('?') > -1) {
        img = img + '&now=' + now.getTime();
      }
      else {
        img = img + '?now=' + now.getTime();
      }
    }

    console.log('img after: ' + img);
    return new Promise(async (resolve, reject) => {

      let hideDots = false;
      if (!img) {
        hideDots = false;
      }
      else {
        hideDots = true;
      }

      console.log(QRCodeStyling);
      if (!QRCodeStyling) {
        reject(null);
      }

      const qrCode = new QRCodeStyling({
        width: 500,
        height: 500,
        margin: outerMargin,
        data: url,
        image: String(img),
        dotsOptions: {
          color: primaryColor,
          type: 'dots'
        },
        cornersSquareOptions: { type: 'extra-rounded', color: primaryColor },
        backgroundOptions: {
          color: '#fff'
        },
        cornersDotOptions: { type: cornerDots, color: primaryColor },

        imageOptions: {
          hideBackgroundDots: hideDots,
          crossOrigin: 'anonymous',
          imageSize: 0.4,
          margin: 1
        },
        qrOptions: { errorCorrectionLevel: 'H' }
      });

      qrCode.getRawData('png').then(blob => {
        if (blob) {
          resolve(blob);
        }
        else {
          reject(null);
        }
      }, err => {
        reject(err);
      });
    });
  }
}

