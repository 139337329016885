import { Injectable } from '@angular/core';
import { getAnalytics, logEvent } from '@firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {

  }

  public logKioskEvent(message) {
    try {

      const analytics = getAnalytics();
      logEvent(analytics, 'custom_mortgage_question', { message });

    } catch (err) {
      console.error(err);
    }
  }
}
