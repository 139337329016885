/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, NgZone, ElementRef, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { NavParams, ModalController, Platform, IonSearchbar, MenuController, AlertController } from '@ionic/angular';
import { Address, LoanOfficer, CurbUser, User_global } from '../../app.models';
import { NetworkProvider } from '../../services/network';
import { Subject, of, Observable, combineLatest, debounceTime, switchMap } from 'rxjs';
import { CommonProvider } from '../../services/common';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import algoliasearch from 'algoliasearch/lite';
import { map } from 'rxjs/operators';
import { ConnectionsService } from 'src/app/services/connections.service';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { ListingDataProviderService } from 'src/app/services/listing-data-provider.service';
import { I8nService } from 'src/app/services/i8nService';
// import { InfoModalComponent } from 'src/app/components/info-modal/info-modal.component';

const searchClient = algoliasearch(
  'N26A8PC608',
  '068c90fcb4bdad125a840343a2725949'
);

declare const google;

@Component({
  selector: 'app-edit-input-address',
  templateUrl: 'edit-input-address.html',
  styleUrls: ['edit-input-address.scss']
})
export class EditInputAddressPage implements AfterViewInit {

  @ViewChild('ionSearchbar') ionSearchbar: IonSearchbar;
  @ViewChild('algoliaSearch') algoliaSearch;
  @ViewChild('clearRefinments') clearRefinments;
  @ViewChild('myHierarchicalMenu') myHierarchicalMenu;

  public inputLabel: any;
  public inputData: string;

  GoogleAutocomplete: any;
  // GooglePlacesService: any;
  autoCompleteAddress: any = {};
  autocompleteItems: any;
  itemSelected: boolean;
  addressInputChanged: boolean;

  testAutocomplete: any;
  inputSubject: Subject<string>;
  dialogLabel: string;
  placeId: string;
  states: Array<string>;
  longStates: Array<string>;
  addressState: string;
  originalState: string;
  originalAddress: string;
  userData: CurbUser;
  assignedByUser: string[];

  stateChanged = false;
  loadInterval: any;
  eligibleStates;
  eligibleStatesLoaded = false;
  config;
  finalDate;
  recommendedAgentConfig;
  recentlyActiveModel;
  env: any = {};
  copySlugTitle = 'Copy';
  loading = true;
  showRefresh = true;
  userSubs: any;
  userZipcode = '';
  currentLocationView = '';  //searchZipCodeView, enterZipCodeView, currentZipCodeView
  loadFailed = false;
  myAgentSubs: any;
  myPairedAgents: any = [];
  myInvitedAgents: any = [];
  showPairedAgentsDiv = true;
  firstLoad = true;
  userRole: string;
  mySearchParams = {};
  dismissData: any = {};
  agent: any;
  allowedStates: any;
  restrictCreation = false;

  isPrimary = 'medium';
  // checkedAgents: any = [];
  checkedAgentsId: any = [];
  displayName = '';

  createMode = true;
  MAX_AGENTS = 10;
  totalPairedAgents = [];

  constructor(
    public navCtrl: Router,
    public zone: NgZone,
    public plt: Platform,
    public networkProvider: NetworkProvider,
    public viewCtrl: ModalController,
    public navParams: NavParams,
    private keyboard: Keyboard,
    private changeDetector: ChangeDetectorRef,
    public common: CommonProvider,
    public utils: UtilsService,
    private menu: MenuController,
    private auth: AuthService,
    private connectionsDataService: ConnectionsService, // user connections
    private listingDataProvider: ListingDataProviderService,
    private i8nService: I8nService,
    private modalCtrl: ModalController,
    private connectionService: ConnectionsService,
    private alertCtrl: AlertController
  ) {

    console.log('start constructor');

    try {

      this.inputLabel = this.navParams.get('label');
      const add = this.navParams.get('add');
      if (add) {
        this.dialogLabel = `Add ${this.inputLabel}`;
      } else {
        this.dialogLabel = `Edit ${this.inputLabel}`;
      }

      this.placeId = this.navParams.get('placeId') || '';
      this.addressState = this.navParams.get('state') || '';
      this.originalState = this.navParams.get('state') || '';
      this.assignedByUser = this.navParams.get('assignedByUser') || [];
      this.checkedAgentsId = this.navParams.get('pairedListingAgents') || [];

      this.getTotalPairedAgents();

      try {
        this.agent = this.checkedAgentsId[0].id || '';
      }
      catch (err) {
        this.agent = this.checkedAgentsId[0] || '';
      }

      setTimeout(() => {
        if (this.networkProvider.checkInternet()) {
          this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
          // this.GooglePlacesService = new google.maps.places.PlacesService(document.getElementById('editAddressDiv'));
        }
      }, 2000);

      if (this.agent || this.placeId || this.addressState) {
        this.createMode = false;
      }

      this.getPrimaryAgent();

      console.log('got address state: ' + this.addressState);
      console.log('placeId is: ' + this.placeId);
      this.originalAddress = this.navParams.get('title');
      this.autoCompleteAddress = { title: this.navParams.get('title'), placeId: this.placeId };
      this.autocompleteItems = [];
      this.addressInputChanged = false;

      this.inputSubject = new Subject();

      this.inputSubject.pipe(
        debounceTime(500),
        switchMap((query) => {
          console.log(`search query ${query}`);
          if (query) {
            return this.predict(query);
          } else {
            return of([]);
          }
        })
      ).subscribe((predictions) => {
        this.autocompleteItems = predictions;
        this.changeDetector.detectChanges();
      });

      this.states = common.states;
      this.longStates = common.statesLongnames;
      console.log('states array is :' + this.states);

      this.restrictRegionalLender();

    }
    catch (err2) {
      console.log('problem with popup ' + err2);
    }

    console.log('finished contructor');
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.viewCtrl.dismiss();
  }

  // RESTRICT THE CREATION OF LISTINGS BY REGIONAL LENDER
  async restrictRegionalLender() {
    if (!User_global.regionalLender) {
      this.restrictCreation = false;
      return;
    }

    if (!this.allowedStates) {
      const data = await (this.getLoanOfficer(this.auth.getUID()) as LoanOfficer);
    }

    if (this.allowedStates && this.allowedStates.indexOf(this.addressState) < 0) {
      this.restrictCreation = true;
    }
    else {
      this.restrictCreation = false;
    }
  }

  async getLoanOfficer(uid) {

    console.log(' get loan officer called for ' + uid);
    if (uid) {
      return await this.listingDataProvider.loanOfficer(uid).then((res) => {
        console.log('loan officer response: ' + JSON.stringify(res));
        this.allowedStates = res.states;
        console.log('lender allowed states: ' + this.allowedStates);
      });
    }
  }



  predict(query: string) {
    return Observable.create((observer) => {
      if (this.GoogleAutocomplete) {
        this.GoogleAutocomplete.getPlacePredictions({ input: query },
          (predictions, status) => {
            observer.next(predictions || []);
            observer.complete();
          });
      }
    });
  }

  addressFieldChange() {
    console.log('addressFieldChange!');
    this.addressInputChanged = true;
    if (this.autoCompleteAddress.title.trim() !== this.originalAddress.trim()) {
      this.placeId = '';
    }
  }

  updateSearchResults() {

    const query = this.autoCompleteAddress.title.trim();
    if (query) {
      this.placeId = undefined;
      this.inputSubject.next(query);
    } else {
      this.autocompleteItems = [];
    }
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  done() {
    // LENDER CREATE
    // if (this.userRole === 'lender') {
    //   this.dismissData.assignedByUser = [this.auth.getUID()];

    //   if (this.agent) {
    //     this.dismissData.listingAgent = this.agent;
    //     this.dismissData.listingAssigned = true;
    //   }

    //   if (this.stateChanged) {
    //     if (this.addressState !== this.dismissData.state) {
    //       this.dismissData.state = this.addressState;
    //       this.dismissData.placeId = this.placeId;
    //     }
    //   }

    //   console.log(this.stateChanged + ' ' + this.itemSelected);
    //   // PRE SELECTED ADDRESS CLOSE

    //   if (this.addressInputChanged) {
    //     this.dismissData.title = this.autoCompleteAddress.title.trim();
    //   }
    //   if (this.itemSelected) {
    //     this.viewCtrl.dismiss(this.dismissData);
    //     return;
    //   }

    //   if (this.autocompleteItems.length) {
    //     this.viewCtrl.dismiss(this.autoCompleteAddress);
    //     return;
    //   }

    //   this.viewCtrl.dismiss(this.dismissData);
    //   return;
    // }

    // AGENT CREATE
    if (this.userData.role === 'agent') {
      if (this.autocompleteItems.length && !this.stateChanged) {
        this.viewCtrl.dismiss(this.autoCompleteAddress);

      } else {

        console.log('sending save with ' + this.addressState);
        this.viewCtrl.dismiss({ title: this.autoCompleteAddress.title.trim(), placeId: this.placeId, state: this.addressState });
      }
    }
    else if (this.userData.role === 'agentAdmin' || this.userData.role === 'lender') {
      //agentAdmin
      if (this.createMode) {
        this.dismissData.assignedByUser = [this.auth.getUID()];
      }

      if (this.agent) {
        console.log(this.agent);
        this.dismissData.listingAgent = this.agent;
        this.dismissData.listingAssigned = true;
        this.dismissData.pairedListingAgents = this.createListingPairs();
        this.dismissData.state = this.addressState;
        this.dismissData.placeId = this.placeId;
        console.log('dismissed data: ', this.dismissData);
      }

      if (this.stateChanged) {
        if (this.addressState !== this.dismissData.state) {
          this.dismissData.state = this.addressState;
          this.dismissData.placeId = this.placeId;
        }
      }

      console.log(this.stateChanged + ' ' + this.itemSelected);
      // PRE SELECTED ADDRESS CLOSE

      if (this.addressInputChanged) {
        this.dismissData.title = this.autoCompleteAddress.title.trim();
      }
      if (this.itemSelected) {
        this.viewCtrl.dismiss(this.dismissData);
        return;
      }

      if (this.autocompleteItems.length) {
        this.autoCompleteAddress.listingAgent = this.agent;
        this.autoCompleteAddress.listingAssigned = true;
        this.autoCompleteAddress.pairedListingAgents = this.createListingPairs();

        this.viewCtrl.dismiss(this.autoCompleteAddress);
        return;
      }

      console.log('this.dismissData ' + JSON.stringify(this.dismissData));
      this.viewCtrl.dismiss(this.dismissData);
      return;
    }
  }

  createListingPairs() {

    // if (!this.createMode) {
    //   return this.checkedAgentsId;
    // }

    const listingIds = this.auth.generateNewDocIds(this.checkedAgentsId.length);

    if (this.checkedAgentsId.length === listingIds.length) {
      for (let i = 0; i < this.checkedAgentsId.length; i++) {

        if (this.checkedAgentsId[i].listingId === undefined) {
          this.checkedAgentsId[i].listingId = listingIds[i];
        }
        if (this.checkedAgentsId[i].enabled === undefined) {
          this.checkedAgentsId[i].enabled = true;
        }
      }
    }

    return this.checkedAgentsId;
  }

  checkboxWrapperClick(hit, event) {

    if (!this.createMode && this.isPrimaryAgent(hit.objectID)) {
      this.clickInfoText();
    }
  }

  async primaryAgentCannotChangeWarning() {
    const myAlert = await this.alertCtrl.create({
      header: 'Primary Agent',
      message: 'The primary agent on a listing can\'t be changed. They can make changes to the listing that will be autoupdated to other agents assigned to the listing. It\'s common to make the primary agent match the actual listing agent, but it\'s not a requirement.',
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
      ]
    });
    myAlert.present();
  }

  async clickInfoText() {

    this.primaryAgentCannotChangeWarning();

    // const modal = await this.modalCtrl.create({
    //   component: InfoModalComponent,
    //   componentProps: {
    //     title: this.i8nService.messages.editAddressInfoAdminHeader,
    //     content: `
    //       <p>${this.i8nService.messages.editAddressInfoAdmin}</p>
    //       <ul>
    //         <li>Make other edits to the listing which will be updated in all assigned agents' accounts</li>
    //         <li>Collect new leads for the listing which will be visible in all assigned agents' accounts</li>
    //         <li>View all leads collected for this listing for as long as they're assigned to it</li>
    //       </ul>
    //       <p>Note: the primary agent's paired lender will be associated with the listing for all assigned agents.</p>
    //     `,
    //     button: 'OK'
    //   },
    // });

    // await modal.present();
  }

  selectSearchResult(item) {
    const self = this;
    console.log('item selected ' + JSON.stringify(item));
    if (item.place_id) {
      // GET DETAILS ABOUT THE PLACE ID SELECTED
      // this.GooglePlacesService.getDetails({ placeId: item.place_id, fields: ['formatted_address', 'geometry'] },
      //   (result, status) => {
      //     if (result) {
      //       console.log('got result ' + JSON.stringify(result));
      //       const fullAddress = self.extractAddressInfo(result.formatted_address);
      //       const location = JSON.parse(JSON.stringify(result.geometry.location));

      //       self.itemSelected = true;
      //       console.log('Item Selected', self.itemSelected);
      //       this.dismissData = {
      //         title: item.description || '',
      //         placeId: item.place_id,
      //         country: fullAddress.country || '',
      //         state: fullAddress.state || '',
      //         zip: fullAddress.zip || '',
      //         city: fullAddress.city || '',
      //         lat: location.lat,
      //         lng: location.lng,
      //       };

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: item.place_id }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          const result = results[0];
          console.log('got result ' + JSON.stringify(result));

          const fullAddress = self.extractAddressInfo(result.formatted_address);
          const location = result.geometry.location;

          self.itemSelected = true;
          console.log('Item Selected', self.itemSelected);
          self.placeId = item.place_id;

          this.dismissData = {
            title: item.description || '',
            placeId: item.place_id,
            country: fullAddress.country || '',
            state: fullAddress.state || '',
            zip: fullAddress.zip || '',
            city: fullAddress.city || '',
            lat: location.lat(),
            lng: location.lng(),
          };


          // DO NOT AUTO DISMISS FOR LENDER
          if (this.userRole === 'lender') {
            this.autoCompleteAddress.title = result.formatted_address;
            if (fullAddress.state !== null) {
              this.addressState = fullAddress.state;
              this.restrictRegionalLender();
            }
          }
          else if (this.userRole === 'agent') {
            self.viewCtrl.dismiss(this.dismissData);
          }
          else if (this.userRole === 'agentAdmin') {
            //agentAdmin
            this.autoCompleteAddress.title = result.formatted_address;
            if (fullAddress.state !== null) {
              this.addressState = fullAddress.state;
              this.restrictRegionalLender();
            }
          }
        }
        else {
          const result = results[0];

          this.dismissData = {
            title: item.description,
            placeId: item.place_id
          };
          self.itemSelected = true;
          console.log('Item Selected', self.itemSelected);

          if (this.userRole === 'lender') {
            this.autoCompleteAddress.title = result.formatted_address;
            this.restrictRegionalLender();
          }
          else if (this.userRole === 'agent') {
            self.viewCtrl.dismiss(this.dismissData);
          }
          else if (this.userRole === 'agentAdmin') {
            //agentAdmin
            this.autoCompleteAddress.title = result.formatted_address;
            this.restrictRegionalLender();
          }
        }
      });
    }
    else {
      self.itemSelected = true;
      console.log('Manual address selected ' + item);

      self.viewCtrl.dismiss({
        title: item.description,
        placeId: item.place_id
      });
    }
  }

  // currently works only on US addresses
  extractAddressInfo(address) {

    const addressArr = address.split(', ');
    if (addressArr.length > 3) {
      console.log('addr length is more than 3');
      this.autoCompleteAddress.country = addressArr[addressArr.length - 1];
      const stateAndZip = addressArr[addressArr.length - 2].split(' ');

      console.log('stateAndZip ' + stateAndZip);
      // check if zip was found
      if (stateAndZip.length > 1) {
        this.autoCompleteAddress.zip = stateAndZip[1];
        this.autoCompleteAddress.state = stateAndZip[0];
        console.log('state ' + stateAndZip[0]);
      }
      // mark zip not found
      else {
        this.autoCompleteAddress.zip = 'not found';
        this.autoCompleteAddress.state = stateAndZip[0];
        console.log('statezip is 1 ' + stateAndZip[0]);
      }

      this.autoCompleteAddress.city = addressArr[addressArr.length - 3];
    }
    else {
      console.log('length is 3 or less ' + addressArr[addressArr.length - 2]);
      this.autoCompleteAddress.city = addressArr[addressArr.length - 3];
      const stateAndZip = addressArr[addressArr.length - 2].split(' ');
      this.autoCompleteAddress.state = stateAndZip[0];
      console.log('state is ' + stateAndZip[0]);
    }

    const longState = this.longStates.indexOf(this.autoCompleteAddress.state);
    if (longState > -1) {
      console.log('state is using longform name. translating to: ' + this.states[longState]);
      this.autoCompleteAddress.state = this.states[longState];
    }

    return this.autoCompleteAddress;
  }

  ionViewWillEnter() {
    this.keyboard.disableScroll(true);
  }

  manualStateChange() {
    console.log('manual state change!');
    this.autocompleteItems = [];
    this.placeId = '';
    this.stateChanged = true;

    this.restrictRegionalLender();
  }


  handleCheckboxClick(event, uid) {

    if (!this.createMode && this.isPrimaryAgent(uid)) {
      this.clickInfoText();
      return;
    }

    if (this.totalPairedAgents.length >= this.MAX_AGENTS && !this.isAgentSelected(uid)) {
      console.log('Max agent limit reached.');
      return;
    }

    // if (this.userRole === 'lender') {
    //   if (this.checkedAgentsId.length > 0 && !this.isPrimaryAgent(uid)) {
    //     alert('Cannot add multiple agents to the listing.');
    //     return;
    //   }
    // }
  }

  selectedPairedAgent(event, hit) {

    console.log('selectedPairedAgent : ' + JSON.stringify(event.detail));
    console.log('selectedPairedAgent hit : ' + JSON.stringify(hit));

    console.log('this.agent: ' + this.agent);
    // this.agent = hit.objectID;

    const index = this.checkedAgentsId.findIndex(x => x.id === hit.objectID);

    // lender can only have one agent assigned
    if (User_global.role === 'lender') {
      if (this.createMode) {
        console.log('index to cut: ' + index);
        this.checkedAgentsId.splice(index, 1);
      }
    }

    // AGENT IS SELECTED
    if (event.detail.checked) {

      console.log('index: ' + index);
      if (!index || index < 0) {
        this.checkedAgentsId.push({ id: hit.objectID, enabled: true });
        console.log('push: ' + hit.objectID);
      }
      else if (index > -1) {
        this.checkedAgentsId[index].enabled = true;
      }
    }
    // AGENT IS REMOVED
    else if (index > -1) {

      // only allow removal on create otherwise just disable
      if (this.createMode) {
        console.log('index to cut: ' + index);
        this.checkedAgentsId.splice(index, 1);
      }
      else {
        this.checkedAgentsId[index].enabled = false;
      }
      //   this.checkedAgents.splice(index, 1);
    }

    if (this.agent !== this.checkedAgentsId[0].id) {
      this.agent = this.checkedAgentsId[0].id || '';
      this.getPrimaryAgent();
    }
    else {
      this.agent = this.checkedAgentsId[0].id || '';
    }

    this.getTotalPairedAgents();
  }

  // Inside your component class
  trackById(index: number, item: any): string {
    return item.objectID; // Assuming that the item has an 'id' property
  }

  isAgentSelected(uid) {
    return this.checkedAgentsId.find(x => (x.id === uid && x.enabled === true));
  }

  isPrimaryAgent(uid) {
    if (this.checkedAgentsId === undefined || this.checkedAgentsId.length === 0) {
      return true;
    }
    else if (this.checkedAgentsId[0].id === uid) {
      return true;
    }
    else {
      return false;
    }
    // return this.checkedAgents[0].objectID === uid ? 'primary' : 'medium';
  }

  selectAgent(agentValue) {
    this.agent = agentValue.objectID;
  }

  getTotalPairedAgents() {
    this.totalPairedAgents = this.checkedAgentsId.filter(x => (x.enabled === true));
    return this.totalPairedAgents;
  }

  ngAfterViewInit() {
    // const element = this.elementRef.nativeElement.querySelector('input');
    // we need to delay our call in order to work with ionic ...

    setTimeout(() => {
      // element.focus();
      this.ionSearchbar.setFocus();
      // this.renderer.invokeElementMethod(element, 'focus', []);
    }, 1000);

  }

  clear() {

  }

  ionViewWillLeave() {
    this.keyboard.disableScroll(false);
  }

  ionViewDidEnter() {
    if (this.auth.userInitialized) {
      this.initPairedAgents();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initPairedAgents();
        }
      }, 2000);
    }
  }

  // cleanup old format phones in DB
  cleanupPhoneNumer() {
    if (this.userData.phoneNumber && this.userData.phoneNumber[0] === '(') {
      const cleanNum = this.common.cleanPhoneNumber(this.userData.phoneNumber);
      this.auth.updateUserDataProperty({ phoneNumber: cleanNum });
    }
  }

  async initPairedAgents() {
    if (User_global.regionalLender === true) {
      //  alert('regoinal lender!');
      this.auth.getLoanOfficerStates().then((states) => {
        //    alert('got loan officer elible states: ' + states);
        this.eligibleStates = states;
        this.eligibleStatesLoaded = true;
      });
    }
    else {
      this.eligibleStatesLoaded = true;
    }

    // PROD ALGOLIA IS DIFF
    if (environment.production) {
      if (User_global.regionalLender === true) {
        // REGIONAL LENDERS NEED ADDITIONAL FILTERS
        this.config = {
          indexName: 'public_profiles_prod',
          searchClient,
          initialUiState: {
            public_profiles_prod: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                mostRecentListingEnhancedModeStatus: ['true'],
                enableLenderCommunication: ['true'],
                role: ['agent']
              }
            }
          }
        };
      }
      // NON REGIONAL LENDERS ONLY NEED PAIRED USER ID FILTER
      else if (User_global.role === 'lender') {
        this.config = {
          indexName: 'public_profiles_prod',
          searchClient,
          initialUiState: {
            public_profiles_prod: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                role: ['agent']
              }
            }
          }
        };
      }
      else if (User_global.role === 'agentAdmin') {
        this.config = {
          indexName: 'public_profiles_prod',
          searchClient,
          initialUiState: {
            public_profiles_prod: {
              refinementList: {
                pairedAdminId: [String(this.auth.getUID())],
                role: ['agent']
              }
            }
          }
        };
      }

      this.recommendedAgentConfig = {
        indexName: 'public_profiles_prod',
        searchClient,
        initialUiState: {
          public_profiles_prod: {
            refinementList: {
              hasActivePreferredLender: ['false'],
              enableLenderCommunication: ['true'],
              role: ['agent']
            }
          }
        }
      };
    }

    // DEV INDEX NAMES
    else if (!environment.production) {
      if (User_global.regionalLender === true) {
        this.config = {
          indexName: 'public_profiles_dev',
          searchClient,
          initialUiState: {
            public_profiles_dev: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                mostRecentListingEnhancedModeStatus: ['true'],
                enableLenderCommunication: ['true'],
                role: ['agent']
              }
            }
          }
        };
      }
      else if (User_global.role === 'lender') {
        this.config = {
          indexName: 'public_profiles_dev',
          searchClient,
          initialUiState: {
            public_profiles_dev: {
              refinementList: {
                pairedUserId: [String(this.auth.getUID())],
                role: ['agent']
              }
            }
          }
        };
      }
      else if (User_global.role === 'agentAdmin') {
        this.config = {
          indexName: 'public_profiles_dev',
          searchClient,
          initialUiState: {
            public_profiles_dev: {
              refinementList: {
                pairedAdminId: [String(this.auth.getUID())],
                role: ['agent']
              }
            }
          }
        };
      }

      this.recommendedAgentConfig = {
        indexName: 'public_profiles_dev',
        searchClient,
        initialUiState: {
          public_profiles_dev: {
            refinementList: {
              hasActivePreferredLender: ['false'],
              enableLenderCommunication: ['true'],
              role: ['agent']
            }
          }
        }
      };
    }


    this.menu.enable(true);
    this.menu.swipeGesture(true, 'left');

    const self = this;
    this.loading = true;
    this.copySlugTitle = 'Copy';
    this.userData = User_global;
    this.env = environment;
    this.userRole = User_global.role;
    // JUST TO CHECK IF CONNECTION IS WORKING
    //  this.userSubs = combineLatest(this.auth.userDataObserver(), (user) => ({ user })).subscribe((data) => {

    this.userSubs = combineLatest([this.auth.userDataObserver()]).pipe(
      map(([user]) => ({ user }))).subscribe((data) => {

        //  console.log('userSubs subscribe: ' + JSON.stringify(data));
        if (data) {
          this.showRefresh = false;

          this.cleanupPhoneNumer();

          if (data.user.address && data.user.address.zip !== this.userZipcode) {
            this.userZipcode = data.user.address.zip;

            if (!data.user.regionalLender && data.user.address.zip) {
              this.userZipcode = data.user.address.zip;
              this.currentLocationView = 'currentZipCodeView'; //searchZipCodeView, enterZipCodeView, currentZipCodeView

              this.auth.restrictedRecommendStates().then(res => {
                this.auth.restrictedRecommendStatesArray = res.states;
                //this.searchRecommendedAgents(); uncomment if we need recommended agents
              });
            }
            else if (!User_global.address || !User_global.address.zip) {
              this.currentLocationView = 'enterZipCodeView';
              this.auth.restrictedRecommendStates().then(res => {
                this.auth.restrictedRecommendStatesArray = res.states;
              });
            }
          }
          else if (!data.user.address || !data.user.address.zip) {
            this.currentLocationView = 'enterZipCodeView';
            this.auth.restrictedRecommendStates().then(res => {
              this.auth.restrictedRecommendStatesArray = res.states;
            });
          }
        }
        else if (data === undefined) {
          this.showRefresh = true;
          this.loadFailed = true;
        }
      });

    this.initLenderRole();
    setTimeout(() => { this.common.closeLoading(); this.loading = false; }, 1000);


    // if (this.userRole !== 'lender') {
    //   console.log('User Role', this.userRole);
    //   this.navCtrl.navigate(['/my-paired-lender']);
    //   return;
    // }
  }

  initLenderRole() {
    const self = this;

    // LENDERS
    if (User_global.role === 'lender') {
      if (!User_global.enableAgentsListingsResultsFiltering) {
        if (this.myAgentSubs) {
          this.myAgentSubs.unsubscribe();
        }
        this.myAgentSubs = this.connectionsDataService.connections().subscribe((data) => {
          self.myPairedAgents = data.filter(agent => (agent.status === 'active' || agent.status === 'regional_lender'));
          if (self.myPairedAgents.length < 1) {
            this.showPairedAgentsDiv = false;
          }
          else {
            this.showPairedAgentsDiv = true;
          }
          if (!this.firstLoad) {
            // RELOAD ALGOLIA SEARCH
            setTimeout(() => {
              this.refreshAlgolia();
            }, 15000);
          }
          this.firstLoad = false;

        });
      }
    }
    else if (this.userRole === 'agent') {
      //agent
    }
    else if (this.userRole === 'agentAdmin') {
      //agentAdmin
      if (!User_global.enableAgentsListingsResultsFiltering) {
        if (this.myAgentSubs) {
          this.myAgentSubs.unsubscribe();
        }
        this.myAgentSubs = this.connectionsDataService.connections().subscribe((data) => {
          self.myPairedAgents = data.filter(agent => (agent.status === 'agent_admin'));
          if (self.myPairedAgents.length < 1) {
            this.showPairedAgentsDiv = false;
          }
          else {
            this.showPairedAgentsDiv = true;
          }
          if (!this.firstLoad) {
            // RELOAD ALGOLIA SEARCH
            setTimeout(() => {
              this.refreshAlgolia();
            }, 15000);
          }
          this.firstLoad = false;
        });
      }
    }
  }

  refreshAlgolia(hit?, hits?, hitIndex?) {
    // refresh algolia after unpair/pair

    // set some search param so it can be reset
    const d = new Date();
    d.setDate(d.getDate() - 150);
    this.finalDate = d.getTime();
    this.mySearchParams = { filters: `mostRecentLeadCreatedAt > ${this.finalDate}` };

    setTimeout(() => {
      searchClient.clearCache();
      //  searchClient.initIndex('public_profiles');
      if (this.algoliaSearch) {
        this.algoliaSearch.refresh();
      }
      this.resetAlgoliaFilters();
    }, 150);

  }

  resetAlgoliaFilters() {

    // document.getElementById('clearRefinments').click();
    this.clearRefinments.state.refine();

    this.myHierarchicalMenu.reset();

    setTimeout(() => {
      this.recentlyActiveModel = 'all';
      this.mySearchParams = {};
    }, 250);
  }

  getPrimaryAgent() {
    if (this.agent) {
      this.connectionService.public_profile(this.agent).then(res => {
        this.displayName = res.username;
      });
    }
    else {
      this.displayName = '';
    }
  }
}
