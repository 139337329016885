import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Hit } from 'instantsearch.js';
import connectSearchBox from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
import connectHits from 'instantsearch.js/es/connectors/hits/connectHits';
import connectRefinementList from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import connectConfigure from 'instantsearch.js/es/connectors/configure/connectConfigure';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';

import {
  AlgoliaInstanceNames,
  AlgoliaInstantSearchService,
  ALGOLIA_CONFIGS,
} from '../../services/algolia-instant-search.service';

import { EMAIL_REGEX } from '../../constants';
import { CurbUser, Listing } from '../../app.models';
import { AuthService } from '../../services/auth.service';
import { CommonProvider } from '../../services/common';
import { Events } from '../../services/events.service';
import { SMSService } from '../../services/sms.service';
import { Roles } from '../../types';
import { exec } from '../../../utils/utils';

type AlgoliaHit = Hit & CurbUser;

@Component({
  selector: 'algolia-search-with-invite',
  templateUrl: './algolia-search-with-invite.html',
  styleUrls: ['algolia-search-with-invite.scss'],
})
export class AlgoliaSearchWithInvite implements AfterContentInit {
  // Name of the algolia instance to use, that holds information like the index and default refinements
  @Input() instanceName: AlgoliaInstanceNames;
  // What, if any, listing are we inviting the user to
  @Input() listing: Listing | undefined;
  // What role are we searching for and inviting to the app
  @Input() roleOfUserToInvite: Roles = Roles.agentAdmin;
  // Enable algolia insights or not
  @Input() hasInsights = false;
  // Filter out hits that are already paired with an admin
  @Input() hideResultsIfAlreadyPaired = false;
  // Hide the invite button
  @Input() hideInviteButton = false;
  // Determine the color of the input and button
  @Input() theme: 'white' | 'blue' = 'white';
  // Placeholder text for the input
  @Input() placeholderText = 'Name or email';
  // Message to display when no results are found
  @Input() noResultsMessage = 'Enter email to invite them';
  // Callback triggered after an account is clicked and the invitation email is sent
  @Output() onSucessfullEmailSent = new EventEmitter<void>();

  // Algolia related fields
  algoliaInstance: InstantSearch;
  refine: (query: string) => void;
  query: string;
  hits: AlgoliaHit[] = [];

  searchText: string;
  isInputDirty = false;

  refinementList: string[] = [];

  constructor(
    private common: CommonProvider,
    private auth: AuthService,
    private messageService: SMSService,
    public events: Events,
    private algoliaService: AlgoliaInstantSearchService
  ) {}

  ngAfterContentInit(): void {
    this.algoliaInstance = this.algoliaService.start(
      this.instanceName,
      this.hasInsights
    );
    const noop = () => {};

    const { refinements = [], searchParameters = {} } =
      ALGOLIA_CONFIGS[this.instanceName]();

    this.refinementList = refinements;

    this.algoliaService.addWidgets(this.instanceName, [
      connectConfigure(noop)({
        searchParameters,
      }),
      connectSearchBox((state) => {
        this.refine = state.refine;
        this.query = state.query;
      })({}),
      connectHits(({ hits }) => {
        this.hits = hits as any;
      })({}),
      ...refinements.map((refinement) => {
        return connectRefinementList(noop)({
          attribute: refinement,
          operator: 'and',
          limit: 10000,
        });
      }),
    ]);
  }

  onInputChange(text: string) {
    this.isInputDirty = true;
    if (text === '') {
      this.clearSearch();
    } else {
      this.searchText = text;
      this.refine(this.searchText);
    }
  }

  // When clicking a profile item the userId gets passed in, otherwise we rely on the typed email
  async sendInviteEmail(userId?: string) {
    this.common.startLoading();

    const [result, error] = await exec(
      this.messageService.sendEmailWebhook(
        this.searchText,
        this.listing,
        this.roleOfUserToInvite,
        userId
      )
    );

    if (!result || error) {
      this.auth.logErrors(
        `Error inviting ${this.searchText}, error: ${
          error || 'n/a'
        } listing ${JSON.stringify(this.listing)}`
      );
      this.common.toast('Invitation failed to send');
      this.common.showDBWriteError();
      console.log('webhook error : ' + error);
      return;
    }

    let toastText = '';
    if (this.roleOfUserToInvite === Roles.agentAdmin) {
      toastText = 'Team admin invitation sent';
    } else if (this.roleOfUserToInvite === Roles.lender) {
      toastText = 'Submitted. More lenders can still be invited.';
    } else {
      toastText = 'Agent invitation sent';
    }
    this.common.toast(toastText);
    this.onSucessfullEmailSent.emit();
    this.common.closeLoading();
  }

  clearSearch() {
    this.searchText = '';
    this.hits = [];
  }

  emailIsValid() {
    return EMAIL_REGEX.test(this.searchText);
  }

  displayNoResultsLabel() {
    return (
      this.searchText !== '' &&
      this.algoliaInstance?.status === 'idle' &&
      this.isInputDirty &&
      this.hits.length === 0
    );
  }

  filterHits(hits) {
    const validHits = [];

    for (const hit of hits) {
      // If this option is disabled, the default behavior, we display all results. Otherwise we only cosider hits with no paired admin
      if (!this.hideResultsIfAlreadyPaired || !hit.pairedAdminId?.length) {
        validHits.push(hit);
      }
    }

    return validHits;
  }
}
