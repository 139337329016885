/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { User_global } from 'src/app/app.models';

@Component({
  selector: 'app-custom-progress',
  templateUrl: './custom-progress.component.html',
  styleUrls: ['./custom-progress.component.scss'],
})
export class CustomProgressComponent implements OnInit, OnChanges {
  @Input() totalSteps: number;
  @Input() currentStep: number;
  @Input() progressBarWidthPercentage: number;

  stepsArray: number[] = [];
  activeSectionWidth: string;
  isMobileScreen = false;

  ngOnInit(): void {

    console.log('OnInit - totalSteps:', this.totalSteps, 'currentStep:', this.currentStep, 'progressBarWidthPercentage:', this.progressBarWidthPercentage);
    this.updateStepsArray();
    this.calculateActiveSectionWidth();

    this.isMobileScreen = window.innerWidth <= 767;
    // Subscribe to window resize events to update the variable dynamically
    window.addEventListener('resize', () => {
      this.isMobileScreen = window.innerWidth <= 767;
    });

    this.updateColors();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalSteps || changes.currentStep) {
      this.updateStepsArray();
      this.calculateActiveSectionWidth();
    }

    console.log('OnChanges - totalSteps:', this.totalSteps, 'currentStep:', this.currentStep, 'progressBarWidthPercentage:', this.progressBarWidthPercentage);

    this.updateColors();
    // else {

    //   if (progress !== null) {
    //     sectionChildren.forEach((child, index) => {
    //       const isStepActive = (index + 1) <= this.currentStep;
    //       child.classList.toggle('progress_secondary', isStepActive);
    //     });
    //   }
    // }

  }

  updateColors() {
    const progress = document.querySelector('.progress-bar');
    const sectionChildren = progress.querySelectorAll('.section');

    if (User_global.secondaryColor.toLocaleLowerCase().includes('#ffffff')) {
      if (progress !== null) {

        for (let index = 0; index < sectionChildren.length; index++) {
          const child = sectionChildren[index];
          const isStepActive = (index + 1) <= this.currentStep;

          if (isStepActive) {
            child.classList.add('progress_primary');

            // Check if the child has a child with class 'circle' and add the class to it
            const circleChild = child.querySelector('.circle');
            if (circleChild) {
              circleChild.classList.add('progress_primary');
            }
          } else {
            child.classList.remove('progress_primary');
          }
        }

      }
    }
  }

  isStepActive(step: number): boolean {
    if (this.isMobileScreen) {
      return (step - 1) === this.currentStep;
    }
    return (step - 1) <= this.currentStep;
  }

  calculateSectionWidth(): string {
    return `${100 / this.totalSteps}%`;
  }


  private updateStepsArray(): void {
    this.stepsArray = Array(this.totalSteps).fill(0).map((_, index) => index + 1);
  }


  private calculateActiveSectionWidth(): void {
    this.activeSectionWidth = `${(100 / this.totalSteps) * this.currentStep}%`;
  }
}
