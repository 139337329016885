import { NgModule } from '@angular/core';
import { MapUrlPipe } from './map-url/map-url.pipe';
import { MomentFormatPipe } from './moment-format/moment-format-pipe';
import { FirebaseUrlPipe } from './firebase-url/firebase-url.pipe';
import { SafePipe } from './safe/safe.pipe';
import { AuthService } from '../services/auth.service';
import { ListingImgPipe } from './listing-img/listing-img.pipe';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { ImagesManagementService } from '../services/images-management.service';
import { PhoneFormatPipe } from './phone-format/phone-format.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';

@NgModule({
  declarations: [
    MapUrlPipe,
    TruncatePipe,
    MomentFormatPipe,
    FirebaseUrlPipe,
    SafePipe,
    ListingImgPipe,
    PhoneFormatPipe
  ],
  imports: [],
  exports: [
    MapUrlPipe,
    TruncatePipe,
    MomentFormatPipe,
    FirebaseUrlPipe,
    SafePipe,
    ListingImgPipe,
    PhoneFormatPipe
  ],
  providers: [
    AuthService,
    File,
    ImagesManagementService
  ]
})
export class PipesModule { }
