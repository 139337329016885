import { Component, Renderer2, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { NavController, NavParams, AlertController, ModalController, Platform, IonInput } from '@ionic/angular';
import { NetworkProvider } from '../../services/network';
import { notAllowedPasswords } from '../../constants/password.constants';
import { EMAIL_REGEX, MASK } from '../../constants/general.constants';
import { CommonProvider } from './../../services/common';
import { I8nService } from 'src/app/services/i8nService';

@Component({
  selector: 'app-update-profile-information',
  templateUrl: 'update-profile-information.html',
  styleUrls: ['update-profile-information.scss']
})
export class UpdateProfileInformationPage implements AfterViewInit {
  @ViewChild('phoneInput') phoneInput: IonInput;
  @ViewChild('mainInput') mainInput: IonInput;
  @ViewChild('websiteInput') websiteInput: IonInput;


  public title: string;
  public subtitle: string;

  public labelName = '';
  public iosEye: string;
  public mdEye: string;
  public showEye: boolean;

  inputType = '';
  inputValue = '';
  blockEmpty: boolean;
  required = false;
  maxChars = 200;
  showMaxChar = true;

  inputLabel: any;
  inputData = '';
  countdown = 0;

  minLimit = 1;
  isDark = true;
  isDisabled = false;

  isPhone = false;
  isWebsite = false;
  mask = MASK;

  isLocked;
  lockMessage = 'This value will be used for all paired agents accounts and will overwrite existing values they\'ve added.';

  eyeIcon = 'eye';

  constructor(
    private elementRef: ElementRef,
    public navCtrl: NavController,
    public navParams: NavParams,
    public plt: Platform,
    public common: CommonProvider,
    public network: NetworkProvider,
    public alertCtrl: AlertController,
    public viewCtrl: ModalController,
    public i8nService: I8nService
  ) {

    this.inputValue = '';
    this.setViewVariables(navParams.get('type'), navParams.get('userData'));
    this.blockEmpty = navParams.get('blockEmpty');
    this.isLocked = navParams.get('isLocked');
    if (this.isLocked !== undefined) {
      console.log('isLocked: ' + this.isLocked);
    }

  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  ionViewDidLoad() {

    console.log('ionViewDidLoad UpdateProfileInformationPage');
    if (this.navParams.get('type') === 2) {
      // this.phoneRef.writeValue(this.inputValue);
    }
  }

  //type
  // 1 - name
  // 2 - phone number
  // 3 - email
  setViewVariables(type, userData) {

    console.log('set variables: ' + type + ' ' + userData);
    this.showEye = false;
    if (type === 1) {
      this.title = 'Name';
      this.labelName = 'Name';
      this.inputType = 'text';
      this.inputValue = userData.username || '';
      this.required = true;
      this.showMaxChar = false;
    } else if (type === 2) {
      this.isPhone = true;
      this.title = 'Phone Number';
      this.labelName = 'Phone number';
      this.inputType = 'tel';

      this.inputValue = this.common.formatPhone(userData.phoneNumber);
      this.showMaxChar = false;

    } else if (type === 3) {
      this.title = 'Email';
      this.labelName = 'Email';
      this.inputType = 'email';
      this.inputValue = userData.email || '';
      this.showMaxChar = false;
    } else if (type === 4) {
      this.title = 'Password';
      this.labelName = 'Password';
      this.inputType = 'password';
      this.inputValue = '';
      this.iosEye = 'ios-eye-off';
      this.mdEye = 'md-eye-off';
      this.showEye = true;
      this.showMaxChar = false;
    }
    else if (type === 5) {
      this.title = 'Company Name';
      this.labelName = 'Company Name';
      this.inputType = 'text';
      this.inputValue = userData.company || '';
      this.maxChars = 50;
      this.showMaxChar = true;
    }
    else if (type === 6) {
      this.title = 'Title';
      this.labelName = 'Title';
      this.inputType = 'text';
      this.inputValue = userData.title || '';
      this.showMaxChar = false;
    }
    else if (type === 7) {
      this.title = 'License';
      this.labelName = 'License';
      this.inputType = 'text';
      this.inputValue = userData.license || '';
      this.required = true;
      this.showMaxChar = false;
    }
    else if (type === 8) {
      this.title = 'Disclaimer Text 1';
      this.labelName = 'Disclaimer Text 1';
      this.inputType = 'text';
      this.inputValue = userData.disclaimerText || '';
      this.required = true;
      this.maxChars = 55;
      this.showMaxChar = true;
    }
    else if (type === 9) {
      this.title = 'Disclaimer URL 1';
      this.labelName = 'Disclaimer URL 1';
      this.inputType = 'url';
      this.inputValue = userData.disclaimerURL || '';
      this.required = true;
      this.showMaxChar = false;
    }
    else if (type === 10) {
      this.title = 'Disclaimer Text 2';
      this.labelName = 'Disclaimer Text 2';
      this.inputType = 'text';
      this.inputValue = userData.secondDisclaimerText || '';
      this.required = true;
      this.maxChars = 55;
      this.showMaxChar = true;
    }
    else if (type === 11) {
      this.title = 'Disclaimer URL 2';
      this.labelName = 'Disclaimer URL 2';
      this.inputType = 'url';
      this.inputValue = userData.secondDisclaimerURL || '';
      this.required = true;
      this.showMaxChar = false;
    }
    else if (type === 12) { // teamname
      this.title = 'Team Name';
      this.labelName = 'Team Name';
      this.inputType = 'text';
      this.inputValue = userData.teamname || '';
      this.showMaxChar = false;
    }
    else if (type === 13) { // website
      this.isWebsite = true;
      this.title = 'Website';
      this.labelName = 'Website';
      this.inputType = 'text';
      this.inputValue = userData.website || '';
      this.showMaxChar = false;
    }
    else if (type === 14) {
      this.title = 'Bank / Broker name';
      this.labelName = 'Bank / Broker name';
      this.inputType = 'text';
      this.inputValue = userData.company || '';
      this.maxChars = 50;
      this.showMaxChar = true;
    }
    else if (type === 15) {
      this.title = 'Broker of Record';
      this.labelName = 'Broker of Record';
      this.inputType = 'text';
      this.inputValue = userData.brokerOfRecord || '';
      this.maxChars = 50;
      this.showMaxChar = true;
    }
  }

  onPhoneChange(phone: string) {
    // this.rawPhone = phone;
    const output = phone.replace(/\D+/g, '');
    console.log(`phone: ${phone} output: ${output}`);
  }

  showHidePassword() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
    this.eyeIcon = this.eyeIcon === 'eye' ? 'eye-off' : 'eye';
  }

  inputValueChange(value: string) {
    this.inputValue = value;
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  public async showAlert(title, subTitle) {

    const alert = await this.alertCtrl.create({
      header: title,
      message: subTitle,
      buttons: ['OK']
    });
    await alert.present();
  }

  done() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    if (this.navParams.get('type') === 3) {
      if (EMAIL_REGEX.test(this.inputValue)) {
        this.viewCtrl.dismiss(this.inputValue);
      } else {
        this.showAlert('', this.i8nService.messages.enterValidEmail);
      }
    }
    else if (this.navParams.get('type') === 4) {
      if (this.inputValue.length < 8) {
        this.showAlert('', this.i8nService.messages.enterValidPasswordLength);
      }

      else if (notAllowedPasswords.indexOf(this.inputValue) >= 0) {
        console.log(notAllowedPasswords.indexOf(this.inputValue));
        this.showAlert('', this.i8nService.messages.invalidPasswordFormat);
      }
      else {
        this.viewCtrl.dismiss(this.inputValue);
      }
    } else if (this.navParams.get('type') === 2) {
      const output = this.inputValue.replace(/\D+/g, '');
      if (output === this.navParams.get('userData').phoneNumber) {
        this.viewCtrl.dismiss();
      } else {
        if (output.length !== 10) {
          this.showAlert('', this.i8nService.messages.enterValidPhoneNumber);
        } else {
          this.viewCtrl.dismiss(output);
        }
      }
    }
    else {
      if (this.isLocked) {
        this.showConfirmationMessage('Confirm Update', this.lockMessage);
      }
      else {
        this.viewCtrl.dismiss(this.inputValue);
      }
    }
  }

  public async showConfirmationMessage(title, subTitle) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: subTitle,
      buttons: [
        {
          text: 'Confirm',
          handler: (data) => {
            this.viewCtrl.dismiss(this.inputValue);
          }
        }, {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    alert.present();
  }

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement.querySelector('input');
    // we need to delay our call in order to work with ionic ...
    if (this.navParams.get('type') === 2) {
      // this.phoneRef.writeValue(this.inputValue);
      setTimeout(() => {
        this.phoneInput.setFocus();
      }, 1000);
    }
    else if (this.navParams.get('type') === 13) {
      setTimeout(() => {
        this.websiteInput.setFocus();
      }, 1000);
    }
    else {
      setTimeout(() => {
        this.mainInput.setFocus();
      }, 1000);
    }
  }

  limitText() {
    if (this.inputData) {
      if (this.inputData.length > this.maxChars) {
        this.isDisabled = true;
        this.isDark = false;
        this.countdown = this.maxChars - this.inputData.length;

        // this.inputData = this.inputData.substring(0, this.limitNum);
      } else {
        this.isDisabled = false;
        this.countdown = this.maxChars - this.inputData.length;
        this.isDark = true;
      }
    } else {
      this.countdown = this.maxChars;
    }
  }
}
