import { Component, Input, OnInit, TemplateRef } from '@angular/core';

import connectSearchBox from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
import connectHits from 'instantsearch.js/es/connectors/hits/connectHits';
import connectRefinementList from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import connectConfigure from 'instantsearch.js/es/connectors/configure/connectConfigure';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';

import {
  AlgoliaHit,
  AlgoliaInstanceNames,
  ALGOLIA_CONFIGS,
} from '../../constants/algolia';
import { noop } from '../../../utils/utils';
import { BaseAlgoliaComponent } from '../../../utils/algolia';

@Component({
  selector: 'algolia-paired-agents-search',
  templateUrl: './algolia-paired-agents-search.html',
  styleUrls: ['algolia-paired-agents-search.scss'],
})
export class AlgoliaPairedAgentsSearch
  extends BaseAlgoliaComponent
  implements OnInit
{
  instanceName = AlgoliaInstanceNames.new_listing_input_admin_search_for_agent;
  noResultsMessage = 'No paired agents found.';

  displayName = '';
  @Input() childComponent!: TemplateRef<any>;

  searchText: string;
  isInputDirty = false;

  // Algolia related fields
  instance: InstantSearch;
  refine: (query: string) => void;
  query: string;
  hits: AlgoliaHit[] = [];
  selectedAgents: AlgoliaHit[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.instance = this.createInstance(this.instanceName);

    const { refinements = [], searchParameters = {} } =
      ALGOLIA_CONFIGS[this.instanceName]();

    this.instance.addWidgets([
      connectConfigure(noop)({
        searchParameters,
      }),
      connectSearchBox((state) => {
        this.refine = state.refine;
        this.query = state.query;
      })({}),
      connectHits(({ hits }) => {
        this.hits = hits as any;
      })({}),
      ...refinements.map((refinement) => {
        return connectRefinementList(noop)({
          attribute: refinement,
          operator: 'and',
          limit: 10000,
        });
      }),
    ]);
  }

  onInputChange(text: string) {
    this.isInputDirty = true;
    this.searchText = text;
    this.refine(this.searchText);
  }

  clearSearch() {
    this.searchText = '';
    // We want to preserve the selected agents when the search is cleared
    this.hits = this.selectedAgents;
  }

  displayNoResultsLabel() {
    return (
      this.searchText !== '' &&
      this.instance?.status === 'idle' &&
      this.isInputDirty &&
      this.hits.length === 0
    );
  }
}
