/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { IntercomService } from '../../services/intercom.service';
import { Component, EventEmitter, Input, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { ImagesManagementService } from '../../services/images-management.service';
import { ListingImage } from '../../app.models';
import { Platform } from '@ionic/angular';
import { CommonProvider } from '../../services/common';

declare const Doka;

@Component({
  selector: 'doka-editor',
  templateUrl: 'doka-editor.html'
})
export class DokaEditorComponent implements AfterViewInit, OnDestroy {
  @Input('imageToEdit') imageToEdit = '';
  @Input('imageType') imageType = '';
  @Input('editingMode') editingMode = '';
  @Output('onClose') onClose = new EventEmitter<boolean>();
  @Output('onSuccess') onSuccess = new EventEmitter<any>();
  @Input('cropAspect') cropAspect: number;
  private doka: any;

  constructor(
    public common: CommonProvider,
    private imageSvc: ImagesManagementService,
    public plt: Platform,
    public intercomService: IntercomService
  ) {

    console.log('doka editor constructor');
  }

  ngOnDestroy() {
    this.doka.clear();
    this.doka.destroy();
  }

  initDoka() {
    console.log('init doka');
    if (this.editingMode !== 'logo') {
      this.doka = Doka.create(document.querySelector('#div'), {
        outputData: true,
        utils: ['crop', 'filter', 'color', 'markup', 'resize'],
        outputWidth: 1200,
        outputUpscale: true,
        onconfirm: (output) => {
          if (!output) {
            this.doka.clear();
            return;
          }
          this.onImageSave(output);
        },
        oncancel: () => {
          this.intercomService.hideLauncher();
          this.onClose.emit();
        },
        cropAspectRatio: this.cropAspect
      });
    }
    else if (this.editingMode === 'logo') {
      this.doka = Doka.create(document.querySelector('#div'), {
        outputData: true,
        utils: ['crop', 'filter', 'color', 'markup', 'resize'],
        outputWidth: 500,
        cropLimitToImageBounds: false,
        cropAspectRatio: this.cropAspect,
        labelCropInstructionZoom: 'Logos are reformatted to square dimensions. Scroll or pinch to zoom and adjust positioning.',
        labelButtonCropZoom: 'crop zoom',
        crop: {
          zoom: 0.8,
          aspectRatio: 1
        },
        outputCanvasBackgroundColor: '#FFF',
        allowPreviewFitToView: true,
        cropShowSize: true,
        cropAllowInstructionZoom: true,
        cropResizeMatchImageAspectRatio: true,
        cropAllowToggleLimit: true,

        onconfirm: (output) => {
          if (!output) {
            this.doka.clear();
            return;
          }
          this.onImageSave(output);
        },
        oncancel: () => {
          this.intercomService.hideLauncher();
          this.onClose.emit();
        }
      });
    }

  }

  onImageSave(output: any) {
    console.log('on image save');

    const image: ListingImage = {
      base64: '',
      imagePath: output.file,
      imageURL: ''
    };
    this.onSuccess.emit({ image, imageType: this.imageType });
    this.intercomService.showLauncher();
    // const reader = new FileReader();
    // reader.readAsDataURL();
    // reader.onloadend = () => {
    //   this.imageSvc.createImageFromBase64({ base64: reader.result as any }).then((imageResult: string) => {
    //     image.imagePath = imageResult;
    //
    //
    //     this.intercomService.showLauncher();
    //     // });
    //   });
    // }
  }

  ngAfterViewInit() {
    this.intercomService.hideLauncher();
    this.initDoka();
    this.doka.open(this.imageSvc.imageData).then((data) => {
      console.log(data);
    }).catch(() => {
      this.intercomService.showLauncher();
      this.onClose.emit();
    });
  }
}
