import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
	transform(value: any, limit: number): string {
		const val = String(value);
		if (val.length > limit) {
			return val.substring(0, limit) + '...';
		}
		return val;
	}
}
