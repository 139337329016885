/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { CommonProvider } from './common';

import { Platform } from '@ionic/angular';
import { AuthService } from './auth.service';
import {
  MAX_KIOSK_EVENT_LOGS
} from '../constants';
import { NetworkProvider } from './network';
import { getFirestore, collection, doc, setDoc, updateDoc, deleteDoc } from '@firebase/firestore';


@Injectable()
export class SqlService {
  sqlDb: SQLiteObject;

  constructor(
    public common: CommonProvider,
    private plt: Platform,
    private auth: AuthService,
    private network: NetworkProvider,
    private sqlite: SQLite) { }

  initDb() {
    if (!this.plt.is('cordova')) { return; }

    this.openDb().then((db: SQLiteObject) => {
      console.log('making tables');
      this.makeTables(db);
    }).catch(e => {
      this.auth.logErrors('error creating local sql: ' + JSON.stringify(e.message));
      console.log('error creating local sql: ' + e);
    });
  }

  openDb(): Promise<SQLiteObject> {
    return this.sqlite.create({
      name: 'curbhero.db',
      location: 'default',
      androidDatabaseLocation: 'system'
    });
  }


  makeTables(sqliteDb: SQLiteObject) {
    const p = [];

    p.push({
      id: 'users',
      sql: 'CREATE TABLE IF NOT EXISTS users(userId PRIMARY KEY unique not null,email,firstTimeListing,logoFile,phoneNumber,primaryColor,secondaryColor,username)',
      log: '*** Created Table users'
    });

    p.push({
      id: 'listings',
      sql: 'CREATE TABLE IF NOT EXISTS listings(listingId  PRIMARY KEY unique not null,address,createdAt,guestFollowUp,guestsCount,kioskQuestions,linkToInfo,textMessage,isNew int,isUpdate int)',
      log: '*** Created Table listing'
    });

    p.push({
      id: 'guests',
      sql: 'CREATE TABLE IF NOT EXISTS guests(guestId  PRIMARY KEY unique not null,uid,createdAt,guest,listingId,messages,questionsAnswers,time,isNew int,isUpdate int,createdDate)',
      log: '*** Created Table guest'
    });

    p.push({
      id: 'offlineque',
      sql: 'CREATE TABLE IF NOT EXISTS offlineque(queId  PRIMARY KEY unique not null,actions)',
      log: '*** Created Table offlineque'
    });

    p.push({
      id: 'kiosktracking',
      sql: 'CREATE TABLE IF NOT EXISTS kiosktracking(id INTEGER PRIMARY KEY AUTOINCREMENT, uid, createdAt, createdDate, listingId, action, guestSurveyData)',
      log: '*** Created Table kiosktracking'
    });

    p.forEach(obj => {
      sqliteDb.executeSql(obj.sql, [])
        .then(() => {
          console.log(obj.log);
          if (obj.id === 'offlineque') {
            // cleanup offline logs
          }
          else if (obj.id === 'kiosktracking') {
            this.DeleteKioskLogForUID(MAX_KIOSK_EVENT_LOGS);
          }
          else if (obj.id === 'guests') {
            this.modifyTable('ALTER TABLE guests ADD COLUMN archived');

            // Add 'disclosureDoc' column if it doesn't exist
            this.modifyTable('ALTER TABLE guests ADD COLUMN disclosureDoc');

            // Add 'disclosureSelection' column if it doesn't exist
            this.modifyTable('ALTER TABLE guests ADD COLUMN disclosureSelection');

            // Add 'disclosureSigned' column if it doesn't exist
            this.modifyTable('ALTER TABLE guests ADD COLUMN disclosureSigned');
          }
        })
        .catch(e => {
          console.log('error making db: ' + e);
          this.auth.logErrors('error making tables: ' + e);
        });
    });
  }

  DeleteKioskLogForUID(records: number): Promise<void> {
    if (!this.plt.is('cordova')) { return; }

    const uid = localStorage.getItem('uid');
    if (!uid || uid.length === 0) { return; }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('SELECT * FROM kiosktracking ORDER BY id DESC LIMIT 1', []).then(data => {
          const maxId = (data.rows.length > 0) ? data.rows.item(0).id : 0;

          // keep recods with id less than top x (maxId - records);
          const maxIdToKeep = (maxId - records);

          me.sqlDb.executeSql('DELETE FROM kiosktracking WHERE id < ?', [maxIdToKeep]).then((res) => {
            console.log('DeleteKioskLogForUID success');
            resolve();
          }).catch(e => {
            console.log('DeleteKioskLogForUID err: ' + e);
            resolve();
          });
        }).catch(e => {
          console.log('DeleteKioskLogForUID err: ' + e);
          this.auth.logErrors('error cleaning up kiosk logs: ' + e);
          resolve();
        });
      });
    });
  }

  modifyTable(sql: string): Promise<void> {
    if (!this.plt.is('cordova')) { return; }

    const self = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        self.sqlDb = db;
        self.sqlDb.executeSql(sql, []).then(data => {

        }).catch(e => {
          console.log('Modify table err: ' + e);
          //   this.auth.logErrors('error modifying table: ' + e);
          resolve();
        });
      });
    });
  }

  getKioskTrackingData(limit: number) {
    if (!this.plt.is('cordova')) { return; }

    let uid = localStorage.getItem('uid');

    if (!uid || uid.length === 0) {
      this.auth.logErrors('UID is empty while getting guests log');
      uid = this.auth.getUID();
    }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        const arr = [];
        let count = 0;

        me.sqlDb.executeSql('select * from kiosktracking order by createdDate desc limit ?',
          [limit]).then((data) => {
            if (data.rows.length > 0) {
              const allData = data.rows;
              for (let i = 0; i < allData.length; i++) {
                const item = allData.item(i);
                count++;

                let guestSurveyData;
                if (item.guestSurveyData) {
                  guestSurveyData = JSON.parse(item.guestSurveyData);
                } else {
                  guestSurveyData = '{}';
                }

                const obj = {
                  createdAt: item.createdAt,
                  createdData: item.createdDate,
                  listingId: item.listingId,
                  action: item.action,
                  guestSurveyData: guestSurveyData
                };

                arr.push(obj);
                if (count === allData.length) {
                  resolve(arr);
                }
              }
            } else {
              resolve(arr);
            }
          }).catch(e => {
            if (e.code === 4 || e.message === 'database or disk is full') {
              this.common.warnLowDiskSpace();
            }
            console.log('error getting kioskTracking data: ' + e);
            this.auth.logErrors('error getting offline Logs: ' + e);
            reject(e);
          });
      });
    });
  }

  InsertKioskLog(listingId: any = '', action: string, guestSurveyData: any): Promise<void> {

    if (!this.plt.is('cordova')) {
      return;
    }

    let uid = localStorage.getItem('uid');
    if (!uid || uid.length === 0) {
      this.auth.logErrors('UID is empty while trying to insert kiosk log: ' + listingId);
      uid = this.auth.getUID();
    }
    if (!uid || uid.length === 0) {
      uid = 'unknown';
    }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('INSERT INTO kiosktracking(uid, createdAt, createdDate, listingId, action, guestSurveyData) values(?, ?, ?, ?, ?, ?)',
          [uid, new Date(), this.common.getPSTTime().getTime(), listingId, action, JSON.stringify(guestSurveyData)])
          .then((res) => {
            console.log('InsertKioskLog success');
            resolve();
          }).catch(e => {
            if (e.code === 4 || e.message === 'database or disk is full') {
              this.common.warnLowDiskSpace();
            }
            // only show other errors for offline usage
            else if (!this.network.checkInternet()) {
              this.common.showDBWriteError();
            }
            this.auth.logErrors('InsertKioskLog err: ' + e.message);
            console.log('InsertKioskLog err: ' + e.message);
            reject(e);
          });
      });
    });
  }

  InsertGuestInSqliteOffline(guestId, guest, isNew, isUpdate): Promise<any> {
    if (!this.plt.is('cordova')) {
      return Promise.reject('Not running on Cordova platform');
    }

    if (!guestId) {
      return Promise.reject('guestId is required');
    }
    if (!guest) {
      return Promise.reject('guest data is required');
    }

    let uid = localStorage.getItem('uid');
    if (!uid || uid.length === 0) {
      this.auth.logErrors('UID is empty while trying to insert guest log: ' + JSON.stringify(guest));
      uid = this.auth.getUID();
    }

    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        this.sqlDb = db;
        return this.sqlDb.executeSql('select * from guests where guestId = ?', [guestId]);
      }).then((data) => {
        if (data.rows.length === 0) {
          // Insert new guest
          this.sqlDb.executeSql(
            `insert into guests(uid, guestId, createdAt, guest, listingId, messages, questionsAnswers, time, isNew, isUpdate, createdDate, archived, disclosureDoc, disclosureSelection, disclosureSigned) 
             values(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
            [uid, guestId, guest.createdAt, JSON.stringify(guest.guest), guest.listingId, JSON.stringify(guest.messages), JSON.stringify(guest.questionsAnswers), guest.time, isNew, isUpdate, guest.createdDate, guest.archived, guest.disclosureDoc, guest.disclosureSelection, guest.disclosureSigned]
          ).then((res) => {
            console.log('Insert Guest In SQL Success', res);
            resolve(res);
          }).catch(e => {
            console.error('InsertGuestInSqliteOfflineError:', e);

            if (e.code === 4 || e.message === 'database or disk is full') {
              this.common.warnLowDiskSpace();
            } else if (!this.network.checkInternet()) {
              this.common.showDBWriteError();
            }

            this.auth.logErrors('InsertGuestInSqliteOfflineError: ' + JSON.stringify(guest) + ' error: ' + JSON.stringify(e));
            reject(e);
          });
        } else {
          // Update existing guest
          console.log('Guest ID already exists! Updating existing guest.');
          this.sqlDb.executeSql(
            `update guests set createdAt=?, guest=?, listingId=?, messages=?, questionsAnswers=?, time=?, isNew=?, isUpdate=?, createdDate=?, archived=?, disclosureDoc=?, disclosureSelection=?, disclosureSigned=? 
             where guestId=?`,
            [guest.createdAt, JSON.stringify(guest.guest), guest.listingId, JSON.stringify(guest.messages), JSON.stringify(guest.questionsAnswers), guest.time, isNew, isUpdate, guest.createdDate, guest.archived, guest.disclosureDoc, guest.disclosureSelection, guest.disclosureSigned, guestId]
          ).then((res) => {
            console.log('Update Guest In SQL Success', res);
            resolve(res);
          }).catch(e => {
            console.error('UpdateGuestInSqliteOfflineError:', e);

            if (e.code === 4 || e.message === 'database or disk is full') {
              this.common.warnLowDiskSpace();
            } else if (!this.network.checkInternet()) {
              this.common.showDBWriteError();
            }

            this.auth.logErrors('UpdateGuestInSqliteOfflineError: ' + JSON.stringify(guest) + ' error: ' + JSON.stringify(e));
            reject(e);
          });
        }
      }).catch(e => {
        console.error('InsertGuestInSqliteOfflineError: Failed to query guests', e);

        if (e.code === 4 || e.message === 'database or disk is full') {
          this.common.warnLowDiskSpace();
        } else if (!this.network.checkInternet()) {
          this.common.showDBWriteError();
        }

        this.auth.logErrors('InsertGuestInSqliteOfflineError: Failed to query guests: ' + JSON.stringify(e));
        reject(e);
      });
    }).catch(err => {
      console.error('InsertGuestInSqliteOfflineError: Failed to open DB', err);
      this.auth.logErrors('InsertGuestInSqliteOfflineError: Failed to open DB: ' + JSON.stringify(err));
      return Promise.reject(err);
    });
  }


  getListingById(listingId) {
    if (!this.plt.is('cordova')) { return; }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('select * from listings where listingId = ?', [listingId]).then((data) => {
          resolve(data.rows);
        }).catch(e => {
          console.log('error' + e);

        });
      });
    });
  }

  getListingFromSqlite() {
    if (!this.plt.is('cordova')) { return; }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('select * from listings order by createdAt desc', []).then((data) => {
          const arr = [];
          for (let i = 0; i < data.rows.length; i++) {
            arr.push({
              id: data.rows.item(i).listingId,
              address: JSON.parse(data.rows.item(i).address),
              createdAt: data.rows.item(i).createdAt,
              guestFollowUp: data.rows.item(i).guestFollowUp,
              guestsCount: data.rows.item(i).guestsCount,
              kioskQuestions: JSON.parse(data.rows.item(i).kioskQuestions),
              linkToInfo: data.rows.item(i).linkToInfo,
              textMessage: data.rows.item(i).textMessage
            });
          }
          console.log('Update listing success');
          resolve(arr);
        })
          .catch(e => {
            console.log('error' + e);
            reject(e);
          });
      })
        .catch(e => {
          console.log('error' + e);
          reject(e);
        });
    });
  }

  async getGuestData() {
    if (!this.plt.is('cordova')) {
      return;
    }

    let uid = localStorage.getItem('uid');

    if (!uid || uid.length === 0) {
      this.auth.logErrors('UID is empty while getting guests log');
      uid = this.auth.getUID();
    }

    try {
      const db: SQLiteObject = await this.openDb();
      this.sqlDb = db;
      const arr = [];

      // Updated SQL query to fetch records where uid matches or is null
      const query = `
        SELECT * 
        FROM guests 
        WHERE (uid = ? OR uid IS NULL) 
        ORDER BY createdDate DESC 
        LIMIT ?
      `;

      const data = await this.sqlDb.executeSql(query, [uid, 500]);

      console.log('data from all guests: ' + JSON.stringify(data));

      if (data.rows.length > 0) {
        for (let i = 0; i < data.rows.length; i++) {
          const guestItem = data.rows.item(i);

          // let kioskQuestions = guestItem.kioskQuestions ? JSON.parse(guestItem.kioskQuestions) : '{}';

          const obj = {
            guestId: guestItem.guestId,
            createdAt: guestItem.guestCreatedAt,
            guest: JSON.parse(guestItem.guest),
            listingId: guestItem.listingId,
            questionsAnswers: JSON.parse(guestItem.questionsAnswers),
            createdDate: guestItem.createdDate,
            createdDateReadable: null,
            archived: guestItem.archived || false,
            disclosureDoc: guestItem.disclosureDoc || '',
            disclosureSelection: guestItem.disclosureSelection || '',
            disclosureSigned: guestItem.disclosureSigned || '',
          };

          try {
            obj.createdDateReadable = new Date(obj.createdDate);
          } catch (err) {
            this.auth.logErrors('Error getting offline guests: ' + err);
          }

          arr.push(obj);
        }
      }

      return arr;
    } catch (e) {
      console.log('error getting offline guests: ' + e);
      this.auth.logErrors('error getting offline Logs 2: ' + e);
      throw e;
    }
  }


  deleteDataFromSqlite() {
    if (!this.plt.is('cordova')) { return; }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('delete from listings');
        me.sqlDb.executeSql('delete from guests');
        me.sqlDb.executeSql('delete from users');
        localStorage.clear();
      });
    });
  }

  deleteListingFromSqlite(id) {
    if (!this.plt.is('cordova')) { return; }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        console.log(id);
        me.sqlDb = db;
        me.sqlDb.executeSql('delete from listings where listingId =?', [id]).then((data) => {
          console.log('deleted' + id);
        }).catch(e => {
          console.log('error' + e);
          reject();
        });
      });
    });
  }


  async importListingDataOnFireStore() {
    const uid = localStorage.getItem('uid');
    const me = this;
    const listingDocRef = doc(collection(getFirestore(), 'users', uid, 'listings'));
    let count = 0;

    try {
      const db: SQLiteObject = await this.openDb();
      me.sqlDb = db;
      const data = await me.sqlDb.executeSql('select * from listings where isNew = ?', [1]);

      if (data.rows.length > 0) {
        for (let i = 0; i < data.rows.length; i++) {
          const listingItem = data.rows.item(i);
          const kioskQuestions = JSON.parse(listingItem.kioskQuestions);
          const address = JSON.parse(listingItem.address);
          const guestFollowUp = (listingItem.guestFollowUp === 'true') ? true : false;
          const obj = {
            address: address,
            createdAt: listingItem.createdAt,
            guestFollowUp: guestFollowUp,
            guestsCount: listingItem.guestsCount,
            kioskQuestions: kioskQuestions,
            linkToInfo: listingItem.linkToInfo,
            textMessage: listingItem.textMessage
          };

          await setDoc(doc(listingDocRef, listingItem.listingId), obj);
          await me.sqlDb.executeSql('update listings set isNew = 0,isUpdate=0 where listingId=?', [listingItem.listingId]);
          count++;
        }
      }

      return count === data.rows.length;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }



  async updateListingDataOnFireStore() {
    const uid = localStorage.getItem('uid');
    const me = this;
    const listingDocRef = doc(collection(getFirestore(), 'users', uid, 'listings'));

    try {
      const db: SQLiteObject = await this.openDb();
      me.sqlDb = db;
      const data = await me.sqlDb.executeSql('select * from listings where isUpdate =1', []);

      if (data.rows.length > 0) {
        for (let i = 0; i < data.rows.length; i++) {
          const listingItem = data.rows.item(i);
          const kioskQuestions = JSON.parse(listingItem.kioskQuestions);
          const address = JSON.parse(listingItem.address);
          const guestFollowUp = (listingItem.guestFollowUp === 'true') ? true : false;
          const obj = {
            address: address,
            createdAt: listingItem.createdAt,
            guestFollowUp: guestFollowUp,
            guestsCount: listingItem.guestsCount,
            kioskQuestions: kioskQuestions,
            linkToInfo: listingItem.linkToInfo,
            textMessage: listingItem.textMessage
          };

          await updateDoc(doc(listingDocRef, listingItem.listingId), obj);
          await me.sqlDb.executeSql('update listings set isNew = 0, isUpdate=0 where listingId=?', [listingItem.listingId]);
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


  async deleteListingDataOnFireStore() {
    const uid = localStorage.getItem('uid');
    const me = this;
    const listingDocRef = doc(collection(getFirestore(), 'users', uid, 'listings'));

    try {
      const db: SQLiteObject = await this.openDb();
      me.sqlDb = db;
      const data = await me.sqlDb.executeSql("select * from offlineque where actions = 'delete '", []);

      if (data.rows.length > 0) {
        for (let i = 0; i < data.rows.length; i++) {
          const listingItem = data.rows.item(i);

          await deleteDoc(doc(listingDocRef, listingItem.queId));
          console.log('pushing offline delete of:' + listingItem.queId);

          await me.sqlDb.executeSql('delete from offlineque Where queId = ?', [listingItem.queId]);
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


  // importGuestDataOnFireStore() {

  //   const uid = localStorage.getItem('uid');
  //   const me = this;
  //   const guestDoc = me.afs.collection('users').doc(uid).collection('guests');

  //   this.openDb().then((db: SQLiteObject) => {
  //     me.sqlDb = db;
  //     me.sqlDb.executeSql('select * from guests where isNew = ?', [1]).then((data) => {
  //       if (data.rows.length > 0) {
  //         // insert Data in firebase
  //         for (let i = 0; i < data.rows.length; i++) {
  //           const guest = data.rows.item(i);
  //           const guestData = JSON.parse(guest.guest);
  //           const questionsAnswers = JSON.parse(guest.questionsAnswers);

  //           console.log(guest);
  //           console.log(guestData);
  //           console.log(questionsAnswers);

  //           const obj = {
  //             createdAt: guest.createdAt,
  //             guest: guestData,
  //             listingId: guest.listingId,
  //             questionsAnswers: questionsAnswers,
  //             time: guest.time,
  //             createdDate: guest.createdDate
  //           };
  //           console.log(obj);
  //           console.log('guestid:');
  //           console.log(guest.guestId);

  //           guestDoc.doc(guest.guestId).set(obj).then(
  //             success => {
  //               console.log(guest.guestId);
  //               me.sqlDb.executeSql('update guests set isNew = 0, isUpdate=0 where guestId=?', [guest.guestId]).then((data3) => {


  //               }).catch(e => {
  //                 console.log('error my:' + e);
  //               });
  //             },
  //             error => {
  //               console.log('error on set obj');
  //               console.log(error);
  //             }
  //           );
  //         }
  //       }
  //     }).catch(e => {
  //       console.log('error2' + e);

  //     });
  //   }).catch(e => {
  //     console.log('error3' + e);

  //   });
  //   ;
  // }

  // updateGuestDataOnFireStore() {

  //   const uid = localStorage.getItem('uid');
  //   const me = this;
  //   const guestDoc = me.afs.collection('users').doc(uid).collection('guests');
  //   this.openDb().then((db: SQLiteObject) => {
  //     me.sqlDb = db;
  //     me.sqlDb.executeSql('select * from guests where isNew = ?, isUpdate=?', [0, 1]).then((data) => {
  //       if (data.rows.length > 0) {
  //         // insert Data in firebase
  //         for (let i = 0; i < data.rows.length; i++) {
  //           const guest = data.rows.item(i);
  //           const obj = {
  //             createdAt: guest.createdAt,
  //             guest: JSON.parse(guest.guest),
  //             listingId: guest.listingId,
  //             questionsAnswers: JSON.parse(guest.questionsAnswers),
  //             time: guest.time,
  //             createdDate: guest.createdDate
  //           };

  //           guestDoc.doc(guest.guestId).update(obj).then(
  //             success => {
  //               me.sqlDb.executeSql('update guests set isNew = 0, isUpdate=0 where guestId=?', [guest.guestId]).then((data2) => {
  //                 // send SMS with 24 hours
  //               }).catch(e => {
  //                 console.log('error' + e);
  //               });
  //             },
  //             error => {
  //               console.log(error);
  //             }
  //           );
  //         }
  //       }
  //     }).catch(e => {
  //       console.log('error' + e);

  //     });
  //   });
  // }

  InsertUsersInSqlite(user, userId) {
    if (!this.plt.is('cordova')) { return; }

    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('select * from users', []).then((data) => {

          if (data.rows.length === 0) {
            me.sqlDb.executeSql('insert into users(userId,email,firstTimeListing,logoFile,phoneNumber,primaryColor,secondaryColor,username) values(?,?,?,?,?,?,?,?)', [userId, user.email, true, user.logoFile, user.phoneNumber, '#14294F', '#32C12C', user.username]).then((res) => {
              console.log('new user created');
            }).catch(e => {
              console.log('insert user error' + e);
            });
          }
        }).catch(e => {
          console.log('error' + e);
        });
      });
    });
  }

  loginUsersInSqlite(user, userId) {
    if (!this.plt.is('cordova')) { return; }

    const me = this;
    this.openDb().then((db: SQLiteObject) => {
      me.sqlDb = db;
      me.sqlDb.executeSql('select * from users', []).then((data) => {
        if (data.rows.length === 0) {
          me.sqlDb.executeSql('insert into users(userId,email,firstTimeListing,logoFile,phoneNumber,primaryColor,secondaryColor,username) values(?,?,?,?,?,?,?,?)', [userId, user.email, user.firstTimeListing, user.logoFile, user.phoneNumber, user.primaryColor, user.secondaryColor, user.username]).then((res) => {
            console.log('login user created');
          }).catch(e => {
            console.log('error login 1' + e);
          });
        }

      }).catch(e => {
        console.log('error login 2' + e);
      });

    });
  }

  updateUsersInSqlite(tagName, tagValue) {
    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('update users set ' + tagName + '=?', [tagValue]).then((res) => {
        }).catch(e => {
          console.log('error' + e);

        });
      });
    });
  }

  getUserSqliteData() {
    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('select * from users', []).then((data) => {
          resolve(data.rows.item(0));
        }).catch(e => {
          console.log('error' + e);

        });
      });
    });
  }

  getLatestListings() {
    console.log('getting latest listings');
    const me = this;
    return new Promise((resolve, reject) => {
      this.openDb().then((db: SQLiteObject) => {
        me.sqlDb = db;
        me.sqlDb.executeSql('select * from listings order by createdAt desc limit 1', []).then((data) => {
          if (data.rows.length > 0) {
            console.log('got listings!');
            const listingId = data.rows.item(0).listingId;
            const listingData = data.rows.item(0);
            resolve({ success: true, listingId: listingId, listingData: listingData });
          } else {
            resolve({ success: false });
          }
        }).catch(e => {
          console.log('error' + e);
          reject();
        });
      });
    });
  }

  // checkExistingData() {
  //   const me = this;
  //   return new Promise((resolve, reject) => {
  //     this.openDb().then((db: SQLiteObject) => {
  //       me.sqlDb = db;
  //       me.sqlDb.executeSql('select * from listings where isNew = ? OR isUpdate=?', [1, 1]).then((data) => {
  //         if (data.rows.length > 0) {
  //           resolve(true);
  //         } else {
  //           resolve(false);
  //           /*	me.sqlDb.executeSql('select * from guests where isNew = ?', [1]).then((dataGuest) => {
  //               if (dataGuest.rows.length > 0) {
  //                 console.log('guests');
  //                 console.log(dataGuest);

  //                 resolve(true);
  //               } else {
  //                 resolve(false);
  //               }
  //             }).catch(e => {
  //               console.log(e);
  //               reject();
  //             }) */
  //         }
  //       }).catch(e => {
  //         reject();
  //       });
  //     });
  //   });
  // }

  async addMessageInfo(guestId: string, messageData: any, uid: any) {
    const guestDocRef = doc(collection(getFirestore(), 'users', uid, 'guests'), guestId);

    try {
      await updateDoc(guestDocRef, { messages: messageData });
      return true;
    } catch (error) {
      throw error;
    }
  }

  generatePushID() {
    // Modeled after base64 web-safe chars, but ordered by ASCII.
    const PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

    // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
    let lastPushTime = 0;

    // We generate 72-bits of randomness which get turned into 12 characters and appended to the
    // timestamp to prevent collisions with other clients.  We store the last characters we
    // generated because in the event of a collision, we'll use those same characters except
    // 'incremented' by one.
    const lastRandChars = [];

    let now = new Date().getTime();
    const duplicateTime = (now === lastPushTime);
    lastPushTime = now;
    let i = 0;
    const timeStampChars = new Array(8);
    for (i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0) { throw new Error('We should have converted the entire timestamp.'); }

    let id = timeStampChars.join('');

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length !== 20) { throw new Error('Length should be 20.'); }

    return id;

  };

}
