/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AlertController, MenuController, ModalController, NavParams, Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { AuthService } from 'src/app/services/auth.service';
import { ImagesManagementService } from 'src/app/services/images-management.service';
import { HttpClient } from '@angular/common/http';
import { NetworkProvider } from 'src/app/services/network';
import { User_global } from 'src/app/app.models';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { I8nService } from 'src/app/services/i8nService';
import { QrCodeService } from 'src/app/services/qr-code.service';
import { Events } from 'src/app/services/events.service';

@Component({
  selector: 'app-qr-popup',
  templateUrl: 'qr-popup.html',
  styleUrls: ['qr-popup.scss']
})
export class QRPopupComponent implements OnInit {

  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  url: any;
  qrURL: any;
  slug: any;
  common: any;
  cordova: any;
  listingQR: any;
  qrCodeImageData: any;
  showLiveQRCode = false;
  segmentValue: any;
  listingQRKiosk: any;
  qrCodeImageDataKiosk: any;
  qrURLKiosk: any;
  currentQRData: any;
  currentQRURL: any;
  qrOnlylink: any;
  qrURLonlylink: any;
  currentListing: any;
  refreshingQR = false;
  userRole = 'agent';

  helperText = 'Designed to showcase property';
  origUrl;
  type: any;
  title = 'Listing QR Code';
  role;

  constructor(
    private changeRef: ChangeDetectorRef,
    private viewCtrl: ModalController,
    private iab: InAppBrowser,
    private navParams: NavParams,
    private file: File,
    private plt: Platform,
    private socialSharing: SocialSharing,
    private auth: AuthService,
    private imageService: ImagesManagementService,
    private http: HttpClient,
    private network: NetworkProvider,
    private nav: Router,
    private menu: MenuController,
    private alertCtrl: AlertController,
    private i8nService: I8nService,
    private qrService: QrCodeService,
    private events: Events
  ) {

    this.url = this.navParams.get('url');
    this.common = this.navParams.get('common');
    this.slug = this.navParams.get('slug');
    this.cordova = this.navParams.get('cordova');
    this.listingQR = this.navParams.get('listingQR');
    this.listingQRKiosk = this.navParams.get('listingQRKiosk');
    this.qrOnlylink = this.navParams.get('qrOnlylink');
    this.currentListing = this.navParams.get('listing');
    this.type = this.navParams.get('type');
    this.userRole = User_global.role;


    this.segmentValue = 'micrositeOverlay';
    // this.origUrl= this.url;


    if (this.type === 'download-qr') {
      this.title = 'Listing QR Code';
      this.origUrl = this.url + '/?display=touch-free-sign-in';

    }
    else {
      this.title = 'Dynamic QR Code';
      this.origUrl = this.url;
    }
  }

  ngOnInit(): void {

    this.role = User_global.role;
    this.initQRCodes();

  }

  initQRCodes() {

    // this.qrURL = 'https://api.qrserver.com/v1/create-qr-code/'
    //   + '?color=' + User_global.primaryColor.replace('#', '')
    //   + '&data=' + environment.blockparty + this.slug
    //   + '&amp;size=250x250';

    // this.qrURLKiosk = 'https://api.qrserver.com/v1/create-qr-code/'
    //   + '?color=' + User_global.primaryColor.replace('#', '')
    //   + '&data=' + environment.blockparty + this.slug
    //   + '/?display=touch-free-sign-in&amp;size=250x250';

    // this.qrURLonlylink = 'https://api.qrserver.com/v1/create-qr-code/'
    //   + '?color=' + User_global.primaryColor.replace('#', '')
    //   + '&data=' + this.url
    //   + '/?display=touch-free-sign-in&amp;size=250x250';

    this.currentQRURL = this.qrURL;

    if (this.listingQR) {

      // LOAD QR CODE IMAGE DATA
      console.log('listingQR popup: ' + this.listingQR);
      this.imageService.realImageData({ imagePath: this.listingQR, imageURL: this.listingQR }).then((data) => {
        console.log('get qr code real image data result2: ' + data);
        const now = new Date();
        this.qrCodeImageData = data;
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.qrCodeImageData = null;
        this.currentQRData = null;

        if (this.plt.is('cordova') && this.network.checkInternet()) {
          this.showLiveQRCode = true;
        }
      });

      this.segmentValue = 'micrositeOverlay';
    }

    if (this.listingQRKiosk) {

      // LOAD QR CODE IMAGE DATA
      console.log('listingQRKiosk popup: ' + this.listingQRKiosk);
      this.imageService.realImageData({ imagePath: this.listingQRKiosk, imageURL: this.listingQRKiosk }).then((data) => {
        console.log('get qr code real image data result1: ' + data);
        const now = new Date();
        this.qrCodeImageDataKiosk = data;
        this.currentQRData = data + '?rand=' + now.getMilliseconds();
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.qrCodeImageDataKiosk = null;
        //    this.currentQRData = null;
        if (this.plt.is('cordova') && this.network.checkInternet()) {
          this.showLiveQRCode = true;
        }
      });
    }

    if (this.qrOnlylink) {

      console.log('qrOnlylink popup: ' + this.qrOnlylink);
      this.imageService.realImageData({ imagePath: this.qrOnlylink, imageURL: this.qrOnlylink }).then((data) => {
        console.log('get qr code real image data result: ' + data);
        const now = new Date();
        this.qrCodeImageData = data;
        this.currentQRData = data + '?rand=' + now.getMilliseconds();

      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.qrCodeImageData = null;
        this.currentQRData = null;

        if (this.plt.is('cordova') && this.network.checkInternet()) {
          this.showLiveQRCode = true;
        }
      });
    }

    this.refreshingQR = false;

    setTimeout(() => { this.changeRef.detectChanges(); }, 500);



    if (this.type === 'download-qr' && User_global.role === 'agent') {

      if (!this.currentListing.qrCodeImageKiosk) {
        this.refreshQRCode();
      }
    }
  }

  async refreshQRCode() {
    const self = this;

    if (this.network.checkInternet()) {
      this.refreshingQR = true;

      if (this.type === 'download-qr') {

        if (User_global.role === 'agent') {


          if (this.plt.is('cordova')) {
            const fileParts = (this.currentListing.qrCodeImageKiosk || '').split('/');
            const fileNameDelete = fileParts[fileParts.length - 1];
            await this.imageService.removeImage(fileNameDelete);
          }

          if (this.plt.is('cordova')) {
            const fileParts2 = (this.currentListing.qrCodeImage || '').split('/');
            const fileNameDelete2 = fileParts2[fileParts2.length - 1];
            await this.imageService.removeImage(fileNameDelete2);
          }

          // KIOSK SPECIFIC QR CODE
          const url1 = environment.blockparty + this.currentListing.slug + '/?display=touch-free-sign-in';
          const fileName = this.currentListing.id + '/' + this.currentListing.slug + '_kiosk';

          await self.qrService.generateQRCode(url1, User_global.primaryColor, User_global.logoFile).then(async blob => {
            await self.imageService.uploadQRCodeBloblListing(blob, fileName, false).then(res => {
              if (res) {

                this.currentListing.qrCodeImageKiosk = String(res);
                this.listingQRKiosk = String(res);
                self.cacheQRImages(this.currentListing.qrCodeImageKiosk);

                this.auth.updateListing(this.currentListing, {
                  qrCodeImageKiosk: this.currentListing.qrCodeImageKiosk
                });
              }
            });
          });

          // REGULAR QR CODE
          const url2 = environment.blockparty + this.currentListing.slug;
          const fileName2 = this.currentListing.id + '/' + this.currentListing.slug;

          await self.qrService.generateQRCode(url2, User_global.primaryColor, User_global.logoFile).then(async blob => {
            await self.imageService.uploadQRCodeBloblListing(blob, fileName2, false).then(res => {

              if (res) {

                self.currentListing.qrCodeImage = String(res);
                this.listingQR = String(res);
                self.cacheQRImages(self.currentListing.qrCodeImage);

                this.auth.updateListing(this.currentListing, {
                  qrCodeImage: self.currentListing.qrCodeImage
                });
              }
            });
          });

          // AGENT SIGN-IN QR
          const url3 = environment.blockparty + self.currentListing.slug + '/?display=agent-sign-in';
          const filename3 = this.currentListing.id + '/' + self.currentListing.slug + '_agentsignin';

          await self.qrService.generateQRCode(url3, User_global.primaryColor, User_global.logoFile).then(blob => {
            self.imageService.uploadQRCodeBloblListing(blob, filename3, false).then(res => {

              if (res) {
                self.currentListing.qrCodeAgentSignIn = String(res);
                //  self.cacheQRImages();
                this.auth.updateListing(this.currentListing, {
                  qrCodeAgentSignIn: this.currentListing.qrCodeAgentSignIn
                });
              }
            });
          });
        }
      }
      else if (this.type === 'your-qr') {
        try {
          await this.imageService.removeImage(this.auth.getUID() + '_qrCodeOnlylink_qr_profile');
          await this.imageService.removeImage('qrCodeOnlylink_qr_profile'); // legacy file
        }
        catch (err) {
          console.log(err);
        }

        setTimeout(() => { this.auth.updateUserDataProperty({ qrCodeOnlylink: '' }); }, 1000);
        setTimeout(() => { this.events.publish('refresh:qrCode'); }, 4000);
      }

      if (self.plt.is('cordova')) {
        // CACHE QR IMAGE DATA
        self.cacheQRImages('all');
      }

      setTimeout(() => { this.initQRCodes(); }, 5000);
    }
  }

  async cacheQRImages(item) {

    if (!this.plt.is('cordova')) {
      return;
    }

    if (this.currentListing.qrCodeImage) {

      const fileParts = (this.currentListing.qrCodeImage || '').split('/');
      const fileNameDelete = fileParts[fileParts.length - 1];
      await this.imageService.removeImage(fileNameDelete);

      await this.imageService.realImageData({
        imagePath: this.currentListing.qrCodeImage,
        imageURL: this.currentListing.qrCodeImage
      }).then((data) => {
        console.log('get qr code real image data result: ' + data);
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.auth.logErrors('cacheQR code image error: ' + error, false);
      });


      if (item === this.currentListing.qrCodeImage) {
        return;
      }
    }

    if (this.currentListing.qrCodeImageKiosk) {

      const fileParts = (this.currentListing.qrCodeImageKiosk || '').split('/');
      const fileNameDelete = fileParts[fileParts.length - 1];
      await this.imageService.removeImage(fileNameDelete);

      await this.imageService.realImageData({
        imagePath: this.currentListing.qrCodeImageKiosk,
        imageURL: this.currentListing.qrCodeImageKiosk
      }).then((data) => {
        console.log('get qr code real image data result: ' + data);
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.auth.logErrors('cacheQR code image error: ' + error, false);
      });

      if (item === this.currentListing.qrCodeImageKiosk) {
        return;
      }
    }

    // if (this.currentListing.qrCodeAgentSignIn) {
    //   await this.imageService.realImageData({
    //     imagePath: this.currentListing.qrCodeAgentSignIn,
    //     imageURL: this.currentListing.qrCodeAgentSignIn
    //   }).then((data) => {
    //     console.log('get qr code real image data result: ' + data);
    //   }).catch((error) => {
    //     console.log('error finding qr code file. ' + error);
    //     this.auth.logErrors('cacheQR code image error: ' + error, false);
    //   });
    // }
  }

  segmentChanged(ev: string, element?) {
    console.log('Segment changed', ev);
    this.segmentValue = ev;
    const now = new Date();

    if (ev === 'microsite') {
      this.currentQRData = this.qrCodeImageData + '?rand=' + now.getMilliseconds();
      this.currentQRURL = this.qrURL;
      this.helperText = 'Designed to showcase property';
      this.origUrl = this.url;
    }
    else {
      this.currentQRData = this.qrCodeImageDataKiosk + '?rand=' + now.getMilliseconds();
      this.currentQRURL = this.qrURLKiosk;
      this.helperText = 'Use to showcase property and capture interest';
      this.origUrl = this.url + '/?display=touch-free-sign-in';
    }


    console.log('qr data: ' + this.currentQRData + ' qr url: ' + this.currentQRURL + ' this.url ' + this.url);
  }

  downloadQRcode(qr, fileName) {
    console.log('downloadQRcode ' + qr);
    // this.iab.create(this.qrURL, '_system');
    const me = this;
    const now = new Date();
    // fileName = 'Curb-Hero-QR_' + new Date().getTime();
    fileName = fileName + '_' + now.getMilliseconds() + '.png';

    if (this.plt.is('cordova')) {

      fetch(qr).then((body) => {

        console.log('fetch url finished ' + qr + ' filename ' + fileName);

        body.blob().then((blob) => {
          //   me.common.startLoading();
          me.file.writeFile(me.file.dataDirectory, fileName, blob).then(writeFile => {

            me.common.closeLoading();
            me.socialSharing.share(null, null, writeFile.nativeURL, null)
              .then(() => {
                console.log('finished sharing.');

              }).catch((err) => {
                console.log('error writing export file: ' + err);
                me.common.closeLoading();
                me.auth.logErrors('Issue exporting qr code: ' + err);
              });
          }).catch(err => {
            console.log('error writing export qr code: ' + err);
            me.common.closeLoading();
            me.auth.logErrors('Issue exporting qr code 2: ' + err);
          });
        });
      }).catch((error) => {
        console.log('error in fetch image: ' + error);
        me.auth.logErrors('Issue exporting qr code 3: ' + error);
      });
    }
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  downloadImage() {

    const qr = this.currentQRData;
    let fileName = this.slug;

    if (this.type === 'your-qr') {
      fileName = User_global.micrositeUserId + '-QR-code';
    }

    if (this.cordova) {

      // if (this.network.checkInternet()) {

      //   if (this.type === 'your-qr') {
      //     qr = this.qrURLonlylink;
      //   }
      //   else {
      //     qr = this.currentQRURL;
      //   }
      // }

      this.downloadQRcode(qr, fileName);
      return;
    }

    // console.log('download clicked');
    // //  this.changeRef.detectChanges();
    // this.common.startLoading('Downloading QR Code...');

    // setTimeout(() => {
    //   html2canvas(this.screen.nativeElement, {
    //     useCORS: true, //By passing this option in function Cross origin images will be rendered properly in the downloaded version of the PDF
    //   }).then(canvas => {
    //     this.canvas.nativeElement.src = canvas.toDataURL();
    //     this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
    //     this.downloadLink.nativeElement.download = this.slug + '.png';
    //     this.downloadLink.nativeElement.click();

    //     this.common.closeLoading();
    //   });
    // }, 250);
    else {

      if (!this.currentQRData) {
        this.common.openLink(this.currentQRURL);
        return;
      }

      this.common.startLoading('Downloading QR Code...');

      setTimeout(() => {
        this.common.closeLoading();
      }, 1500);

      setTimeout(() => {


        this.http.get(this.currentQRData, { responseType: 'blob' })
          .subscribe(blob => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(objectUrl);
          });
      }, 250);
    }
  }

  copyVal(text: string) {
    this.common.copyToClipboard(text);
  }

  updateLandingPage() {
    this.menu.close();
    this.nav.navigate(['my-link']);
    this.dismiss();
  }

  async showQRCodeInfo(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const myAlert = await this.alertCtrl.create({
        header: 'Dynamic QR Code',
        message: this.i8nService.messages.qrcodeInfo,
        buttons: [
          {
            role: 'ok',
            text: 'OK',
            handler: data => {
              resolve(true);
            }
          },
          {
            role: 'Help',
            text: 'Help Section',
            handler: data => {
              this.common.openLink('https://juuj.me/your-qr-code');
              resolve(false);
            }
          }

        ]
      });
      await myAlert.present();
    });
  }
}
