/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { ImagesManagementService } from '../../services/images-management.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { CommonProvider } from 'src/app/services/common';
import { Platform } from '@ionic/angular';

export const GUEST_ICON_WIDTH = 40;
export enum ItemEvents {
  KIOSK,
  EDIT,
  GUESTS,
  SHARE,
  SAVEQR,
  CREATE_MICROSITE,
  DELETE,
  DETAILS
}

@Component({
  selector: 'app-marketplace-service-items',
  templateUrl: './marketplace-service-items.component.html',
  styleUrls: ['./marketplace-service-items.component.scss'],
})
export class ServiceItemsComponent implements OnDestroy, OnChanges, OnInit {

  @Input('marketserviceitem') marketserviceitem: any;
  @Output('objClicked') objClicked = new EventEmitter<any>();

  hasImage = false;
  imageUrl: string = null;
  partialLeadColor = '#ff9839';
  loaded = false;
  marketserviceitemData: any = {};
  isMobile = false;
  resizeEvent: any;

  constructor(
    private images: ImagesManagementService,
    public common: CommonProvider,
    private utils: UtilsService,
    public plt: Platform
  ) {
    this.loaded = true;
    console.log(this.marketserviceitem);
  }

  isMobileCheck() {
    if (window.innerWidth < 550) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

  ngOnInit() {
    this.marketserviceitem.logoFile = this.marketserviceitem.logoURL;
    this.isMobileCheck();

    this.plt.ready().then(() => {
      this.resizeEvent = this.plt.resize.subscribe(() => {
        this.isMobileCheck();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.marketserviceitem.logoURL && this.marketserviceitem.logoURL !== '') {
      this.hasImage = true;
      this.images.realImageData({ imageURL: this.marketserviceitem.logoURL, imagePath: `guest_${this.utils.hashCode(this.marketserviceitem.logoURL)}` }).then((data) => {
        if (data) {
          this.imageUrl = data;
        } else {
          this.imageUrl = null;
          this.hasImage = false;
        }
      }).catch((error) => {
        this.imageUrl = null;
        this.hasImage = false;
      });
    } else {
      this.hasImage = false;
      this.imageUrl = null;
    }
  }

  ngOnDestroy() {

  }

  navigate(link) {
    this.objClicked.emit('OBJ_CLICKED');
    this.common.openLink(link, '_system');
  }

}
