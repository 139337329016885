import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-analytics-popup',
	templateUrl: 'analytics-popup.html',
	styleUrls: ['analytics-popup.scss']
})

export class AnalyticsPopupComponent {

	constructor(public viewCtrl: PopoverController) {

	}

	go(value, label) {
		this.viewCtrl.dismiss([value, label]);
	}
}
