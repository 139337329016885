import {
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

import connectHits from 'instantsearch.js/es/connectors/hits/connectHits';
import connectRefinementList from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import connectConfigure from 'instantsearch.js/es/connectors/configure/connectConfigure';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';

import {
  AlgoliaFilterHitsFn,
  AlgoliaHit,
  AlgoliaInstanceNames,
  ALGOLIA_CONFIGS,
} from '../../constants/algolia';
import { noop } from '../../../utils/utils';
import { BaseAlgoliaComponent } from '../../../utils/algolia';
import { SendEventForHits } from 'instantsearch.js/es/lib/utils';

@Component({
  selector: 'algolia-automatic-search',
  templateUrl: './algolia-automatic-search.html',
  styleUrls: ['algolia-automatic-search.scss'],
})
export class AlgoliaAutomaticSearch
  extends BaseAlgoliaComponent
  implements OnInit
{
  // Name of the algolia instance to use, that holds information like the index and default refinements
  @Input() instanceName: AlgoliaInstanceNames;
  // Message to display when no results are found
  @Input() noResultsMessage = 'Enter email to invite them';

  @Input() filterHitsFn?: AlgoliaFilterHitsFn;

  @Input() childComponent!: TemplateRef<any>;

  // Algolia related fields
  instance: InstantSearch;
  hits: AlgoliaHit[] = [];
  sendEvent: SendEventForHits;
  lastQueriedPage = -1;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.instance = this.createInstance(this.instanceName);

    const {
      refinements = [],
      searchParameters = {},
      searchOnLoad,
    } = ALGOLIA_CONFIGS[this.instanceName]();

    this.instance.addWidgets([
      connectConfigure(noop)({
        searchParameters,
      }),
      connectHits(({ hits, sendEvent, results }) => {
        this.sendEvent = sendEvent;

        // We track the last queried page to support pagination
        if (results && results.page !== this.lastQueriedPage) {
          this.hits = [...this.hits, ...(hits as any)];
          this.lastQueriedPage = results.page;
        }
      })({}),
      ...refinements.map((refinement) => {
        return connectRefinementList(noop)({
          attribute: refinement,
          operator: 'and',
          limit: 10000,
        });
      }),
    ]);

    if (searchOnLoad) {
      this.search();
    }
  }

  isLastPage(): boolean {
    return (
      this.instance?.helper?.lastResults?.nbPages ===
      this.instance?.helper?.lastResults?.page + 1
    );
  }

  queryNextPage(): void {
    if (this.isLastPage()) {
      return;
    }

    this.instance.helper.nextPage().search();
  }

  displayNoResultsLabel() {
    return this.instance?.status === 'idle' && this.isLastPage();
  }

  filterHits(hits: AlgoliaHit[]): AlgoliaHit[] {
    if (!this.filterHitsFn) {
      return hits;
    } else {
      const validHits = [];

      for (const hit of hits) {
        if (this.filterHitsFn(hit.objectID)) {
          validHits.push(hit);
        }
      }

      return validHits;
    }
  }
}
