import { Injectable } from '@angular/core';
import aa from 'search-insights';

aa('init', {
  appId: 'N26A8PC608',
  apiKey: '068c90fcb4bdad125a840343a2725949'
});

@Injectable({
  providedIn: 'root'
})
export class AlgoliaInsightService {

  constructor() { }

  public sendInsightClick(uid, indexName, event, obj) {
    // SEND ALGOLIA INSIGHT
    aa('clickedObjectIDs', {
      userToken: uid, // required for Node.js
      index: indexName,
      eventName: event,
      objectIDs: [obj]
    });
  }
}
