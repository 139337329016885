/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import AppRate from 'cordova-plugin-apprate';
import { CurbUser, User_global } from '../app.models';
import { AuthService } from './auth.service';
import { IntercomService } from './intercom.service';
import { CommonProvider } from './common';

import { AlertController, Platform } from '@ionic/angular';


declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class AppRatingService {
  userData: CurbUser;

  constructor(
    private intercom: IntercomService,
    private auth: AuthService,
    private common: CommonProvider,
    private alertController: AlertController,
    private platform: Platform
  ) { }

  async showRating() {
    const self = this;

    try {

      console.log('show rating!');

      if (this.platform.is('android')) {

        AppRate.setPreferences({
          reviewType: {
            ios: 'AppStoreReview',
            android: 'InAppBrowser'
          },
          customLocale: {
            title: 'Would you mind rating %@?',
            message: 'It takes just a minute and helps promote our app. Thanks for your support!',
            cancelButtonLabel: 'No, Thanks',
            laterButtonLabel: 'Remind Me Later',
            rateButtonLabel: 'Rate It Now',
            yesButtonLabel: 'Definitely!',
            noButtonLabel: 'Not really',
            appRatePromptTitle: 'Are you happy with %@ so far?',
            feedbackPromptTitle: 'Mind giving us some feedback?',
          },
          storeAppURL: {
            ios: '1455260281',
            android: 'market://details?id=app.blockparty.openhouse',
          },
          callbacks: {
            onButtonClicked: (buttonIndex, buttonLabel) => {
              if (buttonLabel === 'Not really') {
                this.intercom.showFeedbackChat();
              }
              const now = new Date();
              this.auth.updateUserDataProperty(
                {
                  isRatingShown: true,
                  ratingTime: now,
                  ratingOptionSelected: buttonLabel
                }
              );
            }
          }
        });

        return;
      }

      const now = new Date();

      const alert = await this.alertController.create({
        header: 'Are you happy with Curb Hero so far?',
        buttons: [
          {
            text: 'Not really',
            role: 'cancel',
            handler: () => {
              console.log('User is not happy. Showing feedback chat.');

              this.intercom.showFeedbackChat();


              this.auth.updateUserDataProperty(
                {
                  isRatingShown: true,
                  ratingTime: now,
                  ratingOptionSelected: 'Not really'
                }
              );
            }
          },
          {
            text: 'Definitely',
            handler: () => {
              console.log('User is happy. Showing rating prompt.');
              if (window.LaunchReview) {
                if (window.LaunchReview.isRatingSupported()) {
                  window.LaunchReview.rating();
                } else {
                  const appId = '1455260281'; // Replace with your App Store ID
                  window.LaunchReview.launch(appId);
                }

                this.auth.updateUserDataProperty(
                  {
                    isRatingShown: true,
                    ratingTime: now,
                    ratingOptionSelected: 'Definitely'
                  }
                );

              } else {
                console.error('LaunchReview plugin not available');
              }
            }
          }
        ]
      });

      await alert.present();

      //  AppRate.promptForRating(true);
    }

    catch (err) {
      console.log(err);
      this.auth.logClientInfo('error getting app review ' + err);
    }
  }
}
