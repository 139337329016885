import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, IonTextarea } from '@ionic/angular';
const DEFAULT_LIMIT = 255;

@Component({
  selector: 'app-edit-review',
  templateUrl: 'edit-review.html',
  styleUrls: ['edit-review.scss']
})

export class EditReviewPage {

  @ViewChild('ionTextarea') ionTextarea: IonTextarea;
  public inputLabel: any;
  public inputData: any;
  public countdown = 0;
  public limitNum = 255;
  public isDark = true;
  public rows = 2;
  public editLink = false;
  public showTitle = true;
  isDisabled = false;

  constructor(
    private elementRef: ElementRef,
    public plt: Platform,
    public navCtrl: NavController,
    public navParams: NavParams,
    public viewCtrl: ModalController) {

    this.inputLabel = this.navParams.get('label');
    this.inputData = this.navParams.get('data');
    this.limitNum = this.navParams.get('limit');
    this.editLink = this.navParams.get('edit');
    this.limitNum = this.navParams.get('limit') || DEFAULT_LIMIT;
    this.rows = this.navParams.get('rows') || 2;
    this.showTitle = this.navParams.get('showTitle');
    this.limitText();
  }

  limitText() {
    if (this.inputData.reviewSnippet) {

      if (this.inputData.reviewSnippet.length > this.limitNum) {
        this.isDisabled = true;
        this.isDark = false;
        this.countdown = this.limitNum - this.inputData.reviewSnippet.length;

        // this.inputData = this.inputData.substring(0, this.limitNum);
      } else {
        this.isDisabled = false;
        this.countdown = this.limitNum - this.inputData.reviewSnippet.length;
        this.isDark = true;
      }
    } else {
      this.countdown = this.limitNum;
    }
  }

  ionViewDidLoad() {
    setTimeout(() => {
      this.ionTextarea.setFocus();
    }, 1000);
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  done() {
    this.inputData.enabled = true;
    this.inputData.reviewCount = Number(this.inputData.reviewCount);
    this.viewCtrl.dismiss(this.inputData);
  }

  logRatingChange(rating) {
    console.log('changed rating: ', rating);
    this.inputData.reviewStars = rating;
    // do your stuff
  }

  deleteLink() {
    this.viewCtrl.dismiss('delete');
  }

  adjust(): void {
    const textArea = this.elementRef.nativeElement.getElementsByTagName('textarea')[0];
    if (textArea.scrollHeight < 100) {
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    } else {
      textArea.style.overflow = 'scroll';
    }
  }
}
