import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, IonTextarea } from '@ionic/angular';
import { NetworkProvider } from '../../services/network';
import { GUEST_NOTE_PLACEHOLDER } from '../../constants';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-notes',
  templateUrl: 'edit-notes.html',
  styleUrls: ['edit-notes.scss']
})
export class EditNotesPage implements OnInit, AfterViewInit {

  @ViewChild('ionTextarea') ionTextarea: IonTextarea;
  public inputLabel: any;
  public inputData: string;
  public isDark = true;

  /*
  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();

  }
*/

  constructor(
    private elementRef: ElementRef,
    public navCtrl: NavController,
    public navParams: NavParams,
    public plt: Platform,
    public network: NetworkProvider,
    public viewCtrl: ModalController,
    public utils: UtilsService
  ) {
    this.inputLabel = this.navParams.get('label');
    console.log('Nav Params', this.navParams.get('label'));
    if (this.navParams.get('data') === '') {
      this.inputData = '';
    } else {
      this.inputData = this.navParams.get('data');
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  ngAfterViewInit() {


  }

  ngOnInit(): void {
    //  setTimeout(() => this.adjust(), 0);
    setTimeout(() => {
      this.ionTextarea.setFocus();
    }, 1000);
  }

  ionViewDidLoad() {

  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  done() {
    if (this.inputData === '') {
      this.viewCtrl.dismiss(GUEST_NOTE_PLACEHOLDER);
    } else {
      this.viewCtrl.dismiss(this.inputData);
    }
  }

  adjust(): void {
    const textArea = this.elementRef.nativeElement.getElementsByTagName('textarea')[0];
    if (textArea.scrollHeight < 100) {
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    } else {
      textArea.style.overflow = 'scroll';
    }
  }
}
