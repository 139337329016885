/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ImagesManagementService } from '../../services/images-management.service';
import { ListingImage, ProfileImage } from '../../app.models';
import { AuthService } from '../../services/auth.service';

const IMG_MAX_WIDTH = 250;
const IMG_MIN_WIDTH = 50;
/**
 * Generated class for the ThumbnailComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-thumbnail',
  templateUrl: 'thumbnail.component.html',
  styleUrls: ['thumbnail.component.scss']
})

export class ThumbnailComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input('property') property: ListingImage;
  @ViewChild('container') container: ElementRef;
  @Input('label') label: string;
  @Input('existLabel') existLabel: string;
  @Input('type') type: string;
  @Input('image') imageURL: string;
  @Input('profileImage') profileImage: ProfileImage;
  @Input('key') key;
  @Input('aspectRatio') aspectRatio: number;
  @Input('isSuggestion') isSuggestion: any;
  @Input('index') index: any;

  @Output('clicked') clicked = new EventEmitter<boolean>();
  @Output('deleted') deleted = new EventEmitter<boolean>();
  @Output('select') select = new EventEmitter();
  @Output('cancel') cancel = new EventEmitter();
  imgWidth: number = IMG_MAX_WIDTH;
  resizeSubs: Subscription;
  propertyData: any;
  containerHeight = 112;
  deviceAspectRatio: number;
  imageAspectRatio: number;
  loaded = false;
  timeoutScheduler;
  lastURL;
  isLoaded = true;
  mainDivClass;
  saving = false;

  constructor(private platform: Platform,
    private imageService: ImagesManagementService,
    private auth: AuthService) { }

  ngOnInit(): void {
    console.log('THUMBNAIL IS BEING INIT');


    // if (this.profileImage) {
    //   this.loadImage(this.profileImage);
    // }
    // else {
    //   this.loadImage(this.property);
    // }
    if (!this.profileImage) {
      this.mainDivClass = 'container';
    }
    else {
      this.mainDivClass = 'noContainer';
    }
    if (this.isSuggestion) {
      this.mainDivClass = this.mainDivClass + ' suggestion';
    }
    else {
      this.mainDivClass = this.mainDivClass + ' suggestion';
    }
  }

  ngOnChanges(): void {
    console.log('isSuggestion:' + this.isSuggestion);
    console.log('THUMBNAIL IS BEING CHANGED');

    if (this.timeoutScheduler) {
      clearTimeout(this.timeoutScheduler);
    }
    this.timeoutScheduler = setTimeout(() => {
      if (this.profileImage) {
        this.loadImage(this.profileImage);
      }
      else {
        this.loadImage(this.property);
      }
    }, 500);
  }

  handleResize() {
    if (this.container) {
      this.imgWidth = Math.max(IMG_MIN_WIDTH, Math.min(IMG_MAX_WIDTH, this.container.nativeElement.clientWidth));
    }
  }

  ngAfterViewInit() {
    this.resizeSubs = this.platform.resize.asObservable().subscribe(() => {
      this.handleResize();
    });
  }

  ngOnDestroy(): void {
    if (this.resizeSubs && !this.resizeSubs.closed) {
      this.resizeSubs.unsubscribe();
    }
  }

  imgHeight() {
    console.log('returning img height: ' + this.aspectRatio * this.imgWidth);
    return this.aspectRatio * this.imgWidth;
  }

  onClick() {
    this.clicked.emit(this.type === 'intro');
  }

  onDelete(event) {
    event.stopPropagation();
    this.deleted.emit(this.type === 'intro');
  }

  loadImage(img) {
    console.log('load image function called!');
    if (img && (img.imageURL || img.imagePath)) {

      if (img.imageURL === this.lastURL && this.propertyData) {
        return;
      }

      this.lastURL = img.imageURL;

      console.log('load thumbnail image function for. ' + JSON.stringify(img));
      this.imageService.realImageData(img).then((data) => {
        if (data) {
          console.log('get thumbnail real image data result: ' + data);

          // this.imageService.aspectRatio(data).then((ar) => {
          //   console.log('calculated aspect ration: ' + ar);
          //   // this.imageAspectRatio = ar;
          // });
          const now = new Date();
          if (this.profileImage) {
            this.propertyData = data + '/?rand=' + now.getMilliseconds();
            this.imageAspectRatio = 1;
            this.handleResize();
            this.containerHeight = this.imgHeight();
          }
          else {
            //   this.propertyData = data + '/?rand=' + now.getMilliseconds();
            this.propertyData = data;
            this.imageAspectRatio = 0.75;
            this.handleResize();
            this.containerHeight = this.imgHeight();
          }

          this.loaded = true;
        }
        else {
          this.propertyData = null;
          this.loaded = true;
        }
      }).catch((error) => {
        console.log('error finding file.');
        this.propertyData = null;
        this.loaded = true;
      });

    } else {
      this.loaded = true;
      this.imageAspectRatio = 0.75;
      this.handleResize();
      this.containerHeight = this.imgHeight();
      this.propertyData = '';
    }
  }

  loadProfileImage() {
    this.imageService.firebaseProfileUrl(this.profileImage.imageURL).then((data) => {
      this.loaded = true;
      this.propertyData = data;
      this.imageAspectRatio = 1;
      this.handleResize();
      this.containerHeight = this.imgHeight();
    });
  }

  setSuggestion(value, index, event) {
    event.stopPropagation();
    this.saving = true;
    const selectedObject = { selectedValue: value, selectedIndex: this.index, key: 'listingImages', id: this.property.id };
    setTimeout(() => {
      this.select.emit(selectedObject);
    }, 300);
  }

  cancelSuggestion(value, index, event) {
    event.stopPropagation();
    this.saving = true;
    const selectedObject = { selectedValue: value, selectedIndex: this.index, key: 'listingImages', id: this.property.id };
    setTimeout(() => {
      this.cancel.emit(selectedObject);
    }, 300);
  }
}
