/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class I8nService {

  public messages: any;

  constructor() {

    this.messages = {
      colorCodeError: 'Hex color codes are six characters long. Only A-F and 0-9 characters are allowed.',
      colorNotDarkEnough: 'This color is not dark enough and may not be readable',
      usernameTaken: 'Username already taken.',
      enterValidEmail: 'Please enter a valid email address',
      enterValidPasswordLength: 'Password should be a minimum of 8 characters',
      invalidPasswordFormat: 'The password you entered is not allowed. Please select a different password',
      enterValidPhoneNumber: 'Please enter a valid phone number',
      urlIsRequiredToEnableLink: 'A URL is required before enabling this link.',
      listingAddressRequired: 'To save this listing, you\'ll need to enter an address or name.',
      listingMicrositeNotPublished: 'In order to view and share your listing microsite, you will need to publish your microsite.',
      listingEnterAddressBeforePublishing: 'Please create Listing Address before publishing.',
      listingPairedLenderRequired: 'This enhanced feature requires a paired lender. You can enable a Paired Lender within each listing\'s setup.',
      featureNotAvailableOnWeb: 'Some features aren\'t available on our web app when using devices with smaller screens. Try our iOS or Android mobile app which can be downloaded below.',
      featureNotAvailableOnWebAdmin: 'Some features aren\'t available on our web app when using devices with smaller screens',
      errorUploadingTryAgain: 'Error uploading photo. Please try again',
      enterNameOrAddressToLaunchListing: 'To launch this listing, you\'ll need to enter an address or name.',
      listingPairedLenderNotSupportedInRegion: 'Based on the listing address, pairing a lender isn\'t supported in your region yet. If you think your region is supported or you\'ve previously had a paired lender without issues, please re-check the listing address and state to make sure it\'s accurate.',
      listingNeedsPublishingBeforeSharing: 'This action requires that a listing microsite is published. See below for how to set this up.',
      loginEnterEmailAddress: 'Please enter your email address. New users can create an account below.',
      loginPleaseEnterPassword: 'Please enter your password',
      loginAllFieldsRequired: 'All fields are required',
      loginTooManyAttempts: 'Too many unsuccessful attempts. Try again later.',
      serviceUnavailable: 'Service Unavailable',
      loginIssueLoggingYouInDetailed: 'There\'s been an issue logging you in. Please retry and make sure your data connection is active and retry the previous action. If you\'re on a web browser, clear your browser cache and cookies and refresh this page. If this issue persists, use our live support chat below (#999)',
      lenderLeadsProfileNotFound: 'We weren\'t able to match a profile with the contact info entered. That doesn\'t necessarily mean that this isn\'t a legitimate lead.',
      lenderLeadGuestDoesntExistOnAccount: 'This link seems to be invalid. The guest does not exist on your account.',
      lenderProfileDisableSocialLinkToEnableAnother: 'Disable an existing link in order to enable another one.',
      errorUploadingPhoto: 'Error uploading photo. Please try again',
      invalidEmailOrPassword: 'Invalid email or password',
      invalidEmailAccountInUse: 'This email isn’t valid or is in use by another account',
      errorChangingEmail: 'Error changing email',
      myMicrositeDisableExistingLinkToEnableAnother: 'Disable an existing link in order to enable another one.',
      myProfileDisableExistingLinkToEnableAnother: 'Disable an existing link in order to enable another one.',
      logoutConnectionError: 'To prevent any data loss, logging out of your account requires a network connection. Please make sure you are connected to the internet and retry.',
      imageTooBig: 'Maximum image size is 4500 X 3000. Please upload another file that is within both these limits.',
      offlineError: 'This information can\'t be saved because the device is offline. To save this information, first make sure you are connected to the internet and then retry this operation. Or download our mobile application which can be used in offline mode.',
      dbWriteError: 'Our app has detected a connection issue which could result in lost data if not resolved. Please close and reopen the Curb Hero app so we can restore the data connection. If you are unsure how to close the Curb Hero app, look at our Help Section.',
      deviceIsOfflineOpenHouseStillActive: 'Guest Follow Up is enabled and requires a network connection. Your Open House can still be launched without Guest Follow Up, but for this feature to work, turn on cellular data or use Wi-Fi.',
      actionRequiresANetworkConnection: 'This action requires a network connection. Please turn on cellular data or use Wi-Fi and retry.',
      signupNameMinimumCharacters: 'Name should be minimum of 4 characters',
      signupEmailRestrictedContactSupport: 'This email is restricted. Please contact support.',
      signupWeakPassword: 'Please use a stronger password.',
      signupEmailInUseLoginHint: 'This email address is already associated with an account. Are you trying to login? Otherwise you can dismiss this message and create an account with another email address.',
      signupCreateUserDBError: 'There\'s been an issue. It\'s not your fault, but please make sure your data connection is active and retry the previous action. If you\'re on a web browser, try clearing your cache and cookies and then refresh this page. If this issue persists, use our live support chat below (#999)',
      signupPasswordNotAllowed: 'The password you entered is not allowed. Please select a different password',
      pendingUpdatesTurnOnDataConnection: 'There are updates in progress for Curb Hero. Please turn on cellular data or use Wi-Fi.',
      inAppBrowserPluginIssue: 'There may have been an issue. It\'s not your fault, but please open this page in your mobile device\'s main browser.',
      invitationLinkUnrecognised: 'This invitation link couldn\'t be recognized. Please verify the exact case and spelling with the user that sent it.',
      reviewLinkLimitReached: 'Only two review platforms can be displayed. To enable a new platform, first disable one of the enabled platforms.',
      listingNeedsPublishingBeforeSharingLender: 'This action requires that your paired agent publishes the microsite for this listing. See below for more info about this.',
      lowDiskSpaceWarning: 'It appears your device is running low on storage space and our app is not able to save data. This can result in lead data being loss if your device goes offline. To avoid data loss, we highly recommend freeing up storage space on your device so data can be saved properly.',
      partialEntriesAlert: 'Enabling "Partial Entries" will include leads that didn\'t complete all questions in our app\'s on screen digital Open House Sign-in.  Note: Text follow up and data integrations are disabled for partial entries.',
      takeoverInfo: 'Making a listing a Full Screen Takeover promotes the listing above all other My Link content. We recommend this for active listings or Open Houses especially when using the Dynamic QR Code. See our Help Section for details.',
      deleteMicrositeLink: 'Are you sure you want to delete this link?',
      takeoverLinkConfirm: 'Are you sure you want to update the Full Screen Takeover?',
      programReferralInfo: 'Our referral program rewards you for sharing Curb Hero. This requires a separate account to be created on our referral platform. For more info, FAQs, or troubleshooting steps visit our Help Section.',
      realestateagentInfo: 'Other Agents can scan this code to sign-in on your listing microsite. If you join our referral program, you\'ll get rewarded every time an agent joins Curb Hero using the follow up text we send them!',
      qrcodeInfo: 'Scanning this QR Code opens the My Link page. You can update it anytime so prospects land on the most relevant listings or content and you can stop juggling multiple QR codes in your marketing.',
      disabledActionMessage: 'This action can only be performed by the paired agents.',
      disabledActionMessageAgentAdmin: 'This function can only be performed by the paired agents. Admin users can access agent accounts to use this function. For more info, visit the Help Section.',
      pairedAgentInfo: 'This listing will appear in the agent\'s account. The agent can launch an Open House Sign-in, publish the listing microsite, edit any of the listing info, or delete the listing entirely.  Note: Once a listing is paired with an agent, you can\'t un-pair them.',
      pairedAgentModalMessage: 'This listing can\'t be edited because it was created by another user, but you can still use our marketing services for this listing.',
      signupNameInvalidCharacters: 'Please remove invalid characters',
      takeoverOptionsUnavailable: 'Additional options for the listing aren\'t available until the listing is enabled to be displayed.',
      newAppVersion: 'There is a new app version available. Would you like to update?',
      editAddressInfoAdmin: 'Once assigned, the primary agent for a listing can\'t be changed. You can change the other assigned agents on a listing. All assigned agents can:',
      editAddressInfoAdminHeader: ' The Role of a Primary Agent',
      primaryAgentInfoTitle1: 'The Role of a Primary Agent',
      primaryAgentInfoMessage1: 'Your Team Admin selected another agent as the primary agent for this listing. A primary agent has control over the paired lender on the listing. However you still are able to:',
      primaryAgentInfoTitle2: 'Editing Lender is Disabled',
      primaryAgentInfoMessage2: 'Because your Team Admin selected another agent as the primary agent for this listing, their paired lender is assigned to this listing. You are not able to edit or disable the paired lender. However you still are able to:',
      addChoicesMessage: 'Choices are displayed to visitors in a multiple choice question format. To get more info from a visitor about their choice, select "Choice with Details". See the Help Section below to learn more.',
      withoutMortgageQuestionLeadsFilter: 'Leads that didn\'t opt-in to having their information shared with a lender won\'t include any contact info. Note: data integrations are disabled for leads without Opt-in',
      teamAdminLockedFeatureMessage: 'Your Team Admin has limited edits to this feature.',
      teamAdminLockedFeatureHeader: 'Admin Disabled Edits',
      disclosureEditingDisabled: 'Editing disclosures here is currently disabled but edits can be requested by emailing support@curbhe.ro',
      disclosureExportDisabled: 'A bulk export of signed disclosures can be requested by emailing support@curbhe.ro',

    };
  }
}
