/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { ListingImage } from '../app.models';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';

import { AuthService } from './auth.service';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { AlertController, Platform } from '@ionic/angular';
import Downscale from 'downscale';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { IntercomService } from './intercom.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';


import { getStorage, ref, getDownloadURL, deleteObject } from '@firebase/storage';
import { getAuth } from '@firebase/auth';
import { uploadBytesResumable } from '@firebase/storage';
import { API } from '../constants';

export const ORIENTATION_PORTRAIT = 'portrait';
export const ORIENTATION_LANDSCAPE = 'landscape';
export const ORIENTATION_EVEN = 'even';
const MIN_DOWNSCALE_BLOB = 60 * 1000;
const DOKA_PATH = 'DOKA/';

declare const cordova;

@Injectable({
  providedIn: 'root'
})
export class ImagesManagementService {

  public imageData: string;
  private imagesPath: string;
  private imageDataCache: any;
  private photoAlert: any;
  private currentUser;
  public currentImageUploadProgress = -1;

  constructor(
    private fileService: File,

    private auth: AuthService,
    private imagePicker: ImagePicker,
    private platform: Platform,
    private transfer: FileTransfer,
    private intercomService: IntercomService,
    private alertControl: AlertController,
    private openNativeSettings: OpenNativeSettings,
    private network: Network
  ) {
    this.setImagesPath(environment.imagesPath);
    this.imageDataCache = {};
  }

  randomId() {
    return Math.random().toString(36).substring(2);
  }

  resizeImage(b64): Promise<string> {
    return new Promise((resolve, reject) => {
      this.image(b64).then((img: HTMLImageElement) => {
        const IMAGE_SIZE_LIMIT = 16000000;
        let targetWidth = img.width;
        let targetHeight = img.height;
        if (img.width * img.height > IMAGE_SIZE_LIMIT) {
          reject('limit');
        } else {
          if (targetWidth * targetHeight > 3 * 1024 * 1024) {
            targetWidth = 2048;
            targetHeight = targetWidth * (img.height / img.width);
            Downscale(b64, targetWidth, targetHeight, { returnBlob: true, imageType: 'jpeg', quality: 1 }).then((blob) => {
              if (blob.size < MIN_DOWNSCALE_BLOB) {
                resolve(b64);
              } else {
                resolve(blob);
              }
            });
          } else {
            resolve(b64);
          }
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  firebaseImageUrl(fileName: string, imageUrl: string): Promise<string> {
    console.log('firebaseImageUrl called.');
    return new Promise((resolve, reject) => {
      console.log('trying to load: ' + imageUrl);
      if (imageUrl.startsWith('/') || imageUrl.startsWith('assets') || imageUrl.startsWith('https://img.fullcontact')) {
        resolve(imageUrl);
      } else if (this.imageDataCache) {
        const cacheUrl = this.imageDataCache[fileName];
        if (cacheUrl) {
          console.log('cacheUrl exists: ' + cacheUrl);
          resolve(cacheUrl);
          return;
        } else {

          console.log('firebaseImageUrl called with ' + imageUrl);
          this.firebaseUrl(imageUrl).then((url) => {
            // this.imageDataCache[imageUrl] = url;
            console.log('firebaseImageUrl: ' + imageUrl);
            console.log('url to save: ' + url);

            resolve(url);
          }).catch((error) => {
            console.log('err in firebaseImageUrl ' + error);
            reject(error);
          });
        }
      }
    });
  }

  public async firebaseUrl(path: string): Promise<any> {
    const storage = getStorage();
    let storageRef;
    if (path.startsWith('http')) {
      storageRef = ref(storage, path.toString());
      return getDownloadURL(storageRef);
    }

    if (path.startsWith('pdfs')) {
      storageRef = ref(storage, path.toString());
      return getDownloadURL(storageRef);
    }

    if (path.indexOf('profile') > -1) {
      storageRef = ref(storage, `${API.users_endpoint}/${path.toString()}`);
      return getDownloadURL(storageRef);
    }

    storageRef = ref(storage, `${API.listings_endpoint}/${path.toString()}`);
    return getDownloadURL(storageRef);
  }

  public firebaseProfileUrl(path: string): Promise<any> {
    const storage = getStorage();
    const storageRef = ref(storage, `${API.users_endpoint}/${path}`);
    return getDownloadURL(storageRef);
  }

  cacheImage(url: string, fileName, override = true): Promise<any> {

    const self = this;
    return new Promise((resolve, reject) => {

      if (!self.imageDataCache) {
        self.imageDataCache = {};
      }

      if (!self.platform.is('cordova')) {
        resolve(url);
        return;
      }

      if (url.startsWith('assets') || url.startsWith('ionic') || url === '') {
        console.log('invalid url not caching: ' + url);
        resolve(url);
        return;
      }

      if (url.startsWith('file')) {
        console.log('url is FILE resolving: ' + self.convertFileSrc(url));
        if (self.imageDataCache && fileName) {
          self.imageDataCache[fileName] = self.convertFileSrc(url);
        }
        resolve(self.convertFileSrc(url));
        return;
      }

      if (url.startsWith('https://localhost') || url.startsWith('http://localhost')) {
        console.log('url is local host resolving: ' + url);
        if (self.imageDataCache && fileName) {
          self.imageDataCache[fileName] = url;
        }
        resolve(url);
        return;
      }

      console.log('cache image called with: ' + url + ' for file: ' + fileName);
      console.log(`start cache for ${fileName}`);

      const path = self.fileService.dataDirectory + '' + fileName;

      if (this.platform.is('cordova')) { // android download
        const fileTransfer: FileTransferObject = self.transfer.create();
        console.log('download url : ' + url + ' path ' + path);
        fileTransfer.download(url, path).then((entry) => {
          // prints the filename
          console.log('cordova download complete for ' + url);
          console.log('cordova download complete ' + path);
          console.log(entry.name);

          if (self.imageDataCache && fileName) {
            console.log('caching data under: ' + fileName + ' path: ' + self.convertFileSrc(path));
            self.imageDataCache[fileName] = self.convertFileSrc(path);
          }

          console.log('fullPath: ' + entry.fullPath);
          console.log('convert file src: ' + self.convertFileSrc(path));
          resolve(self.convertFileSrc(path));
          // prints the filePath
        }, err => {
          console.log('error in file download cordova!' + JSON.stringify(err));

          reject(err);
        });
      }
    });
  }

  convertFileSrc(url) {
    const win: any = window;
    return win.Ionic.WebView.convertFileSrc(url);
  }


  getImageWidth(imageData: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        resolve(width);
      };
      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };
      img.src = imageData;
    });
  }


  /**
   * Retrieves real image data based on the provided image object.
   *
   * @param image - The image object.
   * @param highRes - A boolean indicating whether to retrieve high-resolution image data (default: false).
   * @returns A Promise that resolves to the image URL as a string.
   */
  realImageData(image: any, highRes: boolean = false): Promise<string> {

    console.log('realImageData called for image: ' + JSON.stringify(image));
    const self = this;
    return new Promise((resolve, reject) => {

      if (!self.imageDataCache) {
        self.imageDataCache = {};
      }
      let path: string;

      if (self.platform.is('cordova')) {
        path = self.fileService.dataDirectory;
      }
      else {
        path = '';
      }

      if (!image) {
        console.log('No image data, resolving empty.');
        resolve('');
        return;
      }

      // Local logos are stored in assets
      if (image && image.imageURL && image.imageURL.startsWith('assets')) {
        console.log('Image is a local logo! Returning assets.');
        resolve(image.imageURL);
        return;
      }

      if (image && image.imageURL === '' && image.imagePath === '') {
        console.log('No image data, resolving empty.');
        resolve('');
        return;
      }

      let fileParts: string[] = (image.imagePath || '').split('/');

      if (!highRes && image.thumbnailURL) {
        fileParts = (image.thumbnailURL).split('/');
      }

      const fileName: string = fileParts[fileParts.length - 1];
      console.log('\nPath from realImageData is ' + path);
      console.log('\nFileName: ' + fileName);
      console.log('\nimage.imageURL: ' + image.imageURL);
      console.log('\nimage.thumbnailURL: ' + image.thumbnailURL);
      console.log('\nimage: ' + JSON.stringify(image));

      // For native devices
      if (path && fileName) {
        const cacheData = this.imageDataCache[fileName];
        console.log('cacheData: ' + cacheData);
        if (cacheData) {
          console.log(`Loaded image from cache: ${cacheData}`);
          resolve(cacheData);
          return;
        }
        else {
          console.log(`Check file ${path}${fileName}`);
          this.fileService.checkFile(path, fileName).then(success => {
            console.log('FILE IS CACHED. GETTING FILE...');
            console.log('Check file pass: ' + success);
            console.log('Check dir path is: ' + path);
            console.log('File in question: ' + fileName);
            this.fileService.resolveDirectoryUrl(path).then((dir) => {
              console.log('Resolve dir: ' + dir);
              this.fileService.getFile(dir, fileName, {}).then((file) => {
                console.log('Get file: ' + file);

                const url = this.convertFileSrc(file.nativeURL);
                console.log(`Got file ${url}`);
                this.imageDataCache[fileName] = url;

                resolve(url);
                return;
              });
            });
          }).catch(e => {
            console.log('Error in check file: ' + JSON.stringify(e));

            let bestImageURL;

            if (highRes) {
              bestImageURL = image.imageURL;
            }
            else {
              bestImageURL = image.thumbnailURL || image.imageURL;
            }
            console.log('Best URL is: ' + bestImageURL);
            if (image && image.imageURL) {
              this.firebaseImageUrl(image.imagePath, bestImageURL).then((url) => {
                console.log('In catch statement, need to cache: ' + url + ' for ' + fileName);
                if (fileName) {
                  this.cacheImage(url, fileName);
                }
                resolve(url);
                return;
              }).catch((error) => {
                console.log('2nd catch error in Firebase image URL: ' + error);
                reject(error);
                return;
              });
            }
            else {
              reject('not_found');
              //resolve('not found');
              return;
            }
          });
        }
      } else if (image && image.imageURL) {
        // console.log('Getting image from the web! ' + image.imageURL);
        let bestImageURL;

        if (highRes) {
          bestImageURL = image.imageURL;
        }
        else {
          bestImageURL = image.thumbnailURL || image.imageURL;
        }
        console.log('Best URL is: ' + bestImageURL);
        this.firebaseImageUrl(image.imagePath, bestImageURL).then((url) => {

          this.cacheImage(url, fileName);
          resolve(url);
          return;
        }).catch((error) => {
          console.log('Error getting URL: ' + error);
          reject(error);
        });
      } else {
        console.log('imageURL is missing.');
        resolve('');
        return;
      }
    });
  }


  createImageFromBase64(image: ListingImage) {
    if (((image || {}).base64 || '').length > 0) {
      const fileExtension = image.base64.substring('data:image/'.length, image.base64.indexOf(';base64'));
      const path = this.fileService.dataDirectory;
      const fileName = image.imagePath ? image.imagePath : this.generateImageID(25) + '.' + fileExtension;
      const fullPath = path + fileName;

      return new Promise((resolve, reject) => {
        if (image.base64) {
          const realData = image.base64.split(',')[1];
          const blob = this.b64toBlob(realData, 'image/' + fileExtension);
          this.fileService.writeFile(path, fileName, blob)
            .then(() => {
              resolve(fullPath);
            })
            .catch((err) => {
              console.log('error writing blob');
              reject(err);
            });
        } else { resolve({ id: '', base64: '', imagePath: '' }); }
      });
    } else {
      return Promise.resolve({ id: '', base64: '', imagePath: '' });
    }
  }

  /**
   * Convert a data URI to a Blob object.
   *
   * @param dataURI - The data URI to convert.
   * @returns The converted Blob object.
   */
  dataURItoBlob(dataURI) {
    // Extract the byte string and MIME type from the data URI
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Create an ArrayBuffer to hold the binary data
    const ab = new ArrayBuffer(byteString.length);

    // Create a Uint8Array view to manipulate the ArrayBuffer
    const ia = new Uint8Array(ab);

    // Convert each character in the byte string to its corresponding ASCII code and store it in the Uint8Array
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // Create a Blob object from the ArrayBuffer with the specified MIME type
    return new Blob([ab], { type: mimeString });
  }


  toDataUrl(url, type = 'image/jpeg', quality = 1): Promise<string> {
    return new Promise((resolve, reject) => {
      this.image(url).then((img) => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL(type, quality);
        resolve(dataURL);
      }).catch((error) => {
        reject(error);
      });
    });
  }


  imgToUrl(img, type = 'image/jpeg', quality = 1): Promise<string> {
    console.log('img to url called');
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL(type, quality);
      resolve(dataURL);
    });
  }

  urlToData(url): Promise<string> {

    console.log('urlToData called with ' + url);
    return new Promise((resolve, reject) => {

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onerror = (error) => {
        reject(error);
      };

      (img.onload = () => {
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL());
      });

      img.src = url;
    });
  }

  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]); let n = bstr.length; const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  removeImage(imagePath: any) {

    console.log('REMOVE IMAGE: ' + imagePath);
    return new Promise((resolve, reject) => {
      if (imagePath) {
        if (this.platform.is('cordova')) {

          if (this.imageDataCache && this.imageDataCache[imagePath]) {
            this.imageDataCache[imagePath] = null;
          }

          const path = this.fileService.dataDirectory;
          // const fileName = imagePath.split(path)[1];
          const fileName = imagePath;
          console.log('fileName to delete: ' + fileName);
          this.fileService.removeFile(path, fileName).then(() => {
            console.log('removed file succesfully');
            resolve(true);
          }).catch((error) => {
            console.log('error removing file: ' + error);
            reject(false);
          });
        }
        // remove file from storage
      }
      resolve(true);
    });
  }

  checkImagePath(image: ListingImage) {
    return new Promise((resolve, reject) => {

      if (!this.platform.is('cordova')) {
        resolve(image);
      }

      if (!image.base64 && !image.imagePath) {
        resolve(image);
      }
      if (image.imagePath) {
        const fileName = image.imagePath.split(DOKA_PATH)[1];
        const path = image.imagePath.split(DOKA_PATH)[0] + DOKA_PATH;
        this.fileService.checkFile(path, fileName).then(success => {
          // file exists
          resolve(image);
        }).catch(e => {
          // file does not exist
          this.createImageFromBase64(image).then(result => {
            resolve({ imagePath: result, base64: image.base64 });
          }).catch(err => {
            reject(err);
          });
        });
      } else if (image.base64) {
        this.createImageFromBase64(image).then(result => {
          resolve({ imagePath: result, base64: image.base64 });
        }).catch(err => {
          reject(err);
        });
      }
    });
  }

  image(imageURL: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onerror = () => {
        const error = new Error(`Failed to load image from URL: ${imageURL}`);
        reject(error);
      };
      img.onload = () => {
        resolve(img);
      };
      img.src = imageURL;
    });
  }


  getImageOrientationAsync(imageURL: string): Promise<any> {

    if (!imageURL) {
      return Promise.resolve(ORIENTATION_EVEN);
    }
    let imageUrl = Promise.resolve(imageURL);
    if (!imageURL.startsWith('http')) {
      imageUrl = this.firebaseUrl(imageURL);
    }
    return imageUrl.then((url) => new Promise((resolve) => {
      let orientation = ORIENTATION_EVEN;
      const img = new Image();
      img.src = url;

      img.onerror = () => {
        resolve(ORIENTATION_EVEN);
      };
      img.onload = () => {
        if (img.width > img.height) {
          orientation = ORIENTATION_LANDSCAPE;
        } else if (img.width < img.height) {
          orientation = ORIENTATION_PORTRAIT;
        }
        resolve(orientation);
      };
    }));
  }

  aspectRatio(imageURL: any): Promise<number> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageURL;
      img.onload = () => {
        resolve(img.height / img.width);
      };
    });
  }

  public uploadListingPhotoWeb(photoId: string, listingId: string, file, type: string): Promise<string> {
    console.log('uploading listing photo web called for' + JSON.stringify(listingId));

    return new Promise((resolve, reject) => {

      const photoPath = listingId + '/' + photoId;
      console.log('photoPath is : ' + photoPath);
      this.uploadImage(photoPath, type, file).then((url) => {

        console.log(`end uploading photo for web ${listingId}`);
        resolve(url.toString());

      }).catch((error) => {
        console.log('err uploading web photo ' + error);
        this.auth.logErrors('Error uploading listing photo web 1: ' + error + ' err code ' + error.code, true);
        reject('');
      });
      //  });
    });
  }

  public uploadProfilePhotoWeb(photoId: string, fileExt: string, file): Promise<string> {
    console.log('uploading profile photo web called for' + photoId);

    return new Promise((resolve, reject) => {

      const photoPath = 'profile' + '/' + photoId;
      console.log('photoPath is : ' + photoPath);
      this.uploadImage(photoPath, fileExt, file).then((url) => {

        console.log(`end uploading photo for web ${photoId}`);
        resolve(url.toString());

      }).catch((error) => {
        console.log('err uploading web photo ' + error);
        this.auth.logErrors('Error uploading profile photo web 1: ' + error + ' ', true);
        reject('');
      });
    });
  }

  public uploadProfileLogoWeb(photoId: string, fileExt: string, file): Promise<string> {
    console.log('uploading profile logo web called for' + photoId);

    return new Promise((resolve, reject) => {

      const photoPath = 'logo' + '/' + photoId;
      console.log('logo path is : ' + photoPath);
      this.uploadImage(photoPath, fileExt, file).then((url) => {

        console.log(`end uploading photo for web ${photoId}`);
        resolve(url.toString());

      }).catch((error) => {
        console.log('err uploading web photo ' + error);
        this.auth.logErrors('Error uploading profile logo web 1: ' + error + ' ', true);
        reject('');
      });

    });
  }

  public checkPhotoPermission() {
    return new Promise((resolve, reject) => {

      if (this.platform.is('cordova')) {
        this.imagePicker.hasReadPermission().then((result) => {
          console.log('hasReadPermission result: ' + JSON.stringify(result));
          if (result) {
            console.log('result: ' + result);
            resolve(result);
          }
          else {

            this.imagePicker.requestReadPermission().then((res) => {
              console.log('requestReadPermission result: ' + JSON.stringify(res));
              console.log('res: ' + res);
              resolve(res);
            }, (error) => {
              console.log('error: ' + error);
              this.handlePermissionError(error);

              this.auth.logErrors('Error in image permission 1: ' + error + ' ', false);
              reject(error);
            });

          }
        }, (error) => {
          console.log('error: ' + error);
          this.auth.logErrors('Error in image permission 2: ' + error + ' ', false);
          reject(error);
        });
      }
      else {
        reject('');
      }

    });
  }

  handlePermissionError(error) {
    console.log('case: ' + error);

    switch (error) {
      case 'ACCESS_DENIED':
        this.promptForPermissionChange();
        // code block
        break;
      case 'ACCESS_NOT_DETERMINED':
        if (this.network.type !== null && this.network.type !== 'none') {
          this.intercomService.displayIntercomMessage('21610727');
        }
        else {
          this.selectPhoto();
        }
        // code block
        break;
      default:
        this.promptForPermissionChange();
        break;
    }
  }

  async promptForPermissionChange() {

    if (!this.photoAlert) {

      this.photoAlert = await this.alertControl.create({
        header: 'Give Curb Hero Access to Photos',
        message: 'You\'ll need to change this in your device\'s settings. Look for the toggle labeled "Photos."',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: data => {
              this.photoAlert = false;
            }
          },
          {
            text: 'Settings',
            handler: data => {
              this.openNativeSettings.open('application_details');
              this.photoAlert = false;

            }
          }
        ]
      });
      await this.photoAlert.present();
    }
  }

  public generateImageID(length): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  // UPLOAD LISTING QR CODE
  uploadQRCodeBloblListing(blob, fileName, override = false) {

    return new Promise((resolve, reject) => {

      this.uploadImage(fileName, 'qr', blob).then((res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject(null);
        }
      }).catch((err) => {
        this.auth.logErrors('Error uploading qr code 1: ' + err + ' ', true);
        reject(err);
      });
    });
  }

  uploadQRCodeBlobProfile(blob: Blob, fileName: string, override = false) {

    return new Promise((resolve, reject) => {

      this.uploadImage(fileName, 'qr_profile', blob).then((res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject();
        }
      }).catch((err) => {
        reject(err);
        this.auth.logErrors('Error uploading qr code 1: ' + err + ' ', true);
      });
    });
  }

  // get image for upload
  getBlobFromImage(img): Promise<Blob> {
    return new Promise((resolve, reject) => {
      fetch(img).then(body => {

        body.blob().then((blob) => {
          resolve(blob);
        });
      });
    });
  }

  /**
   * Resize an image to a specified width and return it as a Blob.
   *
   * @param imageBlob The Blob object representing the image to resize.
   * @param targetWidth The desired width of the resized image.
   * @returns A Promise that resolves with the resized image Blob.
   */
  resizeImageToWidth(imageBlob: Blob, targetWidth: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const scaleFactor = targetWidth / img.width;
        const targetHeight = img.height * scaleFactor;

        // Set the canvas dimensions to the target size
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Draw the image onto the canvas with the desired width
        context.drawImage(img, 0, 0, targetWidth, targetHeight);

        // Convert the canvas image to a Blob
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            console.log('Failed to resize the image');
            reject(new Error('Failed to resize the image.'));
          }
        }, 'image/jpeg', 1.0);
      };

      img.onerror = (error) => {
        console.log('error in resize: ' + error);
        reject(error);
      };

      img.src = URL.createObjectURL(imageBlob);
    });
  }

  toJPEGDataURI(base64Str) {
    return 'data:image/jpeg;base64,' + base64Str;
  }

  // https://stackoverflow.com/questions/12168909/blob-from-dataurl
  dataURIToBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async getImageDataURL(imageUrl) {
    console.log('getImageDataURL', imageUrl);

    try {
      // Fetch the image from the given URL
      const response = await fetch(imageUrl);

      // Check if the response status is OK (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Return a promise that resolves to the Data URL
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();

        // When the FileReader finishes reading, resolve the promise with the result
        reader.onloadend = () => resolve(reader.result as string);

        // If there is an error during reading, reject the promise
        reader.onerror = () => reject(new Error('Error reading the Blob as Data URL'));

        // Read the Blob as a Data URL
        reader.readAsDataURL(blob);
      });

    } catch (error) {
      // Handle any errors that occur during the fetch and blob operations
      console.error('Error fetching or converting image data:', error);
      throw new Error('Error fetching or converting image data: ' + error.message);
    }
  }


  blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;
        resolve(dataURL);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async objectUrlToImageData(objectUrl: string): Promise<string | null> {
    try {
      const response = await fetch(objectUrl);
      const blob = await response.blob();

      return new Promise<string | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const imageData = reader.result as string;
          resolve(imageData);
        };
        reader.onerror = () => {
          reject(new Error('Error reading image data.'));
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching object URL:', error);
      throw error;
    }
  }

  selectPhoto(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.imagePicker.getPictures(<any>{ maximumImagesCount: 1, outputType: 0, disable_popover: true })
        .then((result) => {

          console.log('image results: ' + JSON.stringify(result));
          if (result.length === 0) {
            console.log('image results is zero');
            reject(null);
          }
          else {
            const path = this.convertFileSrc(result[0]);

            this.imageData = path;
            resolve(path);
          }
        }, (err) => {
          this.auth.logErrors('Error selecting photo: ' + err + ' ', true);
          console.log(err);
          reject(err);
        });
    });
  }

  protected getImagesPath() {
    return this.imagesPath;
  }

  protected setImagesPath(path: string) {
    this.imagesPath = path;
  }

  protected b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  /**
  * Converts a data URL to a Blob object.
  *
  * @param dataURL - The data URL representing the image.
  * @returns The Blob object.
  */
  dataURLToBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }


  /**
   * Uploads an image to the specified filepath.
   *
   * @param filepath - The filepath to upload the image to.
   * @param type - The type of the image.
   * @param imageData - The image data to upload.
   * @returns A Promise that resolves when the upload is complete.
   */
  async uploadImage(filepath: string, type: string, imageData: any): Promise<string> {
    console.log(`upload image called: ${filepath} ${type}`);

    const auth = getAuth();

    // Ensure the user is authenticated
    if (!auth.currentUser) {
      alert('User not authenticated. Cannot upload image.');
      throw new Error('User not authenticated. Cannot upload image.');
    }

    if (!this.currentUser || !this.currentUser.uid) {
      this.currentUser = { uid: auth.currentUser?.uid };
    }

    // Determine the root URL based on the type
    const rootURL = this.getRootURL(type);

    if (!rootURL) {
      throw new Error('Failed to determine root URL based on image type.');
    }

    const storage = getStorage();
    const storageRef = ref(storage, `${rootURL}/${this.currentUser.uid}/${filepath}_${type}`);
    const newMetadata = { cacheControl: 'public,max-age=533280' };

    try {
      const uploadTask = this.createUploadTask(type, storageRef, imageData, newMetadata);

      console.log(`Path to upload to: ${storageRef.fullPath}`);

      // Await the upload task completion
      await this.monitorUploadTask(uploadTask);

      // Get the download URL after successful upload
      const downloadURL = await getDownloadURL(storageRef);
      setTimeout(() => { this.currentImageUploadProgress = -1; }, 1000);

      return `${this.currentUser.uid}/${filepath}_${type}`;
    } catch (error) {
      this.handleUploadError(error, filepath, type);
      throw error;
    }
  }


  private createUploadTask(type: string, storageRef, imageData: any, metadata) {
    if (type === 'qr' || type === 'qr_profile') {
      return uploadBytesResumable(storageRef, imageData);
    }
    const blob = this.dataURLToBlob(imageData);
    return uploadBytesResumable(storageRef, blob, metadata);
  }

  private monitorUploadTask(uploadTask): Promise<void> {
    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.currentImageUploadProgress = Math.round(progress);
        console.log(`Upload is ${progress}% done`);
        if (snapshot.state === 'paused') {
          console.log('Upload is paused');
        } else if (snapshot.state === 'running') {
          console.log('Upload is running');
        }
      }, reject, resolve);
    });
  }

  private handleUploadError(error: any, filepath: string, type: string): void {
    console.error('Error uploading file:', error);
    this.auth.logErrors(`Error uploading file: ${error.message || JSON.stringify(error)} fileName: ${filepath}_${type}`, true);
  }


  private getRootURL(type: string): string {
    if (['property', 'propertyThumbnail', 'qr'].includes(type)) {
      return API.listings_endpoint;
    } else if (['profile', 'profileThumbnail', 'logo', 'qr_profile'].includes(type)) {
      return API.users_endpoint;
    }
    throw new Error(`Unknown type: ${type}`);
  }

  deleteImage(filepath): Promise<any> {

    return new Promise((resolve, reject) => {
      console.log('call to delete : ' + filepath);
      if (!filepath || filepath === '') {
        resolve(false);
      }
      // Create a reference to the file to delete
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `${API.listings_endpoint}/${filepath}`);
        const imgDelete = storageRef;

        // Delete the file
        deleteObject(imgDelete)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
        console.log('error deleting image: ' + err);
      }
    });
  }

  deleteProfileImage(filepath): Promise<any> {

    console.log('call to delete : ' + filepath);
    return new Promise((resolve, reject) => {
      // Create a reference to the file to delete
      try {

        const storage = getStorage();
        const storageRef = ref(storage, `${API.users_endpoint}/${filepath}`);
        const imgDelete = storageRef;

        // Delete the file
        deleteObject(imgDelete)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject();
        console.log(err);
      }
    });
  }
}
