import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, IonInput } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { CommonProvider } from '../../services/common';

@Component({
  selector: 'app-edit-microsite',
  templateUrl: 'edit-microsite.html',
  styleUrls: ['edit-microsite.scss']
})
export class EditMicrositePage implements OnInit {

  @ViewChild('priceInputID') priceInputID: IonInput;
  @ViewChild('input') myInput: IonInput;
  @ViewChild('txtBedrooms') txtBedrooms: IonInput;
  @ViewChild('txtBathrooms') txtBathrooms: IonInput;
  @ViewChild('txtSquareFeet') txtSquareFeet: IonInput;
  public inputLabel: any;
  public inputData: any;
  public isDark = true;
  isDisabled = false;
  sanitizeInterval: any;

  constructor(public plt: Platform,
    public navCtrl: NavController,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public common: CommonProvider,
    public utils: UtilsService
  ) {

    this.inputLabel = this.navParams.get('label');
    this.inputData = this.navParams.get('data');

    console.log('constructor edit microsite page');
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }


  ngOnInit() {

    // we need to delay our call in order to work with ionic ...
    setTimeout(() => {
      this.priceInputID.setFocus();
    }, 500);
  }


  dismiss() {
    this.viewCtrl.dismiss();
  }


  done() {
    this.viewCtrl.dismiss(this.inputData);
  }

  sanitizeKeyUp(type) {
    let regex = new RegExp(`[^0-9]`, 'g');

    if (type === 'price') {
      console.log('sanitize price is: ' + this.inputData.price);
      this.inputData.price = this.inputData.price.replace(regex, '');
    }
    if (type === 'beds') {
      console.log('sanitize beds is: ' + this.inputData.beds);
      this.inputData.beds = this.inputData.beds.replace(regex, '');
    }
    if (type === 'baths') {
      console.log('sanitize baths is: ' + this.inputData.baths);
      regex = new RegExp(`[^0-9.-]`, 'g');
      this.inputData.baths = this.inputData.baths.replace(regex, '');
    }
    if (type === 'squareFootage') {
      console.log('sanitize squareFootage is: ' + this.inputData.squareFootage);
      this.inputData.squareFootage = this.inputData.squareFootage.replace(regex, '');
    }
  }

  goto(txtId: string) {
    switch (txtId) {
      case 'txtBedrooms':
        return this.txtBedrooms.setFocus();
      case 'txtBathrooms':
        return this.txtBathrooms.setFocus();
      case 'txtSquareFeet':
        return this.txtSquareFeet.setFocus();
      case '##save##': return this.done();
    }
  }
}
