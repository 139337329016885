/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
export const Colors = [
  '32c12c', '009888', '3e49bb', '092045', '000000',
  '87c735', '0fe1e1', '00a5f9', '469dbd', '272727',
  'fd51ff', 'ff9a00', 'd13c2b', '7c5547', '50342c',
  'ff97b2', 'ff5500', 'a82817', '4c2549', '9a999a'
];

export const UserColors = [
  '000000', '092045', '151515', '272727', '373737', '553827', '713624', '935934', '00334c', '161c1b', '1d253b', '262e2d', '2b0f12', '37403f', '40474f', '492d32', '4c2005', '4f2e65', '50342c', '5b5b63', '68786b', '696a6f', '7c5547', '8c8c82', '92959b', '9a999a', 'a28171', 'afa999', 'b19259', 'b29700', 'b6a38f', 'bababd', 'bd804a', 'bd9b17', 'be7f5e', 'c4b157', 'cac2b1', 'cdb8ab', 'cdd0d3', 'd0ccc5', 'd3af37', 'd66b67', 'd7d0ca', 'd8c8b9', 'dd7c1b', 'de965d', 'e2ddda', 'e6a17d', 'e8a144', 'eab003', 'f6d13a', 'fee4d7'
];
