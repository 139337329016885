/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
	selector: '[myNumberOnly]'
})
export class NumberOnlyDirective {

	@Input() decimal: boolean;

	el;
	inputElement;

	constructor(el: ElementRef) {
		this.el = el;
		this.inputElement = el.nativeElement;
	}

	@HostListener('keypress')
	onkeypress(e) {
		const event = e || window.event;
		if (event) {
			return this.isNumberKey(event);
		}
	}

	forecastValue(key) {
		if (!this.inputElement) {
			return null; // or handle the error in an appropriate way
		}

		const selectionStart = this.inputElement.selectionStart;
		const selectionEnd = this.inputElement.selectionEnd;
		const oldValue = this.inputElement.value;

		if (!oldValue) {
			return;
		}

		if (typeof oldValue !== 'string' || oldValue === '') {
			return null;
		}

		return oldValue.substring(0, selectionStart) +
			key +
			oldValue.substring(selectionEnd);
	}

	isNumberKey(event) {
		const charCode = event.which ? event.which : event.keyCode;
		if (this.decimal) {
			console.log('this.decimal: ' + this.decimal + ' charCode ' + charCode);
			const newValue = this.forecastValue(event.key);

			if (!newValue) {
				return;
			}
			if (newValue.split('.').length > 2 && charCode === 46) {
				return false;
			}

			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				return false;
			}
		}
		else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
}
